import { useState } from 'react'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Form, Input, Result, Spin } from 'antd'
import { apiRequestChangeEmail, apiRequestChangePhone } from '../../../utils/api'

export const RequesData = ({ type, setopen }) => {

  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false)
  const [success, setsuccess] = useState(false)
  const [password, setpassword] = useState('')
  const [failedPassword, setfailedPassword] = useState(false)
  const [email, setemail] = useState('')
  const [failedEmailFormat, setfailedEmailFormat] = useState(false)
  const [failedEmail, setfailedEmail] = useState(false)
  const [phone, setphone] = useState('')
  const [failedPhoneFormat, setfailedPhoneFormat] = useState(false)
  const [failedPhone, setfailedPhone] = useState(false)
  const [alreadySent, setalreadySent] = useState(false)


  const requestChangeEmail = () => {
    setloading(true)
    apiRequestChangeEmail(password, email)
      .then(response => {
        console.log(response)
        seterror(false)
        setsuccess(true)
        setfailedPassword(false)
        setpassword('')
      }).catch(error => {
        console.log(error)

        if (error.response.data === 'Error en la contraseña') {
          setfailedPassword(true)
        } else if (error.response.status === 409) {
          setfailedEmail(true)
        } else {
          seterror(true)
        }
        if (error.response.data === 'Ya se ha enviado un codigo de verificacion activo ') {
          setalreadySent(true)
        }

      }).finally(() => {
        setloading(false)
      })
  }

  const requestChangePhone = () => {
    setloading(true)
    apiRequestChangePhone(phone)
      .then(response => {
        console.log(response)
        seterror(false)
        setsuccess(true)
        setfailedPassword(false)
        setpassword('')
      }).catch(error => {
        if (error.response.status === 409) {
          setfailedPhone(true)
        } else {
          seterror(true)
        }
        if (error.response.data === 'Ya se ha enviado un codigo de verificacion activo ') {
          setalreadySent(true)
        }
        console.log(error)
      }).finally(() => {
        setloading(false)
      })
  }

  const handleClose = () => {
    setpassword('')
    setopen({ isOpen: false, type: '' })
    seterror(false)
    setsuccess(false)
    setfailedPassword(false)
  }

  const handlePasswordChange = (event) => {
    setalreadySent(false)
    setfailedPassword(false)
    setpassword(event.target.value)
  }

  const handlePhone = (event) => {
    setfailedPhoneFormat(false)
    setfailedPhone(false)
    setphone(event.target.value)
    const re = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/

    if (event.target.value.length > 0) {
      if (re.test(event.target.value)) {
        setfailedPhoneFormat(false);
      } else {
        setfailedPhoneFormat(true);
      }
    }
  }

  const handleEmail = (event) => {
    setfailedEmail(false)
    setfailedEmailFormat(false)
    setemail(event.target.value)
    const re =
      /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

    if (event.target.value.length > 0) {

      if (re.test(event.target.value)) {
        setfailedEmailFormat(false);
      } else {
        setfailedEmailFormat(true);
      }
    }
  }


  return (
    <Spin spinning={loading}>
      {
        (!error && !success) &&
        <Result
          icon={type === 'phone' ? <PhoneOutlined style={{ color: '#666' }} /> : <MailOutlined style={{ color: '#666' }} />}
          title={`Cambio de ${type.includes('email') ? 'correo electrónico' : 'numero de teléfono'}`}
          subTitle={`Solicitar cambio de ${type.includes('email') ? 'correo electrónico' : 'numero de teléfono'}`}
          extra={[
            <div key='containerForm'>
              {
                type.includes('email') &&
                <Form.Item
                  validateStatus={failedPassword && 'error'}
                  help={
                    failedPassword ? 'Contraseña incorrecta' : 'Contraseña requerida'

                  }
                  style={{ marginBottom: 20 }}
                >
                  <Input.Password placeholder='Contraseña actual' onChange={handlePasswordChange} value={password} />
                </Form.Item>
              }
              <Form.Item
                validateStatus={(failedEmailFormat || failedPhoneFormat || failedEmail || failedPhone) && 'error'}
                help={
                  failedEmail ? 'Este correo electrónico ya se encuentra registrado'
                    : failedEmailFormat ? 'Correo electrónico inválido'
                      : failedPhone ? 'Este numero de teléfono ya se encuentra registrado'
                        : failedPhoneFormat ? 'Numero de teléfono inválido'
                          : type.includes('email') ? 'Ingrese el nuevo correo electrónico' : 'Ingrese el nuevo numero de teléfono'
                }
                style={{ marginBottom: 20 }}
              >
                <Input placeholder={type.includes('email') ? 'Nuevo correo electrónico' : 'Nuevo numero de teléfono'} onChange={type.includes('email') ? handleEmail : handlePhone} value={type.includes('email') ? email : phone} />
              </Form.Item>

              <Button type='primary'
                disabled={
                  (!password && type.includes('email')) ||
                  ((!email || failedEmailFormat || failedEmail) && type.includes('email')) ||
                  ((!phone || failedPhoneFormat || failedPhone) && type.includes('phone'))
                }
                onClick={type.includes('email') ? requestChangeEmail : requestChangePhone}>
                Solicitar cambio
              </Button>
              <Button type='link' onClick={handleClose}>
                Cancelar
              </Button>
            </div>
          ]}
        />
      }
      {
        (error || alreadySent) &&
        <Result
          status='error'
          title='Error'
          subTitle={`No se pudo solicitar el cambio de datos ${alreadySent ? 'debido a que ya se ha enviado un código de verificación a esta cuenta' : ''}`}
          extra={[
            <Button type='primary' key='3' onClick={handleClose}>
              Cerrar
            </Button>,
            <Button type='link' key='4' onClick={type.includes('email') ? requestChangeEmail : requestChangePhone}>
              Reintentar
            </Button>,
          ]}
        />
      }
      {
        success &&
        <Result
          status='success'
          title='Solicitud enviada'
          subTitle={
            type === 'email'
              ? 'Se ha enviado un correo electrónico con las instrucciones para cambiar el correo'
              : type === 'addemail'
                ? 'Se ha enviado un mensaje de texto con las instrucciones para agregar un correo'
                : 'Se ha enviado un mensaje de texto con las instrucciones para cambiar el numero de teléfono'
          }
          extra={[
            <Button type='primary' key='5' onClick={handleClose}>
              Aceptar
            </Button>,
          ]}
        />
      }
    </Spin>
  )
}
