import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'snackbar',
    initialState: {
        snackbarData: {
            open: false,
            type: '',
            pathName: '',
            severity: 'info'
        }
    },
    reducers: {
        update(state, action) {
            state.snackbarData = action.payload;
        },
    }
});

export { actions as snackbarActions };
export { reducer as snackbarReducer };