import { Button, Result } from 'antd'
import React, { useState } from 'react'
import { apiDeleteDependencies } from '../../../../utils/api'
import { FAILURE, REQUEST, SUCCESS } from './Dependencies'

export const DeleteDependency = ({ data, handleClose }) => {
    const [loading, setloading] = useState(false)
    const [resultStatus, setresultStatus] = useState(REQUEST)

    const handleSubmit = () => {
        setloading(true)
        apiDeleteDependencies(data.id)
            .then(res => {
                setresultStatus(SUCCESS)
            }).catch(err => {
                setresultStatus(FAILURE)
            })
            .finally(() => {
                setloading(false)
            })
    }


    return (
        <div>
            {
                resultStatus === REQUEST &&
                <Result
                    status='info'
                    subTitle={`¿Esta seguro de eliminar la dependencia ${data.name}?`}
                    extra={[
                        <Button
                            loading={loading}
                            key='delete'
                            type='link'
                            onClick={handleSubmit}
                        >Eliminar</Button>
                    ]}
                />
            }
            {
                resultStatus === SUCCESS &&
                <Result
                    status='success'
                    title='Dependencia eliminada correctamente'
                    subTitle={`La dependencia ${data.name} ha sido eliminada correctamente`}
                    extra={[
                        <Button
                            key='register'
                            type='primary'
                            onClick={handleClose}
                        >Cerrar</Button>
                    ]}
                />
            }
            {
                resultStatus === FAILURE &&
                <Result
                    status='error'
                    title='Ha ocurrido un error'
                    subTitle={`Ha ocurrido un error al eliminar registrar la dependencia`}
                    extra={[
                        <Button
                            key='try'
                            type='link'
                            onClick={() => setresultStatus(REQUEST)}
                        >Reintentar</Button>,
                        <Button
                            key='close'
                            type='primary'
                            onClick={handleClose}
                        >Cerrar</Button>
                    ]}
                />
            }

        </div>
    )
}