import { Checkbox, Input, Modal, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { ImagesReport } from './ImagesReport'
import { TypeReport } from './TypeReport'
import { Button, Steps } from 'antd';
import { apiCreateReport, apiCreateReportCRM, apiReportsById } from '../../../../utils/api'
import { openMessage } from '../../Utils/MessageDisplay'
// import { useSelector } from 'react-redux'
import { ConfirmColony } from './ConfirmColony'
import { LocationReport } from './LocationReport';
import { useHistory } from 'react-router-dom';
const { Step } = Steps;

export const CreateReport = () => {

    // const { user } = useSelector(state => state.session)

    const idReportReopen = sessionStorage.getItem('reopenFolio')
    const [details, setdetails] = useState({})
    const [dependency, setdependency] = useState('')
    const [postCode, setpostCode] = useState('')
    const [open, setopen] = useState(false)
    const [loading, setloading] = useState(false)
    const [dataReport, setDataReport] = useState({
        description: "",
        latitude: 28.1833,
        longitude: -105.467,
        address: "",
        // userId: null,
        reportTypeId: 0,
        statusId: 1,
        notificationTypeId: 1,
        idColonia: '',
        createBy: null,
        mediaOutlets: false,
        noTracking: false
    })
    const [userData, setuserData] = useState({
        name: '',
        lastName: '',
        phone: '',
        rolesId: [4],
        email: null,
        dependencyId: null
    })
    const [failed, setfailed] = useState({
        description: true,
        latitude: false,
        longitude: false,
        address: false,
    })
    const [stateImg, setstateImg] = useState({
        img1: null,
        img2: null,
        img3: null,
        img4: null,
    })
    const [urls, seturls] = useState({
        img1: null,
        img2: null,
        img3: null,
        img4: null,
    })
    const [found, setfound] = useState(false)
    const [images, setImages] = useState([])

    const handleChange = (e, name) => {
        setDataReport(prev => ({
            ...prev,
            [name]: e
        }))
    }

    const handelCheckMedio = (e) => {
        setDataReport(prev => ({...prev,  mediaOutlets: e.target.checked, noTracking: e.target.checked}))
    }
    
    const handelNoCRM = (e) => {
        setDataReport(prev => ({...prev, noTracking: e.target.checked}))
    }

    const steps = [
        {
            title: 'Datos del reporte',
            content:
                <>
                    <TypeReport idReport={idReportReopen} found={found} setfound={setfound} userData={userData} setuserData={setuserData} setDataReport={setDataReport} dataReport={dataReport} handleChange={handleChange} setdependency={setdependency} dependency={dependency} />
                    <div style={{ marginTop: 24, display: 'flex', flexDirection: 'column' }}>
                        <Typography.Text > Descripción </Typography.Text>
                        <Input.TextArea
                            value={dataReport.description}
                            style={{ marginTop: 5, width: '50%' }}
                            row={4}
                            autoSize={{ minRows: 6, maxRows: 8 }}
                            onChange={(e) => {
                                if (e.target.value.length > 0) {
                                    setfailed(prev => ({ ...prev, description: false }))
                                    handleChange(e.target.value, 'description')
                                } else {
                                    handleChange(e.target.value, 'description')
                                    setfailed(prev => ({ ...prev, description: true }))
                                }
                            }}
                        />
                    </div>
                    <div style={{marginTop: '1rem'}}>
                        <Checkbox style={{borderRadius: 0}} onChange={handelCheckMedio} checked={dataReport.mediaOutlets}>Medio De Comunicación</Checkbox>
                    </div>
                    <div style={{marginTop: '1rem'}}>
                        <Checkbox style={{borderRadius: 0}} onChange={handelNoCRM} checked={dataReport.noTracking}>Sin Seguimiento</Checkbox>
                    </div>
                </>
        },
        {
            title: 'Imágenes ',
            subTitle: '(Opcional)',
            content: <ImagesReport idReport={idReportReopen} images={images} setImages={setImages} setstate={setstateImg} state={stateImg} seturls={seturls} urls={urls} />,
        },
        {
            title: 'Ubicación',
            content: <LocationReport idReport={idReportReopen} dataReport={dataReport} setDataReport={setDataReport} setpostCode={setpostCode} />,
            // content: <MapReport dataReport={dataReport} setDataReport={setDataReport} setpostCode={setpostCode}/>,
        },
    ];

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const clearData = () => {
        setCurrent(0)
        setImages([])
        setstateImg({
            img1: null,
            img2: null,
            img3: null,
            img4: null,
        })
        seturls({
            img1: null,
            img2: null,
            img3: null,
            img4: null,
        })
        setDataReport({
            description: "",
            latitude: 28.1833,
            longitude: -105.467,
            address: "",
            userId: null,
            reportTypeId: 0,
            statusId: 1,
            notificationTypeId: 1,
            idColonia: '',
            mediaOutlets: false,
            noTracking: false
        })
        setuserData({
            name: '',
            lastName: '',
            phone: '',
            rolesId: [4],
            email: null,
            dependencyId: null
        })
        setCurrent(0)
        setfound(false)
    }

    const history = useHistory()
    
    const handleSubmit = () => {
        if (dataReport.description.length > 0 && dataReport.latitude !== 0 && dataReport.longitude !== 0 && dataReport.address.length > 0) {
            setloading(true)
            let report = dataReport

            if (idReportReopen) {
                report.reportTypeId = details.servicesDto.id
                apiCreateReportCRM(details.idOrigin ? details.idOrigin : idReportReopen, details.photos.length > 0 ? details.photos.filter(i => !i.flag).map(i => i.id) : null, report).then(res => {
                    if (res.status === 200) {
                        sessionStorage.removeItem('reopenFolio')
                        openMessage({ type: 'success', message: `Reporte con folio ${res.data.id} reabierto correctamente del folio ${res.data.idOrigin ? res.data.idOrigin : res.data.id}` })
                        history.push('/crm')
                    }
                }).catch(err => {
                    openMessage({ type: 'error', message: 'Error al crear reporte' })
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                    setopen(false)
                })
            } else {

                apiCreateReport(images.length > 0 ? images : [], report).then(res => {
                    if (res.status === 200) {
                        openMessage({ type: 'success', message: `Reporte creado correctamente con el folio ${res.data.id}` })
                        clearData()
                    }
                }).catch(err => {
                    openMessage({ type: 'error', message: err.response.data })
                    console.log(err)
                }).finally(() => {
                    setloading(false)
                    setopen(false)
                })
            }

        }
    }

    useEffect(() => {

        if (!idReportReopen) return

        apiReportsById(idReportReopen).then(res => {
            // let data = res.data.reports[0]
            let data = []
            if(res.data.length > 1){
                data = res.data.find(i => i.id === idReportReopen)
            } else {
                data = res.data[0]
            }
            console.log(data)
            setdetails(data)
            setfound(true)
            setfailed(prev => ({ ...prev, description: false }))
            setuserData({
                name: data?.createBy?.name.split(' ')[0],
                lastName: data?.createBy?.name.split(' ')[1],
                phone: data?.createBy?.phone,
                rolesId: [0],
                email: data?.createBy?.email ?? '',
                dependencyId: 0
            })
            setdependency(data?.servicesDto?.dependency?.name)
            setDataReport({
                createBy: data?.createBy?.id,
                description: data?.description,
                latitude: data?.latitude,
                longitude: data?.longitude,
                address: data?.address,
                userId: null,
                reportTypeId: data?.servicesDto?.name,
                statusId: 1,
                notificationTypeId: 1,
                idColonia: data?.colonia?.id,
                isCreateByCapturist: data.isCreateByCapturist,
                mediaOutlets: data.mediaOutlets,
                noTracking: data.noTracking ?? false
            })

            if (data.photos.length > 0) {
                data.photos
                    .filter(i => !i.flag)
                    .forEach((item, index) => seturls(prev => ({ ...prev, [`img${index + 1}`]: item.url })))
            }


        }).catch(err => {
            new Error()
        })

    }, [idReportReopen])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#FFF', borderRadius: 15, marginTop: 15, padding: 15 }}>
            <Steps current={current}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} subTitle={item.subTitle ?? ''} />
                ))}
            </Steps>
            <div className='location' style={{ margin: 30, height: '70vh', overflowY: 'auto', overflowX: 'hidden', paddingTop: 30 }}>{steps[current].content}</div>
            <div>
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={next} disabled={failed.description || !dataReport.reportTypeId || dataReport.createBy === null}>
                        Siguiente
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button disabled={!dataReport.address} type="primary" onClick={() => postCode ? setopen(true) : handleSubmit()}>
                        Crear
                    </Button>
                )}
                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={prev}
                    >
                        Anterior
                    </Button>
                )}
            </div>
            <Modal
                visible={open}
                footer={null}
                closable={false}
                onCancel={() => setopen(false)}
            >
                <ConfirmColony setOpen={setopen} postCode={postCode} setDataReport={setDataReport} dataReport={dataReport} handleSubmit={handleSubmit} loading={loading} setopen={setopen} />

            </Modal>
        </div>
    )
}
