import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'menu',
    initialState: {
      name: 'Dashboard'
    },
    reducers: {
          updateMenuName(state, action) {
            state.name = action.payload;
          },
    }
  });

export { actions as menuActions };
export { reducer as menuReducer };