import { useEffect, useState } from 'react'
import { Divider, Select, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx'
import { PieChart } from '../../charts/PieChart';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { apiReportsByMonths } from '../../../utils/api';

export const ReportsMonth = () => {

    const [reportsTotal, setReportsTotal] = useState(0)
    const [date, setdate] = useState({
        month: [parseInt(moment().format('M'))],
        year: parseInt(moment().format('YYYY'))
    })
    const [lengths, setLengths] = useState({
        Pendientes: 0,
        Proceso: 0,
        Revisión: 0,
        Solucionados: 0
    });
    const [loadingReports, setloadingReports] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (date.month.length <= 0) {
            setReportsTotal(0)
            setLengths(prev => (
                {
                    ...prev,
                    Pendientes: 0,
                    Proceso: 0,
                    Revisión: 0,
                    Solucionados: 0,
                }))

            return

        }
        setloadingReports(true)

        apiReportsByMonths(date.year, date.month.join(',')).then(res => {
            let data = res.data

            setReportsTotal(data.length)
            setLengths(prev => (
                {
                    ...prev,
                    Pendientes: data.filter(i => i.status.id === 1).length,
                    Proceso: data.filter(i => i.status.id === 2).length,
                    Revisión: data.filter(i => i.status.id === 3).length,
                    Solucionados: data.filter(i => i.status.id === 4).length
                }))
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloadingReports(false)
        })

        setTimeout(() => {
            setRefresh(!refresh)
        }, [120000])

    }, [date, refresh])

    const onChangeMonth = (data) => {
        setdate(prev => ({ ...prev, month: data }))
    }

    return (
        <Spin spinning={loadingReports}>
            <div style={{ backgroundColor: '#fff', borderRadius: 5, padding: 10, height: 350 }}>
                <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '50%' }}>
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 18 }}>Reportes totales por meses <span style={{ fontSize: 14, opacity: .7 }}>({reportsTotal} reportes)</span></Typography.Text>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: '40%' }}>
                        <Select
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{ width: '100%' }}
                            allowClear
                            size='small'
                            value={date.month}
                            onChange={onChangeMonth}
                            dropdownRender={menu => (
                                <>
                                    <Space style={{ padding: '0 8px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <DoubleLeftOutlined onClick={() => setdate(prev => ({ ...prev, year: prev.year - 1 }))} />
                                        {date.year}
                                        <DoubleRightOutlined style={{ opacity: date.year >= parseInt(moment().format('YYYY')) ? .7 : 1 }} onClick={() => date.year >= parseInt(moment().format('YYYY')) ? null : setdate(prev => ({ ...prev, year: prev.year + 1 }))} />
                                    </Space>
                                    <Divider style={{ margin: '8px 0' }} />
                                    {menu}
                                </>
                            )}
                        >
                            <Select.Option value={1} key={1}>Enero</Select.Option>
                            <Select.Option value={2} key={2}>Febrero</Select.Option>
                            <Select.Option value={3} key={3}>Marzo</Select.Option>
                            <Select.Option value={4} key={4}>Abril</Select.Option>
                            <Select.Option value={5} key={5}>Mayo</Select.Option>
                            <Select.Option value={6} key={6}>Junio</Select.Option>
                            <Select.Option value={7} key={7}>Julio</Select.Option>
                            <Select.Option value={8} key={8}>Agosto</Select.Option>
                            <Select.Option value={9} key={9}>Septiembre</Select.Option>
                            <Select.Option value={10} key={10}>Octubre</Select.Option>
                            <Select.Option value={11} key={11}>Noviembre</Select.Option>
                            <Select.Option value={12} key={12}>Diciembre</Select.Option>
                        </Select>
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 12 }}>Seleccione los meses</Typography.Text>
                        {/* <Typography.Text style={{ color: '#6D6D6D', fontSize: 12, marginTop: 10 }}>Del {moment(fromDate).format('DD/MM/YYYY')} al {moment(toDate).format('DD/MM/YYYY')}</Typography.Text> */}
                    </div>
                </section>
                {
                    Object.values(lengths).some(item => item > 0)
                        ? <PieChart lengths={lengths} labelsPostCode={Object.keys(lengths)} seriesPostCode={Object.values(lengths)} height={250} />
                        : <PieChart lengths={lengths} labelsPostCode={[]} seriesPostCode={[]} height={250} />
                }

            </div>
        </Spin>
    );
}
