import { Tabs } from 'antd'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';
import { ReportsContainer } from './Reports/ReportsContainer';

const { TabPane } = Tabs;

export const RequestsPage = () => {

  const { user } = useSelector(state => state.session)
  const [updated, setupdated] = useState(false)

  const tabsPanels = [
    {
      title: 'Reportes',
      key: 1,
      content: <ReportsContainer setupdated={setupdated} updated={updated}/>,
      onlyAdmin: false,
    },
  ]

  const allowTab = (value) => {
    let allow = true
    if(value){
      if(isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)){
        allow = true
      }else{
        allow = false
      }
    }else{
      allow = true
    }

    return allow
  }

  return (
    <div style={{ backgroundColor: '', width: '95%' }}>
      <Tabs type="card" >
        {
          tabsPanels.map(tab => (
                allowTab(tab.onlyAdmin) &&
                <TabPane tab={tab.title} key={tab.key}>
                  <div style={{ backgroundColor: '#FFF', marginTop: -16, padding: 20, paddingTop: 30, borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                    {tab.content}
                  </div>
                </TabPane>
          ))
        }
      </Tabs>
    </div>
  )
}
