import { Button, Form, Input, Result } from 'antd'
import React, { useState } from 'react'
import { apiCreateDependencies } from '../../../../utils/api'
import { FAILURE, REQUEST, SUCCESS } from './Dependencies'

export const CreateDependency = ({ handleClose }) => {

  const [name, setname] = useState('')
  const [loading, setloading] = useState(false)
  const [resultStatus, setresultStatus] = useState(REQUEST)

  const handleChange = (e) => {
    setname(e.target.value)
  }

  const handleSubmit = () => {
    setloading(true)
    apiCreateDependencies({ name })
      .then(res => {
        setresultStatus(SUCCESS)
      }).catch(err => {
        setresultStatus(FAILURE)
      })
      .finally(() => {
        setloading(false)
      })
  }


  return (
    <div>
      {
        resultStatus === REQUEST &&
        <div>
          <Form.Item
            validateStatus={resultStatus === FAILURE ? 'error' : ''}
            help={
              'Ingrese el nombre de la dependencia'
            }
          >
            <Input
              value={name}
              onChange={handleChange}
            />

          </Form.Item>

          <Button disabled={!name} loading={loading} type='primary' style={{ marginTop: 15 }} onClick={handleSubmit}>Guardar</Button>
        </div>
      }
      {
        resultStatus === SUCCESS &&
        <Result
          status='success'
          title='Dependencia registrada correctamente'
          subTitle={`La dependencia ${name} ha sido registrada correctamente`}
          extra={[
            <Button
              key='register'
              type='primary'
              onClick={() => {
                setname('')
                handleClose()
              }}
            >Cerrar</Button>
          ]}
        />
      }
      {
        resultStatus === FAILURE &&
        <Result
          status='error'
          title='Ha ocurrido un error'
          subTitle={`Ha ocurrido un error al intentar registrar la dependencia`}
          extra={[
            <Button
              key='try'
              type='link'
              onClick={() => setresultStatus(REQUEST)}
            >Reintentar</Button>,
            <Button
              key='close'
              type='primary'
              onClick={handleClose}
            >Cerrar</Button>
          ]}
        />
      }

    </div>
  )
}