import React from 'react'
import { CRMTable } from './CRMTable'
import { TabsPage } from '../../Tabs/TabsPage'
import { CRMDashboard } from './Dashboard/CRMDashboard'

export const CRMPage = () => {
    const tabsPanels = [
        {
            title: 'Tablero',
            key: 1,
            content: <CRMDashboard />,
            onlyAdmin: false,
        },
        {
            title: 'Tabla',
            key: 2,
            content: <CRMTable />,
            onlyAdmin: false,
        },
    ]


    return (
        

            <TabsPage tabsPanels={tabsPanels} />
    )
}
