import { Button, Result } from 'antd'
import React from 'react'

export const ResponseForm = ({ response, closeSuccess, close }) => {
    return (
        <div>
            {
                response.success &&
                <div>
                    <Result
                        title='Registro Exitoso'
                        subTitle='Se le ha enviado un código de verificación a su teléfono y/o correo electrónico para que pueda establecer su contraseña'
                        status='success'
                        extra={[
                            <Button type="primary" key="console" onClick={closeSuccess}>
                                Continuar
                            </Button>,
                        ]}
                    />
                </div>
            }
            {
                response.error &&
                <div>
                    <Result
                        title='Error de Registro'
                        subTitle='Ha ocurrido un error al intentar registrar '
                        status='error'
                        extra={[
                            <Button type="primary" key="console" onClick={close}>
                                Aceptar
                            </Button>,
                        ]}
                    />
                </div>
            }
        </div>
    )
}
