import React from 'react'
import styled from 'styled-components'
import { useScreenSize } from '../../../hooks/useScreenSize'

const Container = styled.div`
    text-align: justify;
    padding: .5rem;
    width: ${props => props.fullWidth ? '90vw' : '50vw'};

    .container-text{
        height: 60vh;
        overflow-y: auto;
    }
    
    h2{
        font-size: 1.5rem;
        font-weight: 900;
    }
    
    .sub-title{
        font-size: 1.2rem;
        font-weight: 900;
        margin-top: 1.5rem;
    }
`

export const TermsConditions = () => {
    const { width } = useScreenSize()

    return (
        <Container fullWidth={width < 700}>
            <h2>
                Términos y condiciones
            </h2>
            <div className='container-text'>
                <div className='sub-title'>
                    Política de privacidad
                </div>

                <p>
                    Nuestra política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando utiliza nuestra aplicación móvil. MVP Technology Solutions creó esta aplicación móvil como una aplicación gratuita y está diseñado para ser utilizado tal cual. Esta aplicación móvil se utiliza para informar a los visitantes sobre nuestras políticas de recopilación, uso y divulgación de información personal cuando eligen utilizar nuestro Servicio. Si elige utilizar nuestro servicio, acepta la recopilación y el uso de información en relación con esta política. La información personal que recopilamos se utiliza para proporcionar y mejorar el servicio.

                    No usaremos ni compartiremos su información con nadie excepto como se describe en esta política de privacidad.
                </p>
                <div className='sub-title'>
                    Información que recopilamos y usamos
                </div>
                <p>

                    Para una mejor experiencia, mientras usamos nuestra aplicación móvil, podemos pedirle que nos proporcione cierta información de identificación personal, que incluye, entre otros, nombre, teléfono, correo electrónico. La información que recopilamos incluye identificadores únicos, tipo y configuración del dispositivo, sistema operativo, información de la red móvil, como el nombre del operador y el número de teléfono, y el número de versión de la aplicación. También recopilamos información sobre la interacción de sus aplicaciones, navegadores y dispositivos con nuestros servicios, como la dirección IP, los informes de fallas, la actividad del sistema y la fecha, la hora y la URL de referencia de su solicitud.

                    No compartimos su información personal con ningún servicio de terceros.
                </p>

                <div className='sub-title'>
                    Proveedores de servicio
                </div>
                <p>

                    Es posible que empleemos a empresas e individuos de terceros, sin embargo queremos informar al usuario que, con el fin de realizar únicamente las siguientes tareas asignadas a nuestro nombre:
                </p>
                <ul>
                    <li>
                        Facilitar nuestro servicio
                    </li>
                    <li>
                        Proporcionar el servicio en nuestro nombre
                    </li>
                    <li>
                        Realizar asistencia relacionada con el servicio
                    </li>
                    <li>

                        Ayudarnos a analizar cómo se utiliza nuestro servicio.
                    </li>
                </ul>
                <p>
                    Así mismo estos terceros están obligados a no divulgar ni utilizar la información proporcionada por el usuario para ningún otro fin que no este listado dentro de los anteriormente listados.
                </p>

                <div className='sub-title'>
                    Seguridad
                </div>
                <p>
                    Valoramos su confianza al proporcionarnos su información personal. Por lo tanto, nos esforzamos por utilizar medios comercialmente aceptables para protegerlo. Pero recuerda que ningún método de transmisión por Internet, o método de almacenamiento electrónico, es 100% seguro y confiable, y no podemos garantizar su seguridad absoluta.
                </p>

                <div className='sub-title'>
                    Cambios a esta política de privacidad
                </div>
                <p>

                    Es posible que actualicemos nuestra política de privacidad de vez en cuando. Por lo tanto, se le recomienda revisar esta página periódicamente para ver si hay cambios. Le notificaremos de cualquier cambio realizado en esta página.
                </p>

                <div className='sub-title'>
                    Contáctenos
                </div>
                <p>
                    Si tienes alguna duda o sugerencia sobre nuestra política de privacidad, no dudes en contactarnos a <a href="mailto: contact@mvpts.com.mx">contact@mvpts.com.mx</a>
                </p>
            </div>
        </Container >

    )
}
