import React, { useRef, useState } from 'react';
import * as yup from 'yup'
import { Input, Button, Select, Checkbox } from 'antd'
import { ErrorMessage, Formik } from 'formik';
import styled from "styled-components";
// import { TermsConditions } from '../TermsConditions';
import { LoadingOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { apiCreateProject, apiGetColonysVotes, apiGetPostcode } from '../../../../utils/api';
import { openMessage } from '../../Utils/MessageDisplay';
import { prMain } from '../../../../themeConfig';

export const ErrorMessageForm = styled.div`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;

`

export const ErrorMessageField = styled(ErrorMessage)`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;
`
//Función para validar una CURP

const formSchema = yup.object().shape({
    postCode: yup
        .string()
        .matches(/^[0-9]+$/, "Ingrese solo numeros")
        .min(5, 'Formato de código postal Inválido')
        .max(5, 'Formato de código postal Inválido'),
});

export const CreateProject = ({ getUserData }) => {

    const { width, height } = useScreenSize()
    const inputRef = useRef(null);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState({
        curp: false,
        postCode: false
    })

    const [isSchool, setisSchool] = useState(false)
    const [isGeneral, setisGeneral] = useState(false)

    const clearInput = () => {
        document.getElementById('anuencia').value = "";
        document.getElementById('project').value = "";
        document.getElementById('attached1').value = "";
        document.getElementById('attached2').value = "";
        document.getElementById('letter').value = "";
    }

    const handleSubmit = (values, setValues) => {

        if(!colony || error.postCode || !anuencia || !project || !attached_first || !attached_second || (isSchool && !letter)){

            openMessage({ type: 'warning', message: 'Porfavor ingrese todos los datos requeridos' })

            return
        }

        const { projectName, address, postCode } = values
        setloading(true)
        apiGetPostcode(postCode).then(res => {

            let budget = {
                projectName,
                address,
                idPostcode: res.data.id,
                idColonia: colony,
                isProjectGeneral: isGeneral,
                idPeriod: 1
            }

            apiCreateProject(budget, anuencia, attached_first, attached_second, project, isSchool ? letter : null).then(res => {
                console.log(res, 'res')
                setValues(prev => ({...prev, projectName: '', address: '', postCode: ''}))
                openMessage({ type: 'success', message: 'Se ha creado el proyecto correctamente' })
                setanuencia(null)
                setproject(null)
                setattached_first(null)
                setattached_second(null)
                setletter(null)

                setcolonys([])
                setcolony('')
                setisSchool(false)
                setisGeneral(false)
                clearInput()


            }).catch(err => {
                new Error(err)
                console.log(err, 'err')
                // openMessage({ type: 'error', message: 'Ha ocurrido un error al registrar el proyecto intente nuevamente' })

            }).finally(() => {
                setloading(false)
            })

        }).catch(err => {
            if (err.response.status === 404) {
                seterror(prev => ({ ...prev, postCode: true }))
            } else if (err.response.status === 404) {
                seterror(prev => ({ ...prev, curp: true }))
            } else {
                openMessage({ type: 'error', message: 'Ha ocurrido un error al buscar las colonias del codigo postal' })

            }
            setloading(false)
            new Error(err)
        })

    }

    const [colonys, setcolonys] = useState([])
    const [colony, setcolony] = useState('')
    const [loadingColonys, setloadingColonys] = useState(false)

    const getColonys = (postCode, colonyId) => {
        setloadingColonys(true)
        apiGetColonysVotes(postCode).then(res => {
            if (res.data.length > 0) {
                setcolonys(res.data)

                if (colonyId) {
                    setcolony(colonyId)
                }
            } else {
                seterror(prev => ({ ...prev, postCode: true }))

            }
        }).catch(err => {
            new Error(err)
            seterror(prev => ({ ...prev, postCode: true }))
        }).finally(() => {
            setloadingColonys(false)
        })

    }

    const handleChangeColony = (value) => {
        setcolony(value)
    }

    const [anuencia, setanuencia] = useState(null)
    const [attached_first, setattached_first] = useState(null)
    const [attached_second, setattached_second] = useState(null)
    const [project, setproject] = useState(null)
    const [letter, setletter] = useState(null)


    const handleUploadAnuencia = (e) => {
        console.log('carta', e.target.files[0])
        setanuencia(e.target.files[0])
    }
    const handleUploadAttached1 = (e) => {
        console.log('carta', e.target.files[0])
        setattached_first(e.target.files[0])
    }
    const handleUploadAttached2 = (e) => {
        console.log('carta', e.target.files[0])
        setattached_second(e.target.files[0])
    }
    const handleUploadProject = (e) => {
        console.log('carta', e.target.files[0])
        setproject(e.target.files[0])
    }
    const handleUploadLetter = (e) => {
        console.log('carta', e.target.files[0])
        setletter(e.target.files[0])
    }

    return (
        <div style={classes.main} >
            <div style={classes.image}></div>
            <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '80vh' : '80%' }}>
                <form style={{ width: '100%' }}>
                    <div>
                        <div style={{ fontSize: '1.5rem', fontWeight: '500', textAlign: 'center', marginBottom: '1rem' }}>
                            Datos Generales del Proyecto
                        </div>
                    </div>
                    <Formik
                        validationSchema={formSchema}
                        onSubmit={handleSubmit}
                        initialValues={{
                            projectName: "",
                            address: "",
                            curp: "",
                            postCode: ""
                        }}
                    >
                        {({ handleChange, isSubmitting, handleBlur, values, setErrors, errors, isValid, touched, resetForm, setValues }) => (
                            <section style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: (height > 700 || width < 600) ? 'column' : 'row', gap: '1rem', width: '100%' }}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Nombre del proyecto *</label>
                                        <Input
                                            id='projectName'
                                            name='projectName'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.projectName}
                                            style={{ height: 30 }}
                                            size='small'
                                            status={errors.projectName && 'error'}
                                            placeholder='Ingrese el nombre'
                                        />
                                        <ErrorMessageField name="projectName" component="div" />
                                    </div>
                                </div>


                                <div style={{ display: 'flex', flexDirection: (height > 700 || width < 600) ? 'column' : 'row', gap: '1rem', width: '100%', marginBottom: '0.5rem' }}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Dirección del proyecto*</label>
                                        <Input
                                            id='address'
                                            name='address'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            style={{ height: 30 }}
                                            ref={inputRef}
                                            size='small'
                                            status={errors.address && 'error'}
                                            placeholder='Ingrese la dirección'
                                        />
                                        <ErrorMessageField name="address" component="div" />
                                    </div>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Código Postal *</label>
                                        <div style={{ display: 'flex' }}>
                                            <Input
                                                id='postCode'
                                                name='postCode'
                                                onChange={e => {
                                                    if (!e.target.value.match(/^[0-9]+$/) && e.target.value !== '') return
                                                    setcolonys([])
                                                    setcolony('')
                                                    seterror(prev => ({ ...prev, postCode: false }))
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                                value={values.postCode}
                                                style={{ height: 30, borderRadius: '15px 0 0 15px' }}
                                                size='small'
                                                status={(errors.postCode || error.postCode) && 'error'}
                                                placeholder='Ingrese el Código Postal'
                                                onPressEnter={() => getColonys(values.postCode)}
                                            />
                                            <Button disabled={errors.postCode || !values.postCode} onClick={() => getColonys(values.postCode)} icon={loadingColonys ? <LoadingOutlined /> : <SearchOutlined />} style={{ borderRadius: '0 15px 15px 0', height: 30 }} />
                                        </div>

                                        {
                                            error.postCode
                                                ?
                                                <ErrorMessageForm>
                                                    Este código postal no está registrado
                                                </ErrorMessageForm>
                                                :
                                                <ErrorMessageField name="postCode" component="div" />
                                        }
                                    </div>
                                    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Colonia *</label>
                                        <Select
                                            disabled={colonys.length <= 0}
                                            onChange={handleChangeColony}
                                            value={colony}
                                        >
                                            {
                                                colonys.map((item) => (
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>

                                <div>
                                    <Checkbox checked={isSchool} disabled={isGeneral} onChange={e => setisSchool(e.target.checked)}>Es Proyecto de Escuela?</Checkbox>
                                    <Checkbox checked={isGeneral} disabled={isSchool} onChange={e => setisGeneral(e.target.checked)}>Es Proyecto General?</Checkbox>
                                </div>

                                <div style={{ display: 'flex', gap: '2rem', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '1.5rem' }}>

                                    <div style={{ width: '45%' }}>
                                        <label style={{ backgroundColor: prMain, marginBottom: '1rem' }} className='input-container' >
                                            <UploadOutlined style={{ marginRight: 10 }} />
                                            <input
                                                id='anuencia'
                                                className='inputSelect'
                                                type="file"
                                                name='anuencia'
                                                onChange={handleUploadAnuencia}

                                            />
                                            Documento de Anuencia
                                        </label>

                                        <div style={{opacity: 0.7, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{anuencia ? anuencia.name : ''}</div>
                                    </div>
                                    <div style={{ width: '45%' }}>
                                        <label style={{ backgroundColor: prMain, marginBottom: '1rem' }} className='input-container' >
                                            <UploadOutlined style={{ marginRight: 10 }} />
                                            <input
                                                id='project'
                                                className='inputSelect'
                                                type="file"
                                                name='project'
                                                onChange={handleUploadProject}

                                            />
                                            Documento de Proyecto
                                        </label>
                                        <div style={{opacity: 0.7, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{project ? project.name : ''}</div>

                                    </div>

                                    <div style={{ width: '45%' }}>
                                        <label style={{ backgroundColor: prMain, marginBottom: '1rem' }} className='input-container' >
                                            <UploadOutlined style={{ marginRight: 10 }} />
                                            <input
                                                id='attached1'
                                                className='inputSelect'
                                                type="file"
                                                name='attached1'
                                                onChange={handleUploadAttached1}

                                            />
                                            Anexo 1
                                        </label>

                                        <div style={{opacity: 0.7, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{attached_first ? attached_first.name : ''}</div>

                                    </div>

                                    <div style={{ width: '45%' }}>
                                        <label style={{ backgroundColor: prMain, marginBottom: '1rem' }} className='input-container' >
                                            <UploadOutlined style={{ marginRight: 10 }} />
                                            <input
                                                id='attached2'
                                                className='inputSelect'
                                                type="file"
                                                name='attached2'
                                                onChange={handleUploadAttached2}

                                            />
                                            Anexo 2
                                        </label>
                                        <div style={{opacity: 0.7, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{attached_second ? attached_second.name : ''}</div>

                                    </div>

                                    {
                                        isSchool &&
                                        <div style={{ width: '45%' }}>
                                            <label style={{ backgroundColor: prMain, marginBottom: '1rem' }} className='input-container' >
                                                <UploadOutlined style={{ marginRight: 10 }} />
                                                <input
                                                    id='letter'
                                                    className='inputSelect'
                                                    type="file"
                                                    name='letter'
                                                    onChange={handleUploadLetter}

                                                />
                                                Carta del Director
                                            </label>
                                            <div style={{opacity: 0.7, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{letter ? letter.name : ''}</div>

                                        </div>
                                    }


                                </div>


                                <div style={classes.buttons} >
                                    {/* <Text style={classes.infoText} >Verifique que su colonia sea correcta, ya que se mostrarán solo proyectos de su zona.</Text> */}


                                    <Button loading={loading} disabled={!colony || error.postCode || !anuencia || !project || !attached_first || !attached_second || (isSchool && !letter) || (!isSchool && !isGeneral) } style={{ ...classes.button, width: 'auto', marginTop: '1rem' }} type='primary' size='small' onClick={() => handleSubmit(values, setValues)}>
                                        Registrar proyecto
                                    </Button>
                                </div>
                            </section>
                        )}
                    </Formik>
                </form>
            </div>
            {/* <Modal
                visible={modal.isOpen}
                footer={null}
                onCancel={handleCloseModal}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 30 }}
            >

                <ResponseForm response={response} closeSuccess={handleCloseModal} close={handleCloseModal} />
            </Modal> */}
        </div>
    );
}

const classes = {
    button: {
        textTransform: 'capitalize',
        width: '50%',
        height: '35px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    main: {
        display: 'flex',
        height: '100%',
        // background: '#222',
        // width: '60vw',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red'
    },
    root: {
        height: 'auto',
        overflowY: 'auto',
        borderRadius: '3%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
        padding: '1rem 3rem',
    },
    logo: {
        width: 100,
    },
    buttons: {
        display: 'flex',
        gap: '2rem',
        marginTop: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
    a: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginBottom: 20
    },
    btnFacebook: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
    infoText: {
        fontSize: 11,
        color: '#00000070',
        textAlign: 'center',

    },
}

