import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Avatar, List, Typography } from 'antd'
import { useSelector } from 'react-redux';

const Profile = (props) => {

  const { user } = useSelector(state => state.session);

    // const [user, setuser] = useState(null)

    // const getData = useCallback(() => {
        // getDataProfile(userCache.id)
        // .then(res => {
        //     setuser(res.data)
        //     console.log(res.data)
        // }).catch(err => {
        //     console.log(err)
        // })
    // },[userCache])

    // useEffect(() => {
    //     getData()
    // }, [getData, updateName])

    return (
        <div>
            <Tooltip placement='right' title='Perfil de usuario'>
                <Link to='perfil' style={classes.header}>
                    {
                        user &&
                        <List.Item.Meta
                        style={{textAlign: 'left', color: '#FFF', marginLeft: 22}}
                        avatar={<Avatar size={30} style={{ backgroundColor: '#999' }}>{user.name.substring(0,1).toUpperCase()}</Avatar>}
                        title={props.hide 
                        ? '' 
                        : <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography.Text style={{color: '#FFF', fontSize: 15}}>{user.name.toUpperCase()}</Typography.Text> 
                            <Typography.Text style={{color: '#FFF', fontSize: 10}}>{user.roles[0].description.toUpperCase()}</Typography.Text> 
                        </div>
                        }   
                        >
                    </List.Item.Meta>
                    }
                    <hr color='white' />
                </Link>
            </Tooltip>
        </div>
    );
};

const classes = {

    header: {
        marginTop: '5px',
        marginRight: '5px',
        display: 'block',
        textAlign: 'right',
        textDecoration: 'none',
    }
}
export default Profile;