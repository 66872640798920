import React, { useState } from "react"
import { Input, Button, Modal, Result, Switch } from "antd"
import { useScreenSize } from "../../../../hooks/useScreenSize"
import { apiSendMassiveNotification } from "../../../../utils/api"

const SendNotification = ({ setupdated, updated }) => {
    const { height } = useScreenSize()

    const [values, setValues] = useState({ id: '', title: "", description: "" })
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState({ isOpen: false, success: false })
    const [addLink, setAddLink] = useState(false)
    const [url, setUrl] = useState("")
    // const regex = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/
    const regex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;


    const [wrongUrl, setWrongUrl] = useState(false)
    // const [topic, setTopic] = useState("event")
    const topic = "event"

    const handleSubmit = () => {

        if (addLink && !regex.test(url)) {
            setWrongUrl(true)
            return
        }

        setLoading(true)
        apiSendMassiveNotification(values.title, values.description, topic, (addLink || values.id) ? "16" : "15", addLink ? url : "", values.id).then((response) => {

            setOpenModal({ isOpen: true, success: true })
            setValues({ title: "", description: "" })
            setAddLink(false)
            setUrl("")

        }).catch((error) => {
            setOpenModal({ isOpen: true, success: false })
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChange = (event, key) => {
        setValues({ ...values, [key]: event.target.value })

    }

    const handleChangeUrl = (event) => {
        setUrl(event.target.value)

        if (regex.test(event.target.value)) {
            setWrongUrl(false)
        } else {
            setWrongUrl(true)
        }
    }

    return (
        <div>
            <div style={{ fontSize: '1.2rem' }}>Enviar notificaciones</div>
            <section style={{ display: 'flex', flexDirection: 'column', gap: '1rem', margin: '1.5rem 0' }}>
                <div style={{ display: 'flex', gap: '1.5rem', flexDirection: height > 600 ? 'column' : 'row' }}>
                    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontSize: '1rem', opacity: 0.6 }}>Usuario (opcional)</label>
                        <Input
                            id='id'
                            name='id'
                            onChange={(e) => handleChange(e, "id")}
                            value={values.id}
                            style={{ height: 40, width: '40%' }}
                            size='large'
                            placeholder='ID de Usuario'
                        />
                    </div>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <label style={{ fontSize: '1rem', opacity: 0.6 }}>Título *</label>
                        <Input
                            id='title'
                            name='title'
                            onChange={(e) => handleChange(e, "title")}
                            value={values.title}
                            style={{ height: 40 }}
                            size='large'
                            placeholder='Ingrese el título'
                        />
                    </div>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <label style={{ fontSize: '1rem', opacity: 0.6 }}>Descripción *</label>
                        <Input.TextArea
                            id='description'
                            name='description'
                            onChange={(e) => handleChange(e, "description")}
                            value={values.description}
                            style={{ height: 80, resize: 'none' }}
                            size='large'
                            placeholder='Ingrese la descripción'
                        />
                    </div>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1vw', marginBottom: '1vh' }}>
                            <label style={{ fontSize: '1rem', opacity: 0.6 }}>Agregar liga externa</label>
                            <Switch style={{ width: '40px', alignSelf: 'center' }}
                                checked={addLink} onChange={() => setAddLink(!addLink)}
                            />
                        </div>
                        {
                            addLink &&
                            <>
                                <Input size='large'
                                    value={url}
                                    onChange={(e) => handleChangeUrl(e)}
                                    placeholder={"Ej: https://www.google.com/"}
                                    status={wrongUrl ? "error" : ""}
                                />

                                {
                                    wrongUrl &&
                                    <label style={{ color: 'red', fontSize: 12 }}>
                                        El URL ingresado no es válido.
                                    </label>
                                }
                            </>
                        }
                    </div>
                </div>
                <div>
                    <Button style={{ width: '10%' }}
                        onClick={() => handleSubmit()}
                        loading={loading}
                        disabled={!values.title || !values.description || (addLink && !url)}
                        type='primary'>

                        Enviar
                    </Button>
                </div>


            </section>

            <Modal
                centered
                visible={openModal.isOpen}
                closable={false}
                footer={null}
                destroyOnClose
                onCancel={() => setOpenModal({ isOpen: false, success: false })}
                width={'auto'}>

                <Result
                    status={openModal.success ? 'success' : 'error'}
                    title={openModal.success ? 'Notificación Enviada' : 'Notificación no enviada'}
                    subTitle={openModal.success ? 'La notificación se ha enviado a los usuarios correctamente.'
                        : 'Ha ocurrido un error al intentar enviar la notificación, intente nuevamente.'}

                    extra={[
                        <Button key='ok' type='primary' onClick={() => setOpenModal({ isOpen: false, success: false })} autoFocus >
                            Aceptar
                        </Button>
                    ]}
                />

            </Modal>
        </div>
    )

}

export default SendNotification