import { useEffect, useState } from 'react'
import { DatePicker, Select, Spin, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES';
import { WeekChartColony } from '../../charts/WeekChartColony';
import { apiGetReportsFilter } from '../../../utils/api';

export const ReportsWeekColony = () => {

    const [fullData, setfullData] = useState([])
    const [colonys, setcolonys] = useState([])
    const [services, setservices] = useState([])

    const [categories, setCategories] = useState([]);
    const [lengths, setLengths] = useState({});

    const [toDate, setToDate] = useState(moment().endOf('month').format('YYYY/M/D'));
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY/M/D'));

    const [loadingReports, setloadingReports] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const handleRange = (date, dateString) => {
        setFromDate(moment(date).startOf('month').format('YYYY/M/D'))
        setToDate(moment(date).endOf('month').format('YYYY/M/D'))
    }

    const fillDataFilter = (data) => {
        let colony = {}
        let service = {}
        data.forEach(item => {
            if (colony[item.colonia.name] === undefined) {
                colony[item.colonia.name] = 0
            }
            if (service[item.servicesDto.name] === undefined) {
                service[item.servicesDto.name] = 0
            }
        })
        setcolonys(Object.keys(colony))
        setservices(Object.keys(service))
    }

    const filterReportsLength = (data) => {
        let dataObject = []

        //create first dataObject with the name
        data.forEach(element => {
            if (!dataObject.some(i => i.name && i.name === element.colonia.name)) {
                dataObject.push({ name: element.colonia.name, data: [] })
            }
        })

        //create array of categories for the chart
        let categories = {}
        data.forEach(element => {
            categories[element.servicesDto.name] = 0
        })
        setCategories(Object.keys(categories))


        //create data 
        dataObject = dataObject.map(element => {
            let lengths = {}
            data.forEach(item => {
                if (lengths[item.servicesDto.name] === undefined) {
                    lengths[item.servicesDto.name] = 0
                }
                if (item.colonia.name === element.name) {
                    lengths[item.servicesDto.name]++
                }
            })

            let total = 0
            Object.values(lengths).forEach(i => total += i)

            return { ...element, data: Object.values(lengths), total }
        })

        setLengths(dataObject.sort((a, b) => a.total - b.total).reverse().splice(0, 10))
    }

    const [colonysSelected, setcolonysSelected] = useState([])
    const [servicesSelected, setservicesSelected] = useState([])

    const handleChage = (e, name) => {
        if (name === 'colony') {
            setcolonysSelected(e)
        } else if (name === 'service') {
            setservicesSelected(e)
        }
    }

    useEffect(() => {
        setloadingReports(true)
        apiGetReportsFilter(fromDate, toDate).then(res => {
            let data = res.data

            data = data.filter(item => {
                switch (item.status.id) {
                    case 1:
                        return moment(item.createdAt) >= moment(fromDate) && moment(item.createdAt) <= moment(toDate)

                    case 2:
                        return item.assignedAt && (moment(item.assignedAt) >= moment(fromDate) && moment(item.assignedAt) <= moment(toDate))

                    case 3:
                        return item.inReviewAt && (moment(item.inReviewAt) >= moment(fromDate) && moment(item.inReviewAt) <= moment(toDate))

                    case 4:
                        return item.completedAt && (moment(item.completedAt) >= moment(fromDate) && moment(item.completedAt) <= moment(toDate))


                    default:
                        return null
                }
            })
            setfullData(data)
            fillDataFilter(data)
            // let data = data
            if (colonysSelected.length > 0) {
                data = data.filter(item => colonysSelected.some(i => i === item.colonia.name))
            }

            if (servicesSelected.length > 0) {
                data = data.filter(item => servicesSelected.some(i => i === item.servicesDto.name))
            }
            filterReportsLength(data)

        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloadingReports(false)
        })

        setTimeout(() => {
                setRefresh(!refresh)
          
        },[120000])

    }, [toDate, fromDate, refresh])

    useEffect(() => {
        let data = fullData
        if (colonysSelected.length > 0) {
            data = data.filter(item => colonysSelected.some(i => i === item.colonia.name))
        }

        if (servicesSelected.length > 0) {
            data = data.filter(item => servicesSelected.some(i => i === item.servicesDto.name))
        }
        filterReportsLength(data)
        // filterReports()
    }, [colonysSelected, servicesSelected])

    return (
        <Spin spinning={loadingReports}>
            <div style={{ height: '100%', width: '100%', padding: 10, borderRadius: 5, backgroundColor: '#FFF' }}>
                <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 18 }}>Reportes totales del mes por colonia</Typography.Text>
                        <div style={{ display: 'flex', width: '140%', marginTop: 10 }}>
                            <Select
                                allowClear
                                mode='multiple'
                                placeholder='Colonias'
                                onChange={e => handleChage(e, 'colony')}
                                values={colonysSelected}
                                style={{ width: '100%', marginRight: 5 }}
                                size='small'
                                maxTagCount='responsive'
                                showArrow
                            >
                                {
                                    colonys.map((item, i) => (
                                        <Select.Option key={i} value={item}>{item}</Select.Option>
                                    ))
                                }
                            </Select>

                            <Select
                                allowClear
                                mode='multiple'
                                placeholder='Servicios'
                                onChange={e => handleChage(e, 'service')}
                                values={servicesSelected}
                                style={{ width: '100%' }}
                                size='small'
                                maxTagCount='responsive'
                                showArrow
                            >
                                {
                                    services.map((item, i) => (
                                        <Select.Option key={i} value={item}>{item}</Select.Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                        <DatePicker
                            onChange={handleRange}
                            defaultValue={moment()}
                            picker='month'
                            size='small'
                            locale={locale}
                            allowClear={false}
                        />
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 12, marginTop: 10 }}>Del {moment(fromDate).format('DD/MM/YYYY')} al {moment(toDate).format('DD/MM/YYYY')}</Typography.Text>
                    </div>
                </section>
                <WeekChartColony data={lengths} categories={categories} />
            </div>
        </Spin>
    )
}
