import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd'
import { AuditOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import { apiGetEventsPagination, apiGetEventsPaginationSearch } from '../../../utils/api';
import moment from 'moment';
import CreateEvent from './CreateEvent';
import DetailsEvent from './DetailsEvent';
import { Attendance } from './Attendance';


const EventsTable = ({ filter, openCreate, setOpenCreate, setpage, page, update }) => {

  const columns = [
    {
      title: 'Id',
      dataIndex: 'idEvent',
      key: 'idEvent',
      width: '7%',
      render: id => (
        <div>
          {id}
        </div>
      )
    },
    {
      title: 'Evento',
      key: 'eventName',
      width: '20%',
      render: row => (
        <Tooltip title={row.eventName}>
          {row.eventName}
        </Tooltip>
      )
    },
    {
      title: 'Descripción',
      key: 'description',
      width: '25%',

      ellipsis: {
        showTitle: true
      },
      render: row => (
        <>
          {row.description ? row.description : " "}
        </>
      )
    },
    {
      title: 'Lugar',
      dataIndex: 'place',
      key: 'place',
      ellipsis: {
        showTitle: true
      },
      width: '15%',
    },
    {
      title: 'Fecha',
      key: 'startTime',
      width: '15%',
      ellipsis: {
        showTitle: true
      },
      render: row => (
        <Tooltip title={moment(row.startTime).format('lll')}>
          {moment(row.startTime).format('lll')}
        </Tooltip>
      )
    },
    {
      title: 'Asistencias',
      dataIndex: 'attendance',
      key: 'attendance',
      align: "center",
      ellipsis: {
        showTitle: true
      },
      width: '10%',

    },

  ]

  const actionsTable = {
    title: 'Detalles',
    key: 'details',
    align: "center",
    width: '8%',
    ellipsis: {
      showTitle: true
    },
    render: (row) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Tooltip title="Ver Detalles">
          <Button type='link' icon={<EyeOutlined style={{ color: '#000' }} />} onClick={() => handledetails(row)}>
          </Button>
        </Tooltip>
        <Tooltip title="Lista de Asistencia">
          <Button type='link' icon={<AuditOutlined style={{ color: '#000' }} />} onClick={() => handleAttendance(row)}>
          </Button>
        </Tooltip>
      </div>
    ),
  }

  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openDetails, setOpenDetails] = useState(false)
  const [openAttendance, setOpenAttendance] = useState(false)
  const [details, setDetails] = useState([])

  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({
    order: undefined,
    key: undefined,
  })

  const [totalData, settotalData] = useState(0)

  const handledetails = (data) => {
    setDetails(data)
    setOpenDetails(true)
  }

  const handleAttendance = (data) => {
    setDetails(data)
    setOpenAttendance(true)
  }

  const getEvents = useCallback(() => {
    setLoading(true)
    apiGetEventsPagination(page, pageSize, order.order, order.key).then(res => {
      settotalData(res.data.amount)
      setFilterData(res.data.event)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [page, pageSize, order.order, order.key])

  const getEventsSearch = useCallback(() => {
    setLoading(true)
    apiGetEventsPaginationSearch(page, pageSize, order.order, order.key, filter).then(res => {
      settotalData(res.data.amount)
      setFilterData(res.data.event)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [page, pageSize, order.order, order.key, filter])


  useEffect(() => {

    if (filter) {
      getEventsSearch()
    } else {
      getEvents()
    }

  }, [refresh, update, page, pageSize, order.order, order.key])

  const handleTable = (pagination, filters, sorter) => {

    setpage(pagination.current)
    setpageSize(pagination.pageSize)
    setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : undefined }))
  }

  return (
    <>
      <div style={{ paddingTop: 30 }}>
        <Table
          onChange={handleTable}
          rowKey={record => record.idEvent}
          loading={loading}
          columns={[...columns, actionsTable]}
          dataSource={filterData}
          size="small"
          scroll={{ y: '60vh', x: '100%' }}
          pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        />
        <div style={{ marginTop: '-50px' }} >
          <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
        </div>
      </div>

      <Modal
        centered
        visible={openDetails || openAttendance}
        closable={false}
        footer={null}
        destroyOnClose
        onCancel={() => { setOpenDetails(false); setOpenAttendance(false) }}
        width={'auto'}
      >
        {
          openDetails &&
          <DetailsEvent data={details} setOpenDetails={setOpenDetails} refresh={refresh} setRefresh={setRefresh} />
        }
        {
          openAttendance &&
          <Attendance data={details} setOpenAttendence={setOpenAttendance} refresh={refresh} setRefresh={setRefresh} />
        }
      </Modal>

      <Modal
        centered
        visible={openCreate}
        closable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setOpenCreate(false)}
        width={'auto'}
      >
        <CreateEvent setOpenCreate={setOpenCreate} refresh={refresh} setRefresh={setRefresh} setOpenDetails={setOpenDetails} setDetails={setDetails} />

      </Modal>
    </>
  );
}
export default EventsTable;