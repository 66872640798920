import React, { useState, useEffect } from "react";
import { Row, Tabs, Button, Collapse, Spin, Modal, Typography, Input, Select } from "antd";
import ScholarshipGeneral from "./tabs/ScholarshipGeneral";
import ScholarshipBeneficiary from "./tabs/ScholarshipBeneficiary";
import ScholarshipSE from "./tabs/ScholarshipSE";
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { prMain } from "../../../themeConfig";
import ScholarshipFiles from "./ScholarshipFiles";
// import { NavLink } from "react-router-dom";
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';
import { apiApproveScholarship, apiRejectScholarship, apiRejectScholarshipFiles, apiGetScholarshipsByCurp } from '../../../utils/api'
import { useSelector } from "react-redux";
import ScholarshipHistory from "./ScholarshipHistory";
 
const ScholarshipDetails = ({data, setOpenDetails, setRefresh, refresh}) => {
    const TabPane = Tabs.TabPane;
    const { Panel } = Collapse
    const { Text } = Typography
    const { TextArea } = Input
    const { user } = useSelector(state => state.session)
    // const propsDetails = (props.location ? props.location.state : [])
    const propsDetails = (data ? data : [])
    // const [details, setDetails] = useState(props.location ? props.location.state : [])
    const [details, setDetails] = useState(data ? data : [])
    const [beneficiaryCount, setBeneficiaryCount] = useState(0)


    const modalOptions = [
        { label: 'INE (frontal)', value: 'INE-Front' },
        { label: 'INE (trasera)', value: 'INE-Back' },
        { label: 'Comprobante de domicilio', value: 'Address' },
        { label: 'Comprobante de ingresos', value: 'Income' },
        { label: 'Boleta de estudios', value: 'Bolet' },
        { label: 'CURP', value: 'CURP' },
        { label: 'Documento DIF', value: 'Document' },
    ]
    
    const [loading, setLoading] = useState(!details)
    const [reason, setReason] = useState()
    const [successModal, setSuccessModal] = useState(false)
    const [openModal, setOpenModal] = useState({ isOpen: false, Option: '' })
    const [changed, setChanged] = useState(false)


    const handleOpen = (open, option) => {
        setSuccessModal(false)
        setOpenModal({ isOpen: open, Option: option })

    }
    const [returnStatus, setReturnStatus] = useState(0)

    const handleCompleteReject = () => {

        try {
            let folio = details.folio
            let curp = details.curp
            let idStatus = openModal.Option === 'unapprove'
                ? details.idStatus < 3
                    ? 3
                    : 2
                : 5

            setReturnStatus(details.idStatus)
            apiRejectScholarship(curp, idStatus, reason, 0).then((response) => {

                if (openModal.Option === 'unapprove' && filesSelected.length !== 0) {
                    let concat = '';
                    for (let i = 0; i < filesSelected.length; i++) {
                        for (let j = 0; j < details.scholarshipsFiles.length; j++) {
                            if (filesSelected[i] === details.scholarshipsFiles[j].typeDocument) {
                                concat = concat + details.scholarshipsFiles[j].idFile + ','
                            }
                        }
                    }
                    apiRejectScholarshipFiles(folio, concat.substring(0, concat.length - 1)).then(() => {
                        setFilesSelected([])
                    })
                }
                
                if (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR)) {
                    setChanged(true)
                }

            }).catch((e) => {
                console.log(e)

            }).finally(() => {
                setRefresh(!refresh)
                setSuccessModal(true)
            })
        } catch {
            console.log('error')

        }
    }

    const handleCompleteApprove = () => {
        try {
            let curp = details.curp
            let idStatus = isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) ? 2 : 4
            apiApproveScholarship(curp, idStatus, 0).then((response) => {
                setRefresh(!refresh)

                setSuccessModal(true)
                if (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR)) {
                    setChanged(true)
                }
            }).catch((e) => {
                console.log(e)
            })
        } catch {
            console.log('error')

        }
    }

    const SuccessMessage = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '30vh', }}>
                <CheckCircleOutlined style={modalIcon} />

                <Text style={modalTitle}>
                    { isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) 
                        ? 'Enviado a revisión.'
                        : 'Apoyo aprobado.'
                    }
                </Text>
                <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    
                    <Text style={modalText}>
                        { isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) 
                            ? 'Se ha enviado a revisión correctamente.'
                            : 'Se notificará al solicitante que su apoyo ha sido aprobado.'
                        }
                        
                    </Text>
                </div>
            </div>
        )
    }

    const ApproveModal = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <Text style={modalTitle}>
                    { isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) 
                        ? 'Enviar a revisión'
                        : 'Aprobar apoyo'
                    }
                    </Text>
                <div style={modalContainer}>

                    <Text style={modalText}>
                        { isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) 
                            ? '¿Está seguro de enviar este apoyo a revisión?'
                            : '¿Está seguro de aprobar este apoyo?'

                        }
                    </Text>
                    {/* <div style={{ border: `solid 1px ${prMain}`, borderRadius: '16px', fontSize: '16px', textAlign: 'center', marginTop: '2vh' }}>
                        Folio: {details.folio}
                    </div> */}
                </div>
                <div style={modalButtons}>
                    <Button style={rejectButton} onClick={() => handleOpen(false, '')}>Cancelar</Button>
                    <Button type="primary" style={{ color: '#fff' }} onClick={() => handleCompleteApprove()}>Aceptar</Button>
                </div>

            </div>
        )
    }

    const handleChangeReason = (event) => {
        setReason(event.target.value)
    }

    const [filesSelected, setFilesSelected] = useState([])

    const handleSelect = (event) => {
        setFilesSelected(event)
    }
    const RejectModal = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={modalTitle}>
                    {(openModal.Option === 'unapprove')
                            ? (details.idStatus === 3) 
                                ? 'A revisión' 
                                : 'Solicitar cambios'
                            : 'Rechazar apoyo'}
                </Text>
                <div style={modalContainer}>

                    <Text style={modalText}>Especifique la raz&oacute;n a continuaci&oacute;n:</Text>
                    <TextArea style={modalInput} maxLength={255} value={reason} onChange={(e) => handleChangeReason(e)}>
                    </TextArea>

                    {(openModal.Option === 'unapprove' && details.idStatus < 3)
                        &&
                        <>
                            <Text style={modalText}>Seleccione los archivos incorrectos (opcional):</Text>
                            <Select value={filesSelected} onChange={(e) => handleSelect(e)} mode="multiple" maxTagCount="responsive" style={modalSelect} options={modalOptions} />
                        </>
                    }
                </div>
                <div style={modalButtons}>
                    <Button style={rejectButton} onClick={() => handleOpen(false, '')}>Cancelar</Button>
                    <Button disabled={!reason} type="primary" style={{ color: '#fff' }} onClick={handleCompleteReject}>Continuar</Button>
                </div>

            </div>
        )
    }

    const RejectMessage = () => {
        return (
            returnStatus > 3
                ? ReturnMessage()
                : <div style={{ display: 'flex', flexDirection: 'column', height: '30vh', }}>
                    <CheckCircleOutlined style={modalIcon} />

                    <Text style={modalTitle}>
                            {openModal.Option === 'unapprove' 
                                    ? returnStatus === 3 
                                        ? 'Enviado a revisión.' 
                                        : 'Cambios Solicitados.'
                                    : 'Apoyo no aprobado.'}
                    </Text>
                    <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    {openModal.Option === 'unapprove' 
                                    ? returnStatus === 3 
                                        ? 'Se ha enviado a revisión correctamente.' 
                                        : 'Se notificará al solicitante para que pueda corregir los datos necesarios.'
                                    : 'Se notificará al solicitante la razón de rechazo.'}

                    </div>
                </div>
        )
    }

    const ReturnMessage = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '30vh', }}>
                <CheckCircleOutlined style={modalIcon} />

                <Text style={modalTitle}>Apoyo devuelto.</Text>
                <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    <Text style={modalText}>Se notificar&aacute; al solicitante que su solicitud será revisada nuevamente.</Text>
                </div>
            </div>
        )
    }

    const [beneficiaryList, setBeneficiaryList] = useState([])
    // const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        setLoading(true)
        apiGetScholarshipsByCurp(details.curp).then((response) => {
            setDetails(response.data[0])
            // setBeneficiaryCount(response.data && response.data.beneficiaryList.length)

            if (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR)) {
                let array = []
                for (let i = 0; i < response.data[0].beneficiaryList.length; i++) {
                    if (response.data[0].beneficiaryList[i].idStatus === 1) {
                        array.push(response.data[0].beneficiaryList[i])
                    }
                }
                setBeneficiaryList(array)
                setLoading(false)

            } else {
                setBeneficiaryList(response.data[0].beneficiaryList)
                setLoading(false)
            }

        }).catch((e) => {
            console.log(e)
            setDetails(propsDetails)
            setBeneficiaryList(propsDetails.beneficiaryList)
            setBeneficiaryCount(propsDetails.beneficiaryList.length)
            setLoading(false)
        })
        setBeneficiaryCount(propsDetails.beneficiaryList.length)
    }, [refresh])
    return (

        <div style={{ width: '99%', height: '90vh' }}>

            <Row style={{ marginBottom: '2vh' }}>
                <div style={{ backgroundColor: prMain, borderRadius: '16px 0 0 16px' }}>
                    {/* <NavLink to='/apoyos'> */}
                        <Button onClick={() => setOpenDetails(false)} type='primary' icon={<ArrowLeftOutlined style={{ color: '#fff', borderRadius: '16px 0 0 16px' }} />}>
                        </Button>
                    {/* </NavLink> */}
                </div>

                <div style={folioStyle}>
                    Folio: {details.folio}
                </div>

                {(beneficiaryCount === 0) &&
                    <div style={{ marginLeft: 'auto' }}>
                        {((isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) && details.idStatus < 4) &&
                            <Button type='primary' style={rejectButton} icon={<CloseCircleOutlined style={{ color: prMain }} />} onClick={() => handleOpen(true, 'reject')}>
                                Rechazar
                            </Button>}
                        { ((isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) || (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) && !changed)) &&
                            <Button onClick={() => handleOpen(true, 'unapprove')} type='primary' style={rejectButton} icon={<ExclamationCircleOutlined style={{ color: prMain }} />} >
                                {details.idStatus === 3 ? 'A revisión' : 'Solicitar cambios'}
                            </Button>
                        }
                        {(details.idStatus < 4 && (((isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) || (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) && !changed)))) &&
                            <Button type='primary' icon={<CheckCircleOutlined style={{ color: '#fff' }} />} onClick={() => handleOpen(true, 'approve')}>
                                {isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) ? 'A revisión' : 'Aprobar'}
                            </Button>}
                    </div>}
            </Row>

            <Tabs type="card" >
                <TabPane tab="Datos" key="1">
                    <div style={divStyle}>
                        {<Spin spinning={loading}>
                            <Collapse defaultActiveKey={0}>
                                <Panel header="Datos generales">
                                    <ScholarshipGeneral details={details} beneficiaryCount={beneficiaryCount} refresh={refresh} setRefresh={setRefresh} />
                                </Panel>
                                {beneficiaryList.map((beneficiary, i) => (

                                    <Panel header={`Datos de beneficiario ${beneficiaryList.length > 1 ? `${beneficiary.nameBeneficiary} ${beneficiary.firstLastNameBeneficiary} ${beneficiary.secondLastNameBeneficiary}` : ''}`} key={`id_${beneficiary.idBeneficiary}`}>
                                        <ScholarshipBeneficiary detail={beneficiaryList[i]} scholarship={details} setRefresh={setRefresh} refresh={refresh} loading={loading} />
                                    </Panel>
                                ))}
                                <Panel header="Estudio socioeconómico">
                                    <ScholarshipSE details={details} />
                                </Panel>
                            </Collapse>
                        </Spin>
                        }
                    </div>
                </TabPane>

                <TabPane tab="Archivos" key="2">
                    <div style={divStyle}>
                        <ScholarshipFiles files={details.scholarshipsFiles} />
                    </div>
                </TabPane>
                {/* <TabPane tab="Estudio socieconómico" key="3"> <ScholarshipSE/> </TabPane> */}
                <TabPane tab="Histórico" key="3">
                    {(isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) &&
                        <div style={divStyle}>
                            <ScholarshipHistory details={details} />
                        </div>
                    }
                </TabPane>
            </Tabs>

            <Modal centered
                visible={openModal.isOpen}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => handleOpen(false, '')}>
                <div>
                    {openModal.Option === 'approve'
                        ? successModal
                            ? SuccessMessage()

                            : ApproveModal()
                        : openModal.Option === 'unapprove'
                            ? successModal
                                ? RejectMessage()
                                : RejectModal()

                            : openModal.Option === 'reject'
                                ? successModal
                                    ? RejectMessage()
                                    : RejectModal()
                                : null
                    }

                </div>
            </Modal>

        </div>
    )
}

const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    maxHeight: '80vh',
    overflowY: 'scroll',
    backgroundColor: 'white',
    marginTop: '-16px',
    marginLeft: '1px',
    padding: '4vh 2vw',
    borderRadius: '0px 25px 25px 25px'
}

const folioStyle = {
    borderRadius: '0 16px 16px 0',
    backgroundColor: '#fff',
    border: `solid 1px ${prMain}`,
    minWidth: '100px',
    textAlign: 'center',
    padding: '5px 15px 0 15px',
    fontSize: '16px',
}

const modalTitle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '26px',
}

const modalContainer = {
    minHeight: '10vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2vh',

}

const modalText = {
    marginLeft: 'auto',
    marginRight: 'auto',
}

const modalButtons = {
    marginLeft: 'auto',
    marginTop: '2vh',
    display: 'flex',
    flexDirection: 'row'
}

const rejectButton = {
    marginRight: '1vw',
    backgroundColor: '#ffffff00',
    border: `solid 1px ${prMain}`,
    color: prMain
}

const modalInput = {
    maxHeight: '10vh',
    height: '10vh',
    marginBottom: '3vh',
}

const modalSelect = {
    width: '100%',
    marginBottom: '2vh'
}

const modalIcon = {
    fontSize: '75px',
    marginBottom: '1vh',
    marginTop: '4vh',
    color: prMain,
}

export default ScholarshipDetails