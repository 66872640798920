import React, { useState } from 'react';
import UsersTable from './UsersTable';
import AddUser from './AddUser';
import { Button, Input, Modal, Tooltip, Typography } from 'antd'
import { CloseCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useHistory } from 'react-router-dom';
const { Search } = Input

const classes = {
    textfield: {
        width: '60%',
        marginRight: '10%',
        backgroundColor: '#FFF',
        borderRadius: 20
    },
    button: {
        width: 'auto',
        textTransform: 'capitalize',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}

const UsersListPage = (props) => {

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [isClosable, setisClosable] = useState(true)
    const [update, setUpdate] = useState(false);
    const history = useHistory();
    const pathname = history.location.pathname
    const { width } = useScreenSize()

    const [page, setpage] = useState(1)

    const handleClose = () => {
        setOpen(false);
        setUpdate(prev => !prev)
    }

    const handleFilter = (event) => {
        if (event.target.value === '') {
            setpage(1)
            setUpdate(prev => !prev)
        }
        setFilter(event.target.value);
    }

    const handleSearch = () => {
        if (filter) {
            setpage(1)
            setUpdate(prev => !prev)
        }

    }

    return (
        <>
            <section style={{ ...classes.box, flexDirection: 'column' }}>
                <div style={{ ...classes.box, width: '80%', flexDirection: 'row' }}>
                    <Search allowClear={false} value={filter} onChange={handleFilter} onSearch={handleSearch} style={classes.textfield} placeholder={`Buscar ${pathname.slice(1, pathname.length - 1)}...`} size='large' />
                    {
                        pathname === '/usuarios' &&
                        <>
                            {
                                width > 760
                                    ? (<Button onClick={() => setOpen(true)} size="large" style={classes.button} type="primary">Agregar Nuevo {pathname.slice(1, pathname.length - 1)}</Button>)
                                    : (
                                        <Tooltip title={pathname === '/usuarios' ? 'Agregar Nuevo Usuario' : 'Agregar Nuevo Ciudadano'}>
                                            <Button icon={<UserAddOutlined />} onClick={() => setOpen(true)} size="large" type='primary' />
                                        </Tooltip>
                                    )
                            }
                        </>
                    }
                </div>
                <div style={{ width: '90%', height: '85vh', overflowY: 'auto' }}>
                    <UsersTable filter={filter} setupdate={setUpdate} update={update} pathname={pathname} setpage={setpage} page={page} />
                </div>
            </section>

            <Modal
                visible={open}
                closable={false}
                footer={null}
                centered
                onCancel={handleClose}
                title={
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                        <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Agregar nuevo {pathname.slice(1, pathname.length - 1)}</Typography>
                        {
                            isClosable &&
                            <CloseCircleOutlined onClick={handleClose} style={{ color: 'red', marginLeft: 'auto' }} />
                        }
                    </div>
                }
            >
                <AddUser pathname={pathname} isClosable={setisClosable} handleClose={handleClose} update={update} setUpdate={setUpdate} />
            </Modal>
            {/* <TableFormart headers={ usersHeaders } editPath= 'usuarios' children={ UsersTable } endpoint='user/register'  handleAdd={handleAdd} handleEdit={handleEdit} handleRemove={handleRemove}  updateTimestamp={ updateTimestamp }  /> */}
        </>
    );
}
export default UsersListPage;