import BudgetPage from './BudgetPage';
import BudgetDashboard from '../votes/Dashboard/BudgetDashboard';
import { TabsPage } from '../../Tabs/TabsPage';
import { CreateProject } from './CreateProject/CreateProject';

export const BudgetTabs = () => {

    const tabsPanels = [
        {
            title: 'Presupuesto Participativo',
            key: 1,
            content: <BudgetPage />,
            onlyAdmin: false,
        },
        {
            title: 'Tablero',
            key: 2,
            content: <BudgetDashboard />,
            onlyAdmin: false,
        },
        {
            title: 'Registro de Proyectos',
            key: 3,
            content: <CreateProject />,
            onlyAdmin: false,
        },
    ]

    return (
        <TabsPage tabsPanels={tabsPanels} />
    )
}
