import React, { useEffect, useState } from 'react'
import { PieChart } from '../../../charts/PieChart'
import moment from 'moment'
import { Divider, Select, Space, Spin, Typography } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { apiGetReOpenReports, apiGetReportSuccessfully } from '../../../../utils/api'
import { useScreenSize } from '../../../../hooks/useScreenSize'
import { prMain } from '../../../../themeConfig'
import { antIcon } from './CRMDashboard'

export const Reports = ({ refresh, setrefresh }) => {

    const { width, height } = useScreenSize()

    const [data, setdata] = useState({})
    const [loading, setloading] = useState(false)

    const [date, setdate] = useState({
        month: [parseInt(moment().format('M'))],
        year: moment().format('YYYY')
    })

    const onChangeMonth = (data) => {
        setdate(prev => ({ ...prev, month: data }))
    }

    useEffect(() => {

        if (date.month.length === 0 || !date.year) {
            setdata({})
            return
        }

        setloading(true)

        Promise.all([apiGetReOpenReports(date.year, date.month.join(',')), apiGetReportSuccessfully(date.year, date.month.join(','))])
            .then(([res1, res2]) => {

                setdata(prev => ({
                    ...prev,
                    Reabiertos: res1.data.length > 0 ? res1.data.length : 0,
                    Completados: res2.data.length > 0 ? res2.data.length : 0,
                }))
                
            }).catch(error => {
                console.error('Error al llamar a las APIs:', error);
            }).finally(() => {
                setloading(false)
            })

        setTimeout(() => {
            setrefresh(!refresh)

        }, [120000])

    }, [date.year, date.month, refresh])

    return (
        <div style={{ width: '45%', }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                <label style={{ fontSize: '1.2rem', opacity: 0.7 }}>Seguimiento de Reportes</label>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: '40%' }}>
                    <Select
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: '100%' }}
                        allowClear
                        size='small'
                        value={date.month}
                        onChange={onChangeMonth}
                        dropdownRender={menu => (
                            <>
                                <Space style={{ padding: '0 8px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <DoubleLeftOutlined onClick={() => setdate(prev => ({ ...prev, year: prev.year - 1 }))} />
                                    {date.year}
                                    <DoubleRightOutlined style={{ opacity: date.year >= parseInt(moment().format('YYYY')) ? .7 : 1 }} onClick={() => date.year >= parseInt(moment().format('YYYY')) ? null : setdate(prev => ({ ...prev, year: prev.year + 1 }))} />
                                </Space>
                                <Divider style={{ margin: '8px 0' }} />
                                {menu}
                            </>
                        )}
                    >
                        <Select.Option value={1} key={1}>Enero</Select.Option>
                        <Select.Option value={2} key={2}>Febrero</Select.Option>
                        <Select.Option value={3} key={3}>Marzo</Select.Option>
                        <Select.Option value={4} key={4}>Abril</Select.Option>
                        <Select.Option value={5} key={5}>Mayo</Select.Option>
                        <Select.Option value={6} key={6}>Junio</Select.Option>
                        <Select.Option value={7} key={7}>Julio</Select.Option>
                        <Select.Option value={8} key={8}>Agosto</Select.Option>
                        <Select.Option value={9} key={9}>Septiembre</Select.Option>
                        <Select.Option value={10} key={10}>Octubre</Select.Option>
                        <Select.Option value={11} key={11}>Noviembre</Select.Option>
                        <Select.Option value={12} key={12}>Diciembre</Select.Option>
                    </Select>
                    <Typography.Text style={{ color: '#6D6D6D', fontSize: 12 }}>Seleccione los meses</Typography.Text>
                    {/* <Typography.Text style={{ color: '#6D6D6D', fontSize: 12, marginTop: 10 }}>Del {moment(fromDate).format('DD/MM/YYYY')} al {moment(toDate).format('DD/MM/YYYY')}</Typography.Text> */}
                </div>
            </div>
            <PieChart colors={['#71cf71', '#ffc700']} height={width < 1380 || height < 670 ? 200 : 300} lengths={data} labelsPostCode={Object.keys(data)} seriesPostCode={Object.values(data)} />

            {
                loading &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '.8rem', fontSize: 11, width: '100%', marginLeft: '1rem' }}>
                    <Spin indicator={antIcon} />
                    <div style={{ color: prMain }}>
                        Cargando Datos...
                    </div>
                </div>
            }
        </div>
    )
}
