import { Typography } from 'antd'
import moment from 'moment'
import Chart from 'react-apexcharts'
import { prMain } from '../../themeConfig'

export const WeekChartColony = ({ data, categories }) => {
    const es = require('apexcharts/dist/locales/es.json')

    const defaultValue = [0,0,0,0,0,0,0]
    const defaultCategories = ['', '', '', '', '', '', '']
    const state = {
        series: data.length > 0 ? data : [{name: 'sin datos', data: defaultValue}],
        options: {
            legend:{
                showForSingleSeries: true,
                onItemClick:{
                    toggleDataSeries: false
                }
            },
            chart: {
                height: 350,
                stacked: false,
                locales: [es],
                defaultLocale: 'es',
                toolbar: {
                    tools:{
                        download: true,
                    },
                    export: {
                        csv: {
                            filename: `Reporte_por_colonia${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                            columnDelimiter: ',',
                            headerCategory: 'Servicio',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            }
                        },
                        svg: {
                            filename: `Reporte_por_colonia${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        },
                        png: {
                            filename: `Reporte_por_colonia${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        }
                    }
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: [prMain, "#F3B415", "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#00A7C6", "#18D8D8", '#A9D794',
            '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29'],
            markers: {
                size: 5,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 7,
                    sizeOffset: 3
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 2,
                dashArray: 0,
            },

            xaxis: {
                categories : categories.length > 0 ? categories : defaultCategories,
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: prMain
                    },
                    labels: {
                        style: {
                            colors: prMain,
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }

            ],
            // tooltip: {
            //     fixed: {
            //         offsetY: 30,
            //         offsetX: 60
            //     },
            // },
        },
    };

    return (
        <div style={{ marginTop: 10 , position: 'relative'}}>
            {
                data.length === 0 &&
                <Typography style={{textAlign: 'center', width: '100%', opacity: .6, position: 'absolute', top: 75}}>No hay datos</Typography>
            }
            <Chart options={state.options} series={state.series} type='bar' width={'100%'} height={250} />
        </div>
    )
}
