import { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { RecentReports } from './RecentReports'
import moment from 'moment';
import { apiGetReportsFilter } from '../../../utils/api';
export const RecentsContainer = () => {

    const [reports, setReports] = useState({
        created: [],
        assigned: [],
        review: [],
        completed: []
    });
    const [loadingReports, setloadingReports] = useState(false)
    const [refresh, setRefresh] = useState(false)

    // const defaultToDate = moment()

    useEffect(() => {
        // console.log(data.map(item => ({horas: moment(defaultToDate).diff(item.createdAt, 'minutes'), paso: moment(defaultToDate).diff(item.createdAt, 'minutes') <= 1440, fechaReporte : moment(item.createdAt).format('D/M hh:mm a'), fechaActual : moment().format('D/M HH:mm a')})))
        setloadingReports(false)
        apiGetReportsFilter(moment().subtract('hours', 24), moment()).then(res => {
            let data = res.data

            let dataCreated = data.filter(item => item.createdAt )
            let dataAssigned = data.filter(item => item.assignedAt )
            let dataReview = data.filter(item => item.inReviewAt )
            let dataCompleted = data.filter(item => item.completedAt )

            setReports(prev => ({
                ...prev,
                created: dataCreated,
                assigned: dataAssigned,
                review: dataReview,
                completed: dataCompleted
            }));
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloadingReports(false)
        })

        setTimeout(() => {
            setRefresh(!refresh)
      
          }, [120000])
    }, [refresh])

    return (
        <Spin spinning={loadingReports}>
            <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
                <Col span={6}>
                    <RecentReports status={1} reports={reports.created.filter(i => i.status.id === 1)} title='Reportes pendientes' color='#ff4949' />
                </Col>
                <Col span={6}>
                    <RecentReports status={2} reports={reports.assigned.filter(i => i.status.id === 2)} title='Reportes en proceso' color='#ffc700' />
                </Col>
                <Col span={6}>
                    <RecentReports status={3} reports={reports.review.filter(i => i.status.id === 3)} title='Reportes en revisi&oacute;n' color='#ff9800' />
                </Col>
                <Col span={6}>
                    <RecentReports status={4} reports={reports.completed.filter(i => i.status.id === 4)} title='Reportes solucionados' color='#71cf71' />
                </Col>
            </Row>
        </Spin>
    )
}
