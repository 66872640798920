import React from 'react';
import {initializeApp} from 'firebase/app';
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getMessaging } from 'firebase/messaging'
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import { firebaseConfig } from './firebaseConfig';

const container = document.getElementById('root');
const root = createRoot(container);

const app = initializeApp(firebaseConfig)
getAnalytics(app)
getPerformance(app)
getMessaging(app)

root.render(
  <ConfigProvider locale={es_ES}>

    <Provider store={store}>
      <Router store={store}>
            <App />
      </Router>
    </Provider>
  </ConfigProvider>
)
