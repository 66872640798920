import React, { useEffect, useState } from "react"
import { Form, Input, Select } from "antd"
import BudgetTable from "./BudgetTable";
import { prMain } from "../../../themeConfig";
import { apiGetPeriods } from "../../../utils/api";
const { Search } = Input

const BudgetPage = () => {
    const [filter, setFilter] = useState('');
    const [page, setpage] = useState(1)
    const [update, setupdate] = useState(false)
    const [period, setperiod] = useState('')

    const handleFilter = (event) => {
        if (event.target.value === '') {
            setpage(1)
            setupdate(prev => !prev)
        }
        setFilter(event.target.value);
    }

    const handleSearch = () => {
        if (filter) {
            setpage(1)
            setupdate(prev => !prev)
        }

    }

    const [periods, setperiods] = useState([])

    useEffect(() => {
        apiGetPeriods().then(res => {
            console.log(res.data)
            setperiods(res.data.map(i => ({ value: i.id, label: i.description })))

            let find = res.data.find(i => i.isActive)

            if (find) {
                setperiod(find.id)
            } else {
                setperiod(res.data[res.data.length - 1].description)
            }
        }).catch(err => {
            new Error(err)
        })
    }, [])

    return (
        <>
            <section style={{ ...classes.box, flexDirection: 'column' }}>
                <div style={{ ...classes.box, width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', }}>
                    <Search value={filter} onChange={handleFilter} onSearch={handleSearch} placeholder="Buscar..." style={{ width: '50%' }} />
                    <div style={{ width: '20%' }}>

                        <Form.Item help='Seleccione el periodo' >
                            <Select
                                value={period}
                                onChange={e => setperiod(e)}
                                options={periods}
                            />
                        </Form.Item>
                    </div>

                </div>
                <div style={{ width: '100%', height: '80vh', overflowY: 'auto' }}>
                    <BudgetTable period={period} update={update} setupdate={setupdate} filter={filter} page={page} setpage={setpage} />
                </div>
            </section>

        </>
    )
}


const classes = {
    textfield: {
        width: '60%',
        // marginRight: '10%',
        backgroundColor: '#FFF',
        borderRadius: '20px 0 0 20px',
    },
    button: {
        width: 'auto',
        textTransform: 'capitalize',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchButton: {
        color: '#fff',
        backgroundColor: prMain,
        height: 40,
        width: 40,
        borderRadius: '0 20px 20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-7px',
        marginLeft: '-2px'
    },
}

export default BudgetPage