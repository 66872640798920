import { useEffect, useState } from 'react'
import { DatePicker, Select, Spin, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES';
import { WeekChart } from '../../charts/WeekChart';
import { apiGetReportsFilter } from '../../../utils/api';

export const ReportsWeek = () => {

    const [lengths, setLengths] = useState({
        pending: [],
        inProcess: [],
        review: [],
        solved: []
    });
    const [loadingReports, setloadingReports] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [toDate, setToDate] = useState(moment().endOf('isoWeek').format('YYYY/M/D'));
    const [fromDate, setFromDate] = useState(moment().startOf('isoWeek').format('YYYY/M/D'));

    const [type, settype] = useState('bar')

    const handleRange = (date, dateString) => {
        setFromDate(moment(date).startOf('isoWeek').format('YYYY/M/D'))
        setToDate(moment(date).endOf('isoWeek').format('YYYY/M/D'))
    }

    useEffect(() => {
        // data = data.filter(item => moment(item.createdAt) >= moment(fromDate) && moment(item.createdAt) <= moment(toDate))
        setloadingReports(true)
        apiGetReportsFilter(fromDate, toDate).then(res => {
            let data = res.data

            let dataCreated = data.filter(item => item.createdAt)
            let dataAssigned = data.filter(item => item.assignedAt)
            let dataReview = data.filter(item => item.inReviewAt)
            let dataCompleted = data.filter(item => item.completedAt)

            // console.log(dataCompleted)
            setLengths(prev => (
                {
                    ...prev,
                    pending: filterReportsLength(dataCreated.filter(i => i.status.id === 1), 1),
                    inProcess: filterReportsLength(dataAssigned.filter(i => i.status.id === 2), 2),
                    review: filterReportsLength(dataReview.filter(i => i.status.id === 3), 3),
                    solved: filterReportsLength(dataCompleted.filter(i => i.status.id === 4), 4)
                }))
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloadingReports(false)
        })

        setTimeout(() => {
            setRefresh(!refresh)

        }, [120000])
    }, [toDate, fromDate, refresh])

    const filterReportsLength = (data, status) => {
        let length = {
            'lunes': 0,
            'martes': 0,
            'miércoles': 0,
            'jueves': 0,
            'viernes': 0,
            'sábado': 0,
            'domingo': 0
        }
        data.forEach(element => {

            let day
            switch (status) {
                case 1:
                    day = moment(element.createdAt).format('dddd')
                    break;
                case 2:
                    day = moment(element.assignedAt).format('dddd')
                    break;
                case 3:
                    day = moment(element.inReviewAt).format('dddd')
                    break;
                case 4:
                    day = moment(element.completedAt).format('dddd')
                    break;

                default:
                    break;
            }

            length[day]++

        })
        return Object.values(length)
    }

    const handleChageType = (value) => {
        settype(value)
    }

    return (
        <Spin spinning={loadingReports}>
            <div style={{ height: '100%', width: '100%', padding: 10, borderRadius: 5, backgroundColor: '#FFF' }}>
                <section style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div>
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 18 }}>Reportes totales de la semana</Typography.Text>
                        <Select
                            size='small'
                            style={{ width: 'auto', marginLeft: 10 }}
                            value={type}
                            onChange={handleChageType}
                        >
                            <Select.Option value='bar'>Columnas</Select.Option>
                            <Select.Option value='line'>Líneas</Select.Option>
                        </Select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                        <DatePicker
                            onChange={handleRange}
                            defaultValue={moment()}
                            picker='week'
                            size='small'
                            locale={locale}
                            allowClear={false}
                        />
                        <Typography.Text style={{ color: '#6D6D6D', fontSize: 12, marginTop: 10 }}>Del {moment(fromDate).format('DD/MM/YYYY')} al {moment(toDate).format('DD/MM/YYYY')}</Typography.Text>
                    </div>
                </section>
                {
                    type === 'bar' &&
                    <WeekChart data={lengths} type='bar' />
                }
                {
                    type === 'line' &&
                    <WeekChart data={lengths} type='line' />
                }
            </div>
        </Spin>
    )
}
