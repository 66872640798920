import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Col, Row, Input, Form, DatePicker, Modal, Radio } from "antd"
import { apiGetJaversReports, apiGetJaversUsers } from '../../../../utils/api'
import moment from "moment"
import locale from 'antd/es/date-picker/locale/es_ES'
import { prMain } from '../../../../themeConfig'
import HistoricalDetails from './HistoricalDetails'
import { ReportTable } from './ReportTable'
import { UsersTable } from './UsersTable'

const REPORTS_TABLE = 1
const USER_TABLE = 2

export const HistoricalPage = () => {

  const { RangePicker } = DatePicker;

  const current = new Date();
  const defaultTo = new Date(current)
  const defaultFrom = new Date('1/1/2022')

  const [historic, setHistoric] = useState([])
  const [loading, setLoading] = useState(false)
  const [reportKey, setReportKey] = useState("")
  const [fromDate, setFromDate] = useState(moment(defaultFrom).format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(moment(defaultTo).format('YYYY-MM-DD'))
  const [refresh, setRefresh] = useState(false)

  const [details, setDetails] = useState([])
  const [showDetails, setShowDetails] = useState(false)
  // const [userId, setUserId] = useState("")

  const [totalData, settotalData] = useState(0)

  const [page, setpage] = useState(1)
  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({ key: '', sorter: undefined })

  const [option, setoption] = useState(REPORTS_TABLE)

  useEffect(() => {
    setLoading(true)

    if (option === REPORTS_TABLE) {

      apiGetJaversReports(page, pageSize, null, `${fromDate}T00:00:00`, `${toDate}T23:59:59`, null).then((response) => {
        settotalData(response.data.totalElements)
        setHistoric(response.data.content)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
    } else {
      apiGetJaversUsers(page, pageSize, null, `${fromDate}T00:00:00`, `${toDate}T23:59:59`, null).then((response) => {
        settotalData(response.data.totalElements)
        setHistoric(response.data.content)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
    }
  }, [refresh, page, pageSize, option])


  const handleDetails = (row) => {
    setDetails(row)
    setShowDetails(true)

  }

  function containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  const handleChangeKey = (event) => {
    let value = event.target.value;

    if (!containsOnlyNumbers(value)) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
      setReportKey(event.target.value)
    } else {
      setReportKey(value)
      // setRefresh(!refresh)
    }
    setFromDate(moment(defaultFrom).format('YYYY-MM-DD'));
    setToDate(moment(defaultTo).format('YYYY-MM-DD'));
  }

  const handleRange = (event, newValue) => {
    setFromDate(moment(newValue[0]).format('YYYY-MM-DD'));
    setToDate(moment(newValue[1]).format('YYYY-MM-DD'));
    setReportKey("")
    // console.log(moment(newValue[1]).endOf('isoWeek').format('YYYY/M/D'))

    // getDataReport(null, null, newValue[0], newValue[1])
    // handleDateChanged()
  }

  const handleFilter = (key) => {
    let id = reportKey ? reportKey : null
    let from = fromDate ? `${fromDate}T00:00:00` : null
    let to = toDate ? `${toDate}T23:59:59` : null
    let user = null//userId ? userId : null

    setLoading(true)

    if (option === REPORTS_TABLE) {
      apiGetJaversReports(key ? 1 : page, pageSize, id, from, to, user).then((response) => {
        settotalData(response.data.totalElements)

        setHistoric(response.data.content)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
    } else {
      apiGetJaversUsers(key ? 1 : page, pageSize, id, from, to, user).then((response) => {
        settotalData(response.data.totalElements)

        setHistoric(response.data.content)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
    }
  }

  const handleClear = () => {
    setReportKey("")
    setFromDate(moment(defaultFrom).format('YYYY-MM-DD'))
    setToDate(moment(defaultTo).format('YYYY-MM-DD'))

    setRefresh(!refresh)
  }

  const handleTable = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter)
    setpage(pagination.current)
    setpageSize(pagination.pageSize)
    setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : null }))
  }

  const handleOption = ({ target: { value } }) => {
    setpage(1)
    setoption(value)
  }

  return (
    <div>
      <Row style={{ width: '100%', display: 'flex', gap: '1vw', marginBottom: '2vh' }}>
        <Col xl={4} lg={4} md={8} sm={8} xs={8}>
          <Form.Item help={`Buscar por ${option === REPORTS_TABLE ? 'folio' : 'Id'}`}>
            <Tooltip title="No toma en cuenta filtro de fechas">
              <Input onChange={handleChangeKey} onPressEnter={() => handleFilter(reportKey)} value={reportKey} placeholder={`${option === REPORTS_TABLE ? 'Folio' : 'Id'}`} size='middle' />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col xl={8} lg={8} md={9} sm={9} xs={9} offset={1} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
          {/* <label style={{ marginTop: -18, fontSize: 12, marginLeft: 20 }}>Rango de Fechas</label> */}
          <div style={classes.dateField}>
            <Form.Item help='Rango de fechas'>
              <Tooltip title="No toma en cuenta filtro de folio">
                <RangePicker
                  onChange={handleRange}
                  value={[moment(fromDate), moment(toDate)]}
                  locale={locale}
                  allowClear={false}
                  size='middle'
                  style={{ width: '100%' }}
                />
              </Tooltip>
            </Form.Item>
          </div>
          {/* <Button type='link' onClick={handleFilterToday}>Filtrar reportes de hoy</Button> */}
        </Col>
        <Col xl={5} lg={5} md={5} sm={5} xs={5} offset={1}>
          <Button type='primary' onClick={() => handleFilter()} >Filtrar</Button>
          <Button type="link" onClick={() => handleClear()}>limpiar filtros</Button>
        </Col>
      </Row>

      <Radio.Group onChange={handleOption} value={option} size="small" style={{ marginBottom: '1rem' }}>
        <Radio.Button value={REPORTS_TABLE}>Reportes</Radio.Button>
        <Radio.Button value={USER_TABLE}>Usuarios</Radio.Button>
      </Radio.Group>

      {
        option === 1
          ? (
            <ReportTable handleDetails={handleDetails} handleTable={handleTable} historic={historic} loading={loading} totalData={totalData} />
          )
          : (
            <UsersTable handleDetails={handleDetails} handleTable={handleTable} historic={historic} loading={loading} totalData={totalData} />
          )
      }


      <Modal
        centered
        visible={showDetails}
        closable={true}
        footer={null}
        destroyOnClose
        onCancel={() => setShowDetails(false)}
      >

        <HistoricalDetails option={option} details={details} />

      </Modal>
    </div>
  )
}

const classes = {
  container: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 20,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  button: {
    width: '17%',
    backgroundColor: 'white',
    color: 'inherit',
    // marginRight: '30px',
    marginTop: '10px',
    marginBottom: '5px',
    position: 'relative',
    textTransform: 'capitalize',

  },
  button2: {
    width: '200px',
    backgroundColor: 'white',
    color: 'inherit',
    marginTop: '10px',
    marginBottom: '5px',
    position: 'relative',
    left: '5%',
    textTransform: 'capitalize',

  },
  searchField: {
    height: '40px',
    width: '175px',
    backgroundColor: '#fff',
    borderRadius: '20px 0 0 20px',
  },
  dateField: {
    borderRadius: '20px',
    // backgroundColor: '#fff',
    border: '0px',
    outline: 'none',
    height: 40,
    width: '100%',
  },
  inputField: {
    border: '0px',
    height: '40px',
    width: '200px',
    paddingLeft: '20px',
    paddingRight: '10px',
    borderRadius: '20px',
  },
  inputDate: {
    border: '0px',
    marginLeft: '20px',
    height: '40px',
    width: '40%',
    paddingRight: '10px',
    fontFamily: 'Arial',
  },
  mapContainer: {
    width: '90%',
  },
  gridContainer: {
    width: '100%',
  },
  dialogTitle: {
    color: prMain,
    fontWeight: 'bold',
    textAlign: 'right',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '20px'
  },
  buttons: {
    width: '75%',
    margin: '0 auto 20px auto',
  },
  buttonDialog: {
    width: '100%',
    marginTop: 20,
  },
  twoButtons: {
    width: '50%',
    margin: '15px',
    marginBottom: '0px',
    textTransform: 'capitalize',
  },
  select: {
    height: 40,
    width: '90%',
    border: '0px',
    borderRadius: 20,
    backgroundColor: '#fff',
  },
  inputlabel: {
    // verticalAlign: 'center'  
    marginTop: '-7px',
  },

}