import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import moment from 'moment'
import { prMain } from '../../../themeConfig'

export const Comments = ({ comments, data, setviewcomments }) => {

    return (
        <div>
            <LeftOutlined style={{ color: prMain, cursor: 'pointer' }} onClick={() => setviewcomments(false)} />
            <Typography.Title level={5} style={{ width: '100%', textAlign: 'center', marginBottom: 25 }}>Historial de observaciones y/o material utilizado del reporte con folio {data.id}</Typography.Title>
            <section style={{ height: 500, overflowY: 'auto' }}>
                {comments
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map(item => (
                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', borderBottom: '1px solid #E3E3E3', marginBottom: 15, padding: 10 }}>
                            <Typography.Text style={{ fontSize: 14 }}>{item.userDto.name} {item.userDto.lastName}</Typography.Text>
                            {
                                item.comment.split('\\n').map((item, i) => (
                                    <p key={i} style={{ fontSize: 12, opacity: .7, marginTop: i > 0 ? -12 : 0 }}>{item.replace(/['"]+/g, '')}</p>
                                ))
                            }
                            <Typography.Text style={{ alignSelf: 'flex-end', opacity: 0.6, fontSize: 12 }}>{moment(item.date).format('DD/MMMM/YYYY [a las] hh:mm a')}</Typography.Text>
                        </div>
                    ))}
            </section>
        </div>
    )
}
