import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { prMain } from '../../../themeConfig'

export const Description = ({ description, data, setviewDescription }) => {

    return (
        <div>
            <LeftOutlined style={{color: prMain, cursor: 'pointer'}} onClick={() => setviewDescription(false)}/>
            <Typography.Title level={5} style={{width: '100%', textAlign: 'center', marginBottom: 25}}>Descripción del reporte con folio {data.id}</Typography.Title>
            <section style={{height: 500, overflowY: 'auto', padding: '0 30px 0 30px'}}>
                <Typography.Text style={{textAlign:'justify', fontSize: 14}}>{description}</Typography.Text>
            </section>
        </div>
    )
}
