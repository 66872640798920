import React, { useState } from "react"
import { Table, Typography, Tooltip, Button, Col, Row } from "antd"
import moment from "moment"
import { prMain } from "../../../themeConfig"
import { EyeOutlined, ArrowLeftOutlined } from "@ant-design/icons"
// import ReportChangesDetail from "../requests/UpdatedReports/ReportChangesDetail"

export const HistoricReports = ({ report, setclose, refresh, setRefresh }) => {
    const { Text } = Typography
    const history = report.history
    const loading = !report
    const [showDetails, setShowDetails] = useState(false)
    const [oldData, setOldData] = useState([])
    const [newData, setNewData] = useState([])

    const columns = [

        {
            title: 'Fecha',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '20%',
            render: (updatedAt) => (
                <Text>
                    {moment(updatedAt).format('DD/MM/YYYY hh:mm a')}
                </Text>
            ),
            sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
            // ellipsis: {
            //     showTitle: true
            // }
        },
        {
            title: 'Responsable',
            dataIndex: 'nameResponsibleUser',
            key: 'nameResponsibleUser',
            width: '30%',
            // sorter: (a, b) => a.id - b.id,
            // ellipsis: {
            //     showTitle: true
            // },
            render: nameResponsibleUser => (
                <Tooltip title={nameResponsibleUser}>
                    {nameResponsibleUser}
                </Tooltip>
            )
        },
        {
            title: 'Razón',
            dataIndex: 'reason',
            key: 'reason',
            width: '40%',
            // sorter: (a, b) => a.id - b.id,
            // ellipsis: {
            //     showTitle: true
            // },
            render: reason => (
                <Tooltip title={reason}>
                    {reason}
                </Tooltip>
            )
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (row) => (
                <Tooltip title='Detalles' >
                    <Button type='link' icon={<EyeOutlined onClick={() => handleDetails(row)} />} />
                </Tooltip>
            ),
            width: '10%'
        },
        
        

    ]

    const handleDetails = (row) => {
        setOldData(JSON.parse(row.oldData))
        setNewData(JSON.parse(row.newData))
        setShowDetails(true)
    }

    return (
        <div>
            { showDetails
            ? (
                <div>
                     <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '4vh', flexDirection: 'row' }}>
                         <Tooltip title="Volver">
                         <Button style={{ position:'absolute', left: 20, color: '#000' }} type="link" icon={<ArrowLeftOutlined onClick={() => setShowDetails(false)} />} />
                         </Tooltip>
                    <Text style={{ fontSize: 20, fontWeight: 'bold', color: prMain }}>Detalles de modificación</Text>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2vh' }}>
                    
                        <Row style={{ fontSize: 18, borderBottom: `1px solid ${prMain}` }}>
                            <Text style={{ color: prMain }}>{"Datos modificados"}</Text>
                        </Row>  
                        { (newData.description && oldData.description !== newData.description) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1}>
                            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                Descripción
                            </Text>
                            </Col>
                            <Col span={16} >
                            <Text style={{ fontSize: 11 }}>
                                {newData.description}
                            </Text>
                            </Col>
                        </Row>
                        }
                        { (newData.address && oldData.address !== newData.address) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1} >
                            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                Dirección
                            </Text>
                            </Col>
                            <Col span={16} >
                            <Text style={{ fontSize: 11 }}>
                                {newData.address}
                            </Text>
                            </Col>
                        </Row>
                        }
                        { (newData.Services && oldData.Services !== newData.Services) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1} >
                            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                Servicio
                            </Text>
                            </Col>
                            <Col span={16} >
                            <Text style={{ fontSize: 11 }}>
                                {newData.Services}
                            </Text>
                            </Col>
                        </Row>
                        }
                        <Row style={{ fontSize: 18, borderBottom: `1px solid ${prMain}`, marginTop: '3vh' }}>
                            <Text style={{ color: prMain }}>{"Datos anteriores"}</Text>
                        </Row>
                        { (oldData.description && oldData.description !== newData.description) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1}>
                                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                    Descripción
                                </Text>
                            </Col>
                            <Col span={16} >
                                <Text style={{ fontSize: 11 }}>
                                    {oldData.description}
                                </Text>
                            </Col>
                        </Row>
                        }
                        { (oldData.address && oldData.address !== newData.address) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1}>
                                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                    Dirección
                                </Text>
                            </Col>
                            <Col span={16} >
                                <Text style={{ fontSize: 11 }}>
                                    {oldData.address}
                                </Text>
                            </Col>
                        </Row>
                        }
                        { (oldData.Services && oldData.Services !== newData.Services) &&
                        <Row style={{ marginTop: '3vh' }}>
                            <Col span={6} offset={1} >
                            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                Servicio
                            </Text>
                            </Col>
                            <Col span={16} >
                            <Text style={{ fontSize: 11 }}>
                                {oldData.Services}
                            </Text>
                            </Col>
                        </Row>
                        }
                </div>
                </div>
            ) : (
            <div>

            <Text style={{ fontSize: 20, display: 'flex', justifyContent: 'center', marginBottom: '2vh', color: prMain }}>
                Histórico de reporte
            </Text>
            <Table
                rowKey={record => record.id}
                loading={loading}
                columns={columns}
                dataSource={history}
                size="small"
                // scroll={{ y: height > 940 ? '49vh' : '39vh' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
                <div style={{ marginTop: -50 }}>
                    <Button onClick={() => {setclose(false)}} type="link">Cerrar</Button>
                </div>
            </div>
            )}
        </div>
    )
}