import moment from 'moment';
import apiClient from '../axiosConfig';
import { ASSIGNED_BY, CREATE_BY, IN_CHARGE } from '../components/pages/reports/DisplayedMap';

export const url_api = `http://${process.env.REACT_APP_URL_NAME}`

export const requestChangePassword = async (oldPassword) => {
  let params = new URLSearchParams({ oldPassword })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/request`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
  // }

}
export const apiSetPassword = async (password) => {

  let params = new URLSearchParams({ password })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/setPassword`,
    data: params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
  // }

}

export const apiChangePassword = async (code, newPassword) => {
  let params = new URLSearchParams({ code, newPassword })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/changePassword`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}


export const getDataProfile = async (id) => {
  let idData = new URLSearchParams({ id })
  return await apiClient({
    method: 'GET',
    url: `/api/user`,
    params: idData,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const forgotPassword = async (email) => {
  let login = new URLSearchParams({ email })
  return await apiClient({
    method: 'GET',
    url: `/api/user/forgetPassword`,
    params: login,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiLogin = async (email, password) => {

  let login = new URLSearchParams({ login: email, password: password })
  return await apiClient({
    method: 'POST',
    url: `/api/login`,
    data: login,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiLoginSocial = async (token) => {
  let login = new URLSearchParams({ token: token })
  return await apiClient({
    method: 'POST',
    url: `/api/login/social`,
    data: login,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRegister = async (data) => {
  return await apiClient({
    method: 'POST',
    url: `/api/user/register`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiAllUsers = async (path) => {
  return await apiClient({
    method: 'GET',
    url: `/api/user/all`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiUsers = async (path) => {
  if (path === '/usuarios') {
    return await apiClient({
      method: 'GET',
      url: `/api/user`,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  } else if (path === '/ciudadanos') {
    return await apiClient({
      method: 'GET',
      url: `/api/user/citizen`,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  } else {
    return await apiClient({
      method: 'GET',
      url: `/api/user`,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  }
}
export const apiUsersPaginationSearch = async (path, intPage, size, order, key, search) => {

  intPage = intPage - 1
  const params = new URLSearchParams({ intPage, size, search })

  if (order && key) {
    params.append('key', key)
    params.append('order', order)

  }

  if (path === '/usuarios') {
    return await apiClient({
      method: 'GET',
      url: `/api/user/search/pageable`,
      params,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  } else if (path === '/ciudadanos') {
    return await apiClient({
      method: 'GET',
      url: `/api/user/citizen/search/Pageable`,
      params,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  }
}
export const apiUsersPagination = async (path, intPage, size, order, key) => {
  intPage = intPage - 1

  const params = new URLSearchParams({ intPage, size })

  if (order && key) {
    params.append('key', key)
    params.append('order', order)

  }


  if (path === '/usuarios') {
    return await apiClient({
      method: 'GET',
      url: `/api/user/pageable`,
      params,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  } else if (path === '/ciudadanos') {
    return await apiClient({
      method: 'GET',
      url: `/api/user/citizen/Pageable`,
      params,
      config: {
        headers: {
          'Accept': 'application/json',
        }
      }
    });
  }
}

export const apiAddUsers = async (data) => {
  return await apiClient({
    method: 'POST',
    url: `/api/user`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiAddUsersCapturist = async (data) => {
  return await apiClient({
    method: 'POST',
    url: `/api/user/capturist`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiSearchUserByPhone = async (phone) => {
  let params = new URLSearchParams({ phone })
  return await apiClient({
    method: 'GET',
    url: `/api/user/byPhone`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiResendCode = async (code, select) => {
  let params = new URLSearchParams({ code, select })
  return await apiClient({
    method: 'GET',
    url: `/api/user/reSendCode`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiEditUsers = async (data, id) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/user/admin/${id}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiEditUser = async (data) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/user/${data.id}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiDeleteUsers = async (index) => {
  return await apiClient({
    method: 'DELETE',
    url: `/api/user/${index}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiDependencies = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/govDependency`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiCreateDependencies = async (data) => {
  return await apiClient({
    method: 'POST',
    url: `/api/govDependency`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiUpdateDependencies = async (id, data) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/govDependency/${id}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiDeleteDependencies = async (id) => {
  return await apiClient({
    method: 'DELETE',
    url: `/api/govDependency/${id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRoles = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/roles`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReadAllNotifications = async (id) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/report/user/readAll/notification/${id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReadNotification = async (notification) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/report/user/notification/${notification.id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiNotifications = async (rol) => {
  return await apiClient({
    method: 'GET',
    url: `/api/report/user/notification`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}


export const apiReportType = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/reportType`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiCreateReport = async (images, report) => {
  let data = new FormData()
  let imgs = images.filter(i => i !== null)
  if (imgs.length > 0) {
    imgs.forEach(i => {
      data.append('file', i)
    })
  }

  data.append('report', JSON.stringify(report))

  return await apiClient({
    method: 'POST',
    url: `/api/report`,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  });
}
export const apiCreateReportCRM = async (id, images, report) => {
  let data = new FormData()

  if (images) {
    data.append('files', images.join(','))
  }
  data.append('idReport', id)
  data.append('report', JSON.stringify(report))

  return await apiClient({
    method: 'POST',
    url: `/api/report/crm`,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  });
}

export const apiReports = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/report`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReportsByMonths = async (Year, Month) => {
  let params = new URLSearchParams({ Year, Month })

  return await apiClient({
    method: 'GET',
    url: `/api/report/Month`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiReportsById = async (id) => {
  let params = new URLSearchParams({ folio: id })
  return await apiClient({
    method: 'GET',
    url: `/api/report`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReportsByIdComplete = async (id) => {
  let params = new URLSearchParams({ folio: id })
  return await apiClient({
    method: 'GET',
    url: `/api/report/allComplete`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReportsByStatus = async (id) => {
  let params = new URLSearchParams({ id })
  return await apiClient({
    method: 'GET',
    url: `/api/report/status`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReportsByStatusPagination = async (id, intPage, size, key, order) => {
  console.log(intPage)
  let params = new URLSearchParams({ id, intPage: intPage - 1, size })

  if (key && order) {
    params.append('key', key)
    params.append('order', order)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/report/status/page`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiReportsByStatusPaginationTotal = async (id) => {

  let params = new URLSearchParams({ id })

  return await apiClient({
    method: 'GET',
    url: `/api/report/status/count`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiDeleteReport = async (id) => {
  return await apiClient({
    method: 'DELETE',
    url: `/api/report/${id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiEditService = async (idReport, idServices) => {
  let params = new URLSearchParams({ idReport, idServices });
  return await apiClient({
    method: 'PUT',
    url: `/api/report/changeService`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiReportsFilter = async (statusOptional, status, from, to, reportKey, owner, typeIds, option, users) => {

  let params = new URLSearchParams()

  if (option === CREATE_BY && owner) {
    params.append('owner', owner)
  } else if (option === ASSIGNED_BY && users) {
    params.append('assignedBy', users)
  } else if (option === IN_CHARGE && users) {
    params.append('assigned', users)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/report/filter?from=${moment(from).format('YYYY-M-D')}T00:00:00.000Z&to=${(moment(to).format('YYYY-M-D'))}T23:59:00.000Z${(statusOptional || status) ? `&statusId=${statusOptional ? statusOptional : status}` : ''}${reportKey > 0 ? '&reportId=' + reportKey : ''}${typeIds.length > 0 ? '&reportTypes=' + typeIds : ''}`,
    params,
    // url: `/api/report/filter?from=${moment(from).format('YYYY-M-D')}T00:00:00.000Z&to=${(moment(to).format('YYYY-M-D'))}T23:59:00.000Z${(statusOptional || status) ? `&statusId=${statusOptional ? statusOptional : status}` : ''}${reportKey > 0 ? '&reportId=' + reportKey : ''}${userIds.length > 0 ? `&${option === CREATE_BY ? 'report_created_by_user' : option === ASSIGNED_BY ? 'id_assigned_by' : 'usersId'}=` + userIds : ''}${typeIds.length > 0 ? '&reportTypes=' + typeIds : ''}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiReportsFilterPagination = async (statusOptional, status, from, to, reportKey, owner, typeIds, option, intPage, size, key, order, statusReport, NoTrack, users) => {

  let params = new URLSearchParams({
    intPage: intPage - 1,
    size,
    expired: statusReport,
    NoTrack
  });

  if (key && order) {
    params.append('key', key)
    params.append('order', order)
  }

  if (option === CREATE_BY && owner) {
    params.append('owner', owner)
  } else if (option === ASSIGNED_BY && users) {
    params.append('assignedBy', users)
  } else if (option === IN_CHARGE && users) {
    params.append('assigned', users)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/report/filter/Page?from=${moment(from).format('YYYY-M-D')}T00:00:00.000Z&to=${(moment(to).format('YYYY-M-D'))}T23:59:00.000Z${(statusOptional || status) ? `&statusId=${statusOptional ? statusOptional : status}` : ''}${reportKey > 0 ? '&reportId=' + reportKey : ''}${typeIds.length > 0 ? '&reportTypes=' + typeIds : ''}`,
    // url: `/api/report/filter/Page?from=${moment(from).format('YYYY-M-D')}T00:00:00.000Z&to=${(moment(to).format('YYYY-M-D'))}T23:59:00.000Z${(statusOptional || status) ? `&statusId=${statusOptional ? statusOptional : status}` : ''}${reportKey > 0 ? '&reportId=' + reportKey : ''}${userIds.length > 0 ? `&${option === CREATE_BY ? 'report_created_by_user' : option === ASSIGNED_BY ? 'id_assigned_by' : 'usersId'}=` + userIds : ''}${typeIds.length > 0 ? '&reportTypes=' + typeIds : ''}`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiGetReportsFilter = async (from, to) => {
  return await apiClient({
    method: 'GET',
    url: `/api/report/filter?from=${moment(from).format('YYYY-M-D')}T00:00:00.000Z&to=${(moment(to).format('YYYY-M-D'))}T23:59:00.000Z`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiStatusReport = async (data, inCharge, statusId) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/report/changeStatus?id=${data.id}&status_id=${statusId}&notificationTypeId=1${inCharge ? `&UserID=${inCharge}` : ''}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiAssignmentReports = async (idReport, images, reason, servicesDto, items) => {
  let data = new FormData()

  let imgs = images.filter(i => i !== null)
  if (imgs.length > 0) {
    imgs.forEach(i => {
      data.append('file', i)
    })
  }

  if(servicesDto === 'Alumbrado'){
    data.append('items', JSON.stringify(items))
  }
  data.append('comment', reason)
  data.append('idReport', idReport)

  return await apiClient({
    method: 'POST',
    url: `/api/report/review`,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  });
}

export const apiUpdatReportType = async (id, data) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/reportType/${id}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRequestChangeEmail = async (password, newEmail) => {
  // const data = new FormData() 
  // data.append('password', password)
  let data = new URLSearchParams({ password, newEmail })
  return await apiClient({
    method: 'GET',
    url: `/api/user/changeEmail`,
    params: data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRequestChangePhone = async (newPhone) => {
  let data = new URLSearchParams({ newPhone })

  return await apiClient({
    method: 'GET',
    url: `/api/user/changePhone`,
    params: data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiChangeData = async (param, value, code) => {

  let data = new URLSearchParams({ [param]: value, code })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/changeData`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRequestAdmin = async (param, value, password, id) => {

  let data = new URLSearchParams({ [param]: value, password, id })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/admin/request`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiChangeStatusUser = async (id, idStatus) => {

  let data = new URLSearchParams({ id, idStatus })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/admin/Status`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiActiveUser = async (phone, temporalPassword) => {

  let data = new URLSearchParams({ phone, temporalPassword })
  return await apiClient({
    method: 'PUT',
    url: `/api/user/activeUser`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetColonys = async (id) => {

  let params = new URLSearchParams({ id })
  return await apiClient({
    method: 'GET',
    url: `/api/colonia`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiGetSMSConfig = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/config/phone`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiGetChangeSMS = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/AdminApis`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiListRequestDelete = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/report/listRequestDelete`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiAllDeleteReports = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/report/allDeleteReport`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiRequestDelete = async (id, reason) => {

  let data = new URLSearchParams({ idReport: id, Reason: reason })
  return await apiClient({
    method: 'PUT',
    url: `/api/report/request`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiApproveDelete = async (id, option, reason) => {

  return await apiClient({
    method: 'PUT',
    url: `/api/report/approvedDelete?idReport=${id}&Param=${option}${reason ? `&reason=${reason}` : ''}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetScholarships = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/Scholarships`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  });
}
export const apiGetScholarshipsPagination = async (intPage, size, order, key) => {

  intPage = intPage - 1

  let params = new URLSearchParams({ intPage, size })

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/Scholarships/pageable`,
    params,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  });
}

export const apiGetScholarshipsPaginationSearch = async (intPage, size, order, key, search) => {

  intPage = intPage - 1

  let params = new URLSearchParams({ intPage, size, search })

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/Scholarships/search/pageable`,
    params,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  });
}

export const apiApproveScholarship = async (curp, idStatus, type) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/Scholarships/changeStatus?curp=${curp}&status=${idStatus}&type=${type}&reasonForReject=${''}`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  })
}

export const apiRejectScholarship = async (curp, idStatus, reason, type) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/Scholarships/changeStatus?curp=${curp}&status=${idStatus}&reasonForReject=${reason}&type=${type}`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  })
}

export const apiRejectScholarshipFiles = async (folio, idFiles) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/Scholarships/rejectFiles?Folio=${folio}&idFiles=${idFiles}`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  })
}

export const apiRejectScholarshipFilesBeneficiary = async (idBeneficiary, idFiles) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/Scholarships/rejectFiles?idBeneficiary=${idBeneficiary}&idFiles=${idFiles}`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  })
}

export const apiGetScholarshipsByCurp = async (curp) => {
  return await apiClient({
    method: 'GET',
    url: `/api/Scholarships/beneficiary?curp=${curp}`,
    config: {
      headers: {
        "Accept": 'application/json',
      }
    }
  })
}

export const apiSendScholarshipNotification = async (curps) => {
  // let data = new FormData()
  // data.append('curps', curps)

  let params = new URLSearchParams({ curps })

  return await apiClient({
    method: 'GET',
    url: `/api/Scholarships/sendNotification`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',

      }
    }
  });
}

export const apiAddObservation = async (folio, observation) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/report/addObservation?Folio=${folio}&observation=${observation}`,
    config: {
      headers: {
        'Accept': 'application/json'
      }
    }
  })

}


export const apiGetProjects = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/budget`,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }
  })
}

export const apiGetProjectById = async (id) => {
  let params = new URLSearchParams({id})
  return await apiClient({
    method: 'GET',
    url: `/api/budget`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }
  })
}
export const apiGetProjectsPagination = async (intPage, size, order, key, idPeriod) => {
  intPage = intPage - 1


  let params = new URLSearchParams({ intPage, size, idPeriod })

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/budget/pageable`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }
  })
}
export const apiGetProjectsPaginationSearch = async (intPage, size, order, key, search, idPeriod) => {
  intPage = intPage - 1

  let params = new URLSearchParams({ intPage, size, search, idPeriod })

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/budget/search/pageable`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }
  })
}

export const apiStatusBudget = async (idBudget, status) => {
  return await apiClient({
    method: 'PUT',
    url: `api/budget/changeStatus?idBudget=${idBudget}&Status=${status}`,

  })
}

export const apiGetUpdateRequested = async () => {
  return await apiClient({
    method: 'GET',
    url: `api/report/getRequestUpdate`,
    config: {
      headers: {
        'Accept': 'application/json'
      }
    }
  })
}

export const apiGetUpdateAccepted = async () => {
  return await apiClient({
    method: 'GET',
    url: `api/report/getRequestUpdateAccepted`,
    config: {
      headers: {
        'Accept': 'application/json'
      }
    }
  })
}

export const apiRequestUpdate = async (id, reason, params) => {

  return await apiClient({
    method: 'POST',
    url: `api/report/requestUpdate?id=${id}&Reason=${reason}`,
    data: params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiAcceptUpdate = async (id, flag) => {
  return await apiClient({
    method: 'PUT',
    url: `api/report/acceptedOrDenied?id=${id}&trueOrFalse=${flag}`
  })
}

export const apiAddReportPhotos = async (id, images) => {
  let data = new FormData()
  let imgs = images.filter(i => i !== null)
  if (imgs.length > 0) {
    imgs.forEach(i => {
      data.append('file', i)
    })
  }

  return await apiClient({
    method: 'PUT',
    url: `/api/report/addPhoto/${id}`,
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  })
}

export const apiGetJaversUsers = async (page, amount, reportId, from, to, userId) => {
  return await apiClient({
    method: 'GET',
    url: `api/javers/user?page=${page - 1}&amount=${amount}${reportId ? `&reportId=${reportId}` : ""}${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}${userId ? `&userId=${userId}` : ""}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiGetJaversReports = async (page, amount, reportId, from, to, userId) => {
  return await apiClient({
    method: 'GET',
    //url: `api/javers/user?page=${page - 1}&amount=${amount}${reportId ? `&modifyUserId=${reportId}` : ""}${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}${userId ? `&userId=${userId}` : ""}`,
    url: `api/javers/report?page=${page - 1}&amount=${amount}${reportId ? `&reportId=${reportId}` : ""}${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}${userId ? `&userId=${userId}` : ""}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetWorkers = async (id) => {
  let data = new URLSearchParams({ id })
  return await apiClient({
    method: 'GET',
    url: `/api/user/work`,
    params: data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetGeofence = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/geofence`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiSetGeofence = async (coords, id) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/geofence/${id}`,
    data: coords,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiGetNearbyProjects = async (curp, type) => {
  let params = new URLSearchParams({ curp, type })
  return await apiClient({
    method: 'GET',
    url: `/api/budget/web/nearby`,
    params,
    // url: `/api/budget/nearby`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetVotedProjects = async (curp, onlyActive) => {

  let params = new URLSearchParams({ curp })

  if (onlyActive) {
    params.append('onlyActive', onlyActive)
  }
  return await apiClient({
    method: 'POST',
    url: `/api/budget/myVotes`,
    params,
    // url: `/api/budget/nearby`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiBudgetFindUser = async (curp) => {
  return await apiClient({
    method: 'GET',
    url: `/api/budget/web/findUser?Curp=${curp}`,
    // url: `/api/budget/findUser`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiBudgetVote = async (idBudget, idUser, type) => {
  let params = new URLSearchParams({ idBudget, idUser, type })
  return await apiClient({
    method: 'POST',
    url: `/api/budget/web/vote`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }

  })
}

export const apiGetPostcode = async (postcode) => {
  return await apiClient({
    method: 'GET',
    url: `/api/postcode/web?Postcode=${postcode}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiCreateUserProject = async (data) => {

  return await apiClient({
    method: 'POSt',
    url: '/api/budget/web/register',
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiCreateProject = async (budget, anuencia, attached_first, attached_second, project, letter) => {
console.log(budget, 'budget', anuencia, 'anuencia', attached_first, attached_second, project, 'project', letter, 'apiCreateProject')
  let data = new FormData()
  data.append('budget', JSON.stringify(budget))
  data.append('anuencia', anuencia)
  data.append('attached_first', attached_first)
  data.append('attached_second', attached_second)
  data.append('project', project)

  if (letter) {
    data.append('letter', letter)

  }

  return await apiClient({
    method: 'POST',
    url: `/api/budget`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }

  })
}

export const apiEditProject = async (budget, anuencia, attached_first, attached_second, project, letter) => {

  let data = new FormData()
  data.append('budget', JSON.stringify(budget))
  data.append('anuencia', anuencia)
  data.append('attached_first', attached_first)
  data.append('attached_second', attached_second)
  data.append('project', project)

  if (letter) {
    data.append('letter', letter)

  }

  return await apiClient({
    method: 'PUT',
    url: `/api/budget`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }

  })
}
export const apiUpdateUserProject = async (data) => {

  let params = new URLSearchParams({ curp: data.curp })

  return await apiClient({
    method: 'PUT',
    url: '/api/budget/web/update',
    params,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetConfig = async () => {
  return await apiClient({
    method: 'GET',
    url: '/api/config',
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetColonysVotes = async (id) => {

  let params = new URLSearchParams({ id })
  return await apiClient({
    method: 'GET',
    url: `/api/colonia/web`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetProjectsZone = async (idPeriod) => {

  let params = new URLSearchParams({ idPeriod })

  return await apiClient({
    method: 'GET',
    url: `/api/budget/forZone`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetEvents = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/event/web`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiGetEventsPagination = async (intPage, size, order, key) => {
  intPage = intPage - 1

  let params = new URLSearchParams({ intPage, size });

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/event/pageable`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetEventsPaginationSearch = async (intPage, size, order, key, search) => {
  intPage = intPage - 1

  let params = new URLSearchParams({ intPage, size, search });

  if (order && key) {
    params.append('order', order)
    params.append('key', key)
  }

  return await apiClient({
    method: 'GET',
    url: `/api/event/search/pageable`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetEvent = async (id) => {

  return await apiClient({
    method: 'GET',
    url: `/api/event/web?id=${id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiUpdateEvent = async (id, data, notification) => {
  let sendNotification = notification ? 1 : 0

  console.log(data)

  return await apiClient({
    method: 'PUT',
    url: `/api/event?id=${id}&sendNotification=${sendNotification}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiAddEvent = async (data, notification) => {
  let sendNotification = notification ? 1 : 0
  console.log(data)

  return await apiClient({
    method: 'POST',
    url: `/api/event?sendNotification=${sendNotification}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiUploadEventPhoto = async (idEvent, photo) => {
  let data = new FormData()
  data.append('banner', photo)

  return await apiClient({
    method: 'PUT',
    url: `/api/event/photo?id=${idEvent}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }

  })
}

export const apiUploadEventMessage = async (idEvent, photo, title, description) => {
  let data = new FormData()
  data.append('footPage', photo)

  return await apiClient({
    method: 'PUT',
    url: `/api/event/footPage?id=${idEvent}&title=${title}&message=${description}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }

  })
}

export const apiUpdateTemplate = async (id, photo, title, description) => {
  let data = new FormData()
  data.append('footPage', photo)

  return await apiClient({
    method: 'PUT',
    url: `/api/config/template?id=${id}&title=${title}&message=${description}`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    }

  })
}

export const apiAsignReports = async (data) => {

  let params = new URLSearchParams(data)

  return await apiClient({
    method: 'PUT',
    url: `/api/report/assigment`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetReportsByUser = async (idUser) => {

  let params = new URLSearchParams({ idUser })

  return await apiClient({
    method: 'GET',
    url: `/api/report/user`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiSendMassiveNotification = async (title, msg, topicR, type, data, id) => {

  let topic = `${topicR}${process.env.REACT_APP_TOPIC}`

  let params = new URLSearchParams({ title, msg, topic, type, data })

  if (id) {
    params.append('id', id)
  }

  return await apiClient({
    method: 'POST',
    url: `/api/AdminApis`,
    params,
  })
}

export const apiCancelEvent = async (id) => {
  return await apiClient({
    method: 'PUT',
    url: `/api/event/cancelEvent?id=${id}`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiItemsInventory = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/itemInventory`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiItemsHistory = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/itemInventory`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiUpdateItem = async (id, amount) => {

  let params = new URLSearchParams({ id, amount })
  return await apiClient({
    method: 'PUT',
    url: `/api/itemInventory`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiSaveSCRM = async (data) => {

  return await apiClient({
    method: 'POST',
    url: `/api/crm`,
    data,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetReOpenReports = async (Year, ListMonth) => {

  if (!Year || !ListMonth) return

  let params = new URLSearchParams({ Year, ListMonth })

  return await apiClient({
    method: 'GET',
    url: '/api/report/reOpenReports',
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}
export const apiGetReportSuccessfully = async (Year, ListMonth) => {

  if (!Year || !ListMonth) return

  let params = new URLSearchParams({ Year, ListMonth })
  return await apiClient({
    method: 'GET',
    url: '/api/report/reportSuccefully',
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetReportCalls = async (Year, ListMonth) => {

  if (!Year || !ListMonth) return

  let params = new URLSearchParams({ Year, ListMonth })
  return await apiClient({
    method: 'GET',
    url: '/api/report/calledAndNoCalled',
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiGetNoContactOptions = async () => {
  return await apiClient({
    method: 'GET',
    url: `/api/noCommunication`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiCreateNoContactOptions = async () => {

  return await apiClient({
    method: 'POST',
    url: `/api/govDependency`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiUpdateNoContactOptions = async () => {

  return await apiClient({
    method: 'PUT',
    url: `/api/govDependency`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiDeleteNoContactOptions = async () => {

  return await apiClient({
    method: 'PUT',
    url: `/api/govDependency`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetUsersByRole = async (rols) => {

  let params = new URLSearchParams({ rols })

  return await apiClient({
    method: 'GET',
    url: `/api/user/findByRole`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetConfigurations = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/config`,
    params: { isWeb: 1 },
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiGetConfiguration = async (configuration) => {

  let params = new URLSearchParams({ configuration })
  return await apiClient({
    method: 'GET',
    url: `/api/config/web`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiChangeConfigurations = async (isActive, idConfig) => {

  let params = new URLSearchParams({ isActive, idConfig })
  return await apiClient({
    method: 'PUT',
    url: `/api/config`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiGetPeriods = async () => {

  return await apiClient({
    method: 'GET',
    url: `/api/period`,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}
export const apiEditPeriod = async (id, description, isActive) => {

  let params = new URLSearchParams({ id })

  return await apiClient({
    method: 'PUT',
    url: `/api/period`,
    data: {
      id,
      description,
      isActive,

    },
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}

export const apiChangeAppVersion = async (data) => {

  let params = new URLSearchParams(data)

  return await apiClient({
    method: 'PUT',
    url: `/api/appversion`,
    params,
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  })
}

export const apiCreatePeriod = async (description, isActive) => {

  return await apiClient({
    method: 'POST',
    url: `/api/period`,
    data: {
      description,
      isActive,

    },
    config: {
      headers: {
        'Accept': 'application/json',
      }
    }
  });
}