import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notificationsActions } from '../../store'
import { useHistory } from 'react-router-dom'
import 'moment/locale/es';
import { Button, Avatar, Typography, Popover, Spin } from 'antd';
import { CalendarOutlined, CheckCircleOutlined, EllipsisOutlined, ExclamationCircleOutlined, InfoCircleOutlined, MailOutlined, SnippetsOutlined, WalletOutlined } from '@ant-design/icons'
import { getPerformance, trace } from 'firebase/performance'
import { apiNotifications, apiReadAllNotifications, apiReadNotification } from '../../utils/api'
import { DatePicker } from 'antd';
import { prMainLighter } from '../../themeConfig'
const { RangePicker } = DatePicker;

export const NotificationsPage = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const perf = getPerformance()
    const { update, updateNotifications } = useSelector(state => state.notifications)
    const { user } = useSelector(state => state.session)
    const [notifications, setnotifications] = useState([])
    const [notificationsSorted, setnotificationsSorted] = useState([])
    const [sorted, setsorted] = useState(false)
    // const [users, setUsers] = useState([])
    const [notificationSelected, setNotificationSelected] = useState({})
    const [loading, setloading] = useState(false)

    const getNotifications = useCallback(async () => {
        const t = trace(perf, 'notifications_loaf')
        t.start()
        setloading(true)

        apiNotifications().then((response) => {
            setnotifications(response.data.sort((a, b) => {
                a = moment(a.assigmentDateToUser)
                b = moment(b.assigmentDateToUser)

                return a > b ? -1 : a < b ? 1 : 0
            }));
            setnotificationsSorted(response.data.sort((a, b) => {
                a = moment(a.assigmentDateToUser)
                b = moment(b.assigmentDateToUser)

                return a > b ? -1 : a < b ? 1 : 0
            }));
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setloading(false)
        })
        t.stop()

    }, [])

    useEffect(() => {
        getNotifications()
    }, [update, updateNotifications])

    const handleClick = (data) => {
        setNotificationSelected(data)
    };


    const clickNotification = (notification) => {
        if (notification.notificationType === 8) {
            apiReadNotification(notification).then((response) => {
                history.push(`/peticiones/${notification.idReport}`)
                dispatch(notificationsActions.update())
            })
        } else {

            apiReadNotification(notification).then((response) => {
                history.push(`/reportes/${0}/${notification.idReport ? notification.idReport : 0}`)
                dispatch(notificationsActions.update())
            })
        }
    }

    const handleRead = () => {
        if (notificationSelected) {
            apiReadNotification(notificationSelected).then((response) => {
                getNotifications()
                dispatch(notificationsActions.update())
            })
        }
    }

    const handleReadAll = () => {
        setloading(true)
        apiReadAllNotifications(user.id)
            .then((response) => {
                getNotifications()
                dispatch(notificationsActions.update())
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setloading(false)
            })

    }

    const sortedRead = () => {
        const sorted = notifications.sort(value => {
            return value.isRead ? 1 : -1
        })

        setsorted(true)
        setnotificationsSorted(sorted)
    }

    const sortedDate = () => {
        const sorted = notifications.sort((a, b) => {
            a = moment(a.assigmentDateToUser)
            b = moment(b.assigmentDateToUser)

            return a > b ? -1 : a < b ? 1 : 0
        })

        setsorted(false)
        setnotificationsSorted(sorted)
    }

    const [fromDate, setfromDate] = useState()
    const [toDate, settoDate] = useState()

    const handleChangeDates = (event) => {
        setfromDate(event[0])
        settoDate(event[1])
    }

    const handleFilter = () => {
        let notificationsFiltered = []

        if (fromDate.isSame(toDate)) {
            console.log('today')
            notificationsFiltered = notificationsSorted.filter(notification =>
                notification.assigmentDateToUser.includes(moment(toDate).format('YYYY-MM-DD'))
            )
        } else {
            notificationsFiltered = notificationsSorted.filter(notification =>
                moment(notification.assigmentDateToUser).isBetween(fromDate, toDate) || notification.assigmentDateToUser.includes(moment(toDate).format('YYYY-MM-DD'))
            )
        }

        setnotificationsSorted(notificationsFiltered)
    }

    const cleanFilter = () => {
        setfromDate()
        settoDate()
        setnotificationsSorted(notifications)
    }

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', marginLeft: '10%', }}>
                <RangePicker allowClear={false} value={[fromDate, toDate]} onChange={handleChangeDates} style={{ backgroundColor: 'rgba(255, 255, 255,0)' }} />
                <Button disabled={!fromDate || !toDate} onClick={handleFilter} style={{ backgroundColor: 'rgba(255, 255, 255,0)', marginLeft: 10 }} type='link'>Filtrar</Button>
                <Button disabled={!fromDate || !toDate} onClick={cleanFilter} type='link'>Limpiar filtro</Button>
            </div>
            <Button type='link' icon={<CheckCircleOutlined />} onClick={handleReadAll} variant='text' style={{ alignSelf: 'flex-end', marginRight: '10%', position: 'sticky' }}>Marcar todas como leídas</Button>
            {
                !sorted &&
                <Button type='link' icon={<MailOutlined />} onClick={sortedRead} variant='text' style={{ alignSelf: 'flex-end', marginRight: '10%', position: 'sticky' }}>Ordenar por no le&iacute;das</Button>
            }
            {
                sorted &&
                <Button type='link' icon={<CalendarOutlined />} onClick={sortedDate} variant='text' style={{ alignSelf: 'flex-end', marginRight: '10%', position: 'sticky' }}>Ordenar por m&aacute;s recientes</Button>
            }
            <div style={{ width: '100%', height: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', overflowY: 'auto' }}>
                {
                    (notificationsSorted && notificationsSorted.length > 0) &&
                    notificationsSorted.map((notification, index) => (

                        <div key={index} style={{ backgroundColor: notification.isRead ? '#fff' : prMainLighter, width: '80%', padding: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', marginBottom: 1 }}>
                            <section style={{ display: 'flex', width: '80%', cursor: (notification.notificationType !== 1 && notification.notificationName !== 'Alerta') ? 'pointer' : 'default' }} onClick={() => (notification.notificationType !== 1 && notification.notificationName !== 'Alerta') ? clickNotification(notification) : null} >
                                <div>
                                    {
                                        notification.notificationName === 'Alerta'
                                            ? <Avatar shape='square' size={44} icon={<ExclamationCircleOutlined />} style={{ marginRight: 25, alignSelf: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
                                            : notification.notificationName.toUpperCase().includes('REPORTE')
                                                ? <Avatar shape='square' size={44} icon={<SnippetsOutlined />} style={{ marginRight: 25, alignSelf: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
                                                : notification.notificationName === 'Estatus del pago'
                                                    ? <Avatar shape='square' size={44} icon={<WalletOutlined />} style={{ marginRight: 25, alignSelf: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
                                                    : notification.notificationType === 8
                                                    ? <Avatar shape='square' size={44} icon={<InfoCircleOutlined />} style={{ marginRight: 25, alignSelf: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />
                                                    : ''
                                    }
                                </div>
                                <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 15 }}>{
                                        notification.notificationName
                                    }</Typography>
                                    <Typography.Text style={{ fontSize: 11 }}>{`${notification.notificationDescription}`.replace(/Clave/g, ', Clave').replace(/Folio/g, ', Folio')} </Typography.Text>
                                </div>
                            </section>
                            <div style={{ display: 'flex', width: '20%', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', marginLeft: 25 }}>
                                <Typography style={{ fontSize: 12 }}>
                                    {(moment(notification.assigmentDateToUser).isSame(
                                        moment(),
                                        "date"
                                    ) &&
                                        moment(notification.assigmentDateToUser).format("[Hoy]")) ||
                                        (moment(notification.assigmentDateToUser).isSame(
                                            moment().subtract(1, "days"),
                                            "date"
                                        ) &&
                                            moment(notification.assigmentDateToUser).format(
                                                "[Ayer]"
                                            )) ||
                                        (moment(notification.assigmentDateToUser).isBefore(moment()) &&
                                            moment(notification.assigmentDateToUser).locale('es').format(
                                                "MMMM D, YYYY"
                                            ))}{" "}
                                </Typography>
                                <Typography style={{ fontSize: 11 }} >
                                    {" "}
                                    {moment(notification.assigmentDateToUser).format(
                                        "h:mm a"
                                    )}{" "}
                                </Typography>
                                <Popover placement="left" trigger='hover' content={
                                    <div onClick={notificationSelected.isRead ? null : handleRead} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', cursor: notificationSelected.isRead ? '' : 'pointer', opacity: notificationSelected.isRead ? .5 : 1, borderBottom: '1px solid rgba(0, 0, 0, .2)', paddingBottom: 5 }}>
                                        <Typography style={{ fontSize: 15, fontWeight: 'bold' }}>Marcar como leída</Typography>
                                        <Typography style={{ fontSize: 12 }}>Marcar como leída esta notificación</Typography>
                                    </div>
                                } >
                                    <EllipsisOutlined onMouseEnter={(e) => handleClick(notification)} style={{ fontSize: 20, cursor: 'pointer' }} onClick={(e) => handleClick(notification)} />
                                </Popover>
                            </div>
                        </div>
                    ))
                }
                {
                    (notificationsSorted && notificationsSorted.length === 0 && !loading) &&
                    <Typography style={{ fontSize: 20, marginTop: '20%', opacity: .7 }}>No hay notificaciones</Typography>
                }
                {
                    loading &&
                    <Spin size="large" style={{ marginTop: '20vh' }} tip='Cargando...' />
                }
            </div>
        </div>
    )
}
