import { useEffect, useState } from "react"
import { Spin, Table, Input, Tooltip } from "antd"
import moment from 'moment'


const ScholarshipHistory = (props) => {
const details = props.details
const [historic, setHistoric] = useState([])
const [loading, setLoading] = useState(true)
const [filter, setFilter] = useState('');
const [filterData, setFilterData] = useState([]);
const [refresh, setRefresh] = useState(false)

useEffect(() => {
  setLoading(true)
  try {

    let array = []
    let tempObj = {}

    for ( let i = 0; i < details.scholarshipsObservationList.length; i++ ) {

      tempObj.id = details.scholarshipsObservationList[i].idScholarshipsObservation
      tempObj.curp = details.curp
      tempObj.name = details.name
      tempObj.lname1 = details.firstLastName
      tempObj.lname2 = details.secondLastName
      tempObj.currStatus = details.scholarshipsObservationList[i].currentStatus
      tempObj.prevStatus = details.scholarshipsObservationList[i].previousStatus
      tempObj.whoChanged = details.scholarshipsObservationList[i].userWhoChangeStatus
      tempObj.observation = details.scholarshipsObservationList[i].observation
      tempObj.time = details.scholarshipsObservationList[i].dateLastUpdate

      array.push(tempObj)
      tempObj = {}
    }
    if (details.beneficiaryList.length !== 0) {
        for (let i = 0; i < details.beneficiaryList.length; i++) {
          for (let j = 0; j < details.beneficiaryList[i].scholarshipsObservationList.length; j++) {
            tempObj.id = details.beneficiaryList[i].scholarshipsObservationList[j].idScholarshipsObservation
            tempObj.curp = details.beneficiaryList[i].curpBeneficiary
            tempObj.name = details.beneficiaryList[i].nameBeneficiary
            tempObj.lname1 = details.beneficiaryList[i].firstLastNameBeneficiary
            tempObj.lname2 = details.beneficiaryList[i].secondLastNameBeneficiary
            tempObj.currStatus = details.beneficiaryList[i].scholarshipsObservationList[j].currentStatus
            tempObj.prevStatus = details.beneficiaryList[i].scholarshipsObservationList[j].previousStatus
            tempObj.whoChanged = details.beneficiaryList[i].scholarshipsObservationList[j].userWhoChangeStatus
            tempObj.observation = details.beneficiaryList[i].scholarshipsObservationList[j].observation
            tempObj.time = details.beneficiaryList[i].scholarshipsObservationList[j].dateLastUpdate
          
            array.push(tempObj)
            tempObj = {}
          }
        }

      } 
      let sortedData = array.sort((a, b) => moment(b.time).format('lll').localeCompare(moment(a.time).format('lll')))

      setFilterData(sortedData)
      setHistoric(sortedData)
      setLoading(false)
  } catch (e) {
      console.log(e)
      setLoading(false)
  } finally {
    setRefresh(true)
    // setLoading(false)
  }

},[props, refresh])


const columns = [
    
    {
        title: 'CURP',
        width: '10%',
        ellipsis: {
          showTitle: true
        },
        sorter: (a, b) => a.curp.localeCompare(b.curp),
        render: row => (
          <Tooltip title={row.curp}>
            {row.curp}
          </Tooltip>
        )
      },
      {
        title: 'Nombre',
        width: '12%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: row => (
          <Tooltip title={''}>
            {row.name} {row.lname1} {row.lname2}
          </Tooltip>
        )
      },
      {
        title: 'Estatus anterior',
        width: '10%',
        sorter: (a, b) => a.prevStatus.localeCompare(b.prevStatus),
        render: row => (
          <Tooltip title={''}>
            {row.prevStatus}
          </Tooltip>
        )
      },
      {
        title: 'Estatus actual',
        width: '10%',
        sorter: (a, b) => a.currStatus.localeCompare(b.currStatus),
        render: row => (
          <Tooltip title={''}>
            {row.currStatus}
          </Tooltip>
        )
      },
      {
        title: 'Fecha',
        width: '10%',
        sorter: (a, b) => moment(a.time).format('lll').localeCompare(moment(b.time).format('lll')),
        render: row => (
          <Tooltip title={''}>
            {moment(row.time).format('lll')}
          </Tooltip>
        )
      },
      {
        title: 'Verificador',
        width: '12%',
        sorter: (a, b) => a.whoChanged.localeCompare(b.whoChanged),
        render: row => (
          <Tooltip title={''}>
            {row.whoChanged}
          </Tooltip>
        )
      },
      {
        title: 'Observación',
        width: '25%',
        // sorter: (a, b) => a.observation - b.observation,
        ellipsis: {
          showTitle: true
        },
        render: row => (
          <Tooltip title={row.observation}>
            {row.observation}
          </Tooltip>
        )
      },
]

  useEffect(() => {
    if (filter.length === 0) {
      setFilterData(historic)
    } else {
      const search = filter.split(' ').filter(item => item !== '');

      if (search.length > 0) {
        const dataFilter = historic.filter(item => {
          return (search.every(word =>
            item.curp.toString().toLowerCase().startsWith(word.toLowerCase()) ||
            item.name.toString().toLowerCase().includes(word.toLowerCase()) ||
            item.lname1.toString().toLowerCase().includes(word.toLowerCase()) ||
            item.lname2.toString().toLowerCase().includes(word.toLowerCase()) ||
            item.whoChanged.toString().toLowerCase().includes(word.toLowerCase())
          ))
        })
        setFilterData(dataFilter);
      }
    }
  }, [filter])

const handleFilter = (event) => {
  setFilter(event.target.value);
}

    return (
        <div style = {{ maxHeight: '70vh' }}>
        <div style = {divSearch}>
          <Input value={filter} onChange={(e) => handleFilter(e)} style={textfieldStyle} placeholder={`Buscar histórico...`} size='large' allowClear />
        </div>

        <Spin spinning={loading}>
          <div>

         <Table
          rowKey={record => record.id }
          loading={loading}
          columns={columns}
          dataSource={filterData}
          size="small"
          scroll={{ y: '45vh', x: '100%' }}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
          />
          </div>
        </Spin>
        </div>
    )

}

const textfieldStyle = {
  width: '60%',
  marginRight: '10%',
  backgroundColor: '#FFF',
  borderRadius: 20,
  height: '30px',
}

const divSearch = {
  width: '50%',
  marginBottom: '2vh',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '30px'
}

export default ScholarshipHistory