import React, { useState, useEffect, useCallback } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { Col, Row, Select, Typography, Button, Image, Tooltip, Spin, Modal, Result } from 'antd';
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';
import './details.css'
import { getAnalytics, logEvent } from 'firebase/analytics';
import { apiGetWorkers, apiReportsById, apiStatusReport } from '../../../utils/api';
import { useSelector } from 'react-redux';
import { Comments } from './Comments';
import { openMessage } from '../Utils/MessageDisplay';
import moment from 'moment';
import { Description } from './Description';
import { EditService } from './EditService';
import { prMain } from '../../../themeConfig';
import { DeleteReports } from './DeleteReports/DeleteReports';
import { AssignmentReports } from './AssignmentReport/AssignmentReport'
import { RequestDelete } from './DeleteReports/RequestDelete';
import FollowUpReports from './FollowUpReport';
import { CommentOutlined, EditOutlined, HistoryOutlined, SnippetsOutlined } from '@ant-design/icons';
import { EditReport } from '../requests/UpdatedReports/EditReport';
import { AddPhotos } from './AddPhotos';
import { HistoricReports } from './HistoricReports';
import { CRMDetails } from '../CRM/CRMDetails';


const classes = {
    container: {
        width: 550,
        padding: '12px 50px',
        overflowY: 'scroll',
    },

    title: {
        color: '#FB9738',
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 20,

    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 15,
    },
    photoRow: {
        display: 'flex',
        flexDirection: 'row',
        wrap: 'wrap',
        marginBottom: 10,
        height: 'fit-content',
        maxHeight: 400,
        width: 200,
        marginLeft: 30,
        overflowY: 'auto'
    },
    descriptionRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    subtitle: {
        color: '#757575',
        fontWeight: 'bold',
        textAlign: 'justify',
    },
    description: {
        color: '#757575',
        textAlign: 'justify',
        fontSize: '12px',

        maxHeight: 100,
        overflowY: 'scroll',
    },
    text: {
        color: '#757575',
        textAlign: 'center',
    },
    buttons: {
        width: '80%',
        margin: '0 auto 20px auto',
    },
    button: {
        width: '100%',
        marginTop: 20,
    },
    twoButtons: {
        width: '45%',
        marginTop: 20,
        marginLeft: '2.5%',
        marginRight: '2.5%',
    },
    select: {
        height: 35,
        width: '90%',
        borderRadius: 5,
    },
}

export const ReportDetails = ({refreshReports,...props}) => {

    const analytics = getAnalytics()
    const id = props.data.id
    const { width } = useScreenSize()

    const [data, setdata] = useState(null)
    const [report, setReport] = useState({
        idReport: '',
        description: '',
        user: '',
        reportType: '',
        statusId: '',
        priority: '',
        expired: '',
        images: '',
        comments: '',
        assignedBy: '',
        createBy: '',
        phoneUser: '',
        imagesAfter: [],
        imagesBefore: [],
    })
    const [users, setUsers] = useState([]);
    const [inCharge, setInCharge] = useState('');
    const { user: currentUser } = useSelector(state => state.session)

    const [viewcomments, setviewcomments] = useState(false);
    const [isAssignment, setIsAssignment] = useState(false);
    const [viewDescription, setviewDescription] = useState(false)
    const [loading, setloading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openHistoric, setOpenHistoric] = useState(false)
    const [openAddPhotos, setopenAddPhotos] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [viewCRM, setviewCRM] = useState(false)
    const [step, setStep] = useState(0)

    const findUser = id => {
        const find = users.filter(item => (isAllowed(item, ROLES_DIVISIONS.WORKER)))
            .find(item => item.id === id)
        return find ? true : false
    }

    const getReport = useCallback(() => {
        setloading(true)
        apiReportsById(id)
            .then(res => {
                // console.log(res)
                if(res.data.length > 1){
                    res.data = res.data.find(i => i.id === id)
                } else {
                    res.data = res.data[0]
                }

                setdata(res.data)
                setReport(prev => ({
                    ...prev,
                    idReport: res.data.id,
                    description: res.data.description,
                    user: res.data.user ? `${res.data.user.name} ${res.data.user.lastName} ` : '',
                    reportType: res.data.servicesDto ? res.data.servicesDto.name : '',
                    statusId: res.data.status.id,
                    priority: res.data.priority,
                    expired: res.data.expired,
                    images: res.data.photos,
                    comments: res.data.comments ? res.data.comments : [],
                    assignedBy: res.data.assignedBy ? `${res.data.assignedBy.name} ${res.data.assignedBy.lastName}` : '',
                    createBy: res.data.createBy.name,
                    phoneUser: res.data.createBy ? res.data.createBy.phone :  '',
                    imagesAfter: res.data.photos.filter(item => item.flag),
                    imagesBefore: res.data.photos.filter(item => !item.flag),
                }))
                setInCharge(res.data.user ? res.data.user.id : '')
                getUsers(res.data.servicesDto.dependency.id)
            })
            .catch(error => {
                console.log(error)
            }).finally(() => {
                setloading(false)
            })
    }, [id])

    const getUsers = useCallback((res) => {
        setloading(true)
        apiGetWorkers(res).then((response) => {
            setUsers(response.data)
            setloading(false)
        }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setloading(false)
            })
    }, [])

    const handleInCharge = (event) => {
        setInCharge(event);
    }

    const handleLogEvent = (status, id) => {
        logEvent(analytics, 'report_status_changed', {
            'report_id': id,
            'status_id': status,
        })
    }

    const handleNotification = (buttonId, data) => {
        setIsAssignment(false);
        setloading(true);

        if (buttonId === 1) {

            apiStatusReport(data, inCharge, 2)
                .then(response => {
                    props.getDataReport()
                    handleLogEvent(2, data.id)
                    openMessage({ type: 'success', message: `Reporte con el folio ${data.id} asignado` })

                })
                .catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })

                })

        } else if (buttonId === 2) {
            apiStatusReport(data, inCharge, 2)
                .then(response => {
                    props.getDataReport()
                    handleLogEvent(2, data.id)
                    openMessage({ type: 'success', message: `Reporte con el folio ${data.id} reasignado` })

                })
                .catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })

                })

        // } else if (buttonId === 3) {
        //     apiStatusReport(data, inCharge, 3)
        //         .then(response => {
        //             props.getDataReport()
        //             handleLogEvent(3, data.id)
        //             openMessage({ type: 'success', message: `Reporte con el folio ${data.id} en revisión` })

        //         })
        //         .catch(error => {
        //             console.log(error)
        //             openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })

        //         })

        } else if (buttonId === 4) {
            apiStatusReport(data, inCharge, 2)
                .then(response => {
                    props.getDataReport()
                    handleLogEvent(2, data.id)
                    openMessage({ type: 'success', message: `Reporte con el folio ${data.id} retornado` })
                })
                .catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })
                })

        } else if (buttonId === 5) {
            apiStatusReport(data, inCharge, 4)
                .then(response => {
                    props.getDataReport()
                    handleLogEvent(4, data.id)
                    openMessage({ type: 'success', message: `Reporte con el folio ${data.id} solucionado` })
                    setStep(0)
                })
                .catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })
                })

        } else if (buttonId === 6) {
            apiStatusReport(data, inCharge, 1)
                .then(response => {
                    props.getDataReport()
                    handleLogEvent(2, data.id)
                    openMessage({ type: 'success', message: `Reporte con el folio ${data.id} retornado` })
                })
                .catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la petición' })
                })

        }

        props.onCloseDetails(buttonId);
        setloading(false)
    }

    useEffect(() => {
        getReport()
    }, [getReport, refresh])


    const handleClose = () => {
        setIsOpen(false)
        setStep(0)
    }

    const handleOpenAssignmentReport = (data) => 
    {
        setIsAssignment(true);
    }

    return (
        <Spin spinning={loading}>
            {
                viewCRM 
                ? <CRMDetails refreshReports={refreshReports} row={data} handleClose={props.onCloseDetails} setviewCRM={setviewCRM}/>
                : viewcomments
                    ? <>
                        <Comments comments={report.comments} data={data} setviewcomments={setviewcomments} />
                    </>
                    : viewDescription
                        ? <Description description={report.description} data={data} setviewDescription={setviewDescription} />
                        : <>
                            {
                                data && step === 0 &&
                                <>
                                    <div style={{ width: width < 600 ? '100%' : 550 }}>

                                        <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Folio </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {data.id}</Typography.Text>

                                            </Col>
                                        </Row>

                                        <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Servicio </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {report.reportType}</Typography.Text>
                                                {
                                                    (report.statusId === 1 && !data.reasonForDelete && !data.deletedAt) &&
                                                    <EditService data={data} getReport={getReport} getDataReport={props.getDataReport} />
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Fecha </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left', width: '60%' }}> {moment(data.createdAt).format('DD/MMMM/YYYY hh:mm a')}</Typography.Text>

                                            </Col>
                                        </Row>
                                        <Row style={classes.descriptionRow}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Descripci&oacute;n </Typography.Text>

                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div className='description' style={{ width: '60%' }}>
                                                    <Typography.Text align='left' variant='body2' style={{ fontSize: 12, opacity: .7, width: '40%' }}> {report.description} </Typography.Text>
                                                </div>
                                                <span style={{ color: prMain, cursor: 'pointer' }} onClick={() => setviewDescription(true)}>ver más</span>
                                            </Col>
                                        </Row>
                                        <Row style={{ ...classes.row, marginTop: 10 }}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Dirección </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {data.address} </Typography.Text>

                                            </Col>
                                        </Row>

                                        <Row style={{ ...classes.row, marginTop: 10 }}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Antes </Typography.Text>

                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={classes.text}> Despu&eacute;s</Typography.Text>

                                            </Col>
                                        </Row>
                                        <div style={{ display: 'flex'}}>
                                            <Row style={classes.photoRow}>
                                                <Image.PreviewGroup>
                                                    {/* <Image width={70} height={90} src="https://f004.backblazeb2.com/file/DeliciasTouch/prueba.jpg"/>  */}
                                                    {report.imagesBefore.map((image, index) => (
                                                        <Tooltip key={index} title='Previsualizar'>
                                                            <Image width={70} height={90} src={`${image.url}`} />
                                                        </Tooltip>
                                                    ))}
                                                </Image.PreviewGroup>
                                                {(report.imagesBefore.length < 4 && report.statusId < 4) &&
                                                    <div style={{ width: '100%', marginTop: '1vh' }}>
                                                        <Button type='primary' onClick={() => setopenAddPhotos(true)}>Agregar fotos</Button>
                                                    </div>
                                                }
                                            </Row>
                                            <Row style={classes.photoRow}>
                                                <Image.PreviewGroup>
                                                    {report.imagesAfter.slice(0, 4).map((image, index) => (
                                                        <Tooltip key={index} title='Previsualizar'>
                                                            <div style={{ marginRight: 20 }}>
                                                                <Image rootClassName='imagePreview' fallback='Cargando' width={70} height={90} src={`${image.url}`} />
                                                            </div>
                                                        </Tooltip>
                                                    ))}
                                                </Image.PreviewGroup>
                                            </Row>
                                        </div>
                                        {
                                            (data.status.id === 3 || data.status.id === 4) &&
                                            <>
                                                <Row>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Tooltip title='Observaciones y/o material utilizado'>
                                                            <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Observaciones </Typography.Text>
                                                        </Tooltip>

                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <div className='description' style={{ width: '75%' }}>
                                                            {
                                                                report.comments.length > 0 && report.comments[0].comment.split('\\n').map((item, i) => (
                                                                    <p key={i} style={{ fontSize: 12, opacity: .7, marginTop: i > 0 ? -12 : 0 }}>{item.replace(/['"]+/g, '')} </p>
                                                                ))
                                                            }
                                                        </div>
                                                        {
                                                            report.comments.length > 0 &&
                                                            <span style={{ color: prMain, cursor: 'pointer' }} onClick={() => setviewcomments(true)}>ver más</span>
                                                        }
                                                    </Col>
                                                </Row>
                                                {/* <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Button type='link' onClick={() => setviewcomments(true)} style={{ marginTop: -10, marginLeft: -15 }}>Ver historial</Button>
                                            </Col>
                                        </Row> */}
                                            </>
                                        }
                                        <Row style={{ ...classes.row, marginTop: 10 }}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Creado por </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {data.isCreateByCapturist ? 'Capturista' : 'Ciudadano'} </Typography.Text>

                                            </Col>
                                        </Row>
                                        <Row style={{ ...classes.row, marginTop: 10 }}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Autor del reporte </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {report.createBy} </Typography.Text>

                                            </Col>
                                        </Row>
                                        <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Teléfono del autor </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {report.phoneUser}</Typography.Text>

                                            </Col>
                                        </Row>
                                        {
                                            (report.statusId !== 1) &&
                                            <Row style={classes.row}>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Asignado por </Typography.Text>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }}>
                                                    <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {report.assignedBy} </Typography.Text>

                                                </Col>
                                            </Row>
                                        }
                                        <Row style={classes.row}>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Encargado </Typography.Text>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }} id="selectarea">
                                                {((report.statusId !== 4 && !data.reasonForDelete && !data.deletedAt) && !isAllowed(currentUser, ROLES_DIVISIONS.CAPTURIST) && !isAllowed(currentUser, ROLES_DIVISIONS.CRM_MANAGER) ) && <>
                                                    <Select
                                                        style={{ width: `100%` }}
                                                        value={(findUser(inCharge) ? inCharge : null)}
                                                        onChange={handleInCharge}
                                                        getPopupContainer={() => document.getElementById('selectarea')}
                                                        placeholder={loading ? 'Cargando...' : 'Seleccione al usuario'}
                                                        loading={loading}
                                                    >
                                                        {users.map(user => user && (
                                                            <Select.Option key={user.id} value={user.id}> {user.name} {user.lastName} </Select.Option>
                                                        ))}
                                                    </Select>
                                                </>}
                                                {((report.statusId === 4) || isAllowed(currentUser, ROLES_DIVISIONS.CAPTURIST)) && <Typography.Text style={{ ...classes.text, textAlign: 'left' }}> {report.user} </Typography.Text>}

                                            </Col>
                                        </Row>
                                        {
                                            report.statusId !== 4 &&
                                            <>
                                                <Row style={classes.row}>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Prioridad </Typography.Text>

                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Typography.Text style={{ ...classes.text, textAlign: 'left' }}>{report.priority} </Typography.Text>

                                                    </Col>
                                                </Row>
                                                <Row style={classes.row}>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Estatus </Typography.Text>

                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <Typography.Text style={{ ...classes.text, textAlign: 'left' }}>{report.expired ? 'Expirado' : 'Vigente'} </Typography.Text>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {
                                            data.reasonForDelete &&
                                            <Row style={classes.row}>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Typography.Text style={{ ...classes.subtitle, marginLeft: width < 600 ? 0 : '25%' }}>Razón de modificación </Typography.Text>

                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <div className='description' style={{ width: '60%' }}>
                                                        <Typography.Text style={{ ...classes.text, textAlign: 'left' }}>{data.reasonForDelete} </Typography.Text>
                                                    </div>

                                                </Col>
                                            </Row>
                                        }
                                        {
                                            (data.status.id <= 3 && !data.reasonForDelete && (isAllowed(currentUser, ROLES_DIVISIONS.PURE_MANAGEMENT) || isAllowed(currentUser, ROLES_DIVISIONS.CAPTURIST))) &&
                                            <Row style={classes.row}>
                                                <Col span={24}>
                                                    <RequestDelete data={data} getDataReport={props.getDataReport} close={props.onCloseDetails} />
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            <div>
                                                {
                                                    <Row style={{...classes.row}}>

                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 250 }}>
                                                            <Button onClick={() => setOpenHistoric(true)} type='link' style={{ marginTop: 15 }} icon={<HistoryOutlined />} >
                                                                Ver histórico
                                                            </Button>
                                                        </Col>
                                                        {
                                                            window.location.pathname.toLowerCase().includes('crm') && data.status.id  === 4 && !data.noTracking &&
                                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 250 }}>
                                                            <Button onClick={() => setviewCRM(true)} type='link' style={{ marginTop: 15 }} icon={<SnippetsOutlined />} >
                                                                Seguimiento del Reporte
                                                            </Button>
                                                        </Col>}

                                                        {((data.status.id <= 3 && ((data.reasonForDelete && !data.deletedAt && isAllowed(currentUser, ROLES_DIVISIONS.PURE_ADMIN))
                                                            || (!data.reasonForDelete && !data.deletedAt && isAllowed(currentUser, ROLES_DIVISIONS.PURE_ADMIN))) && (window.location.pathname.toString().startsWith('/reportes'))
                                                        ) || (window.location.pathname === '/peticiones' && data.status.id !== 4)) &&
                                                            (
                                                                window.location.pathname === '/peticiones'
                                                                    ? <Col span={12}>
                                                                        <DeleteReports refresh={props.refresh} data={data} getDataReport={props.getDataReport} close={props.onCloseDetails} setRefresh={props.setRefresh} onCloseDetails={props.onCloseDetails} />
                                                                    </Col>
                                                                    :
                                                                    (!data.reasonForDelete && !data.deletedAt && !isAllowed(currentUser, ROLES_DIVISIONS.CRM_MANAGER)) &&
                                                                    <>
                                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200 }}>
                                                                            <DeleteReports refresh={props.refresh} data={data} getDataReport={props.getDataReport} close={props.onCloseDetails} setRefresh={props.setRefresh} onCloseDetails={props.onCloseDetails} />
                                                                        </Col>
                                                                        <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 250 }}>
                                                                            <Button onClick={() => setIsOpen(true)} type='link' style={{ width: '90%', marginTop: 15 }} icon={<CommentOutlined />}>
                                                                                Enviar aviso de seguimiento
                                                                            </Button>
                                                                        </Col>
                                                                        
                                                                    </>
                                                            )} 
                                                            {(data.status.id === 1 && window.location.pathname !== '/peticiones' && !isAllowed(currentUser, ROLES_DIVISIONS.CRM_MANAGER)) &&
                                                                            <Col lg={12} md={12} sm={12} xs={12} style={{ maxWidth: 200, }}>
                                                                                <Button onClick={() => setOpenEdit(true)} type='link' style={{ marginTop: 15 }} icon={<EditOutlined />} >
                                                                                    Modificar reporte
                                                                                </Button>
                                                                            </Col>
                                                                        }
                                                    </Row>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {(data.length !== 0 && !isAllowed(currentUser, ROLES_DIVISIONS.CAPTURIST) && !isAllowed(currentUser, ROLES_DIVISIONS.CRM_MANAGER) && !data.reasonForDelete && !data.deletedAt) &&
                                        <div style={classes.buttons}>
                                            {data.status.id === 1 && <Button disabled={!findUser(inCharge)} onClick={() => handleNotification(1, data)} style={classes.button} type='primary' size="large"><b> Asignar </b></Button>}
                                            {data.status.id === 2 && <>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Button onClick={() => handleNotification(6, data)} style={classes.twoButtons} type='default' size="large"><b> Retornar </b></Button>
                                                    <Button onClick={() => handleNotification(2, data)} style={classes.twoButtons} type='default' size="large"><b> Reasignar </b></Button>
                                                    <Button onClick={() => handleOpenAssignmentReport(data)} style={classes.twoButtons} type="primary" size="large"><b> Revisar </b></Button>
                                                </div>
                                            </>}
                                            {data.status.id === 3 && <>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Button onClick={() => handleNotification(4, data)} style={classes.twoButtons} type='default' size="large"><b> Retornar </b></Button>
                                                    <Button onClick={ () => setStep(1) } style={classes.twoButtons} type="primary" size="large"><b> Completar </b></Button>
                                                    {/* () => handleNotification(5, data) */}
                                                </div>
                                            </>}

                                        </div>
                                    }
                                </>
                            }
                            {       
                            step === 1 &&
                                (
                                    <Result
                                        status={'warning'}
                                        title='Confirme Mover Reporte a Completado'
                                        subTitle={`Esta operación es irreversible, ¿está seguro de realizarla?`}
                                        extra={[
                                            <Button loading={loading} type='link' key='delete' onClick={() => handleNotification(5, data)} >Si, Mover</Button>,
                                            <Button disabled={loading} type='primary' key='cancel' onClick={handleClose}>No, Cancelar</Button>,
                                        ]}
                                    />
                                )
                            }

                        </>
            }
            <Modal
                centered
                visible={isOpen}
                closable={false}
                footer={null}
                onCancel={() => handleClose()}
            >
                <FollowUpReports data={data} isOpen={isOpen} setIsOpen={setIsOpen} onClose={handleClose} option={"message"} />

            </Modal>

            <Modal
                centered
                visible={openAddPhotos}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => setopenAddPhotos(false)}
            >
                <AddPhotos size={4 - report.imagesBefore.length} idReport={report.idReport} refresh={refresh} setRefresh={setRefresh} setOpen={setopenAddPhotos} />

            </Modal>

            <Modal
                centered
                visible={isAssignment}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => setIsAssignment(false)}
            >
                <AssignmentReports isVisible={isAssignment} data={data} setIsAssignment={setIsAssignment} getDataReport={props.getDataReport} setRefresh={props.setRefresh}  handleLogEvent={handleLogEvent} close={props.onCloseDetails}/>
            </Modal>
 
            <Modal
                centered
                visible={openEdit}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => setOpenEdit(false)}
            >
                <EditReport report={data} close={props.onCloseDetails} setOpenEdit={setOpenEdit} refresh={refresh} setRefresh={setRefresh} />

            </Modal>
            <Modal
                centered
                visible={openHistoric}
                closable={false}
                footer={null}
                destroyOnClose
                onCancel={() => setOpenHistoric(false)}
            >
                <HistoricReports report={data} setclose={setOpenHistoric} refresh={refresh} setRefresh={setRefresh} />

            </Modal>
        </Spin>
    )
}