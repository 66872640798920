import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { apiGetScholarships } from "../../../utils/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportScholarships = ({clickSearch, filter }) => {

    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])

    useEffect(() => {
    setloading(true)
    apiGetScholarships().then(res => {
        const filterData = [];
        for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].beneficiaryList.length > 0) {

                for (let j = 0; j < res.data[i].beneficiaryList.length; j++) {
                    let tempObj = {};
                    tempObj = {};

                    tempObj.nameBeneficiary2 = res.data[i].beneficiaryList[j].nameBeneficiary
                    tempObj.firstLastNameBeneficiary2 = res.data[i].beneficiaryList[j].firstLastNameBeneficiary
                    tempObj.secondLastNameBeneficiary2 = res.data[i].beneficiaryList[j].secondLastNameBeneficiary
                    tempObj.curpBeneficiary2 = res.data[i].beneficiaryList[j].curpBeneficiary
                    tempObj.dateBirthBeneficiary2 = res.data[i].beneficiaryList[j].dateBirth
                    tempObj.ageBeneficiary2 = res.data[i].beneficiaryList[j].ageBeneficiary
                    tempObj.schoolBeneficiary2 = res.data[i].beneficiaryList[j].school
                    tempObj.schoolLevelBeneficiary2 = res.data[i].beneficiaryList[j].schoolLevel
                    tempObj.schoolGradeBeneficiary2 = res.data[i].beneficiaryList[j].schoolGrade
                    tempObj.shiftBeneficiary2 = res.data[i].beneficiaryList[j].shift
                    tempObj.qualificationAverageBeneficiary2 = res.data[i].beneficiaryList[j].qualificationAverageBeneficiary
                    tempObj.differentCapacityBeneficiary2 = res.data[i].beneficiaryList[j].differentCapacityBeneficiary
                    tempObj.typeScholarShipBeneficiary2 = res.data[i].beneficiaryList[j].typeScholarship

                    tempObj = { ...tempObj, ...res.data[i] }
                    filterData.push(tempObj)
                    tempObj = {}
                }
            } else {
                let tempObj = {}
                tempObj = res.data[i]
                filterData.push(tempObj)
            }
        }
        setdata(filterData)
    }).catch(err => {
        new Error(err)
    }).finally(() => {
        setloading(false)
    })
    },[])

    return (
        <ExcelFile
            filename={`Apoyos_${moment().format('DD_MM_YYYY.hhmmss')}`}
            element={<Button loading={loading} disabled={data.length === 0 || loading} type="link" icon={<FileExcelOutlined />}>Exportar a Excel</Button>}
        >
            <ExcelSheet data={data} name="Apoyos" >

                <ExcelColumn label="Folio" value={col => col.folio} />
                <ExcelColumn label="Nombre/s" value={col => col.name} />
                <ExcelColumn label="Apellido Paterno" value={col => col.firstLastName} />
                <ExcelColumn label="Apellido Materno" value={col => col.secondLastName} />
                <ExcelColumn label="Tipo de apoyo" value={col => col.typeScholarShip ? col.typeScholarShip : col.typeScholarShipBeneficiary2} />
                <ExcelColumn label="CURP" value={col => col.curp} />
                <ExcelColumn label="Sexo" value={col => col.gender} />
                <ExcelColumn label="Edad" value={col => col.age} />
                <ExcelColumn label="Capac. diferente" value={col => col.differentCapacity} />
                <ExcelColumn label="Direccion" value={col => col.address} />
                <ExcelColumn label="Numero exterior" value={col => col.outdoorNumber} />
                <ExcelColumn label="Colonia" value={col => col.colony} />
                <ExcelColumn label="Codigo postal" value={col => col.postalCode} />
                <ExcelColumn label="Teléfono" value={col => col.firstPhone} />
                <ExcelColumn label="Teléfono 2" value={col => col.secondPhone} />
                <ExcelColumn label="Teléfono 3" value={col => col.thirdPhone} />
                <ExcelColumn label="Escolaridad" value={col => col.scholarship} />
                <ExcelColumn label="Estado civil" value={col => col.maritalStatus} />
                <ExcelColumn label="Ocupacion" value={col => col.employment} />
                <ExcelColumn label="Num hijos" value={col => col.numberChildren} />
                <ExcelColumn label="Escuela" value={col => col.school} />
                <ExcelColumn label="Grado" value={col => col.schoolGrade} />
                <ExcelColumn label="Turno" value={col => col.shift} />
                <ExcelColumn label="Calf. Promedio " value={col => col.qualificationAverage} />


                <ExcelColumn label="Nombre (beneficiario)" value={col => col.nameBeneficiary2} />
                <ExcelColumn label="Apellido Paterno (beneficiario)" value={col => col.firstLastNameBeneficiary2} />
                <ExcelColumn label="Apellido Materno (beneficiario)" value={col => col.secondLastNameBeneficiary2} />
                <ExcelColumn label="CURP (beneficiario)" value={col => col.curpBeneficiary2} />
                <ExcelColumn label="fecha nacimiento (beneficiario)" value={col => col.dateBirthBeneficiary2} />
                <ExcelColumn label="Edad (beneficiario)" value={col => col.ageBeneficiary2} />
                <ExcelColumn label="Nivel escolar (beneficiario)" value={col => col.schoolLevelBeneficiary2} />
                <ExcelColumn label="Escuela (beneficiario)" value={col => col.schoolBeneficiary2} />
                <ExcelColumn label="Grado (beneficiario)" value={col => col.schoolGradeBeneficiary2} />
                <ExcelColumn label="Turno (beneficiario)" value={col => col.shiftBeneficiary2} />
                <ExcelColumn label="Calf. Promedio (beneficiario)" value={col => col.qualificationAverageBeneficiary2} />
                <ExcelColumn label="Capac. Diferente (beneficiario)" value={col => col.differentCapacityBeneficiary2} />


                <ExcelColumn label="Dependientes económicos" value={col => col.dependents} />
                <ExcelColumn label="Tipo de vivienda" value={col => col.typeLivingPlace} />
                <ExcelColumn label="Techo" value={col => col.roof} />
                <ExcelColumn label="Paredes" value={col => col.walls} />
                <ExcelColumn label="Pisos" value={col => col.floors} />
                <ExcelColumn label="Num. Habitaciones" value={col => col.roomsNumber} />
                <ExcelColumn label="Num. Baños" value={col => col.bathroomsNumber} />
                <ExcelColumn label="Patio" value={col => col.backYard} />
                <ExcelColumn label="Total habitaciones" value={col => col.total} />
                <ExcelColumn label="Servicio médico" value={col => col.medicalServices} />
                <ExcelColumn label="Gastos de renta" value={col => col.rent} />
                <ExcelColumn label="Gastos de luz" value={col => col.light} />
                <ExcelColumn label="Gastos de gas" value={col => col.gas} />
                <ExcelColumn label="Gastos de transporte" value={col => col.transport} />
                <ExcelColumn label="Deudas" value={col => col.debt} />
                <ExcelColumn label="Gastos de agua" value={col => col.water} />
                <ExcelColumn label="Gastos de Teléfono" value={col => col.billsPhone} />
                <ExcelColumn label="Gastos de Alimentación" value={col => col.feed} />
                <ExcelColumn label="Gastos escolares" value={col => col.schoolFees} />
                <ExcelColumn label="Otros gastos" value={col => col.other} />

                <ExcelColumn label="INE Frontal" value={col => (!col.scholarshipsFiles[0] || !col.scholarshipsFiles[0]["url"]) ? '' : `${col.scholarshipsFiles[0]["url"]}`} />
                <ExcelColumn label="INE Trasera" value={col => (!col.scholarshipsFiles[1] || !col.scholarshipsFiles[1]["url"]) ? '' : `${col.scholarshipsFiles[1]["url"]}`} />
                <ExcelColumn label="CURP" value={col => (!col.scholarshipsFiles[2] || !col.scholarshipsFiles[2]["url"]) ? '' : `${col.scholarshipsFiles[2]["url"]}`} />
                <ExcelColumn label="Comprobante Domicilio" value={col => (!col.scholarshipsFiles[3] || !col.scholarshipsFiles[3]["url"]) ? '' : `${col.scholarshipsFiles[3]["url"]}`} />
                <ExcelColumn label="Comprobante Ingresos" value={col => (!col.scholarshipsFiles[4] || !col.scholarshipsFiles[4]["url"]) ? '' : `${col.scholarshipsFiles[4]["url"]}`} />
                <ExcelColumn label="Boleta de Estudios" value={col => (!col.scholarshipsFiles[5] || !col.scholarshipsFiles[5]["url"]) ? '' : `${col.scholarshipsFiles[5]["url"]}`} />
                {/* <ExcelColumn label="Observaciones" value={col => col.observations}/> */}
                {/* <ExcelColumn label="Encargado" value={col => col.user ? (col.user.name + " " + col.user.lastName) : ''}/>
                    <ExcelColumn label="Fecha" value={col => moment(col.createdAt).format('DD/MM/YYYY')}/>
                    <ExcelColumn label="Estatus" value={col => col.expired ? 'Expirado' : 'Vigente'}/> */}
            </ExcelSheet>
        </ExcelFile>
    );
}