import { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Button, Input, Modal, Form, Result, Typography } from 'antd';
import { apiSetPassword } from '../../utils/api';
import { background, logo } from '../../themeConfig';

const classes = {
    button: {
        textTransform: 'capitalize',
        width: 'auto',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    main: {
        height: '100vh',
        minHeight: '100vh',
        display: 'flex',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // background: '#222',
        minwidth: 400,
        overflowX: 'hiden',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red'
    },
    root: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        height: 'auto',
        overflowY: 'auto',
        background: '#fafafa',
        borderRadius: '3%',
        display: 'flex',
        flexDirection: 'flex',
        justifyContent: 'center',
        padding: '20px',
        margin: '10%'
    },
    logo: {
        width: 100,
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 20,
    },
    a: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex'
    },
    btnFacebook: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
}

const SetFirstPassword = () => {
    
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passDontMatch, setPassDontMatch] = useState(false);
    const [passLength, setPassLength] = useState(false);
    const [loading, setloading] = useState(false)
    const [failed, setfailed] = useState(false)

    const [success, setsuccess] = useState(false)

    const { width } = useScreenSize();

    const [runEffect, setRunEffect] = useState(false);

    useEffectAsync(async () => {
        if (password.length !== 0) {
            setloading(true)
            apiSetPassword(password)
                .then(res => {
                    console.log('constraseña establecida')
                    setsuccess(true)
                })
                .catch(err => {
                    console.log(err)
                    setfailed(true)
                }).finally(() => {
                    setloading(false)
                })
        }
    }, [runEffect])

    const handleClose = () => {
        sessionStorage.removeItem('user');
        window.location.replace('/')
    }

    const handleSetPassword = () => {

        if (password !== confirmPassword) {
            setPassDontMatch(true);
            setPassLength(false);

        } else if (password.length < 8) {
            setPassLength(true);
            setPassDontMatch(false);

        } else {
            setPassDontMatch(false);
            setPassLength(false);
            setRunEffect(!runEffect);
        }
    }

    const handlePasswordChange = (event) => {
        setfailed(false)
        setPassDontMatch(false);
        setPassLength(false);
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setfailed(false)
        setPassDontMatch(false);
        setPassLength(false);
        setConfirmPassword(event.target.value);
    }

    return (
        <div>
            <div style={classes.main} direction="coloum">
                <div style={classes.image}></div>
                <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '30vw' }}>
                    <form style={{ width: '100%' }}>
                        <div style={classes.a}>
                            <img style={classes.logo} src={logo} alt="Logo" />
                        </div>
                        <Typography.Title level={3} style={{textAlign: 'center', margin: 15}}>Establezca una nueva contraseña</Typography.Title>
                        <div style={{ width: '100%', marginTop: 20 }}>
                            <Form.Item
                                validateStatus={(passDontMatch || passLength || failed) ? 'error' : ''}
                                help={
                                    failed
                                    ?'Ha ocurrido un error al intentar establecer la contraseña'
                                    : passDontMatch ? 'Las contraseñas no coinciden' 
                                    : passLength ? 'La contraseña debe tener al menos 8 caracteres' 
                                    : (!passDontMatch && !passLength) ? 'Ingrese su nueva contraseña' 
                                    : failed 
                                }
                            >
                                <Input.Password
                                    size='large'
                                    onChange={handlePasswordChange}
                                    value={password}
                                    placeholder="Nueva contraseña"
                                    id="password"
                                    status={passLength || passDontMatch ? 'error' : ''}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Form.Item
                                validateStatus={passDontMatch || passLength ? 'error' : ''}
                                help={
                                    passDontMatch ? 'Las contraseñas no coinciden' : passLength ? 'La contraseña debe tener al menos 8 caracteres' : (!passDontMatch && !passLength) ? 'Confirme su nueva contraseña' : ''
                                }
                            >

                                <Input.Password
                                    size="large"
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmPassword}
                                    placeholder="Confirme contraseña"
                                    id="password"
                                    status={passLength || passDontMatch ? 'error' : ''}
                                />
                            </Form.Item>
                        </div>
                        <div style={classes.buttons} >
                                <Button loading={loading} style={classes.button} onClick={handleSetPassword} variant='contained' type='primary' size='large'>
                                    Guardar contrase&ntilde;a
                                </Button>
                        </div>
                    </form>
                </div>

            </div>
            {
                success &&
                <Modal
                    visible={success}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    footer={null}
                    closable={false}
                >
                            <Result
                                status='success'
                                title='Contraseña establecida correctamente'
                                subTitle='Su nueva contraseña ha sido establecida correctamente, vuelva a iniciar sesión con su nueva contraseña'
                                extra={[
                                    <Button key='ok' type='primary' onClick={handleClose} autoFocus >
                                        Aceptar
                                    </Button>
                                ]}
                            />
                </Modal>
            }
        </div>

    )
}

export default SetFirstPassword;