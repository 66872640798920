import { BellTwoTone } from "@ant-design/icons";
import { notification } from "antd";
import { prMain } from "../../../themeConfig";

export const openNotification = ( data ) => {

    const args = {
        message: data.message,
        description: data.description,
        duration: 6,
        placement: data.placement,
        icon: <BellTwoTone twoToneColor={prMain}/>,
        maxCount: 1,
    }
    notification[data.type]({
        ...args,
    })
}