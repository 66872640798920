import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useScreenSize } from '../../hooks/useScreenSize'
import { notificationsActions } from '../../store'
import './notifications.css'
import 'moment/locale/es';
import { Button, Typography } from 'antd'
import { apiReadNotification } from '../../utils/api'
import { prMainLighter } from '../../themeConfig'

export const Notifications = ({ handleNotifications, handleClose, data }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const notifications = data.sort((a, b) => {
    a = moment(a.assigmentDateToUser)
    b = moment(b.assigmentDateToUser)

    return a > b ? -1 : a < b ? 1 : 0
  })

  const clickNotification = (notification) => {
    if (notification.notificationType === 8) {
      apiReadNotification(notification).then((response) => {
        history.push(`/peticiones/${notification.idReport}`)
        dispatch(notificationsActions.update())
        handleClose()

      })
    } else {

      apiReadNotification(notification).then((response) => {
        history.push(`/reportes/${0}/${notification.idReport ? notification.idReport : 0}`)
        dispatch(notificationsActions.update())
        handleClose()
      })
    }


  }

  const { width } = useScreenSize()

  return (
    <div style={{ width: width > 800 ? '30vw' : '50vw', height: 'auto', maxHeight: '50vh', padding: '20px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography.Title level={5}>Notificaciones</Typography.Title>
      <Button type='link' onClick={handleNotifications} style={{ textDecoration: 'none', color: 'FF9800' }}>
        Ver todas las notificaciones
      </Button>
      <div className='notifications' style={{ width: '100%', overflowY: 'auto' }}>
        {
          (notifications && notifications.length > 0) &&
          notifications.slice(0, 50).map((notification, index) => (
            !notification.isRead &&
            <div key={index} onClick={() => (notification.notificationType !== 1 && notification.notificationName !== 'Alerta') ? clickNotification(notification) : null} style={{ backgroundColor: prMainLighter, width: '100%', borderRadius: 10, padding: 5, marginBottom: 4, cursor: (notification.notificationType !== 1 && notification.notificationName !== 'Alerta') ? 'pointer' : 'default' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: 13, width: '100%', paddingRight: 10, textAlign: 'right' }} >
                  {(moment(notification.assigmentDateToUser).isSame(
                    moment(),
                    "date"
                  ) &&
                    moment(notification.assigmentDateToUser).format("[Hoy]")) ||
                    (moment(notification.assigmentDateToUser).isSame(
                      moment().subtract(1, "days"),
                      "date"
                    ) &&
                      moment(notification.assigmentDateToUser).format(
                        "[Ayer]"
                      )) ||
                    (moment(notification.assigmentDateToUser).isBefore(moment()) &&
                      moment(notification.assigmentDateToUser).locale('es').format(
                        "MMMM D, YYYY"
                      ))}{" "}
                </Typography.Text>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: 11, width: '100%', paddingRight: 10, textAlign: 'right' }}>
                  {" "}
                  {moment(notification.assigmentDateToUser).format(
                    "h:mm a"
                  )}{" "}
                </Typography.Text>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: 13 }}> {
                  notification.notificationName
                }</Typography.Text>
                <Typography.Text style={{ fontSize: 11 }}>{`${notification.notificationDescription}`.replace(/Clave/g, ', Clave')} </Typography.Text>
              </div>
            </div>
          ))
        }
        {
          (notifications.length === 0 || !notifications) &&
          <Typography variant='body1' align='center' style={{ marginTop: '30%', opacity: .5 }}>No hay notificaciones</Typography>
        }

      </div>

    </div>
  )
}
