import { Radio } from 'antd'
import React, { useState } from 'react'
import { ConfigDataUser } from './ConfigDataUser'
import { StatusUser } from './StatusUser'

export const UserConfig = ({ dataRow, handleClose, setRefresh, refresh }) => {

    const [value, setValue] = useState(dataRow.user_status.id === 4 ? 'data' : 'status')
    const [isDone, setIsDone] = useState(false)

    const onChangeRadio = (e) => {
        console.log(value)
        setValue(e.target.value);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {
                !isDone &&
                <>
                <Radio.Group size='small' onChange={onChangeRadio} defaultValue={value} buttonStyle='outline' style={{ marginBottom: 25,  }}>
                    <Radio.Button value="status">Cambiar Estado</Radio.Button>
                    <Radio.Button value="data">Cambiar Datos</Radio.Button>
                </Radio.Group>
                <div style={{height: 1, backgroundColor: '#e3e3e3', width: '100%', marginBottom: 30}}></div>
                </>
            }

            {
                value === 'status' &&
                <StatusUser isDone={isDone} setIsDone={setIsDone} data={dataRow} onClose={handleClose} setRefresh={setRefresh} refresh={refresh} />
            }
            {
                value === 'data' &&
                <ConfigDataUser isDone={isDone} setIsDone={setIsDone} data={dataRow} onClose={handleClose} setRefresh={setRefresh} refresh={refresh} />
            }
        </div>
    )
}
