import { Button, Form, Input, Switch } from 'antd'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { apiCreatePeriod } from '../../../../utils/api'
import { openMessage } from '../../Utils/MessageDisplay'

export const CreatePeriod = ({getData, close}) => {

    const inputRef = useRef(null)
    const [description, setdescription] = useState('')
    const [status, setstatus] = useState(true)
    const [errors, seterrors] = useState({
        period: false
    })

    const create = () => {
        apiCreatePeriod(description, status).then(res => {
            getData()
            close()
            setdescription('')
        }).catch(err => {
            openMessage({type: 'error', message: 'Ha ocurrido un error al intentar crear el perido'})
        })
    }

    useEffect(() => {
        inputRef.current.focus()
    },[])
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
            <div style={{fontSize: '1.2rem'}}>Crear Nuevo Periodo</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
                <Form.Item validateStatus={errors.period && 'error'} help={errors.period ? 'Ya existe un periodo con esta descripción' : 'Periodo'} style={{width: '100%'}}>
                    <Input ref={inputRef} value={description} onChange={e => {setdescription(e.target.value); seterrors(prev => ({...prev, period: false})) }} />
                </Form.Item>
                <Form.Item help='Status'>
                    <Switch checked={status} onChange={value => setstatus(value)} />
                </Form.Item>
            </div>

            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                <Button type='link' onClick={close}>Cancelar</Button>
                <Button type='primary' onClick={create} disabled={!description} >Guardar</Button>
            </div>
        </div>
    )
}
