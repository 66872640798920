import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'notifications',
  initialState: {
    update: false,
    updateNotifications: false,
  },
  reducers: {
    update(state){
      state.update = !state.update
    },
    updateNotification(state){
      state.updateNotifications = !state.updateNotifications
    },
  },
});

export { actions as notificationsActions };
export { reducer as notificationsReducer };
