import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { menuReducer as menu } from './menu';
import { loadingReducer as loading} from './loading';
import { sessionReducer as session } from './session';
import { usersReducer as users } from './usersStore';
import { snackbarReducer as snackbar } from './snackbar';
import { notificationsReducer as notifications } from './notifications';

const reducer = combineReducers({
    menu, 
    loading,
    session,
    users,
    snackbar,
    notifications
});
export { usersActions } from './usersStore';
export { sessionActions } from './session';
export { loadingActions } from './loading';
export { menuActions } from './menu';
export { snackbarActions } from './snackbar'
export { notificationsActions } from './notifications';

export default configureStore({ reducer });