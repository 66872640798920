import { EyeOutlined } from '@ant-design/icons'
import { Button, Table, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'

export const UsersTable = ({ handleTable, loading, historic, totalData, handleDetails }) => {
    const columns = [

        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date',
            width: '30%',
            render: (date) => (
                <div>
                    {moment(date).format('lll')}
                </div>
            ),
            // ellipsis: {
            //     showTitle: true
            // }
        },
        {
            title: 'Id',
            key: 'id',
            width: '10%',
            // sorter: (a, b) => a.id - b.id,
            // ellipsis: {
            //     showTitle: true
            // },
            render: (row) => (
                <Tooltip title={row.id ? row.message : ''}>
                    {row.id ? row.id : ''}
                </Tooltip>
            )
        },
        {
            title: 'Responsable',
            dataIndex: 'author',
            key: 'author',
            width: '30%',
            // sorter: (a, b) => a.id - b.id,
            // ellipsis: {
            //     showTitle: true
            // },
            render: author => (
                <Tooltip title={author}>
                    {author}
                </Tooltip>
            )
        },
        {
            title: 'Modificación',
            key: 'message',
            width: '30%',
            // sorter: (a, b) => a.id - b.id,
            // ellipsis: {
            //     showTitle: true
            // },
            render: (row) => (
                <Tooltip title={row.message ? row.message : ''}>
                    {row.message ? row.message : ''}
                </Tooltip>
            )
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (row) => (
                <Tooltip title='Detalles' >
                    <Button type='link' icon={<EyeOutlined onClick={() => handleDetails(row)} />} />
                </Tooltip>
            ),
            width: '10%'
        }
    ]
    return (
        <div>
            <Table
                onChange={handleTable}
                rowKey={record => `${record.id}_${record.date}`}
                loading={loading}
                columns={columns}
                dataSource={historic}
                size="small"
                scroll={{ y: '60vh' }}
                pagination={{ total: totalData, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
        </div>
    )
}
