import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { openNotification } from "./components/pages/Utils/NotificationsDisplay";

export const FirebaseNotifications = () => {

    const vapidKey = 'BAqYU4a8FiI3Wt43IJ7WBR57W3dJPH9k801Y2zEa5z14uFYf1w5j5JSqB_Uw0-u8sVuKDOI1wrSmmvyKOvOkyvs'

    const messaging = getMessaging();
    getToken(messaging, { vapidKey }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // console.log('Token: ', currentToken);
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

    onMessage(messaging, (payload) => {
        // console.log('Message received. ', payload);s
        openNotification({ type: 'info', message: `${payload.notification.title}`, description: payload.notification.body, placement: 'topRight' })
        // ...
    });
}
