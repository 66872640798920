import { Button, Form, Input, Result, Typography } from "antd"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { sessionActions } from "../../../store"
import { apiEditUser } from "../../../utils/api"

export const EditPersonalData = ({ user, setopen }) => {

    const dispatch = useDispatch()
    const [state, setstate] = useState({
        id: user.id,
        name: user.name,
        lastName: user.lastName
    })
    const [loading, setloading] = useState(false)
    const [response, setresponse] = useState('pending')

    const handleChange = (event) => {
        setstate({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = () => {
        setloading(true)

        apiEditUser(state).then((res) => {
            setresponse('success')
            dispatch(sessionActions.updateName())
        }).catch((err) => {
            console.log(err)
            setresponse('error')
        }).finally(() => {
            setloading(false)
        })
    }

    const handleClose = (event) => {
        setopen(prev => ({...prev, isOpen: false, type: ''}))
    }

    return (
        <div>
            {
                response === 'success'
                    ? (<>
                        <Result
                            status='success'
                            title='Información Guardada correctamente'
                            subTitle='Los nuevos datos se han guardado correctamente'
                            extra={[
                                <Button key='success' type="primary" onClick={handleClose}> Aceptar</Button>
                            ]}
                        />

                    </>)
                    : response === 'error'
                        ? (<>
                            <Result
                            status='error'
                            title='Ha ocurrido un error'
                            subTitle='Ha ocurrido un error al intentar guardar los datos'
                            extra={[
                                <Button key='success' type='link' onClick={() => setresponse('pending')}> Reintentar</Button>,
                                <Button key='success' type='primary' onClick={handleClose}> Salir</Button>,
                            ]}
                            />
                        </>)
                        : (<>
                            <Typography.Title level={3} style={{ marginBottom: 30 }}>Editar datos del usuario</Typography.Title>
                            <Form.Item
                                help={'Nombre del usuario'}
                            >
                                <Input
                                    name="name"
                                    onChange={handleChange}
                                    value={state.name}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{marginTop: 10 }}
                                help={'Apellido del usuario'}
                            >
                                <Input
                                    name="lastName"
                                    onChange={handleChange}
                                    value={state.lastName}
                                />
                            </Form.Item>
                            <div style={{ marginTop: 30 }}>
                                <Button loading={loading} type="primary" onClick={handleSubmit}>Guardar</Button>
                                <Button type="link" onClick={handleClose}>Cancelar</Button>
                            </div>
                        </>)
            }

        </div>
    )
}
