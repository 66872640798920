import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Result } from 'antd'
import React, { useState } from 'react'
import { apiRequestDelete } from '../../../../utils/api'

export const RequestDelete = ({ data, getDataReport, close }) => {

    const [isOpen, setisOpen] = useState(false)
    const [reason, setreason] = useState(null)
    const [state, setstate] = useState({
        loading: false,
        failed: false,
        success: false,
    })

    const handleClose = () => {
        setreason(null)
        setisOpen(false)
        setstate(prev => ({ ...prev, failed: false, success: false }))
    }

    const handleSubmit = () => {
        setstate(prev => ({ ...prev, loading: true }))

        apiRequestDelete(data.id, reason)
        .then(res => {
            setstate(prev => ({ ...prev, success: true }))
        }).catch(error => {
            setstate(prev => ({ ...prev, failed: true }))
            console.log(error)
        }).finally(() => {
            setstate(prev => ({ ...prev, loading: false }))
        })
    }

    return (
        <div>
            <Button onClick={() => setisOpen(true)} type='link' style={{ width: 'fit-content', marginTop: 15 }} icon={<DeleteOutlined />}>Completar Reporte</Button>

            <Modal
                centered
                visible={isOpen}
                closable={false}
                footer={null}
                onCancel={handleClose}
            >
                {
                    (!state.failed && !state.success) &&
                    <Result
                        status={'info'}
                        title={`Petición para mover reporte`}
                        subTitle={`Realizar solicitud para mover el reporte con folio ${data.id} a Completado`}
                        extra={[
                            <Form.Item
                                key='reason'
                                help='Ingrese la razón para completar este reporte'
                                style={{ width: '100%', marginBottom: 15}}
                            >
                                <Input name='reason' maxLength={50} value={reason} onChange={e => setreason(e.target.value)}/>
                            </Form.Item>,
                            <Button key='accept' disabled={!reason} type='link' loading={state.loading}  onClick={handleSubmit}>Realizar solicitud</Button>,
                            <Button  key='cancel' type='primary' loading={state.loading}  onClick={handleClose}>Cancelar</Button>,

                        ]}
                    />
                }

                {
                    state.failed &&
                    <Result
                        status={'error'}
                        title='Error al realizar la solicitud'
                        subTitle='Ha ocurrido un error al intentar realizar la solicitud'
                        extra={[
                            <Button key='try' type='link' loading={state.loading}  onClick={() => setstate(prev => ({...prev, failed: false}))}>Reintentar</Button>,
                            <Button key='cancel' type='primary' loading={state.loading}  onClick={handleClose}>Cancelar</Button>,
                        ]}
                    />
                }

                {
                    state.success &&
                    <Result
                        status={'success'}
                        title='Solicitud realizada correctamente'
                        subTitle={`La solicitud para mover el reporte con folio ${data.id} a Completado se ha realizado correctamente`}
                        extra={[
                            <Button key='accept' type='link' loading={state.loading}  onClick={() => {
                                close()
                                setstate(prev => ({...prev, failed: false, success: false}))
                                setreason(null)
                                getDataReport()
                            }}>Aceptar</Button>,
                        ]}
                    />
                }
            </Modal>

        </div>
    )
}
