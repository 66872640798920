import { EditOutlined } from '@ant-design/icons'
import { Button, Result, Select, Tooltip } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { useEffect, useState } from 'react'
import { prMain } from '../../../themeConfig'
import { apiEditService, apiReportType } from '../../../utils/api'

export const EditService = ({ data, getReport, getDataReport }) => {

    const [isOpen, setisOpen] = useState(false)
    const [success, setsuccess] = useState(false)
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const [loadingServices, setloadingServices] = useState(false)
    const [services, setservices] = useState()
    const [service, setservice] = useState(data.servicesDto.id)

    const handleClose = () => {
        setisOpen(false)
        seterror(false)
        setsuccess(false)

    }

    const handleChange = (e) => {
        setservice(e)
    }

    const handleSubmit = () => {
        setloading(true)
        apiEditService(data.id, service)
            .then(res => {
                setsuccess(true)
            })
            .catch(err => {
                console.log(error)
                seterror(true)
            })
            .finally(() => {
                setloading(false)
            })
    }

    useEffect(() => {
        setloadingServices(true)
        apiReportType()
            .then((response) => {
                response.data = response.data.filter(item => item.name !== 'CRM')

                setservices(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setloadingServices(false)
            })
    }, [])

    return (
        <>
            <Tooltip title='Cambiar servicio del reporte'>
                <EditOutlined style={{ marginLeft: 15, color: prMain, cursor: 'pointer' }} onClick={() => setisOpen(true)} />
            </Tooltip>

            <Modal
                centered
                visible={isOpen}
                closable={false}
                footer={null}
                onCancel={handleClose}
            >
                {
                    success
                    && (<>
                        <Result
                            status='info'
                            title={`La operación se ha realizado correctamente`}
                            subTitle={`El reporte con folio ${data.id} ha cambiado su servicio correctamente`}
                            extra={[
                                <Button key='edit' type='primary' loading={loading} onClick={() => {
                                    getReport()
                                    handleClose()
                                }}>Aceptar</Button>,
                            ]}
                        />

                    </>)
                }
                {error
                    && (<>
                        <Result
                            status='info'
                            title={`Ha ocurrido un error en la operación`}
                            subTitle={`Ha ocurrido un error al intentar cambiar el reporte con folio ${data.id}`}
                            extra={[
                                <Button key='try' type='link' loading={loading} onClick={() => seterror(false)}>Reintentar</Button>,
                                <Button key='edit' type='primary' loading={loading} onClick={handleClose}>Cerrar</Button>,
                            ]}
                        />
                    </>)
                }
                {(!error && !success) &&
                    <Result
                        status='info'
                        title={`Cambiar servicio del reporte con folio ${data.id}`}
                        subTitle={'Seleccione el nuevo servicio que sera asignado a este reporte'}
                        extra={[
                            <Select
                                key='select'
                                style={{ width: `100%`, marginBottom: 20 }}
                                value={service}
                                onChange={handleChange}
                                placeholder={'Seleccione el servicio'}
                                loading={loadingServices}
                            >
                                {
                                    services &&
                                    services.map(item => (
                                        <Select.Option key={item.id} value={item.id}> {`${item.name} (${item.dependency.name})`}</Select.Option>
                                    ))
                                }
                            </Select>,
                            <Button key='edit' type='link' loading={loading} onClick={handleSubmit} disabled={data.servicesDto.id === service}>Cambiar</Button>,
                            <Button key='cancel' type='primary' onClick={handleClose} disabled={loading}> Cancelar</Button>,
                        ]}
                    />
                }

            </Modal>
        </>
    )
}
