import { Button, Typography, Modal, Result, Radio } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, Polygon, useMapEvents } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import L from 'leaflet';
import { apiGetGeofence, apiSetGeofence } from '../../../../utils/api';
import dot from '../../../../asserts/dot.png';
import { RollbackOutlined } from '@ant-design/icons';
const { Text } = Typography

const MarkerIcon = L.icon({
    iconUrl: dot,
    iconSize: [16, 16],
    iconAnchor: [11, 11],
    className: 'iconMarker',
});

const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY


export const ConfigurationMap = ({ setupdated, updated }) => {

    // const [map, setmap] = useState(null)
    const [polygon, setPolygon] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [step, setStep] = useState(0)
    const [polyReference, setpolyReference] = useState(null)
    const [radio, setradio] = useState('edit')
    const defaultpolygon = [[28.080616854040024, -105.5483072730958],
    [28.135197099719036, -105.55269578608555],
    [28.227649060811537, -105.49432856332157],
    [28.28099493770124, -105.42411235548518],
    [28.261283266784602, -105.34336371647336],
    [28.08284572753004, -105.34720670531766],
    [28.06998508104916, -105.38425386957198],]


    const purpleOptions = { color: 'purple' }

    const setLimit = (isDefault) => {
        if (Object.values(polygon).length < 3) return


        let newLimit = {
            coordinates: isDefault ? JSON.stringify(defaultpolygon) : JSON.stringify(Object.values(polygon))
        }

        apiSetGeofence( newLimit, 1).then((response) => {
            setPolygon([])
            setStep(3)
            setupdated(!updated)

        }).catch(e => {
            console.log(e)
            setStep(4)
        })

    }

    useEffect(() => {
        apiGetGeofence().then(res => {
            // console.log(res)
            JSON.parse(res.data[0].coordinates).forEach((i, index) => {
                setPolygon(res => ({ ...res, [index + 1]: i }))

            })

        }).catch(err => {
            new Error(err)
        })
    }, [updated])

    const handleOpen = (isDefault) => {
        setStep(isDefault ? 2 : 1)
        setIsOpen(true)
    }

    const handleClose = () => {
        setStep(0)
        setupdated(!updated)
        setIsOpen(false)
    }

    const MapLocation = () => {

        const map = useMapEvents({
            mouseout: e => {
                if (polyReference) {
                    setpolyReference(null)
                }
            },
            mousemove: e => {
                if (Object.values(polygon).length >= 1 && radio === 'draw') {
                    setpolyReference({ ...polygon, [polygon ? Object.values(polygon).length + 1 : 1]: [e.latlng.lat, e.latlng.lng] })
                }
            },
            click: (e) => {
                // console.log(e)
                if (radio === 'draw') {
                    setPolygon(prev => ({ ...prev, [polygon ? Object.values(polygon).length + 1 : 1]: [e.latlng.lat, e.latlng.lng] }))
                }
                map.locate()
            },
        })
        return null
    }

    const deleteLastPoint = () => {
        const object = { ...polygon }
        const claves = Object.keys(object);
        if (claves.length > 0) {
            const ultimaClave = claves.pop();

            delete object[ultimaClave];
        } else{
            setradio('draw')
        }

        setPolygon(object)
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button type='link' onClick={() => handleOpen(true)} style={{ marginBottom: 32 }}>Reestablecer límites por defecto</Button>
                <Button disabled={Object.values(polygon).length < 3} type='primary' onClick={() => handleOpen(false)} style={{ marginBottom: 32 }}>Establecer Limite</Button>


            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Radio.Group onChange={e => setradio(e.target.value)} value={radio}>
                        <Radio value={'draw'}>Dibujar Area</Radio>
                        <Radio value={'edit'}>Editar Puntos</Radio>
                    </Radio.Group>
                    <div>
                        <Button type='link' onClick={() => { setPolygon({}); setradio('draw') }}>Deshacer Toda El Area</Button>
                        <Button icon={<RollbackOutlined />} type='link' onClick={deleteLastPoint}>Deshacer Ultimo Punto</Button>
                    </div>
                </div>


            </div>
            <Text style={{ textAlign: 'center', color: '#00000090', fontSize: 12 }}>Arrastre los puntos para delimitar la zona disponible para generar reportes.</Text>
            <MapContainer center={[28.192226945392576, -105.4792397460938]} zoom={11} style={{ width: '100%', height: '70vh' }} whenReady={map => console.log(map.target)} whenCreated={map => console.log(map)}>
                <ReactLeafletGoogleLayer apiKey={googleKey} type={'roadmap'} />
                <MapLocation />

                {
                    polyReference &&
                    <Polygon pathOptions={purpleOptions} opacity={0.1} positions={Object.values(polyReference)} />
                }
                <Polygon pathOptions={purpleOptions} positions={Object.values(polygon)} />
                {
                    Object.values(polygon).map((i, index) => (
                        radio === 'edit' && <DraggableMarker key={index + 1} positionPoly={i} setPolygon={setPolygon} id={index + 1} />
                    ))
                }
            </MapContainer >

            <Modal
                visible={isOpen}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => setIsOpen(false)}>

                <>
                    {step === 1 &&
                        <div>
                            <Result
                                status='info'
                                title={`Modificar límites del mapa`}
                                subTitle={`Esto podría afectar a los usuarios al momento de generar reportes, ¿Está seguro de querer continuar? `}
                                extra={[
                                    <div key={'infoDiv'}>
                                        <Button key='back' type='link' onClick={() => setIsOpen(false)} autoFocus >
                                            Volver
                                        </Button>
                                        <Button key='ok' type='primary' onClick={() => setLimit(false)} >
                                            Continuar
                                        </Button>
                                    </div>
                                ]}
                            />
                        </div>
                    }
                    {step === 2 &&
                        <div>
                            <Result
                                status='info'
                                title={`Reestablecer límites del mapa`}
                                subTitle={`Esto podría afectar a los usuarios al momento de generar reportes, ¿Está seguro de reestablecer a los valores por defecto?`}
                                extra={[
                                    <div key={'infoDiv'}>
                                        <Button key='back' type='link' onClick={() => setIsOpen(false)} autoFocus >
                                            Volver
                                        </Button>
                                        <Button key='ok' type='primary' onClick={() => setLimit(true)} >
                                            Continuar
                                        </Button>
                                    </div>
                                ]}
                            />
                        </div>
                    }
                    {step === 3 &&
                        <div>
                            <Result
                                status='success'
                                title={`Límites modificados correctamente`}
                                subTitle={'Los límites permitidos para generar reportes han sido modificados correctamente.'}
                                extra={[
                                    <Button key='okSuccess' type='primary' onClick={() => handleClose()} autoFocus >
                                        Aceptar
                                    </Button>
                                ]}
                            />
                        </div>
                    }
                    {step === 4 &&
                        <div>
                            <Result
                                status='error'
                                title={`Error al intentar modificar límites del mapa`}
                                subTitle='Se ha producido un error. Por favor, intente nuevamente'
                                extra={[
                                    <Button style={{ marginTop: 15 }} key='exitError' type='primary' onClick={() => setIsOpen(false)} autoFocus >
                                        Salir
                                    </Button>
                                ]}
                            />
                        </div>
                    }
                </>
            </Modal>


        </div>
    )
}

const DraggableMarker = ({ positionPoly, setPolygon, id }) => {
    const [position, setPosition] = useState(positionPoly)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {

                    setPosition(marker.getLatLng())
                    setPolygon(prev => ({ ...prev, [id]: Object.values(marker.getLatLng()) }))
                }
            },
        }),
        [],
    )

    return (
        <Marker
            draggable
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
            icon={MarkerIcon}>
        </Marker>
    )
}
