import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Table, Tooltip } from "antd"
import { useState } from "react"
import styled from "styled-components"
import { prMain, prMainLighter } from "../../../../themeConfig"
import { GraphicZone } from "./GraphicZone"

const Container = styled.div`
    padding: .5rem 2rem;

    .tabs-container{
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin-top: 2rem;
    }

`

const Tabs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: rgba(0,0,0,0.03);
    padding: 0.5rem;
    border-radius: 24px;

`

const Tab = styled.div`
    padding: 4px 8px;
    border-radius: 24px;
    cursor: ${props => props.active ? '' : 'pointer'};
    color: ${props => props.active ? prMain : '#000'};
    background-color: ${props => props.active ? prMainLighter : 'transparent'};

`

const TableContainer = styled.div`
    width: 85%;
    margin-top: -60px;
`

export const TableBudgetVotes = ({ close, details, setdetails, projects }) => {

    const columns = [
        {
            title: 'Proyecto',
            key: 'projectName',
            render: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem' }}>
                    <Tooltip title={row.projectName}>
                        <div style={{ inlineSize: '85%', overflowWrap: 'break-word' }}>
                            {row.projectName}
                        </div>
                    </Tooltip>
                </div>
            )
        }, {
            title: 'Votos',
            key: 'votes',
            render: row => row.votes,
            width: '15%',
        },
    ]

    const [tab, settab] = useState(details.zone)
    const [tabGraphic, settabGraphic] = useState(1)

    const changeTab = (value) => {
        settab(value)
        setdetails(prev => ({ ...prev, zone: value }))
    }

    return (
        <Container>
            <Button style={{ marginLeft: -15 }} icon={<ArrowLeftOutlined />} type='link' onClick={close}>Gráficas</Button>
            <div className="tabs-container">

                <Tabs>
                    {
                        projects.length > 0 && projects.map(i => (
                            <Tab key={i.zona} onClick={() => i.zona !== tab && changeTab(i.zona)} active={tab === i.zona}>{`${i.zona === '99999' ? 'Generales' : `Zona ${i.zona}`}`}</Tab>
                        ))
                    }

                </Tabs>
                <TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ marginBottom: '1rem', display: 'flex', gap: '.5rem', backgroundColor: prMainLighter, padding: '6px', borderRadius: '32px' }}>
                            <div onClick={() => tabGraphic !== 1 && settabGraphic(1)} style={{ transition: '.5s', padding: '2px 8px', borderRadius: '32px', cursor: tabGraphic === 1 ? '' : 'pointer', color: tabGraphic === 1 ? '#fff' : 'rgba(0,0,0,0.6)', backgroundColor: tabGraphic === 1 ? prMain : 'transparent' }}>Tabla</div>
                            <div onClick={() => tabGraphic !== 2 && settabGraphic(2)} style={{ transition: '.5s', padding: '2px 8px', borderRadius: '32px', cursor: tabGraphic === 2 ? '' : 'pointer', color: tabGraphic === 2 ? '#fff' : 'rgba(0,0,0,0.6)', backgroundColor: tabGraphic === 2 ? prMain : 'transparent' }}>Grafica</div>
                        </div>
                    </div>
                    {
                        tabGraphic === 1 ?
                        <>
                            <Table
                                rowKey={record => record.idBudgetParticipatory}
                                // loading={loading}
                                columns={columns}
                                dataSource={projects.find(i => i.zona === tab).budgetParticipatoryDtos}
                                size="small"
                                scroll={{ y: '50vh', x: '100%' }}
                                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                            />
                            <div style={{ marginTop: '-50px' }} >
                                {/* <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button> */}
                            </div>
                        </>
                    : 
                    <>
                        <GraphicZone data={projects} tab={tab}/>
                    </>
                    }
                </TableContainer>
            </div>

        </Container>
    )
}
