import React, { useState } from 'react'
import { useEffect } from 'react'
import { apiGetEvent } from '../../../utils/api'
import { Table } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

export const Attendance = ({ data, setOpenAttendence, refresh, setRefresh }) => {

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            //sorter: (a, b) => a.idBudgetParticipatory - b.idBudgetParticipatory,
            render: id => (
                <div>
                    {id}
                </div>
            )
        },
        {
            title: 'Nombre',
            dataIndex: 'userName',
            key: 'userName',
            render: id => (
                <div>
                    {id}
                </div>
            )
        },
        {
            title: 'Total de Check In',
            dataIndex: 'total_checks-in',
            key: 'total_checks-in',
            width: '20%',
            render: id => (
                <div>
                    {id}
                </div>
            )
        },
    ]

    const [loading, setloading] = useState(false)
    const [rows, setrows] = useState([])

    useEffect(() => {
        setloading(true)
        apiGetEvent(data.idEvent).then(res => {
            console.log(res.data)
            setrows(res.data.usersAttendence)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })

    }, [])

    return (
        <div style={{ width: '60vw' }}>
            <div style={{ fontSize: '1.5rem', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '1rem' }}>
                <div>
                    Asistencia al Evento <span style={{ opacity: 0.8 }}>{data.eventName}</span>
                </div>
                <CloseOutlined onClick={() => setOpenAttendence(false)} style={{ fontSize: '1rem', cursor: 'pointer' }} />
            </div>
            <div style={{ paddingTop: 30 }}>
                <Table
                    rowKey={record => record.id}
                    loading={loading}
                    columns={columns}
                    dataSource={rows}
                    size="small"
                    scroll={{ y: '60vh', x: '100%' }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                />
            </div>
        </div>
    )
}
