import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { apiReportsFilter } from "../../../utils/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportReports = ({status, dataType, owner, optionCall, rangeDate, userOption, refreshExcel, isFilter, folio}) => {

    const [loading, setloading] = useState(true)
    const [fullData, setfullData] = useState([])
    const [data, setdata] = useState([])

    useEffect(() => {
        setloading(true)

        let from = new Date(rangeDate[0])
        let to = new Date(rangeDate[1])
                apiReportsFilter(status, status, from, to, folio, owner, dataType.join(','), userOption).then(res => {
                    setfullData(res.data)
                    if(optionCall === 3){
                        res.data = res.data.filter(i => !i.crm)
                    } else if(optionCall === 2){
                        res.data = res.data.filter(i => i.crm)
                    }
                    setdata(res.data)
                }).catch(err => {
                    new Error(err)
                }).finally(() => {
                    setloading(false)
                })
    }, [refreshExcel, status])

    useEffect(() => {

        if(fullData.length === 0) return
        setloading(true)

        let dataFilter = fullData

        if(optionCall === 3){
            dataFilter = dataFilter.filter(i => !i.crm)
        } else if(optionCall === 2){
            dataFilter = dataFilter.filter(i => i.crm)
        }
        setdata(dataFilter)

        setloading(false)

    }, [optionCall])

    return (
        <ExcelFile
            filename={`Reportes_${moment().format('DD_MM_YYYY.hhmmss')}`}
            element={<Button loading={loading} disabled={data.length === 0 || loading} type="link" icon={<FileExcelOutlined />}>Exportar a Excel</Button>}
        >
            <ExcelSheet data={data} name="Reportes">
                <ExcelColumn label="Folio" value={col => col.id} />
                <ExcelColumn label="Folio Origen" value={col => col.idOrigin ? col.idOrigin : ''} />

                <ExcelColumn label="Dependencia" value={col => col.servicesDto.dependency.name} />
                <ExcelColumn label="Tipo" value={col => col.servicesDto.name} />

                <ExcelColumn label="Creado por" value={col => col.createBy ? `${col.createBy.name} (${col.isCreateByCapturist ? 'Capturista' : 'Ciudadano'})` : ''} />
                <ExcelColumn label="Teléfono" value={col => col.createBy ? (`${col.createBy.phone}`) : ''} />
                <ExcelColumn label="Fecha de creaci&oacute;ón" value={col => moment(col.createdAt).format('DD/MM/YYYY')} />

                <ExcelColumn label="Asignado por" value={col => col.assignedBy ? (col.assignedBy.name + " " + col.assignedBy.lastName) : ''} />
                <ExcelColumn label="Encargado" value={col => col.user ? (col.user.name + " " + col.user.lastName) : ''} />
                <ExcelColumn label="Fecha de asignado" value={col => col.assignedAt ? moment(col.assignedAt).format('DD/MM/YYYY') : ""} />
                <ExcelColumn label="Vigencia" value={col => col.expired ? 'Expirado' : 'Vigente'} />
                <ExcelColumn label="Estatus" value={col => col.status.name === "Pending" ? "Pendiente" : col.status.name === "Assigned" ? "Asignado" : col.status.name === "InReview" ? "En revisión" : col.status.name === "Completed" ? "Solucionado" : ""} />
                <ExcelColumn label="Fecha de cierre" value={col => col.completedAt ? moment(col.completedAt).format('DD/MM/YYYY') : ""} />

                <ExcelColumn label="Observaciones" value={col => {
                    let msg = ""
                    col.comments.forEach((item) => {
                        msg = msg + `${item.userDto.name} ${item.userDto.lastName}: ${item.comment} (${moment(item.date).format('DD/MM/YYYY')}), `
                    })
                    return msg.substring(0, msg.length - 2)
                }} />

                <ExcelColumn label="Dirección" value={col => col.address ? col.address : ""} />
                <ExcelColumn label="Colonia" value={col => col.colonia.name ? col.colonia.name : ""} />
                <ExcelColumn label="Descripción" value={col => col.description ? col.description : ""} />
                <ExcelColumn label="Prioridad" value={col => col.priority ? col.priority : ""} />
                <ExcelColumn label="Aceptado por" value={col => col.requestAcceptedByName ? col.requestAcceptedByName : ""} />
                <ExcelColumn label="Solicitado por" value={col => col.requestedByName ? col.requestedByName : ""} />
                <ExcelColumn label="Fecha completado" value={col => col.deletedAt ? col.deletedAt : ""} />
                <ExcelColumn label="Razón de completado" value={col => col.reasonForDelete ? col.reasonForDelete : ""} />

                <ExcelColumn label="Fecha de Llamada" value={col => col.crm ? moment(col.crm.dateBeenCalled).format('DD/MM/YYYY HH:mm:ss a') : ""} />
                <ExcelColumn label="Quien atendió" value={col => col.crm ? col.crm.nameUser : ""} />
                <ExcelColumn label="Anotaciones" value={col => col.crm ? col.crm.observation : ""} />
            </ExcelSheet>
        </ExcelFile>
    );
}