import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Result } from 'antd'
import { useState } from 'react'
import { apiApproveDelete } from '../../../../utils/api'
import FollowUpReports  from '../FollowUpReport'

export const DeleteReports = ({ data, close, refresh, setRefresh, onCloseDetails }) => {

    const [isOpen, setisOpen] = useState(false)
    const [isApprove, setisApprove] = useState(false)
    const [isReject, setisReject] = useState(false)
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const [reason, setreason] = useState(null)
    const [followUp, setFollowUp] = useState(false)
    const [step, setStep] = useState(0)

    const handleClose = () => {
        setisOpen(false)
        setisReject(false)
        setisApprove(false)
        seterror(false)
        setloading(false)
        setFollowUp(false)
        setreason('')
        setStep(0)
    }

    const handleApproveOrReject = (option) => {
        setloading(true)
        apiApproveDelete(data.id, option, reason)
            .then(res => {
                option === 'Reject'
                ? setStep(4)
                : setStep(2)
                // setisApprove(true)
            }).catch(error => {
                setStep(5)
                seterror(true)
                console.log(error)
            }).finally(() => {
                setloading(false)
            })
    }

    return (
        <div>
            <Button onClick={() => setisOpen(true)} type='link' style={{ width: 'fit-content', marginTop: 15 }} icon={<ExclamationCircleOutlined />}>
                {window.location.pathname.includes('reportes')
                    ? 'Mover a Completado'
                    : 'Resolver petición'
                }
            </Button>

            <Modal
                centered
                visible={isOpen}
                closable={false}
                footer={null}
                onCancel={() => handleClose()}
            >
                {
                    (!isApprove && !isReject && !error) &&
                    <>
                        {
                           step === 0 &&
                                
                                (
                                    <>
                                        {
                                            window.location.pathname.includes('reportes')
                                                ? (
                                                    <Result
                                                        status={'info'}
                                                        title='Mover reporte a Completado'
                                                        subTitle={`¿Desea mover el reporte con folio ${data.id} a Completado?`}
                                                        extra={[
                                                            <Form.Item
                                                                key='reason'
                                                                help='Ingrese la razón para mover este reporte'
                                                                style={{ width: '100%', marginBottom: 15 }}
                                                            >
                                                                <Input name='reason' maxLength={50} value={reason} onChange={e => setreason(e.target.value)} />
                                                            </Form.Item>,
                                                            <Button disabled={!reason} type='link' key='approve' onClick={() => setStep(1)} >Completar</Button>,
                                                            <Button type='primary' key='cancel' onClick={handleClose}>Cancelar</Button>,
                                                        ]}
                                                    />
                                                )
                                                : !followUp && (
                                                    <Result
                                                        status={'info'}
                                                        title='Solicitud para mover reporte a Completado'
                                                        subTitle={`Seleccione la opción para esta solicitud de eliminación del reporte con folio ${data.id}`}
                                                        extra={[
                                                            <Button type='link' key='approve' onClick={() => setStep(1)} >Autorizar</Button>,
                                                            <Button type='link' key='reject' onClick={() => handleApproveOrReject('Reject')} >Rechazar</Button>,
                                                            <Button type='primary' key='cancel' onClick={handleClose}>Cancelar</Button>,
                                                        ]}
                                                    />
                                                )
                                        }

                                    </>
                                )
                        }
                        {       
                            step === 1 &&
                                (
                                    <Result
                                        status={'warning'}
                                        title='Confirme Mover Reporte a Completado'
                                        subTitle={`Esta operación es irreversible, ¿está seguro de realizarla?`}
                                        extra={[
                                            <Button loading={loading} type='link' key='delete' onClick={() => handleApproveOrReject('Approved')} >Si, Mover</Button>,
                                            <Button disabled={loading} type='primary' key='cancel' onClick={handleClose}>No, Cancelar</Button>,
                                        ]}
                                    />
                                )
                        }
                    </>
                }

                { step === 2 &&
                <FollowUpReports data={data} isOpen={isOpen} setIsOpen={setisOpen} onClose={handleClose} setStep={setStep} option={"complete"} />
                }

                {
                    step === 3 &&
                    <Result
                        status={'success'}
                        title='Operación realizada correctamente'
                        subTitle={`El reporte con folio ${data.id} se ha movido correctamente a Completado`}
                        extra={[
                            <Button loading={loading} type='link' key='delete' onClick={() => {
                                handleClose()
                                close()
                                // refresh(prev => !prev)
                                setRefresh(!refresh)
                            }} >Salir</Button>,
                        ]}
                    />
                }
                {
                    step === 4 &&
                    <Result
                        status={'success'}
                        title='Operación realizada correctamente'
                        subTitle={`La solicitud para mover el reporte con folio ${data.id} a completado ha sido rechazada correctamente`}
                        extra={[
                            <Button loading={loading} type='link' key='delete' onClick={() => {
                                handleClose()
                                close()
                                // refresh(prev => !prev)
                                setRefresh(!refresh)
                            }} >Salir</Button>,
                        ]}
                    />
                }

                {
                    step === 5 &&
                    <Result
                        status={'error'}
                        title='Error al mover Reporte'
                        subTitle={`Ha ocurrido un error al intentar mover el reporte con folio ${data.id} a completado`}
                        extra={[
                            <Button disabled={loading} type='primary' key='cancel' onClick={() => handleClose()}>Salir</Button>,
                            <Button loading={loading} type='link' key='delete' onClick={() => seterror(false)} >Reintentar</Button>,
                        ]}
                    />
                }

            </Modal>
        </div>
    )
}
