import React, { useState } from "react"
import { Input, Button } from "antd"
import { prMain } from "../../../themeConfig";
import EventsTable from "./EventsTable";
const { Search } = Input

const EventsPage = () => {
    const [filter, setFilter] = useState('');
    const [openCreate, setOpenCreate] = useState(false)
    const [page, setpage] = useState(1)
    const [update, setupdate] = useState(false)

    const handleFilter = (event) => {
        if (event.target.value === '') {
            setpage(1)
            setupdate(prev => !prev)
        }
        setFilter(event.target.value);
    }

    const handleSearch = () => {
        if (filter) {
            setpage(1)
            setupdate(prev => !prev)
        }
    }

    return (
        <>
            <section style={{ ...classes.box, flexDirection: 'column' }}>
                <div style={{ display: 'flex', width: '90%', flexDirection: 'row' }}>
                    <div style={{ ...classes.box, width: '80%', flexDirection: 'row' }}>
                        <Search style={{width: '80%'}} value={filter} onChange={handleFilter} onSearch={handleSearch} placeholder="Buscar..." />
                    </div>
                    <Button type="primary" onClick={() => setOpenCreate(true)}>Agregar Evento</Button>
                </div>
                <div style={{ width: '90%', height: '80vh', overflowY: 'auto' }}>
                    <EventsTable filter={filter} openCreate={openCreate} setOpenCreate={setOpenCreate} setpage={setpage} page={page} update={update} />
                </div>
            </section>


        </>)
}

const classes = {
    textfield: {
        width: '60%',
        // marginRight: '10%',
        backgroundColor: '#FFF',
        borderRadius: '20px 0 0 20px',
    },
    button: {
        width: 'auto',
        textTransform: 'capitalize',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchButton: {
        color: '#fff',
        backgroundColor: prMain,
        height: 40,
        width: 40,
        borderRadius: '0 20px 20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-7px',
        marginLeft: '-2px'
    },
}


export default EventsPage