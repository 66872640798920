

import React, { useState } from 'react';
// import UsersTable from './../users/UsersTable';
import ScholarshipsTable from './ScholarshipsTable';
import { Input } from 'antd'
import { useHistory } from 'react-router-dom';
import { prMain } from '../../../themeConfig';
const { Search } = Input

const classes = {
    textfield: {
        width: '60%',
        // marginRight: '10%',
        backgroundColor: '#FFF',
        borderRadius: '20px 0 0 20px'
    },
    button: {
        width: 'auto',
        textTransform: 'capitalize',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchButton: {
        color: '#fff',
        backgroundColor: prMain,
        height: 40,
        width: 40,
        borderRadius: '0 20px 20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-7px',
        marginLeft: '-2px'
    },
}

const ScholarshipsPage = (props) => {

    const [filter, setFilter] = useState('');
    const history = useHistory();
    const pathname = history.location.pathname
    const [page, setpage] = useState(1)
    const [update, setupdate] = useState(false)

    const handleFilter = (event) => {
        if (event.target.value === '') {
            setpage(1)
            setupdate(prev => !prev)
        }
        setFilter(event.target.value);
    }

    const handleSearch = () => {
        if (filter) {
            setpage(1)
            setupdate(prev => !prev)
        }
    }

    return (
        <>
            <section style={{ ...classes.box, flexDirection: 'column' }}>
                <div style={{ ...classes.box, width: '80%', flexDirection: 'row' }}>
                    <Search style={{ width: '80%' }} value={filter} onChange={handleFilter} onSearch={handleSearch} placeholder="Buscar..." />

                </div>
                <div style={{ width: '90%', height: '80vh', overflowY: 'auto' }}>
                    <ScholarshipsTable filter={filter} pathname={pathname} page={page} setpage={setpage} update={update} />
                </div>
            </section>

        </>
    );
}
export default ScholarshipsPage;