import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectAsync } from '../../reactHelper';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Button, Input, Modal, Form, Result } from 'antd';
import { apiChangePassword } from '../../utils/api';
import { ResendCode } from './ResendCode';
import { validateNumbers } from '../../utils/validateNumbers';
import { background, logo } from '../../themeConfig';

const classes = {
    button: {
        textTransform: 'capitalize',
        width: 'auto',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    main: {
        height: '100vh',
        minHeight: '100vh',
        display: 'flex',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // background: '#222',
        minwidth: 400,
        overflowX: 'hiden',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red'
    },
    root: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        height: 'auto',
        overflowY: 'auto',
        background: '#fafafa',
        borderRadius: '3%',
        display: 'flex',
        flexDirection: 'flex',
        justifyContent: 'center',
        padding: '20px',
        margin: '10%'
    },
    logo: {
        width: 100,
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 20,
    },
    a: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex'
    },
    btnFacebook: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
}

const SetPassword = () => {
    const { code } = useParams()
    const [codeC, setcodeC] = useState(code)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    //ERROR CONSTS
    const [passDontMatch, setPassDontMatch] = useState(false);
    const [passLength, setPassLength] = useState(false);
    const [loading, setloading] = useState(false)
    const [failed, setfailed] = useState(false)

    const [invalidCode, setinvalidCode] = useState(false)
    const [expiredCode, setexpiredCode] = useState(false)
    const [notFoundCode, setnotFoundCode] = useState(false)
    const [success, setsuccess] = useState(false)
    const [resend, setResend] = useState(false)

    const { width } = useScreenSize();

    const history = useHistory();
    // const path = useState(window.location.href.split("/"));
    // const code = path[0][path[0].length-1]

    const [runEffect, setRunEffect] = useState(false);

    useEffectAsync(async () => {
        if (password.length !== 0) {
            setloading(true)
            apiChangePassword(codeC, password)
                .then(res => {
                    console.log('constraseña establecida')
                    setsuccess(true)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.status === 400) {
                        setinvalidCode(true)
                    } else if (err.response.status === 404) {
                        setnotFoundCode(true)
                    } else if (err.response.status === 498) {
                        setexpiredCode(true)
                    }else{
                        setfailed(true)
                    }
                }).finally(() => {
                    setloading(false)
                })
        }
    }, [runEffect])

    const handleClose = () => {
        setexpiredCode(false)
        setinvalidCode(false)
        setnotFoundCode(false)
        setfailed(false)
        setsuccess(false)
        history.push('/')
    }

    const handleSetPassword = () => {

        if (password !== confirmPassword) {
            setPassDontMatch(true);
            setPassLength(false);

        } else if (password.length < 8) {
            setPassLength(true);
            setPassDontMatch(false);

        } else {
            setPassDontMatch(false);
            setPassLength(false);
            setRunEffect(!runEffect);
        }
    }

    const handlePasswordChange = (event) => {
        setPassDontMatch(false);
        setPassLength(false);
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setPassDontMatch(false);
        setPassLength(false);
        setConfirmPassword(event.target.value);
    }

    const handleChangeCode = (e) => {
        setfailed(false)
        setexpiredCode(false)
        setinvalidCode(false)
        setnotFoundCode(false)
        setcodeC(validateNumbers(e))

    }

    const handleOpenResend = () => {
        setResend(true)
        setsuccess(true)
    }

    const handleClear = () => {
        setfailed(false)
        setexpiredCode(false)
        setinvalidCode(false)
        setnotFoundCode(false)
        setPassword('')
        setConfirmPassword('')
    }

    return (
        <div>
            <div style={classes.main} direction="coloum">
                <div style={classes.image}></div>
                <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '30vw' }}>
                    <form style={{ width: '100%' }}>
                        <div style={classes.a}>
                            <img style={classes.logo} src={logo} alt="Logo" />
                        </div>
                        <div style={{ width: '100%', marginTop: 20 }}>
                            <Form.Item
                                validateStatus={(invalidCode || expiredCode || notFoundCode || failed) ? 'error' : ''}
                                help={
                                    invalidCode
                                        ? 'Ha ocurrido un error al intentar establecer la contraseña, el código de verificación no es válido'
                                        : expiredCode
                                            ? 'Ha ocurrido un error al intentar establecer la contraseña, el código de verificación ha expirado'
                                            : notFoundCode
                                                ? 'Ha ocurrido un error al intentar establecer la contraseña, no cuenta con un código de verificación'
                                                : failed
                                                    ? 'Ha ocurrido un error al intentar establecer la contraseña'
                                                    : codeC
                                                        ? 'Código de verificación'
                                                        : ''}
                            >
                                <Input
                                    size='large'
                                    onChange={handleChangeCode}
                                    value={codeC}
                                    maxLength={6}
                                    placeholder="Código de verificación"
                                    name="code"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ width: '100%', marginTop: 20 }}>
                            <Form.Item
                                validateStatus={passDontMatch || passLength ? 'error' : ''}
                                help={
                                    passDontMatch ? 'Las contraseñas no coinciden' : passLength ? 'La contraseña debe tener al menos 8 caracteres' : (!passDontMatch && !passLength) ? 'Ingrese su nueva contraseña' : ''
                                }
                            >
                                <Input.Password
                                    size='large'
                                    onChange={handlePasswordChange}
                                    value={password}
                                    placeholder="Nueva contraseña"
                                    id="password"
                                    status={passLength || passDontMatch ? 'error' : ''}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Form.Item
                                validateStatus={passDontMatch || passLength ? 'error' : ''}
                                help={
                                    passDontMatch ? 'Las contraseñas no coinciden' : passLength ? 'La contraseña debe tener al menos 8 caracteres' : (!passDontMatch && !passLength) ? 'Confirme su nueva contraseña' : ''
                                }
                            >

                                <Input.Password
                                    size="large"
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmPassword}
                                    placeholder="Confirme contraseña"
                                    id="password"
                                    status={passLength || passDontMatch ? 'error' : ''}
                                />
                            </Form.Item>
                        </div>
                        <div style={classes.buttons} >
                            {
                                (!invalidCode && !expiredCode && !notFoundCode) &&
                                <Button disabled={!password || !confirmPassword || !codeC || codeC.length < 6 } loading={loading} style={classes.button} onClick={handleSetPassword} variant='contained' type='primary' size='large'>
                                    Guardar contrase&ntilde;a
                                </Button>
                            }
                            {
                                (invalidCode || expiredCode || notFoundCode) &&
                                <>
                                    {
                                        expiredCode &&
                                        <Button style={classes.button} onClick={handleOpenResend} variant='contained' type='link
                                        ' size='large'>
                                            Reenviar código de verificación
                                        </Button>
                                    }
                                    <Button className={classes.button} onClick={handleClose} type='primary' size='large'>
                                        Salir
                                    </Button>
                                </>
                            }
                        </div>
                    </form>
                </div>

            </div>
            {
                success &&
                <Modal
                    visible={success}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    footer={null}
                    closable={false}
                >
                    {
                        resend
                            ? <>
                                <ResendCode loading={loading} clear={handleClear} code={codeC} setcodeC={setcodeC} setloading={setloading} handleClose={handleClose} />
                            </>
                            :
                            <Result
                                status='success'
                                title='Contraseña establecida correctamente'
                                subTitle='Su nueva contraseña ha sido establecida correctamente.'
                                extra={[
                                    <Button key='ok' type='primary' onClick={handleClose} autoFocus >
                                        Aceptar
                                    </Button>
                                ]}
                            />
                    }
                </Modal>
            }
        </div>

    )
}

export default SetPassword;