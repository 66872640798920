import React from 'react'
import { GlobalOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { prMain } from '../../../themeConfig'

export const SwitchMap = ({ checked, handleChange }) => {
    return (
        <div style={{ display: 'flex', backgroundColor: '#fff', borderRadius: 15 }}>

            <section onClick={() => handleChange(false)} style={{ backgroundColor: checked ? '' : prMain, height: 'auto', borderRadius: 15, padding: 5, cursor: 'pointer', width: 70 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <GlobalOutlined style={{color: checked ? prMain : '#fff'}} />
                    <Typography.Text style={{ color: checked ? prMain : '#FFF', fontSize: 12, marginTop: -3 }}>Mapa</Typography.Text>
                </div>
            </section>

            <section onClick={() => handleChange(true)} style={{ backgroundColor: !checked ? '' : prMain, height: 'auto', borderRadius: 15, padding: 5, cursor: 'pointer', width: 70 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <UnorderedListOutlined style={{ color: !checked ? prMain : '#FFF' }} />
                    <Typography.Text style={{ color: !checked ? prMain : '#FFF', fontSize: 12, marginTop: -3 }}>Lista</Typography.Text>
                </div>
            </section>
        </div>
    )
}
