import moment from 'moment';
import Chart from 'react-apexcharts'
import { prMain } from '../../themeConfig';

export const PieChart = ({lengths, labelsPostCode, seriesPostCode, height, colors = ['#ff4949', '#ffc700', '#ff9800', '#71cf71']}) => {
  var options = {
    series: seriesPostCode,
    colors,
    chart: {
      width: 380,
      type: 'pie',
      toolbar: {
        tools:{
            download: true,
        },
        export: {
            csv: {
                filename: `Reporte_semanal_${moment().format('YYYY_W')}`,
                columnDelimiter: ',',
                headerCategory: 'Día',
                headerValue: 'value',
                dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                }
            },
            svg: {
                filename: `Reporte_semanal_${moment().format('YYYY_W')}`
            },
            png: {
                filename: `Reporte_semanal_${moment().format('YYYY_W')}`
            }
        }
    },
    },
    noData: {
      text: 'No hay datos para mostrar',
      style: {
        color: prMain,
        fontSize: '14px',
      }

    },
    legend: {
      show: true,
      formatter: value => `${value}: ${lengths[value]}`
    },
    labels: labelsPostCode,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
  return (
    <div >
      <Chart options={options} series={options.series} type="pie" width='100%' height={height} />
    </div>
  )
}