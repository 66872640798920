import moment from 'moment'
import Chart from 'react-apexcharts'
import { prMain } from '../../themeConfig'

export const WeekChart = ({ data, type }) => {
    const es = require('apexcharts/dist/locales/es.json')

    const defaultValue = [0,0,0,0,0,0,0]
    const state = {


        series: [{
            name: 'Pendientes',
            data: data ? data.pending : defaultValue,
            color: '#ff4949',
        }, {
            name: 'En proceso',
            data: data ? data.inProcess : defaultValue,
            color: '#ffc700',
        }, {
            name: 'En revisión',
            data: data ? data.review : defaultValue,
            color: '#ff9800',
        }, {
            name: 'Solucionados',
            data: data ? data.solved : defaultValue,
            color: '#71cf71',
        },],
        options: {
            legend:{
                onItemClick:{
                    toggleDataSeries: false
                }
            },
            chart: {
                height: 350,
                stacked: false,
                locales: [es],
                defaultLocale: 'es',
                toolbar: {
                    tools:{
                        download: true,
                    },
                    export: {
                        csv: {
                            filename: `Reporte_semanal_${moment().format('YYYY_W')}`,
                            columnDelimiter: ',',
                            headerCategory: 'Día',
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            }
                        },
                        svg: {
                            filename: `Reporte_semanal_${moment().format('YYYY_W')}`
                        },
                        png: {
                            filename: `Reporte_semanal_${moment().format('YYYY_W')}`
                        }
                    }
                },
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 5,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 7,
                    sizeOffset: 3
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 2,
                dashArray: 0,
            },

            xaxis: {
                categories: [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo' ],
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: prMain
                    },
                    labels: {
                        style: {
                            colors: prMain,
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }

            ],
            // tooltip: {
            //     fixed: {
            //         offsetY: 30,
            //         offsetX: 60
            //     },
            // }
        },
    };

    return (
        <div style={{ marginTop: 10 }}>
            <Chart options={state.options} series={state.series} type={type} width={'100%'} height={250} />
        </div>
    )
}
