import { Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Bookmark } from '../Utils/Bookmark/Bookmark';

export const RecentReports = ({ reports, title, color, status }) => {

    return (
        <Tooltip title={title} placement='bottom'>
            <Link to={`reportes/${status}`}>
                <div style={{ width: '100%', height: 'auto', padding: 10, position: 'relative', backgroundColor: '#FFF', borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ position: 'absolute', top: 0, right: 15 }}>
                        <Bookmark color={color} />
                    </div>
                    <Typography.Text style={{ fontSize: 18, color: '#6D6D6D', width: '85%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</Typography.Text>
                    <Typography.Text style={{ fontSize: 10, color: '#6D6D6D' }}>en las últimas 24 hrs</Typography.Text>
                    {
                        reports.length > 0
                            ? <Typography.Text style={{ fontSize: 45, color: '#6D6D6D', textAlign: 'center', marginTop: 10 }}>{reports.length}</Typography.Text>
                            : <Typography.Text style={{ fontSize: 45, color: '#6D6D6D', textAlign: 'center', marginTop: 10 }}>0</Typography.Text>
                    }
                </div>
            </Link>
        </Tooltip>
    )
}