import { Tabs } from 'antd'
import React, { useState } from 'react'
import { ReportsDeleted } from '../../reports/DeleteReports/ReportsDeleted'
// import { UpdateAccepted } from '../UpdatedReports/UpdateAccepted'
// import { UpdateRequested } from '../UpdatedReports/UpdateRequested'
import { RequestReports } from './RequestReports'

export const ReportsContainer = () => {
  const [refresh, setRefresh] = useState(false)
  const TabPane = Tabs.TabPane

  return (
    <section>
      <div style={{ marginBottom: 15 }}>
        <Tabs type='card' defaultActiveKey="1" onChange={() => setRefresh(!refresh)}>
          <TabPane tab="Pendientes por completar" key={"1"}>
            <div>
              <RequestReports refresh={refresh} setRefresh={setRefresh} />
            </div>
          </TabPane>
          <TabPane tab="Completados" key={"2"}>
            <div>
              <ReportsDeleted refresh={refresh} setRefresh={setRefresh} />
            </div>
          </TabPane>
          {/* <TabPane tab="Solicitud para modificar" key={"3"}>
            <div>
              <UpdateRequested refresh={refresh} setRefresh={setRefresh} />
            </div>
          </TabPane>
          <TabPane tab="Modificados" key={"4"}>
            <div>
              <UpdateAccepted refresh={refresh} setRefresh={setRefresh} />
            </div>
          </TabPane> */}

        </Tabs>
      </div>
    </section>
  )
}
