import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd'
import { EyeOutlined, ReloadOutlined, SendOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';
import { ExportScholarships } from './ExportScholarships';
// import { NavLink } from "react-router-dom";
import { apiGetScholarshipsPagination, apiGetScholarshipsPaginationSearch } from '../../../utils/api';
import moment from 'moment';
import ListForNotifications from './ListForNotifications';
import ScholarshipDetails from './ScholarshipsDetails';


const ScholarshipsTable = ({ filter, clickSearch, page, setpage, update }) => {
  const columns = [
    {
      title: 'Folio',
      dataIndex: 'folio',
      sorter: true,
      key: 'folio',
      width: '7%',
      render: folio => (
        <Tooltip title={folio}>
          {folio}
        </Tooltip>
      )
    },
    {
      title: 'Nombre',
      width: '15%',
      render: row => (
        <Tooltip title={`${row.name} ${row.firstLastName ? row.firstLastName : ''} ${row.secondLastName ? row.secondLastName : ''}`}>
          {row.name} {row.firstLastName ? row.firstLastName : ''} {row.secondLastName ? row.secondLastName : ''}
        </Tooltip>
      )
    },
    {
      title: 'Tipo de apoyo',
      width: '12%',
      render: row => (
        <>{
          // row.beneficiaryList.length !== 1
          // ?
          // <Tooltip title={'multiples solicitudes'}> </Tooltip>
          // :
          <Tooltip title={row.beneficiaryList.length === 0 ? row.typeScholarShip : row.beneficiaryList[0].typeScholarship}>
            {row.beneficiaryList.length === 0 ? row.typeScholarShip : row.beneficiaryList[0].typeScholarship}
            {/* row.beneficiaryList[0].typeScholarship} */}
          </Tooltip>
        }</>
      )
    },
    {
      title: 'Teléfono',
      dataIndex: 'firstPhone',
      key: 'firstPhone',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: firstPhone => (
        <Tooltip title={firstPhone}>
          {firstPhone}
        </Tooltip>
      )
    },
    {
      title: 'CURP',
      dataIndex: 'curp',
      key: 'curp',
      ellipsis: {
        showTitle: true
      },
      width: '18%',
    },
    {
      title: 'Estatus',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: row => (
        <>
          {
            <Tooltip title={row.nameStatusGlobal}>
              {row.nameStatusGlobal}
            </Tooltip>
          }
        </>
      )
    },

  ]

  const lastUpdateTable =
  {
    title: 'Último cambio',
    width: '10%',
    render: row => (
      <>
        {
          <Tooltip>
            {row.beneficiaryList.length === 0 && `${row.scholarshipsObservationList[0] ? moment(row.scholarshipsObservationList[row.scholarshipsObservationList.length - 1].dateLastUpdate).format('lll') : ''}`}
            {row.beneficiaryList.length === 1 && ""}
          </Tooltip>
        }
      </>
    )
  }

  const responsableTable =
  {
    title: 'Responsable',
    width: '12%',
    render: row => (
      <>
        {row.beneficiaryList.length > 1
          ? <Tooltip title={'Multiples solicitudes'}>     </Tooltip>
          :
          <Tooltip title={''}>
            {row.beneficiaryList.length === 0 && `${row.scholarshipsObservationList[0] ? row.scholarshipsObservationList[row.scholarshipsObservationList.length - 1].userWhoChangeStatus : ''}`}
            {row.beneficiaryList.length === 1 && `${row.beneficiaryList[0].scholarshipsObservationList[0] ? row.beneficiaryList[0].scholarshipsObservationList[row.beneficiaryList[0].scholarshipsObservationList.length - 1].userWhoChangeStatus : ''}`}
          </Tooltip>
        }
      </>
    )
  }

  const actionsTable = {
    title: 'Detalles',
    width: '8%',
    ellipsis: {
      showTitle: true
    },
    render: (row) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {
          <Tooltip title="Ver">
            {/* <NavLink to={{ pathname: '/apoyos/detalles', state: row }}> */}
            <Button type='link' icon={<EyeOutlined style={{ color: '#000' }} />} onClick={() => handledetails(row)}>
            </Button>
            {/* </NavLink> */}
          </Tooltip>
        }
      </div>
    ),
  }

  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(state => state.session)
  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [details, setDetails] = useState([])

  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({
    order: undefined,
    key: undefined,
  })
  const [totalData, settotalData] = useState(0)

  const handledetails = (data) => {
    setDetails(data)
    setOpenDetails(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const getData = useCallback(() => {

    setLoading(true)

    apiGetScholarshipsPagination(page, pageSize, order.order, order.key).then(response => {
      settotalData(response.data.amount)
      setFilterData(response.data.scholarships)

    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })

  }, [page, pageSize, order.order, order.key])

  const getDataSearch = useCallback(() => {

    setLoading(true)

    apiGetScholarshipsPaginationSearch(page, pageSize, order.order, order.key, filter).then(response => {
      settotalData(response.data.amount)
      setFilterData(response.data.scholarship)

    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })

  }, [page, pageSize, order.order, order.key, filter])

  useEffect(() => {

    if (filter) {
      getDataSearch()
    } else {
      getData()
    }

  }, [update, refresh, page, pageSize, order.order, order.key])

  const handleTable = (pagination, filters, sorter) => {

    setpage(pagination.current)
    setpageSize(pagination.pageSize)
    setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : undefined }))
  }

  return (
    <>
      <div style={{ paddingTop: 30 }}>
        <Table
          onChange={handleTable}
          rowKey={record => record.folio}
          loading={loading}
          columns={
            (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN))
              ? [...columns, lastUpdateTable, responsableTable, actionsTable]
              : [...columns, actionsTable]}
          dataSource={filterData}
          size="small"
          scroll={{ y: '60vh', x: '100%' }}
          pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        />
        <div style={{ marginTop: '-50px' }} >
          {(isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) &&
            <ExportScholarships filter={filter} data={filterData} />
          }
          <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
          <Button type='link' icon={<SendOutlined />} onClick={() => setOpenModal(true)} disabled={!filterData}>Enviar mensajes</Button>
        </div>
      </div>

      <Modal
        centered
        visible={openModal}
        closable={true}
        footer={null}
        destroyOnClose
        onCancel={handleCloseModal}>

        <ListForNotifications setOpenModal={setOpenModal} />

      </Modal>

      <Modal
        centered
        visible={openDetails}
        closable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setOpenDetails(false)}
        width={'75%'}
      >
        {/* <div style = {{ width: '70vw' }}> */}
        <ScholarshipDetails data={details} setOpenDetails={setOpenDetails} setRefresh={setRefresh} refresh={refresh} />
        {/* </div> */}

      </Modal>
    </>
  );
}
export default ScholarshipsTable;