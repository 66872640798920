
export const firebaseConfig = {
    apiKey: "AIzaSyC4_0oN_MzIo7XpL41rfAJuQvhxeK62G6I",
    authDomain: "delicias-touch-e6956.firebaseapp.com",
    projectId: "delicias-touch-e6956",
    storageBucket: "delicias-touch-e6956.appspot.com",
    messagingSenderId: "207251665916",
    appId: "1:207251665916:web:187aa182bd3ad9323bf879",
    measurementId: "G-8JJRQ0E8LG"
};
