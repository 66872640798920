import { useState } from 'react';
import { Dependencies } from './dependencies/Dependencies';
import { HistoricalPage } from './historical/HistoricalPage';
import { ConfigurationMap } from './mapConfiguration/ConfigurationMap';
import { Configuration } from './TimeConfiguration/Configuration';
import { EventsConfig } from './eventsConfig/EventsConfig';
import { ReportConfig } from './Reports/ReportConfig';
import SendNotification from './Notifications/SendNotification';
import { TabsPage } from '../../Tabs/TabsPage';
// import { SMSConfig } from './smsConfig/SMSConfig';
import { ConfigurationsPage } from './Configurations/ConfigurationsPage';
import { PeriodsPage } from './Periods/PeriodsPage';

export const SettingsPage = () => {

  const [updated, setupdated] = useState(false)

  const tabsPanels = [
    {
      title: 'Servicios',
      key: 1,
      content: <Configuration setupdated={setupdated} updated={updated} />,
      onlyAdmin: false,
    },
    {
      title: 'Dependencias',
      key: 2,
      content: <Dependencies setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Históricos',
      key: 3,
      content: <HistoricalPage setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Mapa',
      key: 4,
      content: <ConfigurationMap setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Eventos',
      key: 5,
      content: <EventsConfig setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Reportes',
      key: 6,
      content: <ReportConfig setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Periodos',
      key: 10,
      content: <PeriodsPage setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    {
      title: 'Notificaciones',
      key: 7,
      content: <SendNotification setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    // {
    //   title: 'SMS',
    //   key: 8,
    //   content: <SMSConfig setupdated={setupdated} updated={updated} />,
    //   onlyAdmin: true,
    //   superAdmin: true,
    // },
    {
      title: 'Configuraciones',
      key: 9,
      content: <ConfigurationsPage setupdated={setupdated} updated={updated} />,
      onlyAdmin: true,
    },
    // {
    //   title: 'CRM',
    //   key:9,
    //   content: <CRMPage setupdated={setupdated} updated={updated}/>,
    //   onlyAdmin: true,
    // },
  ]

  return (
    <TabsPage tabsPanels={tabsPanels} />
  )
}
