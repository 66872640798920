
import { Typography, Row, Col } from "antd"

export const HistoricalRow = ({label, value}) => {

    const { Text } = Typography


    return (
        <Row style={{ marginTop: '3vh' }}>
                <Col span={6} offset={1}>
                    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {label}
                    </Text>
                </Col>
                <Col span={16}>
                    <Text style={{ fontSize: 12 }}>
                        {value}
                    </Text>
                </Col>
            </Row>
    )
}