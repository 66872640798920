import React, { useCallback, useEffect, useState } from 'react'
import { apiChangeConfigurations, apiGetConfigurations, apiChangeAppVersion } from '../../../../utils/api'
import { Switch, Table, Tag, Tooltip } from 'antd'
import isAllowed, { ROLES_DIVISIONS } from '../../../../layout/roles'
import { useSelector } from 'react-redux'
import Input from 'antd/lib/input/Input'

export const ConfigurationsPage = () => {

  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const { user } = useSelector(state => state.session)

  const getData = useCallback(() => {
    setloading(true)
    apiGetConfigurations().then(res => {
      let datos = JSON.parse(res.data[10]["message"])
      setInputValue(datos['appVersion'])
      console.log(res.data)
      // res.data = res.data.filter(i =>  (!isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN) && i.typeServices !== 'Login'))
      res.data = res.data.filter(i => {
        return !i.isExclusiveToSuperAdmin || (i.isExclusiveToSuperAdmin && isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN))
      })
      setdata(res.data.filter(i => i.typeServices !== 'template'))
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setloading(false)
    })
  }, [])

  const handleInput = (event) => {
    setInputValue( event.target.value ) 
  }
   

  const handleKey = (event) => {
    apiChangeAppVersion(inputValue).then(res => {
      getData()
    }).catch(err => {
      new Error(err)
    })
  }

  const handleChange = (value, item) => {
    apiChangeConfigurations(value ? 1 : 0, item.idConfiguration).then(res => {
      getData()
    }).catch(err => {
      new Error(err)
    })
  }

  const columns = [
    {
      title: 'Descripción',
      render: row => (
        <div>
          <div>
            {row.nameServices}
          </div>

        </div>
      )
    },
    {
      title: 'Status',
      width: '20%',
      render: row => (
        <div style={{ display: 'flex', }}>
          {
            row.typeServices === 'SmsEnable'
              ?
              <>
                {row.active ? <Tag color='green'>Twilio</Tag> : <Tag color='green'>SMS API</Tag>}
              </>
              :
              <>
                {row.active ? <Tag color='green'>Activo</Tag> : <Tag color='red'>Inactivo</Tag>}
              </>
          }

        </div>
      )
    },
    {
      title: 'Accion',
      width: '15%',
      render: row => (
        <div style={{ display: 'flex', alignItems: 'center',  }}>
          {
            row.typeServices === 'SmsEnable'
              ?
              <>
                <Tooltip title={`${row.active ? 'Cambiar a SMS Api' : 'Cambiar a Twilio'}`}>
                  <Switch checked={row.active} onChange={e => handleChange(e, row)} />
                </Tooltip>
              </>
              :
              <>
                <Tooltip title={`${row.active ? 'Desactivar' : 'Activar'}`}>
                  <Switch checked={row.active} onChange={e => handleChange(e, row)} />
                </Tooltip>
              </>
          }
          {/* {
            row.typeServices === 'documentation_participatory_budget' &&
            <div>
              <MdOutlineDriveFolderUpload style={{fontSize: 28, color: prMain}} />
            </div>
          } */}
        </div>
      )
    },
    {
      title: "",
      width: '15%',
      render: row => (
        row.typeServices === 'app_version'
        ?
        <div>
          version:
          <Input 
            value= { inputValue } 
            onChange= {e => handleInput(e)} 
            onKeyPress= {e => { if(e.key === 'Enter') {handleKey(e)} }}/>
        </div>
        : ""
      )
    },
  ]

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <div style={{ width: '100%', height: 1, opacity: .1, backgroundColor: '#000' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', width: '100%' }}>
        <Table
          rowKey={record => record.idConfiguration}
          loading={loading}
          columns={columns}
          dataSource={data}
          size='large'
          scroll={{ y: '60vh', x: '100%' }}
        />
      </div>
    </div>
  )
}
