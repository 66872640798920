import React, { useEffect, useState } from "react"
import { apiRequestUpdate } from "../../../../utils/api"
import { Button, Input, Typography, Spin, Result } from "antd"
import { LocationReport } from "../../reports/Create/LocationReport"


export const EditReport = ({ report, close, setOpenEdit, refresh, setRefresh }) => {
    const { Text } = Typography
    const [postalCode, setPostalCode] = useState("")
    const [newAddress, setNewAddress] = useState([])
    const [reason, setReason] = useState("")
    const [request, setRequest] = useState([])
    const [loading, setLoading] = useState(!request)
    const [showMap, setShowMap] = useState(false)
    const [step, setStep] = useState(0)

    useEffect(() => {
        setRequest({ description: report.description, latitude: report.latitude, longitude: report.longitude, address: report.address })
        setLoading(!request)
    }, [])

    const handleChangeDescription = (event) => {
        setRequest({ ...request, description: event.target.value })
    }

    const handleChangeReason = (event) => {
        setReason(event.target.value)
    }

    const handleShowMap = () => {
        setNewAddress([])
        setShowMap(!showMap)
    }

    const handleSave = () => {
        let req = {}
        if (showMap && newAddress.length !== 0) {
            req = { description: request.description, latitude: newAddress.latitude, longitude: newAddress.longitude, address: newAddress.address }
        } else {
            req = request
        }
        req.length !== 0 &&
        (
        apiRequestUpdate(report.id, reason, req).then(() => {
            setStep(1)

        }).catch(error => {
            console.log(error)
            console.log(postalCode)
            setStep(2)
        }))
    }
    
    const handleClose = () => {
        setRefresh(!refresh)
        close()
        setOpenEdit(false)
    }

    return (
        <div style={{ maxHeight: '90vh' }}>
            { step === 0 &&
            <div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Text style={{ fontSize: 24 }}>Editar Reporte</Text>

                </div>
                <div style={{ maxHeight: '80vh', overflowY: 'scroll', paddingRight: '15px', paddingBottom: '1vh', marginTop: '2vh' }}>
                    <Spin spinning={loading}>
                        <Text>Razón de la modificación:</Text>
                        <Input.TextArea onChange={(e) => handleChangeReason(e)} placeholder="Razón de modificación" value={reason} style={{ height: '10vh', maxHeight: '10vh', minHeight: '10vh', marginBottom: '2vh' }} maxLength={100} />
                        <Text>Descripción:</Text>
                        <Input.TextArea onChange={(e) => handleChangeDescription(e)} placeholder="Descripción" value={request.description} style={{ height: '10vh', maxHeight: '10vh', minHeight: '10vh', marginBottom: '2vh' }} maxLength={255} />

                        {showMap &&
                            <div>
                                <LocationReport setDataReport={setNewAddress} setpostCode={setPostalCode} />
                            </div>}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '2vw', justifyContent: 'center' }}>
                            <Button type="link" onClick={() => handleShowMap()}>
                                {showMap ? "No cambiar dirección" : "Cambiar dirección"}
                            </Button>
                            <Button type="primary" onClick={() => handleSave()} disabled={!reason || !request.description || (showMap && newAddress.length === 0)}>
                                Guardar
                            </Button>
                        </div>
                    </Spin>
                </div>
            </div>
            }
            { step === 1 &&
            <div>
                <Result
                status='success'
                title='Reporte modificado'
                subTitle='El reporte ha sido modificado exitosamente'
                extra={[
                    <Button key='ok' type='primary' onClick={() => handleClose()} autoFocus >
                        Aceptar
                    </Button>
                ]}
                />
            </div>
            }
            { step === 2 &&
            <div>
                <Result
                status='error'
                title='Error en la petición'
                subTitle={"Se ha producido un error en la petición. Por favor, intente nuevamente."}
                extra={[
                    <Button style={{marginTop: 15}} key='ok' type='primary' onClick={() => handleClose()} autoFocus >
                        Salir
                    </Button>
                ]}
                />
            </div>
            }
        </div>
    )

}