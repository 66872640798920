import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { apiAllDeleteReports } from '../../../../utils/api'
import { ReportDetails } from '../ReportDetails'

export const ReportsDeleted = ({ refresh, setRefresh }) => {

    const [reports, setreports] = useState([])
    const [loading, setloading] = useState(false)
    const [visible, setvisible] = useState({
        isVisible: false,
        reportSelected: {}
    })

    useEffect(() => {
        setloading(true)
        apiAllDeleteReports()
            .then(res => {
                setreports(res.data)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [refresh])

    const handleClose = () => {
        setvisible(prev => ({ ...prev, isVisible: false, reportSelected: {} }))
    }

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            width: '10%'
        },
        {
            title: 'Dependencia',
            dataIndex: 'servicesDto',
            key: 'dependency',
            render: row => row.dependency.name
        },
        {
            title: 'Servicio',
            dataIndex: 'servicesDto',
            key: 'service',
            render: row => row.name
        },
        {
            title: 'Razón de modificación',
            dataIndex: 'reasonForDelete',
            key: 'reasonForDelete',
        },
        {
            title: 'Fecha de modificación',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            render: row => row ? moment(row).format('DD/MM/YYYY') : ''
        },
        {
            title: 'Petición realizada por',
            dataIndex: 'requestedByName',
            key: 'requestedByName',
        },
        {
            title: 'Petición aceptada por',
            dataIndex: 'requestAcceptedByName',
            key: 'requestAcceptedByName',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: row => (
                <Tooltip title='Detalles'>
                    <Button type='link' icon={<InfoCircleOutlined onClick={() => setvisible(prev => ({ ...prev, isVisible: true, reportSelected: row }))} />} />
                </Tooltip>
            ),
            width: '10%'
        },
    ]

    return (
        <div>
            <Table
                rowKey={record => record.id}
                loading={loading}
                columns={columns}
                dataSource={reports}
                size="small"
                scroll={{ y: '60vh' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
            <div style={{ marginTop: -50 }}>
                <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
            </div>

            {
                visible.reportSelected &&
                <Modal
                    centered
                    visible={visible.isVisible}
                    closable={false}
                    footer={null}
                    onCancel={handleClose}>
                    <ReportDetails data={visible.reportSelected} />
                </Modal>
            }
        </div>
    )
}
