export const ROLE_SUPERADMIN = 'superAdmin';
export const ROLE_MANAGER = 'manager';
export const ROLE_WORKER = 'worker';
export const ROLE_ADMIN = 'admin';
export const ROLE_REGULAR_USER = 'regularUser';
export const ROLE_CAPTURIST = 'capturist';
export const ROLE_SCHOLARSHIPS_ADMIN = 'adminScholarships';
export const ROLE_SCHOLARSHIPS_VERIFICATOR = 'verificationScholarship';
export const ROLE_BUDGET_MANAGER = 'managerBudget';
export const ROLE_EVENTS_MANAGER = 'eventManager';
export const ROLE_CRM_MANAGER = 'crmManager';

const isAllowed = (rolesCurrentUserHas, rolesNeeded) => {
  if (rolesCurrentUserHas.hasOwnProperty('roles') && rolesCurrentUserHas.roles !== []) {
      let roles = rolesCurrentUserHas.roles.map(role => {
        return role.name;
      });
    
      return rolesNeeded.some(item => roles.includes(item));
  } else { 
      return false
  }
};
export const ROLES_DIVISIONS = {
  PURE_SUPERADMIN: [ROLE_SUPERADMIN],
  MANAGEMENT: [ROLE_SUPERADMIN, ROLE_MANAGER, ROLE_ADMIN],
  CAPTURIST: [ROLE_CAPTURIST],
  PURE_ADMIN: [ROLE_SUPERADMIN, ROLE_ADMIN],
  PURE_MANAGEMENT: [ROLE_MANAGER],
  USER: [ROLE_REGULAR_USER],
  WORKER: [ROLE_WORKER],
  SCHOLARSHIP_ADMIN: [ROLE_SCHOLARSHIPS_ADMIN],
  SCHOLARSHIP_VERIFICATOR: [ROLE_SCHOLARSHIPS_VERIFICATOR],
  BUDGET_MANAGER: [ROLE_BUDGET_MANAGER],
  EVENTS_MANAGER: [ROLE_EVENTS_MANAGER],
  CRM_MANAGER: [ROLE_CRM_MANAGER],

};

export default isAllowed;