import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Radio, Result, Select, Spin } from 'antd'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import { cammelCase } from '../../../utils/cammelCase'
import { formatPhoneNumber } from '../../../utils/phoneFormat'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { useHistory } from 'react-router-dom'
import { apiGetNoContactOptions, apiSaveSCRM } from '../../../utils/api'
import { openMessage } from '../Utils/MessageDisplay'

const Label = styled.div`

    margin: 1rem 0;
    text-align: center;

    .title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: .7rem;
    }

    .content{
        font-size: 15px;
    }

    `

const OPTIONS = {
    SATISFECHO: 'Satisfecho',
    NO_SATISFECHO: 'No Satisfecho',
    NO_CONTACTO: 'No Contacto',

}

export const CRMDetails = ({ refreshReports, row, handleClose, setviewCRM }) => {

    const history = useHistory()

    const userRef = useRef(null)
    const annotationRef = useRef(null)


    const [loading, setloading] = useState(false)
    const [details, setdetails] = useState(row)
    const [date, setdate] = useState(moment())
    const [time, settime] = useState(moment())
    const [data, setdata] = useState({
        user: row.crm ? row.crm.nameUser : '',
        annotation: row.crm ? row.crm.observation : ''
    })

    const [noContactOptions, setnoContactOptions] = useState([])
    const [option, setoption] = useState(row.crm ? row.crm.levelOfSatisfaction ? row.crm.levelOfSatisfaction : OPTIONS.SATISFECHO : null)
    const [optionNoContact, setoptionNoContact] = useState(row.crm ? row.crm.idNoComunication : null)

    const handleOptionNoContact = (value) => {
        setoptionNoContact(value)
    }

    const handleOption = (e) => {
        setoption(e.target.value)
    }

    const getDetails = useCallback(() => {
        setloading(true)
        apiGetNoContactOptions().then(res => {
            console.log(res)
            setnoContactOptions(res.data)
            setdetails(row)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])

    useEffect(() => {
        getDetails()
    }, [getDetails])

    const handleData = (e) => {
        setdata(prev => ({ ...prev, [e.target.name]: e.target.name === 'user' ? cammelCase(e.target.value) : e.target.value }))
    }

    const handleChangeDate = (date) => {
        console.log(date)
        setdate(date)
    }

    const handleChangeTime = (time) => {
        console.log(time)
        settime(time)
    }

    const completeReport = () => {

        if ((option !== OPTIONS.NO_CONTACTO && !data.annotation) || !data.user || !option || (option === OPTIONS.NO_CONTACTO && !optionNoContact)) return

        let dataApi = {
            dateBeenCalled: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}.000Z`,
            idReport: row.id,
            nameUser: data.user,
            observation: data.annotation,
            phoneNumber: details?.createBy?.phone,
            levelOfSatisfaction: option
        }
        if (option === OPTIONS.NO_CONTACTO) {
            dataApi = { ...dataApi, idNoComunication: optionNoContact }
        }

        apiSaveSCRM(dataApi).then(res => {
            openMessage({ type: 'success', message: `Seguimiento del folio ${row.id} exitoso` })
            refreshReports(prev => !prev)
            handleClose()
        }).catch(err => {
            openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la operación' })
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }

    const reopen = () => {

        if ((option !== OPTIONS.NO_CONTACTO && !data.annotation) || !data.user || !option || (option === OPTIONS.NO_CONTACTO && !optionNoContact)) return

        setloading(true)
        let dataApi = {
            dateBeenCalled: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}.000Z`,
            idReport: row.id,
            nameUser: data.user,
            observation: data.annotation,
            phoneNumber: details?.createBy?.phone,
            levelOfSatisfaction: option
        }

        if (option === OPTIONS.NO_CONTACTO) {
            dataApi = { ...dataApi, idNoComunication: optionNoContact }
        }
        apiSaveSCRM(dataApi).then(res => {
            openMessage({ type: 'success', message: `Seguimiento del folio ${row.id} exitoso` })
            sessionStorage.setItem('reopenFolio', details.id)
            history.push(`crear-reporte`)
        }).catch(err => {
            openMessage({ type: 'error', message: 'Ha ocurrido un error al intentar realizar la operación' })
            new Error(err)
        }).finally(() => {
            setloading(false)

        })
    }

    useEffect(() => {
        if (details.crm) return

        if (option === OPTIONS.NO_CONTACTO) {
            setdata(prev => ({ ...prev, user: 'Sin Exito' }))
        } else {
            setoptionNoContact(null)
            setdata(prev => ({ ...prev, user: '',  annotation: ''}))

        }
    }, [option])

    useEffect(() => {

        if (!optionNoContact || noContactOptions.length === 0) return

        setdata(prev => ({ ...prev, annotation: noContactOptions[optionNoContact - 1].reason }))

    }, [optionNoContact])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', width: 400, position: 'relative' }}>
            <div>
                {
                    sessionStorage.getItem('reopenFolio')
                        ? (
                            <div>
                                <Result
                                    title='Seguimiento en Proceso'
                                    subTitle={`El folio ${sessionStorage.getItem('reopenFolio')} está en proceso de reabrirse, para dar lugar a un nuevo seguimiento, por favor, finaliza el seguimiento actual que esté en curso.`}
                                    extra={[
                                        <Button type='primary' onClick={() => history.push('crear-reporte')}>Ir a Folio en Proceso</Button>
                                    ]}
                                />
                            </div>
                        )
                        : (
                            <>
                                {
                                    loading &&
                                    <div style={{ width: '100%', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.02)', borderRadius: 20 }}>
                                        <Spin size='large' />
                                    </div>
                                }
                                <div style={{ display: 'flex', justifyContent: 'flex-start', fontSize: 16 }}>
                                    <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => setviewCRM(false)} />
                                </div>
                                <h2 style={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center' }}>Reporte con Folio {details.id} {details.idOrigin ? `Seguimiento del Folio ${details.idOrigin}` : ''}</h2>
                                <Label>
                                    <div className='title'>Creado por</div>
                                    <div className='content'>{cammelCase(details?.createBy?.name ?? '')}</div>
                                </Label>
                                <Label>
                                    <div className='title'>Número de Teléfono</div>
                                    <div className='content'>{formatPhoneNumber(details?.createBy?.phone ?? '')}</div>
                                </Label>
                                <Label>
                                    <div className='title'>Datos de la LLamada</div>
                                    <div style={{ display: 'flex', gap: '2rem', justifyContent: 'center', flexDirection: 'column' }}>
                                        <div>
                                            <Radio.Group onChange={details.crm ? null : handleOption} value={option}>
                                                <Radio value={OPTIONS.SATISFECHO}>Satisfecho</Radio>
                                                <Radio value={OPTIONS.NO_SATISFECHO}>No Satisfecho</Radio>
                                                <Radio value={OPTIONS.NO_CONTACTO}>No Contacto</Radio>
                                            </Radio.Group>
                                        </div>
                                        {
                                            option === 'No Contacto' &&
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label>Motivo De No Contacto</label>
                                                <Select value={optionNoContact} onChange={details.crm ? null : handleOptionNoContact}>
                                                    {noContactOptions.map(i => (
                                                        <Select.Option value={i.id}>{i.reason}</Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        }
                                        <div style={{ display: 'flex', gap: '1rem' }}>
                                            {
                                                details.crm
                                                    ? (
                                                        <div style={{ width: '100%' }}>
                                                            <label>Fecha </label>
                                                            <Input readOnly={details.crm} name='date' value={moment(details.crm.dateBeenCalled).format('DD [de] MMMM [del] YYYY [a las] HH:mm a')} />
                                                        </div>
                                                    )
                                                    : (
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                <label>Fecha </label>
                                                                <DatePicker style={{ width: '100%' }} value={date} onChange={handleChangeDate} />
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <label>Hora</label>
                                                                <DatePicker style={{ width: '100%' }} value={time} picker='time' onChange={handleChangeTime} />
                                                            </div>
                                                        </>
                                                    )
                                            }
                                        </div>
                                        <div>
                                            <label>Llamada Atendida Por </label>
                                            <Input ref={userRef} readOnly={details.crm || option === OPTIONS.NO_CONTACTO} name='user' onChange={handleData} value={data.user} />
                                        </div>
                                        <div>
                                            <label>Anotaciones </label>
                                            <TextArea ref={annotationRef} readOnly={details.crm || option === OPTIONS.NO_CONTACTO} rows={4} name='annotation' onChange={handleData} value={data.annotation} />
                                        </div>
                                    </div>
                                </Label>
                                {
                                    !details.crm &&
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
                                        <Button disabled={(option !== OPTIONS.NO_CONTACTO && !data.annotation) || !data.user || !option || (option === OPTIONS.NO_CONTACTO && !optionNoContact)} onClick={completeReport} type='primary'>Completar Seguimiento</Button>
                                        {
                                            option !== OPTIONS.NO_CONTACTO &&
                                            <Button disabled={(option !== OPTIONS.NO_CONTACTO && !data.annotation) || !data.user || !option || (option === OPTIONS.NO_CONTACTO && !optionNoContact)} onClick={reopen} type='link'>Reabrir Reporte</Button>
                                        }
                                    </div>
                                }
                            </>
                        )
                }
            </div>
        </div>
    )
}
