
import Chart from 'react-apexcharts'
import { prMain } from '../../themeConfig'

export const VotesChart = ({ openDetails, series, zone, type, height }) => {
    const es = require('apexcharts/dist/locales/es.json')

    const state = {


        series: [{
            data: series
        }],
        options: {
            legend: {
                onItemClick: {
                    toggleDataSeries: false
                }
            },
            chart: {
                height: '100%',
                stacked: false,
                locales: [es],
                defaultLocale: 'es',
                toolbar: {
                    show: false,
                    // tools:{
                    //     download: false,
                    // },
                    // export: {
                    //     csv: {
                    //         filename: `Reporte_semanal_${moment().format('YYYY_W')}`,
                    //         columnDelimiter: ',',
                    //         headerCategory: 'Día',
                    //         headerValue: 'value',
                    //         dateFormatter(timestamp) {
                    //             return new Date(timestamp).toDateString()
                    //         }
                    //     },
                    //     svg: {
                    //         filename: `Reporte_semanal_${moment().format('YYYY_W')}`
                    //     },
                    //     png: {
                    //         filename: `Reporte_semanal_${moment().format('YYYY_W')}`
                    //     }
                    // }
                },
            },
            dataLabels: {
                enabled: true
            },
            markers: {
                size: 5,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 7,
                    sizeOffset: 3
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 2,
                dashArray: 0,
            },

            // xaxis: {
            //     categories: [ 'Zona 1', 'Zona 2', 'Zona 3', 'Zona 4', 'Zona 5', 'Zona 6', 'Zona 7' ],
            // },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: prMain
                    },
                    labels: {
                        style: {
                            colors: prMain,
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }

            ],
            xaxis: {
                labels: {
                    rotate: 0,
                    trim: true,
                    hideOverlappingLabels: false
                    // formatter: function (value) {
                    //     return value.substring(0, 9) + '...';
                    // }
                }
            }
            // tooltip: {
            //     fixed: {
            //         offsetY: 30,
            //         offsetX: 60
            //     },
            // }
        },
    };

    return (
            <Chart options={state.options} series={state.series} type={'bar'} width={'100%'} height={height ? height : '100%'} />
    )
}
