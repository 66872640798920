import React, { useEffect, useRef, useState } from 'react'
import 'leaflet-geosearch/dist/geosearch.css'
import { Marker, MapContainer, useMapEvents } from 'react-leaflet'
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import axios from 'axios';
import { Button, Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import './SearchResults.css'

const key1 = process.env.REACT_APP_MAPBOX_KEY_1
const key2 = process.env.REACT_APP_MAPBOX_KEY_2
const key3 = process.env.REACT_APP_MAPBOX_KEY_3
const key4 = process.env.REACT_APP_MAPBOX_KEY_4
const googleKey = process.env.REACT_APP_GOOGLE_MAP_KEY


const MarkerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    className: 'iconMarker'
});

export const LocationReport = ({ idReport, setDataReport, setpostCode, dataReport }) => {

    // const map = useRef(null)
    const inputSearchRef = useRef()
    const [showResults, setshowResults] = useState(false)
    const [map, setmap] = useState(null)
    const [loading, setloading] = useState(false)
    const [queryString, setqueryString] = useState('')
    const [locations, setlocations] = useState([])
    const [locationSelected, setlocationSelected] = useState(null)

    useEffect(()=>{
        if(!idReport) return

        setlocationSelected({ center: [dataReport.longitude, dataReport.latitude], place_name: dataReport.address })
        

    },[])

    const MapLocation = ({ setDataReport, setpostCode }) => {

        let useKey = key1

        const getLocation = (e) => {

            axios({
                method: 'GET',
                url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.latlng.lng},${e.latlng.lat}.json?access_token=${useKey}`
            }).then(res => {
                setDataReport(prev => ({ ...prev, latitude: e.latlng.lat, longitude: e.latlng.lng, address: res.data.features[0].place_name, idColonia: '' }))
                setlocationSelected({ center: [e.latlng.lng, e.latlng.lat], place_name: res.data.features[0].place_name })
                let postCodeIndex = res.data.features[0].place_name.includes(', 33') ? res.data.features[0].place_name.indexOf(', 33') : res.data.features[0].place_name.indexOf(' 33')
                // console.log(postCodeIndex)
                // console.log(res.data.features[0].place_name.substring(postCodeIndex +( res.data.features[0].place_name.includes(', 33') ? 2 : 1), postCodeIndex + (res.data.features[0].place_name.includes(', 33') ? 7 : 6)))
                setpostCode(res.data.features[0].place_name.substring(postCodeIndex + (res.data.features[0].place_name.includes(', 33') ? 2 : 1), postCodeIndex + (res.data.features[0].place_name.includes(', 33') ? 7 : 6)))
            }).catch(err => {
                console.log(err)
                if (!useKey) return

                if (useKey === key1) {
                    useKey = key2
                    getLocation(e)
                } else if (useKey === key2) {
                    useKey = key3
                    getLocation(e)
                } else if (useKey === key3) {
                    console.log(err)
                } else {
                    console.log(err)
                }
            })
        }

        const map = useMapEvents({
            click: (e) => {
                getLocation(e)
                map.locate()
            },
        })
        return null
    }

    const searchApi = async () => {

        let useKey = key1
        if (queryString.length === 0) {
            setlocations([])
        } else {
            setshowResults(true)
            setloading(true)
            await axios({
                method: 'GET',
                url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${queryString}.json`,
                params: {
                    limit: 3,
                    language: 'es',
                    country: 'MX',
                    access_token: useKey,
                    proximity: [-105.4792397460938, 28.192226945392576]
                }
            }).then(res => {
                setlocations(res.data.features)

            }).catch(err => {
                console.log(err)
                if (!useKey) return

                if (useKey === key1) {
                    useKey = key2
                    searchApi(queryString)
                } else if (useKey === key2) {
                    useKey = key3
                    searchApi(queryString)
                } else if (useKey === key3) {
                    useKey = key4
                    searchApi(queryString)
                } else if (useKey === key4) {
                    console.log(err)
                } else {
                    console.log(err)
                }
            }).finally(() => {
                setloading(false)
            })
        }
    }

    const handleChange = (e) => {
        setqueryString(e.target.value)
        setshowResults(false)
    }

    const handleSelectLocation = (e) => {
        const [lng, lat] = e.center
        map.flyTo([lat, lng], 18)
        setlocationSelected(e)
        setshowResults(false)
        setDataReport(prev => ({ ...prev, latitude: e.center[1], longitude: e.center[0], address: e.place_name, idColonia: '' }))

        let postCodeIndex = e.place_name.includes(', 33') ? e.place_name.indexOf(', 33') : e.place_name.indexOf(' 33')
        setpostCode(e.place_name.substring(postCodeIndex + (e.place_name.includes(', 33') ? 2 : 1), postCodeIndex + (e.place_name.includes(', 33') ? 7 : 6)))
    }

    return (
        <div style={{ height: '65vh', width: '100%', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <MapContainer center={[28.192226945392576, -105.4792397460938]} zoom={13} style={{ width: '100%', height: '80vh' }} whenReady={map => setmap(map.target)} whenCreated={map => console.log(map)}>
            <ReactLeafletGoogleLayer apiKey={googleKey} type={'roadmap'} />
                <MapLocation setDataReport={setDataReport} setpostCode={setpostCode} />
                {
                    locationSelected &&
                    <Marker position={[locationSelected.center[1], locationSelected.center[0]]} icon={MarkerIcon} />
                }
            </MapContainer >
            <p>Seleccione la ubicación del reporte</p>
            <div style={{ width: '60%', padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Input
                            ref={inputSearchRef}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    searchApi()
                                }
                            }} 
                            size='small' value={queryString} onFocus={() => setshowResults(true)} onChange={handleChange} placeholder='Buscar dirección...' style={{ marginBottom: 25, width: '100%' }} />
                        <Button size='small' icon={<SearchOutlined />} onClick={searchApi} />
                    </div>
                    {
                        (queryString && showResults && locations.length > 0) &&
                        <SearcherResults inputSearchRef={inputSearchRef} setshowResults={setshowResults} loading={loading} locations={locations} handleSelectLocation={handleSelectLocation} />
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>Dirección Seleccionada:</div>
                    {
                        locationSelected &&

                        <div style={{ fontSize: 14, textAlign: 'center' }}>{locationSelected.place_name}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export const SearcherResults = ({inputSearchRef, loading, locations, handleSelectLocation, setshowResults }) => {
    return (
        <div 
            onMouseLeave={() => {
                setshowResults(false)
                inputSearchRef.current.blur()
                }} 
            style={{transition: '.5s', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: '#FFF', position: 'absolute', top: 30, border: '1px solid #e3e3e3', borderRadius: 15, padding: 10 }}>
            {
                loading
                    ? <Spin tip='Buscado ...' />
                    : (<>
                        {
                            locations.map(item => (
                                <Button size='small' onClick={() => handleSelectLocation(item)} key={item.id} className='result'  style={{width: '100%',textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{item.place_name}</Button>
                            ))
                        }

                    </>)
            }
        </div>
    )

}
