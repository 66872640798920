import { Result, Select, Button, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { apiGetColonys } from '../../../../utils/api'

export const ConfirmColony = ({ postCode, setDataReport, dataReport, handleSubmit, loading, setopen}) => {
    const { Text } = Typography
    const [colonys, setcolonys] = useState([])
    const [loadingColonys, setLoadingColonys] = useState(false)

    const handleChange = (e) => {
        setDataReport(prev => ({
            ...prev,
            idColonia: e
        }))
    }

    useEffect(() => {
        setLoadingColonys(true)
        apiGetColonys(postCode)
            .then(res => {
                setcolonys(res.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoadingColonys(false)
            })
    }, [postCode])

    return (
        <Result
            status='info'
            title='Confirme la colonia'
            subTitle='Seleccione la colonia del reporte'
            extra={[
                <Select
                    key='select'
                    value={dataReport.idColonia}
                    onChange={handleChange}
                    style={{ width: '70%' }}
                    loading={loadingColonys}
                >
                    {
                        colonys.map((item) => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))
                    }

                </Select>,
                <br key='br'/>,
                (colonys.length === 0 && <Text style={{ fontSize: 11, color: '#00000070' }} >Esta zona no esta disponible para generar reportes.</Text>),
                <br key='br2'/>,
                <Button key='buttonConfirm' style={{marginTop: 15}} loading={loading} disabled={!dataReport.idColonia} onClick={handleSubmit} type='primary'>Confirmar</Button>,
                <Button key='buttonClose' onClick={() => setopen(false)} type='link'>Cerrar</Button>,
            ]}
        />
    )
}
