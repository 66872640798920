import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Form, Input, Menu, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { apiGetUsersByRole, apiReportType, apiReportsFilterPagination } from '../../../utils/api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ASSIGNED_BY, CREATE_BY, IN_CHARGE } from '../reports/DisplayedMap'
const { Option } = Select;

export const Filters = ({
    setoptionCall,
    setfulldata,
    rangeDate,
    setrangeDate,
    status,
    setstatus,
    userName,
    setUserName,
    dataUsers,
    setDataUsers,
    userOption,
    setuserOption,
    typeName,
    setTypeName,
    dataType,
    setDataType,
    owner,
    setowner,
    folio,
    setrefresh, hide, setloading, setfilterData, optionCall, setrefreshExcel, settotalData, page, pageSize, order, setisFilter, setsearch }) => {

    const { user } = useSelector(state => state.session);

    const [reportTypes, setreportTypes] = useState([])

    useEffect(() => {
        apiReportType().then(res => {
            res.data = res.data.filter(item => item.name !== 'CRM')

            setreportTypes(res.data)
        }).catch(err => {
            new Error(err)
        })

    }, [])

    const handleClickMenu = ({ key }) => {
        setuserOption(key)
    }

    const menu = (
        <Menu
            selectable
            selectedKeys={[userOption]}
            onClick={handleClickMenu}
            items={[
                {
                    label: 'Creado por',
                    key: CREATE_BY,
                },
                {
                    label: 'Asignado por',
                    key: ASSIGNED_BY,
                },
                {
                    label: 'Encargado',
                    key: IN_CHARGE,
                },
            ]}
        />
    )

    const handleUserChange = (e) => {
        setowner(e.target.value)
    }

    const handleChangeUser = (event) => {
        const data = event.map(i => JSON.parse(i).id)
        setUserName(event)
        setDataUsers(data)
    }

    const handleChangeType = (event) => {
        const data = event.map(i => JSON.parse(i).id)
        setTypeName(event)
        setDataType(data);
    }


    const handleFilter = () => {
        setloading(true)
        setrefreshExcel(prev => !prev)

        apiReportsFilterPagination(status, status, new Date(rangeDate[0]), new Date(rangeDate[1]), folio, owner, dataType.join(','), userOption, 1, pageSize, order.key, order.order, optionCall, 0, dataUsers.join(',')).then(res => {
            settotalData(res.data.amount)
            setfulldata(res.data.reports)
            setfilterData(res.data.reports)



        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }

    const handleClearFilter = () => {
        setDataType(user.service ? user.service.map(i => ({ idType: i.id })) : [])
        setTypeName(user.service ? user.service.map(i => (JSON.stringify({ idType: i.id, name: i.name }))) : [])
        setrefresh(prev => !prev)
        setrefreshExcel(prev => !prev)
        setsearch('')
        // setstatus(4)
        setrangeDate([moment('1/1/2022'), moment()])
        setUserName([])
        setDataUsers([])
        setisFilter(false)
        setowner('')
        // setrefresh(prev => !prev)
    }

    const [users, setusers] = useState([])

    useEffect(() => {

        apiGetUsersByRole([2, 3, 5].join(',')).then(res => {
            setusers(res.data)
        }).catch(err => {
            new Error(err)
        })

}, [])

return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: '2rem', marginTop: '2rem' }}>
        {/* <div style={{ width: '100%' }}>
                <Form.Item help={'Seleccione el rango de fechas'}>
                    <RangePicker value={rangeDate} onChange={handleChangeDates} />
                </Form.Item>
            </div> */}
        <div style={{ width: '100%', display: 'flex', gap: '2rem' }}>

            <div style={{ position: 'relative', width: '35%' }}>

                <Dropdown overlay={menu}>
                    <Space style={{ overflow: 'hidden', position: 'absolute', top: -22, marginLeft: 12, opacity: .8, backgroundColor: '#FFF', border: '1px solid #e3e3e3', padding: '0px 10px 0px 10px', borderRadius: '10px 10px 0 0' }}>
                        {userOption}
                        <DownOutlined />
                    </Space>
                </Dropdown>

                <Form.Item
                    help={userOption === CREATE_BY ? 'Ingrese el nombre del ciudadano' : 'Seleccione los usuarios'}
                >
                    {
                        userOption === CREATE_BY
                            ? <Input placeholder='Nombre del ciudadano' onChange={handleUserChange} value={owner} size='middle' />
                            : <Select
                                size='middle'
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Usuarios"
                                onChange={handleChangeUser}
                                value={userName}
                                maxTagCount='responsive'
                            >
                                {users.map((user) => (
                                    <Option title={`${user.name} ${user.lastName ? user.lastName : ''} ${user.email ? `(${user.email})` : ''}`} key={user.id} value={JSON.stringify(user)}>{user.name} {user.lastName ? user.lastName : ''} {user.email ? `(${user.email})` : ''}</Option>
                                ))}
                            </Select>
                    }
                </Form.Item>
            </div>

            <div style={{ width: '35%' }}>
                <Form.Item help={userName && 'Seleccione los servicios'}>
                    <Select
                        size='middle'
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Servicios"
                        onChange={handleChangeType}
                        value={typeName}
                        maxTagCount='responsive'
                    >
                        {reportTypes.map(i => ({ id: i.id, name: i.name })).map((type) => (
                            <Option key={type.id} value={JSON.stringify(type)}>{`${type.name} `}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>

            <Button onClick={handleFilter} type='primary' style={{ width: '15%' }}>Filtrar</Button>
            <Button onClick={handleClearFilter} type='link' style={{ width: '15%' }}>Limpiar Filtros</Button>

        </div>

    </div >
)
}
