import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { apiReportsFilter } from "../../../utils/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportReports = ({ status, dataType, owner, statusReport, fromDate, toDate, userOption, refreshExcel, isFilter, dataUsers }) => {

    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const excelRef = useRef(null);

    const getApiReportsExcel = () => {
        setloading(true)

        let from = fromDate
        let to = toDate
        let vardata = []
    
        apiReportsFilter(status, status, from, to, null, owner, dataType.map(i => i.idType).join(','), userOption,  dataUsers.map(i => i.idUser).join(',')).then(res => {
            if (statusReport === 1) {
                res.data = res.data.filter(i => i.expired)
            } else if (statusReport === 0) {
                res.data = res.data.filter(i => !i.expired)
            }
            vardata = res.data
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
            setdata(vardata)
        })
    }
    
    useEffect(() => {
        if (data.length > 0 && excelRef.current) {
            excelRef.current.click();
        }
    }, [data]);

    return (
        <>
            <Button loading={loading} disabled={loading} type="link" icon={<FileExcelOutlined />} onClick={getApiReportsExcel}>Exportar a Excel</Button>
                {data.length > 0 && (
                    <ExcelFile
                        filename={`Reportes_${moment().format('DD_MM_YYYY.hhmmss')}`}
                        element={<button ref={excelRef} style={{ display: "none" }} />}
                        // element={<Button loading={loading} disabled={loading} type="link" icon={<FileExcelOutlined />} onClick={getApiReportsExcel}>Exportar a Excel</Button>}
                    >
                    <ExcelSheet data={data} name="Reportes" >
                        <ExcelColumn label="Folio" value="id" />
                        <ExcelColumn label="Dependencia" value={col => col.servicesDto.dependency.name} />
                        <ExcelColumn label="Tipo" value={col => col.servicesDto.name} />

                        <ExcelColumn label="Creado por" value={col => col.createBy ? `${col.createBy.name} (${col.isCreateByCapturist ? 'Capturista' : 'Ciudadano'})` : ''} />
                        <ExcelColumn label="Teléfono" value={col => col.createBy ? (`${col.createBy.phone}`) : ''} />
                        <ExcelColumn label="Fecha de creación" value={col => moment(col.createdAt).format('DD/MM/YYYY')} />

                        <ExcelColumn label="Asignado por" value={col => col.assignedBy ? (col.assignedBy.name + " " + col.assignedBy.lastName) : ''} />
                        <ExcelColumn label="Encargado" value={col => col.user ? (col.user.name + " " + col.user.lastName) : ''} />
                        <ExcelColumn label="Fecha de asignado" value={col => col.assignedAt ? moment(col.assignedAt).format('DD/MM/YYYY') : ""} />
                        <ExcelColumn label="Vigencia" value={col => col.expired ? 'Expirado' : 'Vigente'} />
                        <ExcelColumn label="Estatus" value={col => col.status.name === "Pending" ? "Pendiente" : col.status.name === "Assigned" ? "Asignado" : col.status.name === "InReview" ? "En revisión" : col.status.name === "Completed" ? "Solucionado" : ""} />
                        <ExcelColumn label="Fecha de cierre" value={col => col.completedAt ? moment(col.completedAt).format('DD/MM/YYYY') : ""} />

                        <ExcelColumn label="Observaciones" value={col => {
                            let msg = ""
                            col.comments.forEach((item) => {
                                msg = msg + `${item.userDto.name} ${item.userDto.lastName}: ${item.comment} (${moment(item.date).format('DD/MM/YYYY')}), `
                            })
                            return msg.substring(0, msg.length - 2)
                        }} />

                        <ExcelColumn label="Dirección" value={col => col.address ? col.address : ""} />
                        <ExcelColumn label="Colonia" value={col => col.colonia.name ? col.colonia.name : ""} />
                        <ExcelColumn label="Descripción" value={col => col.description ? col.description : ""} />
                        <ExcelColumn label="Prioridad" value={col => col.priority ? col.priority : ""} />
                        <ExcelColumn label="Aceptado por" value={col => col.requestAcceptedByName ? col.requestAcceptedByName : ""} />
                        <ExcelColumn label="Solicitado por" value={col => col.requestedByName ? col.requestedByName : ""} />
                        <ExcelColumn label="Fecha completado" value={col => col.deletedAt ? col.deletedAt : ""} />
                        <ExcelColumn label="Razón de completado" value={col => col.reasonForDelete ? col.reasonForDelete : ""} />
                    </ExcelSheet>
                </ExcelFile>
            )}
        </>
    );
}