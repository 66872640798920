import { LoadingOutlined, ReloadOutlined, RiseOutlined } from "@ant-design/icons"
import { Button, Form, Select, Tooltip } from "antd"
import { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { prMain } from "../../../../themeConfig"
import { apiGetPeriods, apiGetProjectsZone } from "../../../../utils/api"
import { VotesChart } from "../../../charts/VotesChart"
import { TableBudgetVotes } from "./TableBudgetVotes"

const ContainerPage = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100%;
    gap: .5rem
    overflow-y: auto;
`

const ContainerGraphics = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    flex: 1;
    background-color: #FFF;
    border-radius: 1rem;
    overflow-y: auto;

`

const ContainerTable = styled.div`
    width: 100%;
    flex: 1;
    background-color: #FFF;
    border-radius: 1rem;
    overflow-y: auto;
`

const BudgetDashboard = () => {

    const [loading, setloading] = useState(false)
    const [zones, setzones] = useState([])
    const [seriesZones, setseriesZones] = useState(null)
    const [period, setperiod] = useState('')
    const [details, setdetails] = useState({
        isOpen: false,
        zone: 1
    })

    const openDetails = (zone) => {
        setdetails(prev => ({ ...prev, isOpen: true, zone }))
    }

    const closeDetails = (zone) => {
        setdetails(prev => ({ ...prev, isOpen: false, zone }))
    }


    const createSeries = (data) => {
        const colors = ['#ff4949', '#ffc700', '#ff9800']
        let seriesZone = {}
        data.forEach(i => {
            let dataBudget = i.budgetParticipatoryDtos.sort((a, b) => b.votes - a.votes)

            if (i.budgetParticipatoryDtos.length > 3) {
                dataBudget = i.budgetParticipatoryDtos.slice(0, 3)
            }

            if (i.budgetParticipatoryDtos.length === 0) {
                let noData = [{
                    x: 'Sin Datos',
                    y: 0,
                    fillColor: colors[0],
                    strokeColor: colors[0],
                }]
                seriesZone = { ...seriesZone, [i.zona]: noData }

            } else {


                let data = dataBudget.map((item, index) => ({
                    x: item.projectName,
                    y: item.votes,
                    fillColor: colors[index],
                    strokeColor: colors[index],
                }))

                seriesZone = { ...seriesZone, [i.zona]: data }
            }
        })
        setseriesZones(seriesZone)

    }

    const [refresh, setRefresh] = useState(false)

    const getProjectsZone = useCallback(() => {
        if(!period) return
        setloading(true)
        apiGetProjectsZone(period).then(res => {
            createSeries(res.data)
            setzones(res.data)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }, [period])

    useEffect(() => {
        getProjectsZone()
        setTimeout(() => {
            setRefresh(!refresh)

        }, [120000])
    }, [getProjectsZone, refresh])

    const [periods, setperiods] = useState([])

    useEffect(() => {
        apiGetPeriods().then(res => {
            console.log(res.data)
            setperiods(res.data.map(i => ({value: i.id, label: i.description})))

            let find = res.data.find(i => i.isActive)

            if(find){
                setperiod(find.id)
            }else{
                setperiod(res.data[res.data.length - 1].description)
            }
        }).catch(err => {
            new Error(err)
        })
    }, [])

    return (
        <ContainerPage>
            <div style={{ fontSize: '1.5rem', fontWeight: 600, marginLeft: '1rem', opacity: 0.7, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <div style={{width: '50%'}}>

                {details.isOpen ? `Proyectos ${details.zone === '99999' ? 'Generales' : `de la Zona ${details.zone}`}` : 'Top 3 Proyectos Más Votados'}
                {
                    !details.isOpen &&
                    <>
                        {
                            loading ? (
                                <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', color: prMain}}>
                                    <LoadingOutlined style={{ color: prMain, marginLeft: '.5rem', fontSize: '1.2rem' }} />
                                    <div style={{fontSize: '.7rem'}}>Cargando</div>
                                </div>
                            ) : (
                                <Tooltip title='Recargar Datos'>
                                    <ReloadOutlined onClick={getProjectsZone} style={{ color: prMain, marginLeft: '.5rem', fontSize: '1.2rem' }} />
                                </Tooltip>
                            )
                        }
                    </>
                }
                </div>
                {
                    !details.isOpen &&
                    <Form.Item help='Seleccione el periodo' >
                        <Select 
                        value={period} 
                        onChange={e => setperiod(e)}
                        options={periods}
                        />
                    </Form.Item>
                }

            </div>
            {
                details.isOpen &&
                <ContainerTable>
                    <TableBudgetVotes close={closeDetails} details={details} setdetails={setdetails} projects={zones}></TableBudgetVotes>
                </ContainerTable>
            }
            {
                !details.isOpen &&
                <ContainerGraphics>
                    {
                        (zones.length > 0 && seriesZones) && zones.map(i => (
                            <div key={i.zone} style={{ marginTop: 10, width: '30%', height: '25vh' }}>
                                <Tooltip title={`Ver Proyectos ${i.zona ==='99999' ? 'Generales' : `de Zona ${i.zona}`}`}>
                                    <Button onClick={() => openDetails(i.zona)} style={{ textAlign: 'center', width: '100%', marginBottom: -20, color: '#000' }} type='link' icon={<RiseOutlined />}>
                                        {i.zona === '99999' ? 'Generales' : `Zona ${i.zona}`} 
                                    </Button>

                                </Tooltip>
                                <div>
                                    <VotesChart openDetails={openDetails} series={seriesZones[i.zona]} zone={i.zona} />
                                </div>
                            </div>
                        ))
                    }
                </ContainerGraphics>
            }


        </ContainerPage>
    )
}

export default BudgetDashboard