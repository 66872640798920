import { useState } from "react"
import { Row, Col, Spin, Button, Modal, Typography, Input, Select } from "antd"
import { prMainLight } from "../../../../themeConfig"
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { prMain } from "../../../../themeConfig";
import { apiRejectScholarship, apiRejectScholarshipFiles } from '../../../../utils/api'
import { useScreenSize } from '../../../../hooks/useScreenSize';


const ScholarshipGeneral = ({ details, beneficiaryCount, refresh, setRefresh }) => {
    const [openModal, setOpenModal] = useState(false)
    const [reason, setReason] = useState('')
    const [filesSelected, setFilesSelected] = useState([])
    const [successModal, setSuccessModal] = useState(false)
    
    const { width } = useScreenSize()
    const { Text } = Typography
    const { TextArea } = Input

    const modalOptions = [
        { label: 'INE (frontal)', value: 'INE-Front' },
        { label: 'INE (trasera)', value: 'INE-Back' },
        { label: 'Comprobante de domicilio', value: 'Address' },
        { label: 'Comprobante de ingresos', value: 'Income' },
        { label: 'CURP', value: 'CURP' },
        { label: 'Documento DIF', value: 'Document' },
    ]

    const handleCloseModal = () => {
        setOpenModal(false)
        setSuccessModal(false)
        setReason('')
        setFilesSelected([])
    }

    const handleChangeReason = (event) => {
        setReason(event.target.value)
    }

    const handleSelect = (event) => {
        setFilesSelected(event)
    }

    const handleUnapprove = () => {
        try {
            let folio = details.folio
            let curp = details.curp
            let idStatus = 3

            apiRejectScholarship(curp, idStatus, reason, 0).then(() => {

                if (filesSelected.length !== 0) {
                    let concat = ''
                    for (let i = 0; i < filesSelected.length; i++) {
                        for (let j = 0; j < details.scholarshipsFiles.length; j++) {
                            if (filesSelected[i] === details.scholarshipsFiles[j].typeDocument) {
                                concat = concat + details.scholarshipsFiles[j].idFile + ','
                            }
                        }
                    }
                    apiRejectScholarshipFiles(folio, concat.substring(0, concat.length - 1)).then(() => {
                        setFilesSelected([])
                        setRefresh(!refresh)
                        setSuccessModal(true)
                    }).catch((e) => {
                        console.log(e)
                    })
                }
                setRefresh(!refresh)
                setSuccessModal(true)
            })
        } catch (e) {
            console.log(e)
        }
    }

    const UnapproveModal = () => {


        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={modalTitle}> Solicitar cambios </Text>
                <div style={modalContainer}>

                    <Text style={modalText}>Especifique la raz&oacute;n a continuaci&oacute;n:</Text>
                    <TextArea style={modalInput} maxLength={255} value={reason} onChange={(e) => handleChangeReason(e)}>
                    </TextArea>

                    <Text style={modalText}>Seleccione los archivos incorrectos (opcional):</Text>
                    <Select value={filesSelected} onChange={(e) => handleSelect(e)} mode="multiple" maxTagCount="responsive" style={modalSelect} options={modalOptions} />
                </div>
                <div style={modalButtons}>
                    <Button style={rejectButton} onClick={() => handleCloseModal()}>Cancelar</Button>
                    <Button disabled={!reason} type="primary" style={{ color: '#fff' }} onClick={() => handleUnapprove()}>Continuar</Button>
                </div>

            </div>
        )
    }

    const UnapproveMessage = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '30vh', }}>
                <CheckCircleOutlined style={modalIcon} />

                <Text style={modalTitle}>
                    Cambios solicitados
                </Text>

                <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                    Se notificará al solicitante para que pueda corregir los datos necesarios.

                </div>
            </div>
        )
    }

    return (
        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Spin spinning={!details}>
                {(beneficiaryCount > 0 && details.idStatus <= 3) &&
                    <div style={{ width: 'fit-content', marginLeft: 'auto', marginBottom: '2vh' }}>
                        <Button onClick={() => setOpenModal(true)} type='primary' style={rejectButton} icon={<ExclamationCircleOutlined />} >
                            Solicitar cambios
                        </Button>
                    </div>
                }
                <Row style={{ marginTop: '1vh' }}>
                    <Col span={11}>
                        <Row>
                            <Col style={colHeader} span={8}>Nombres</Col>
                            <Col style={colStyle} span={16}>{details.name}</Col>
                            <Col style={colHeader} span={8}>Apellidos</Col>
                            <Col style={colStyle} span={16}> {details.firstLastName} {details.secondLastName ? details.secondLastName : ''}</Col>
                            <Col style={colHeader} span={8}>Sexo</Col>
                            <Col style={colStyle} span={16}> {details.gender}</Col>
                            <Col style={colHeader} span={8}>Edad</Col>
                            <Col style={colStyle} span={16}> {details.age}</Col>
                            <Col style={colHeader} span={8}>CURP</Col>
                            <Col style={colStyle} span={16}>{details.curp}</Col>
                            {
                                details.differentCapacity &&
                            <>
                            <Col style={colHeader} span={8}>{ width > 1350 ? 'Capac. diferente' : 'Cap. Dif.' }</Col>
                            <Col style={colStyle} span={16}>{details.differentCapacity}</Col>
                            </>
                            }
                            <Col style={colHeader} span={8}>Escolaridad</Col>
                            <Col style={colStyle} span={16}>{details.scholarship}</Col>
                            <Col style={colHeader} span={8}>Estado civil</Col>
                            <Col style={colStyle} span={16}>{details.maritalStatus}</Col>
                            {
                                details.shift &&
                                <>
                                    <Col style={colHeader} span={8}>Turno</Col>
                                    <Col style={colStyle} span={16}>{details.shift}</Col>
                                </>
                            }
                            {
                                details.school &&
                                <>
                                    <Col style={colHeader} span={8}>Escuela</Col>
                                    <Col style={colStyle} span={16}>{details.school}</Col>
                                </>
                            }

                            {details.reasonForReject && <>
                                <Col style={colHeader} span={8}>{ width > 1200 ? 'Estatus de apoyo' : 'Estatus'}</Col>
                                <Col style={colStyle} span={16}>{details.nameStatus}</Col>
                            </>}

                        </Row>
                    </Col>
                    <Col span={11} offset={2} >
                        <Row>
                            <Col style={colHeader} span={8}>Direcci&oacute;n</Col>
                            <Col style={colStyle} span={16}>{details.address}</Col>
                            <Col style={colHeader} span={8}>{ width > 1200 ? 'Núm. Exterior' : 'Núm. Ext.' }</Col>
                            <Col style={colStyle} span={16}>{details.outdoorNumber ? details.outdoorNumber : ''}</Col>
                            <Col style={colHeader} span={8}>Colonia</Col>
                            <Col style={colStyle} span={16}>{details.colony}</Col>
                            <Col style={colHeader} span={8}>{ width > 1200 ? 'Código postal' : 'C. P.' }</Col>
                            <Col style={colStyle} span={16}>{details.postalCode}</Col>
                            <Col style={colHeader} span={8}>Ocupaci&oacute;n</Col>
                            <Col style={colStyle} span={16}>{details.employment}</Col>
                            <Col style={colHeader} span={8}>Tel&eacute;fono</Col>
                            <Col style={colStyle} span={16}>{details.firstPhone}</Col>
                            <Col style={colHeader} span={8}>{ width > 1200 ? 'Núm. de hijos' : 'Núm. Hijos' }</Col>
                            <Col style={colStyle} span={16}>{details.numberChildren}</Col>
                            {
                                details.schoolGrade &&
                                <>
                                    <Col style={colHeader} span={8}>{ width > 1200 ? 'Grado Escolar' : 'Grado' }</Col>
                                    <Col style={colStyle} span={16}>{details.schoolGrade}</Col>
                                </>
                            }
                            {
                                details.qualificationAverage &&
                                <>
                                    <Col style={colHeader} span={8}>{ width > 1200 ? 'Calif. Promedio' : 'Calf. Prom.'}</Col>
                                    <Col style={colStyle} span={16}>{details.qualificationAverage}</Col>
                                </>
                            }

                            {beneficiaryCount === 0 && <>
                                <Col style={colHeader} span={8}>Tipo Apoyo</Col>
                                <Col style={colStyle} span={16}>{details.typeScholarShip}</Col>
                            </>
                            }

                            {details.reasonForReject && <>
                                <Col style={colHeader} span={8}>Revisado por</Col>
                                <Col style={colStyle} span={16}>{details.scholarshipsObservationList.length !== 0 ? details.scholarshipsObservationList[details.scholarshipsObservationList.length - 1].userWhoChangeStatus : ''}</Col>

                                {details.idStatus !== 4 &&
                                    <Col span={24}>
                                        <Row justify="center">
                                            <Col style={observationHeader} span={20}>Observaciones</Col>
                                            <Col style={observationsStyle} span={20}>{details.reasonForReject ? details.reasonForReject : ''}</Col>
                                        </Row>
                                    </Col>}
                            </>
                            }
                        </Row>
                    </Col>

                    {/* {details.reasonForReject && <>
                        <Col span={11}>
                            <Row>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={8}></Col>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={16}></Col>
                            </Row>
                        </Col>
                        <Col span={11}>
                            <Row>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={8}></Col>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={16}></Col>
                            </Row>
                        </Col>
                        <Col span={11}>
                            <Row>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={8}></Col>
                                <Col style={{ height: '30px', marginBottom: '15px' }} span={16}></Col>
                            </Row>
                        </Col>
                    </>} */}
                </Row>
            </Spin>

            <Modal centered
                visible={openModal}
                closable={true}
                footer={null}
                destroyOnClose
                onCancel={() => handleCloseModal()}>

                {successModal
                    ? UnapproveMessage()
                    : UnapproveModal()
                }

            </Modal>
        </div>
    )

}


//styles 
const colHeader = {
    border: 'solid 0px #000',
    height: '30px',
    marginBottom: '15px',
    backgroundColor: prMainLight,
    borderRadius: '100px 0px 0px 100px',
    textAlign: 'center',
    paddingTop: '4px',
    color: '#fff'

}

const colStyle = {
    border: `solid 1px ${prMainLight}`,
    maxHeight: '50px',
    marginBottom: '15px',
    // backgroundColor: prMainLight,
    borderRadius: '0px 100px 100px 0px',
    paddingLeft: '15px',
    paddingRight: '10px',
    paddingTop: '4px',
    // overflowX: 'hidden'
}

const observationHeader = {
    border: 'solid 0px #000',
    height: '30px',
    marginBottom: '0px',
    backgroundColor: prMainLight,
    borderRadius: '25px 25px 0px 0px',
    textAlign: 'center',
    paddingTop: '4px',
    color: '#fff'
}

const observationsStyle = {
    border: `solid 1px ${prMainLight}`,
    height: '200px',
    marginBottom: '15px',
    // backgroundColor: prMainLight,
    borderRadius: '0px 0px 25px 25px',
    padding: '10px 15px',
    textAlign: 'justify',

}

const modalTitle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '26px',
}

const modalContainer = {
    minHeight: '10vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2vh',

}

const modalText = {
    marginLeft: 'auto',
    marginRight: 'auto',
}

const modalButtons = {
    marginLeft: 'auto',
    marginTop: '2vh',
    display: 'flex',
    flexDirection: 'row',
    gap: '1vw',
}

const rejectButton = {
    // marginRight: '1vw',
    backgroundColor: '#ffffff00',
    border: `solid 1px ${prMain}`,
    color: prMain
}

const modalInput = {
    maxHeight: '10vh',
    height: '10vh',
    marginBottom: '3vh',
}

const modalSelect = {
    width: '100%',
    marginBottom: '2vh'
}

const modalIcon = {
    fontSize: '75px',
    marginBottom: '1vh',
    marginTop: '4vh',
    color: prMain,
}

export default ScholarshipGeneral