import { useState } from "react"
import { Button, Typography, Input } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons" 
import { apiAddObservation } from "../../../utils/api"

const FollowUpReports = ({ data, setIsOpen, onClose, setStep, option }) => {
    // const [isOpen, setIsOpen] = useState(false)
    const [success, setsuccess] = useState(false)
    const [message, setMessage] = useState('')

    const { Text } = Typography
    const handleChangeMessage = ( event ) => {
        setMessage(event.target.value)
    }
    
    const handleClose = () => {
        setIsOpen(false)
        setsuccess(false)
        setMessage('')
        onClose()
    }

    const handleSendMessage = () => {
        let folio = data.id
        
        try {
            apiAddObservation(folio, message).then((response) => {
                option === "complete" && setStep(3)
                setsuccess(true)
            } )
        } catch (e) {
            console.log(e)
        }
    }

    const FollowUpModal = () => {
        return (
            <div style = {{ display: 'flex', flexDirection: 'column', gap: '4vh' }}>
                <div style = {{ textAlign: 'center' }}>
                    <Text style = {{ fontWeight: 'bold', fontSize: '24px' }}>Aviso de Seguimiento</Text>
                </div>
                <div style = {{ display: 'flex', flexDirection: 'column', gap: '1vh' }}>
                    <Text>
                        Especifique el mensaje que desea enviar: 
                    </Text>
                    <Input.TextArea style = {{ height: '10vh', maxHeight: '10vh', minHeight: '10vh' }} value={message} onChange={handleChangeMessage} maxLength={255}>
                    </Input.TextArea>
                </div>
                <div style = {{ display: 'flex', flexDirection: 'row', width: 'fit-content', marginLeft: 'auto',  gap: '1vw' }}>
                    <Button type="link" onClick={() => handleClose()}>Cancelar</Button>
                    <Button disabled={!message} type="primary" onClick={() => handleSendMessage()}>Enviar</Button>
                </div>
            </div>
        )
    }

    const SuccessModal = () => {
        return (
            <div style = {{ display: 'flex', flexDirection: 'column', gap: '4vh' }}>
            <div style = {{ textAlign: 'center' }}>
                <Text style = {{ fontWeight: 'bold', fontSize: '24px' }}>Aviso de seguimiento enviado</Text>
            </div>
            <div style = {{ display: 'flex', flexDirection: 'column', gap: '1vh', textAlign: 'center' }}>
                <CheckCircleOutlined style = {{ fontSize: '75px' }} />
                <Text>
                    El aviso de seguimiento se ha enviado correctamente.
                </Text>
            </div>
            <div style = {{ display: 'flex', flexDirection: 'row', width: 'fit-content', marginLeft: 'auto',  gap: '1vw' }}>
                <Button type="primary" onClick={() => window.location.pathname === '/peticiones' ? setStep((3)) : handleClose()}>Volver</Button>
            </div>
        </div>
        )
    }

    return (
        <div>
{/* 
            <Modal
                centered
                visible={isOpen}
                closable={false}
                footer={null}
                onCancel={() => handleClose()}
                >
                 */}
                {
                success
                ? SuccessModal()
                : FollowUpModal()
                }
            {/* </Modal> */}
        </div>
    )
}

export default FollowUpReports