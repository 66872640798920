import { Col, Row } from 'antd';
import { ReportsWeek } from './ReportsWeek';
import { ReportWeekMap } from './ReportWeekMap';
import { RecentsContainer } from './RecentsContainer';
import { ReportsMonth } from './ReportsMonth';
import { ReportsWeekColony } from './ReportsWeekColony';
import './dashboard.css'

const Dashboard = () => {  

  // const { update } = useSelector(state => state.notifications)

  // const [data, setdata] = useState([])
  // const [loadingReports, setloadingReports] = useState(false)
  // const [refresh, setRefresh] = useState(false)

  // const getDataReport = useCallback(() => {
  //   setloadingReports(true)
  //   apiReports()
  //       .then((response) => {
  //           setdata(response.data)
  //       }).catch(error => {
  //           console.log(error)
  //       }).finally(() => {
  //           setloadingReports(false)
  //       })
  // }, [])

  // useEffect(() => {
  //   getDataReport()
  //   setTimeout(() => {
  //     setRefresh(!refresh)

  //   },[120000])
  // }, [update, refresh])

  return (
    <div className='container'>
      {/* <RecentsContainer data={data} loadingReports={loadingReports}/> */}
      <RecentsContainer />
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24} md={24} lg={16}>
        {/* <ReportsWeek data={data} loadingReports={loadingReports}/> */}
        <ReportsWeek />
      </Col>
      <Col xs={24} sm={24} md={24} lg={8}>
        {/* <ReportsMonth data={data} loadingReports={loadingReports}/> */}
        <ReportsMonth />
      </Col>
      <Col xs={24} sm={24} md={24} lg={8}>
        {/* <ReportWeekMap data={data} loadingReports={loadingReports}/> */}
        <ReportWeekMap />
      </Col>
      <Col xs={24} sm={24} md={24} lg={16}>
        {/* <ReportsWeekColony data={data} loadingReports={loadingReports}/> */}
        <ReportsWeekColony />
      </Col>
    </Row>
    </div>
  );
}

export default Dashboard;