import { Tabs } from 'antd'
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';
const { TabPane } = Tabs;

export const TabsPage = ({ tabsPanels }) => {

    const { user } = useSelector(state => state.session)

    // const tabsPanels = [
    //     {
    //         title: 'Presupuesto Participativo',
    //         key: 1,
    //         content: <BudgetPage/>,
    //         onlyAdmin: false,
    //     },
    //     {
    //         title: 'Tablero',
    //         key: 2,
    //         content: <BudgetDashboard/>,
    //         onlyAdmin: false,
    //     },
    // ]

    const allowTab = (value, superAdmin) => {
        let allow = true

        if (superAdmin) {
            if (isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN)) {
                allow = true
            } else {
                allow = false
            }
        } else {

            if (value) {
                if (isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)) {
                    allow = true
                } else {
                    allow = false
                }
            } else {
                allow = true
            }
        }


        return allow
    }

    return (
        <div style={{ backgroundColor: '', width: '95%' }}>
            <Tabs type="card" >
                {
                    tabsPanels.map(tab => (
                        allowTab(tab.onlyAdmin, tab.superAdmin) &&
                        <TabPane tab={tab.title} key={tab.key}>
                            <div style={{ backgroundColor: '#FFF', marginTop: -16, padding: 20, paddingTop: 30, borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                                {tab.content}
                            </div>
                        </TabPane>
                    ))
                }
            </Tabs>
        </div>
    )
}
