import React, { useEffect, useState } from "react";
import { Input, Select, Button, Typography, Form } from 'antd'
import { openMessage } from "../Utils/MessageDisplay";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getAnalytics, logEvent } from "firebase/analytics";
import { apiDependencies, apiEditUsers, apiReportType, apiRoles } from "../../../utils/api";
import { useSelector } from "react-redux";
import isAllowed, { ROLES_DIVISIONS } from "../../../layout/roles";
const { Option } = Select;

const classes = {

    container: {
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    div: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'space-between',
    },
    textfield: {
        width: '45%',
        marginBottom: '40px',
    },
    mailfield: {
        width: '100%',
        marginBottom: '40px',

    },
    formcontrol: {
        width: '45%',
        marginBottom: '40px',

    },
    button: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    notification: {
        width: '450px',
        height: '100%',
        padding: '30px',
    },
    title: {
        textAlign: 'center',
        marginBottom: 10,
        fontSize: 20,
        marginTop: -40,
        opacity: .8
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: 20,
        fontSize: '15px',
        opacity: .8

    },
    checkIcon: {
        fontSize: '75px',
        marginLeft: '40%',
        marginBottom: '20px',

    },
}

const EditUser = (props) => {

    const analytics = getAnalytics()
    const id = props.data.id
    const role = props.data.roles[0] ? props.data.roles[0].id : 0
    const email = props.data.email
    const phone = props.data.phone
    const userDependecy = props.data.govDependency ? props.data.govDependency.id : 1
    const userServices = props.data.service.map(i => i.id)
    const [roles, setRoles] = useState([]);
    const [name, setName] = useState(props.data.name);
    const [lastName, setLastName] = useState(props.data.lastName);
    const [userType, setUserType] = useState(role);
    const [servicesData, setservicesData] = useState([])
    const [servicesUser, setservicesUser] = useState(userServices)
    const [dependencies, setDependencies] = useState([]);
    const [dependency, setDependency] = useState(userDependecy);
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setloading] = useState(false)
    const [loadings, setloadings] = useState({
        isLoadingRoles: false,
        isLoadingDependencies: false,
        isLoadingServices: false,
    })
    const { user } = useSelector(state => state.session)

    useEffect(() => {
        setloadings({ ...loadings, isLoadingRoles: true, isLoadingDependencies: true })
        apiRoles()
            .then((response) => {
                if(isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)) {
                    let array = []
                    response.data.forEach((item) => {
                        if (item.id === 7 || item.id === 8) {
                            array.push(item)
                        }
                    })
                    setRoles(array)
                } else {
                    setRoles(response.data);
                }
                setloadings({ ...loadings, isLoadingRoles: false })
            })

        apiDependencies()
            .then((response) => {
                setloadings({ ...loadings, isLoadingDependencies: false })
                setDependencies(response.data)
            })
    }, [])

    useEffect(() => {
        if(dependency === userDependecy){
            setservicesUser(userServices)
        }
        setloadings({ ...loadings, isLoadingServices: true })
        apiReportType()
            .then(response => {
                setservicesData(response.data.filter(i => i.dependency.id === dependency))
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setloadings({...loadings, isLoadingServices: false })
            })

    }, [dependency])

    const handleChangeServices = (event) => {
        setservicesUser(event)
    }

    const handleName = (event) => {
        setName(event.target.value);
    }

    const handleLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleDependency = (event) => {
        setservicesUser([])
        setDependency(event);
    }

    const handleUserType = (event) => {
        setservicesUser([])
        setUserType(event);
    }

    const handleAddUser = () => {

        if (!name || !lastName || !userType) {
            openMessage({ type: 'warning', message: 'Por favor llene todos los campos' })

        } else if ((userType === 3 || userType === 5) && !dependency) {
            openMessage({ type: 'warning', message: 'Por favor seleccione una dependencia' })
        } else {
            setloading(true)
            let data = {
                id,
                email: email,
                lastName: lastName,
                name: name,
                rolesId: [userType],
                dependencyId: (userType === 3 || userType === 5) ? dependency : null,
                phone,
                servicesId: servicesUser
            }
            apiEditUsers(data, id)
                .then((response) => {
                    setIsEdited(true);
                    // props.setRefresh(!props.refresh)
                    logEvent(analytics, 'edit_user', {
                        'user_id': id,
                    })

                }).catch(error => {
                    console.log(error)
                    openMessage({ type: 'error', message: 'Error al agregar el usuario' })
                }).finally(() => {
                    setloading(false)
                })
        }
    }

    return (
        <div>

            {!isEdited && <>
                <div style={classes.container}>
                    {/* <Typography style={classes.title} variant="h5">Editar usuario</Typography> */}
                    <div style={classes.div}>
                        <Form.Item
                            style={classes.textfield}
                            help={
                                (!name && 'Por favor, llene este campo') ||
                                (name && 'Ingrese el nombre')
                            }
                        >
                            <Input value={name} onChange={handleName} placeholder="Nombre(s)" size="medium" />
                        </Form.Item>
                        <Form.Item
                            style={classes.textfield}
                            help={
                                (!lastName && 'Por favor, llene este campo') ||
                                (lastName && 'Ingrese el apellido')
                            }
                        >
                            <Input value={lastName} onChange={handleLastName} placeholder="Apellido(s)" size="medium" />
                        </Form.Item>
                    </div>
                    <div style={classes.div}>
                        <Form.Item
                            style={classes.formcontrol}
                            help={
                                (!userType && 'Por favor, llene este campo') ||
                                (userType && 'Elija el tipo de usuario')
                            }
                        >
                            <Select loading={loadings.isLoadingRoles} value={userType} onChange={handleUserType} placeholder='Tipo de Usuario'>
                                {
                                    user.roles[0].id === 3
                                        ?
                                        roles.map((item) => (
                                            item.id === 5 &&
                                            <Option key={item.id} value={item.id}>{item.description}</Option>
                                        ))
                                        :
                                        user.roles[0].id === 1
                                            ?
                                            roles.map((item) => (
                                                <Option key={item.id} value={item.id}>{item.description}</Option>
                                            ))
                                            :
                                            roles.map((item) => (
                                                (item.id !== 1) &&
                                                <Option key={item.id} value={item.id}>{item.description}</Option>
                                            ))

                                }
                            </Select>
                        </Form.Item>
                        {
                            (userType === 3 || userType === 5) &&

                            <Form.Item
                                style={classes.formcontrol}
                                help={
                                    (!dependency && 'Por favor, llene este campo') ||
                                    (dependency && 'Elija la dependencia')
                                }
                            >
                                <Select loading={loadings.isLoadingDependencies} value={dependency} onChange={handleDependency} placeholder='Dependencia'>
                                    {
                                        user.roles[0].id === 3
                                            ?
                                            dependencies.map((item) => (
                                                item.id === user.govDependency.id &&
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            ))
                                            :
                                            dependencies.map((item) => (
                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                            ))

                                    }
                                </Select>
                            </Form.Item>
                        }
                    </div>
                    {
                        userType === 3 && dependency &&
                        <div style={classes.div}>
                            <div style={classes.formcontrol}></div>
                            <Form.Item
                                style={classes.formcontrol}
                                help={
                                    (servicesUser && 'Elija los servicios')
                                }
                            >
                                <Select
                                    placeholder='Servicios'
                                    value={servicesUser}
                                    onChange={handleChangeServices}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    allowClear
                                >
                                    {
                                        servicesData.map(item => (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    }
                    <div style={classes.button}>
                        <Button
                            loading={loading}
                            onClick={handleAddUser}
                            style={{ width: '100%' }}
                            type="primary"
                            disabled={(!name || !lastName || !userType)}
                        >
                            Guardar
                        </Button>
                    </div>

                </div>
            </>}

            {isEdited && <>
                <div style={classes.notification}>

                    <Typography style={classes.title} variant="h5">El usuario {name} ha sido actualizado.</Typography>
                    <CheckCircleOutlined style={classes.checkIcon} />

                </div>
            </>}

        </div>

    )
}

export default EditUser;