import { CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip, Typography } from 'antd'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { apiDependencies } from '../../../../utils/api'
import { CreateDependency } from './CreateDependency'
import { DeleteDependency } from './DeleteDependency'
import { EditDependency } from './EditDependency'

export const REQUEST = 1
export const SUCCESS = 2
export const FAILURE = 3

export const Dependencies = ({setupdated, updated}) => {
  const [loading, setloading] = useState()
  const [filterData, setfilterData] = useState([])
  const [visible, setVisible] = useState({
    isVisible: false,
    option: null,
    selected: {}
  })
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      sorter: (a, b) =>  a.id - b.id,
    },
    {
      title: 'Descripción',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: true
      },
      render: title => (
        <Tooltip title={title}>
          {title}
        </Tooltip>
      )
    },
    {
      title: 'Acciones',
      render: row => (
        <>
        <Tooltip title='Editar'>
          <Button icon={<EditOutlined />} onClick={() => setVisible(prev => ({...prev, isVisible: true, option: 'edit', selected: row}))}/>
        </Tooltip>
        <Tooltip title='Eliminar'>
          <Button icon={<DeleteOutlined />} onClick={() => setVisible(prev => ({...prev, isVisible: true, option: 'delete', selected: row}))}/>
        </Tooltip>
        </>
      ),
      width: '15%'

    }
  ]

  const getDependencies = useCallback(() => {
    setloading(true)
    apiDependencies()
      .then(res => {
        res.data = res.data.sort((a, b) => a.id - b.id);
        setfilterData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setloading(false)
      })
  }, [])

  const handleClose = () => {
    setVisible(prev => ({
      ...prev,
      isVisible: false,
      option: null,
      selected: {}
    }))
    getDependencies()
    setupdated(prev => !prev)
  }

  useEffect(() => {
    getDependencies()
  }, [getDependencies, updated])

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Button 
        type='link' 
        style={{marginBottom: 15, alignSelf: 'flex-end', fontSize: 15}} 
        icon={<PlusCircleOutlined/>}
        onClick={() => setVisible(prev => ({...prev, isVisible: true, option: 'add'}))}
      > Nueva dependencia </Button>

      <Table
        rowKey={record => record.id}
        loading={loading}
        columns={columns}
        dataSource={filterData}
        size="small"
        scroll={{ y: '60vh' }}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
      />
      {
        visible.option &&
        <Modal
        centered
        visible={visible.isVisible}
        closable={false}
        footer={null}
        onCancel={handleClose}
        title={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {visible.option === 'edit' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Editar Dependencia</Typography>}
            {visible.option === 'delete' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Eliminar Dependencia</Typography>}
            {visible.option === 'add' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Agregar Dependencia</Typography>}
            <CloseCircleOutlined onClick={handleClose} style={{ color: 'red', marginLeft: 'auto' }} />
          </div>
        }
      >
        {visible.option === 'edit' && <EditDependency data={visible.selected} handleClose={handleClose}/>}
        {visible.option === 'delete' && <DeleteDependency data={visible.selected} handleClose={handleClose}/>}
        {visible.option === 'add' && <CreateDependency handleClose={handleClose}/>}

      </Modal>
      }
      
    </div>
  )
}
