import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { sessionActions } from './store';
import apiClient from './axiosConfig';
import { deleteSession } from './common/DeleteSession';

const SocketController = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let authenticated = false;
  let user = [];
  if (sessionStorage.user !== undefined) {
    user = JSON.parse(sessionStorage.user);
    authenticated = true
  }

  useEffect(() => {
    if(sessionStorage.user !== undefined){
 
    dispatch(sessionActions.updateToken(user.token))
    dispatch(sessionActions.updateUser(user));
    apiClient.get(`/api/login`)
      .then(response => {
        if (response.status === 202) {
          dispatch(sessionActions.updateUser(user));
          console.info('Usuario Con Session Iniciada')
        } else {
          deleteSession()
        }
      }).catch(error => { 
        console.log(error.response.status)
        if(error.response.status === 403){
          deleteSession()
        }
        //error catch  
        // dispatch(sessionActions.updateToken(null))
        // dispatch(sessionActions.updateUser({}));
        // sessionStorage.removeItem('user');
        // history.push('/')
      
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log("Error : ", error.message)
        }
      });
    }
  }, [authenticated, history]);
  // return null;
}

export default connect()(SocketController);
