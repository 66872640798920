import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    user: {},
    token: null,
    tokenExpired: false,
    updateName: false
  },
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateToken(state, action) {
      state.token = action.payload;
    },
    updateTokenExpired(state, action) {
      state.tokenExpired = action.payload;
    },
    updateName(state) {
      state.updateName = !state.updateName;
    }
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
