import { Button, Form, Input, Spin, Switch, Typography } from 'antd'
import { useState } from 'react'
import { apiUpdatReportType } from '../../../../utils/api'
import { openMessage } from '../../Utils/MessageDisplay'

export const ConfigurationModal = ({ openModal, setopenModal, setUpdated }) => {

    const { data } = openModal
    const [state, setstate] = useState({
        minTime: data.minTime,
        maxTime: data.maxTime,
        active: data.active,
        loading: false,
    })

    const handleSave = () => {
        setstate({ ...state, loading: true })
        let dataApi = {
            dependencyId: data.dependency.id,
            id: data.id,
            max_time: state.maxTime,
            min_time: state.minTime,
            active: state.active,
            name: data.name
        }
        apiUpdatReportType(data.id, dataApi)
            .then(res => {
                if (res.status === 200) {
                    setopenModal({ ...openModal, isOpen: false, data: {} })
                    setstate({ ...state, loading: false })
                    openMessage({ message: `La configuración del servicio ${data.name} se ha actualizado correctamente`, type: 'success' })
                    setUpdated(prev => !prev)
                }
            }).catch(err => {
                console.log(err)
                setstate({ ...state, loading: false })
                openMessage({ message: `Ocurrio un error al actualizar la configuración del servicio ${data.name}`, type: 'error' })
            })
    }

    const handleChange = (e, name) => {
        if (e.target.value === '') {
            e.target.value = 0
        }

        const regex = /[^\d]/u;
        if (regex.test(e.target.value)) {
            e.target.value = parseInt(e.target.value.substring(0, e.target.value.length - 1));
        } else {
            setstate(prev => ({ ...prev, [name]: parseInt(e.target.value) }))
        }
    }

    const handleChangeSwitch = (checked) => {
        setstate(prev => ({ ...prev, active: checked }))
    }

    return (
        <div>
            <Spin spinning={state.loading}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
                    <Typography.Title level={4} style={{ marginRight: 15 }}>{data.name}</Typography.Title>
                    <Switch style={{ marginTop: 5, backgroundColor: '#23ba27' }} onChange={handleChangeSwitch} checked={state.active} checkedChildren="Activo" unCheckedChildren="Inactivo" />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Item
                        style={{ marginRight: 30 }}
                        help={state.minTime > state.maxTime ? 'El mínimo debe ser menor que el máximo' : ''}
                        validateStatus={state.minTime > state.maxTime ? 'error' : 'success'}
                    >
                        <Typography.Text>Mínimo (horas)</Typography.Text>
                        <Input onChange={e => handleChange(e, 'minTime')} value={state.minTime} />
                    </Form.Item>

                    <Form.Item
                        help={state.minTime > state.maxTime ? 'El mínimo debe ser menor que el máximo' : ''}
                        validateStatus={state.minTime > state.maxTime ? 'error' : 'success'}
                    >
                        <Typography.Text>Máximo (horas)</Typography.Text>
                        <Input onChange={e => handleChange(e, 'maxTime')} value={state.maxTime} />
                    </Form.Item>
                </div>
                <Button
                    style={{ marginTop: 20 }}
                    disabled={state.minTime > state.maxTime || state.minTime === 0 || state.maxTime === 0}
                    type='primary'
                    onClick={handleSave}
                >Guardar</Button>
                <Button
                    type='link'
                    onClick={() => setopenModal(prev => ({ ...prev, isOpen: false, data: {} }))}
                >Cerrar</Button>
            </Spin>
        </div>
    )
}
