import React, { useState } from 'react'
import { useEffect } from 'react'
import { apiGetReportsByUser } from '../../../utils/api'
import { Table, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import moment from 'moment'

export const ReportsAssigned = ({ data , onClose}) => {

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            //sorter: (a, b) => a.idBudgetParticipatory - b.idBudgetParticipatory,
            render: id => (
                <div>
                    {id}
                </div>
            )
        },
        {
            title: 'Tipo',
            dataIndex: 'servicesDto',
            key: 'servicesDto',
            render: text => (
                <Tooltip title={text.name}>
                    {text.name}
                </Tooltip>
            ),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => (
                <Tooltip title={moment(text).format('DD/MM/YYYY hh:mm a')}>
                    {moment(text).format('DD/MM/YYYY hh:mm a')}
                </Tooltip>
            ),
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Estatus',
            width: '15%',
            render: (row) => {
                if (row.status.id !== 4) {
                    return row.expired ? 'Expirado' : 'Vigente'
                }
            },
            ellipsis: {
                showTitle: true
            }
        }
    ]

    const [loading, setloading] = useState(false)
    const [rows, setrows] = useState([])

    useEffect(() => {
        setloading(true)
        apiGetReportsByUser(data.id).then(res => {
            console.log(res.data)
            setrows(res.data)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })

    }, [])

    return (
        <div style={{ width: '80vw' }}>
            <div style={{ fontSize: '1.2rem', fontWeight: 600, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', gap: '1rem' }}>
                <div>
                    Reportes Asignados a {`${data.name} ${data.lastName || ''}`}
                </div>
                <CloseOutlined onClick={onClose} style={{ fontSize: '1rem', cursor: 'pointer' }} />

            </div>
            <div style={{ paddingTop: 30 }}>
                <Table
                    rowKey={record => record.id}
                    loading={loading}
                    columns={columns}
                    dataSource={rows}
                    size="small"
                    scroll={{ y: '60vh', x: '100%' }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                />
            </div>
        </div>
    )
}
