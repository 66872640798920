import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useState } from 'react'
import { apiAddUsersCapturist, apiSearchUserByPhone } from '../../../../utils/api'
import { cammelCase } from '../../../../utils/cammelCase'

export const UserCreate = ({idReport, found, setfound, setDataReport,userData,setuserData }) => {

    const [disableInputs, setdisableInputs] = useState(true)
    const [registerPhone, setregisterPhone] = useState(false)
    const [register, setregister] = useState(false)
    const [registered, setregistered] = useState(false)
    const [failedEmailFormat, setfailedEmailFormat] = useState()

    const handleChangePhone = (e) => {
        const re = /^[0-9]*$/
        if (e.target.value.length > 0) {
            if (re.test(e.target.value)) {
                if(e.target.value.length > 10){
                    e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                }
            } else {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            }
        }
        setuserData(prev => ({ ...prev, phone: e.target.value}))
    }

    const handleChange = (e) => {
        if (e.target.name === 'email') {
            setfailedEmailFormat(false);
            const re =
                /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

            if (e.target.value.length > 0) {

                if (re.test(e.target.value)) {
                    setfailedEmailFormat(false);
                } else {
                    setfailedEmailFormat(true);
                }
            }
        }
        setuserData(prev => ({
            ...prev,
            [e.target.name]: e.target.name === 'email' ? e.target.value : cammelCase(e.target.value)
        }))
    }

    const createUser = () => {

        const dataUser = {
            name: userData.name,
            lastName: userData.lastName,
            phone: userData.phone,
            rolesId: userData.rolesId,
            email: userData.email ? userData.email : null,
            dependencyId: null
        }

        apiAddUsersCapturist(dataUser)
            .then(res => {
                console.log(res)
                setDataReport(prev => ({ ...prev, createBy: res.data.id }))
                setregistered(true)
            })
            .catch(err => {
                console.log(err)
                setregistered(false)
            })
    }

    const searchUser = () => {
        apiSearchUserByPhone(userData.phone)
            .then(res => {
                setuserData(prev => ({
                    ...prev,
                    name: cammelCase(res.data.name),
                    lastName: cammelCase(res.data.lastName),
                    email: res.data.email ? res.data.email : '',
                }))
                setDataReport(prev => ({ ...prev, createBy: res.data.id }))
                setregister(false)
                setfound(true)
                setregisterPhone(true)
                setfailedEmailFormat(false)
            })
            .catch(err => {
                setDataReport(prev => ({ ...prev, createBy: null }))
                setuserData(prev => ({
                    ...prev,
                    name: '',
                    lastName: '',
                    email: '',
                }))
                setregisterPhone(true)
                setregister(true)
                setdisableInputs(false)

            })
    }

    const handleCancelRegister = () => {
        setDataReport(prev => ({ ...prev, createBy: null }))
        setdisableInputs(true)
        setregisterPhone(false)
        setregister(false)
        setfound(false)
        setuserData(prev => ({
            ...prev,
            name: '',
            lastName: '',
            email: '',
            phone: '',
        }))
        setfound(false)
        setfailedEmailFormat(false)
    }


    return (
        <div style={{marginTop: -20}}>
            <Typography.Title level={3}>Información del ciudadano</Typography.Title>
            <Row gutter={[24, 24]}>
                <Col span={12} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Form.Item
                        help={'Por favor ingrese el número de teléfono'}
                        style={{ width: '100%', }}
                    >
                        <Input disabled={registered || registerPhone || idReport} style={{borderRadius: '15px 0 0 15px'}} placeholder='Número de teléfono *' name='phone' onChange={handleChangePhone} value={userData.phone} />
                    </Form.Item>
                    <Button disabled={registered || registerPhone || userData.phone.length < 10 || idReport} onClick={searchUser} icon={<SearchOutlined/>} style={{borderRadius: '0 15px 15px 0'}}/>
                        {/* <Button icon={<SearchElement/>}>Buscar</Button> */}
                </Col>
                <Col span={12}>
                    <Form.Item
                        help={
                            'Por favor ingrese el nombre(s)'
                        }
                    >
                        <Input disabled={registered || disableInputs || found} placeholder='Nombre del ciudadano *' name='name' onChange={handleChange} value={userData.name} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        help={
                            'Por favor ingrese el apellido(s)'
                        }
                    >
                        <Input disabled={registered || disableInputs || found } placeholder='Apellido del ciudadano *' name='lastName' onChange={handleChange} value={userData.lastName} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        validateStatus={failedEmailFormat ? 'error' : ''}
                        help={
                            failedEmailFormat ? 'Formato de correo inválido' : ''
                        }
                    >
                        <Input disabled={registered || disableInputs || found} placeholder='Correo electrónico del ciudadano' name='email' onChange={handleChange} value={userData.email} />
                    </Form.Item>
                </Col>
                    <Col span={24}>
                {
                    register &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            {
                                (!registered) &&    
                                <Button type='link' onClick={handleCancelRegister}>Cancelar</Button>
                        }
                            <Button disabled={!userData.name || !userData.lastName || registered || failedEmailFormat || userData.phone.length < 10} type='primary' onClick={createUser}>Registrar Ciudadano</Button>
                            {
                                (registered) &&
                                <>
                                <Typography style={{color: 'green', marginLeft: 14, marginTop: 5}}><CheckCircleOutlined/> Registro Exitoso </Typography>
                                </>
                            }
                        </div>
                            }
                            {
                                (found && !idReport) &&
                                <div style={{display: 'flex'}}>
                                <Typography style={{color: 'green', marginLeft: 14, marginTop: 5}}><CheckCircleOutlined/> Ciudadano encontrado  </Typography>
                                <Button type='link' onClick={handleCancelRegister}>Cancelar</Button>
                                </div>
                            }
                    </Col>
            </Row>
            <Typography.Title level={3} style={{ marginTop: 20, marginBottom: -20 }}>Información del reporte</Typography.Title>

        </div>
    )
}
