
import Background from './asserts/deliciasBackground.jpg'
import Logo from './asserts/logoDeliciasTouch2.svg'

export const webName = 'Delicias Touch'
export const contactEmail = 'contacto@deliciastouch.com'

export const prMain = '#22408D';
export const prMainLight = '#22408D';
export const prMainLighter = '#22408D';
export const ColorBackground = '#007632';

export const background = Background
export const logo = Logo
