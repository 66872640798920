import { Col, Row, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { apiDependencies, apiReportType } from '../../../../utils/api'
import { UserCreate } from './UserCreate'

export const TypeReport = ({ idReport, found, setfound, setDataReport, dataReport, handleChange, setdependency, dependency, userData, setuserData }) => {

    const [dependencies, setdependencies] = useState([])
    const [reportTypes, setreportTypes] = useState([])

    const [loadings, setloadings] = useState({
        loadingDependencies: false,
        loadingReportTypes: false,
    })

    const handleChangeSelect = (e) => {
        setdependency(e)
    }

    useEffect(() => {
        setloadings(prev => ({ ...prev, loadingDependencies: true }))
        apiDependencies().then(res => {
            setdependencies(res.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setloadings(prev => ({ ...prev, loadingDependencies: false }))
        })
    }, [])

    useEffect(() => {
        if (idReport) return
        setloadings(prev => ({ ...prev, loadingReportTypes: true }))
        // setDataReport(prev => ({ ...prev, reportTypeId: '' }))
        apiReportType().then(res => {
            if (res.status === 200) {
                res.data = res.data.filter(item => item.name !== 'CRM')

                let filter = res.data.filter(item => item.dependency.id === dependency)
                let find = filter.find(item => item.id === dataReport.reportTypeId)
                if (find) {
                    setDataReport(prev => ({ ...prev, reportTypeId: find.id }))
                } else {
                    setDataReport(prev => ({ ...prev, reportTypeId: '' }))
                }
                setreportTypes(filter)
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setloadings(prev => ({ ...prev, loadingReportTypes: false }))
        })
    }, [dependency])

    return (

        <Row style={{ width: '100%' }} gutter={[24, 24]}>
            <Col span={24}>
                <UserCreate idReport={idReport} found={found} setfound={setfound} userData={userData} setuserData={setuserData} setDataReport={setDataReport} />
            </Col>
            <Col span={12}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text style={{ margin: 0 }}> Dependencia </Typography.Text>
                    <Select
                        onChange={handleChangeSelect}
                        loading={loadings.loadingDependencies}
                        value={dependency}
                        disabled={idReport}
                    >
                        {
                            dependencies.map(dependency => (
                                <Select.Option key={dependency.id} value={dependency.id}> {dependency.name} </Select.Option>
                            ))
                        }
                    </Select>
                </div>
            </Col>
            <Col span={12}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography.Text style={{ margin: 0 }}> Tipo de reporte </Typography.Text>
                    <Select
                        disabled={!dependency || idReport}
                        onChange={(e) => handleChange(e, 'reportTypeId')}
                        value={dataReport.reportTypeId}
                        loading={loadings.loadingReportTypes}
                    >
                        {
                            reportTypes.map(reportType => (
                                reportType.active &&
                                <Select.Option key={reportType.id} value={reportType.id}> {reportType.name} </Select.Option>
                            ))
                        }
                    </Select>
                </div>
            </Col>
        </Row>
    )
}
