import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd'
import { EditOutlined, EyeOutlined, ReloadOutlined, StarOutlined } from '@ant-design/icons';
import { apiGetProjectsPagination, apiGetProjectsPaginationSearch } from '../../../utils/api';
import moment from 'moment';
import BudgetDetails from './BudgetDetails';
import { EditProject } from './CreateProject/EditProject';

const BudgetTable = ({ filter, clickSearch, page, setpage, update, setupdate, period }) => {

  const columns = [
    {
      title: 'Id',
      dataIndex: 'idBudgetParticipatory',
      key: 'idBudgetParticipatory',
      width: '7%',
      render: idBudgetParticipatory => (
        <div>
          {idBudgetParticipatory}
        </div>
      )
    },
    {
      title: 'Proyecto',
      key: 'projectName',
      width: '15%',
      render: row => (
        <Tooltip title={row.projectName}>
          {row.projectName}
        </Tooltip>
      )
    },
    {
      title: 'Tipo',
      key: 'type',
      width: '15%',
      render: row => row.type ? 'General' : 'Zona'
    },
    {
      title: 'Código Postal',
      key: 'CP',
      width: '12%',
      render: row => (
        <Tooltip title={row.postcode ? row.postcode.postcode : ""}>
          {row.postcode ? row.postcode.postcode : " "}
        </Tooltip>
      )
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      ellipsis: {
        showTitle: true
      },
      width: '18%',
    },
    {
      title: 'Creado por',
      dataIndex: 'nameUserCreated',
      key: 'nameUserCreated',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: nameUserCreated => (
        <Tooltip title={nameUserCreated}>
          {nameUserCreated}
        </Tooltip>
      )
    },
    {
      title: 'Fecha creación',
      key: 'created_at',
      sorter: true,
      width: '15%',
      ellipsis: {
        showTitle: true
      },
      render: row => (
        row.createdAt ?
          <Tooltip title={moment(row.createdAt).format('lll')}>
            {moment(row.createdAt).format('lll')}
          </Tooltip> : ''
      )
    },
    {
      title: 'Votación',
      key: 'votes',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: row => (
        <Tooltip title={row.votes === '1' ? row.votes + ' voto' : row.votes + ' votos'} >
          <StarOutlined />{row.votes}
        </Tooltip>
      )
    },
  ]

  const actionsTable = {
    title: 'Detalles',
    key: 'details',
    width: '8%',
    ellipsis: {
      showTitle: true
    },
    render: (row) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>

        <Tooltip title="Ver">
          {/* <NavLink to={{ pathname: '/apoyos/detalles', state: row }}> */}
          <Button type='link' icon={<EyeOutlined style={{ color: '#000' }} />} onClick={() => handledetails(row)}>
          </Button>
          {/* </NavLink> */}
        </Tooltip>
        <Tooltip title="Editar">
          {/* <NavLink to={{ pathname: '/apoyos/detalles', state: row }}> */}
          <Button type='link' icon={<EditOutlined style={{ color: '#000' }} />} onClick={() => handleEdit(row)}>
          </Button>
          {/* </NavLink> */}
        </Tooltip>

      </div>
    ),
  }


  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openDetails, setOpenDetails] = useState(false)
  const [openEdit, setopenEdit] = useState(false)
  const [details, setDetails] = useState([])

  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({
    key: undefined,
    order: undefined
  })
  const [totalData, settotalData] = useState(0)

  const handleEdit = data => {
    setDetails(data)
    setopenEdit(true)
  }

  const handleCloseEdit = () => {
    setDetails(null)
    setopenEdit(false)
  }

  const handledetails = (data) => {
    setDetails(data)
    setOpenDetails(true)
  }

  const getData = useCallback(() => {
    if (!period) return

    setLoading(true)
    apiGetProjectsPagination(page, pageSize, order.order, order.key, period).then(res => {
      settotalData(res.data.amount)
      setFilterData(res.data.budgetParticipatory)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })

  }, [page, pageSize, order.order, order.key, period])

  const getDataSearch = useCallback(() => {
    if (!period) return

    setLoading(true)
    apiGetProjectsPaginationSearch(page, pageSize, order.order, order.key, filter, period).then(res => {
      settotalData(res.data.amount)
      setFilterData(res.data.budgetParticipatory)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })

  }, [page, pageSize, order.order, order.key, filter, period])

  useEffect(() => {
    if (filter) {
      getDataSearch()
    } else {
      getData()
    }

  }, [update, refresh, page, pageSize, order.key, order.order, period])

  const handleTable = (pagination, filters, sorter) => {

    setpage(pagination.current)
    setpageSize(pagination.pageSize)
    setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : undefined }))
  }

  return (
    <>
      <div style={{ paddingTop: 30 }}>
        <Table
          onChange={handleTable}
          rowKey={record => record.idBudgetParticipatory}
          loading={loading}
          columns={[...columns, actionsTable]}
          dataSource={filterData}
          size="small"
          scroll={{ y: '60vh', x: '100%' }}
          pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        />
        <div style={{ marginTop: '-50px' }} >
          <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
        </div>
      </div>

      <Modal
        centered
        visible={openDetails}
        closable={false}
        footer={null}
        destroyOnClose
        onCancel={() => setOpenDetails(false)}
        width={'75%'}
      >
        <BudgetDetails details={details} setOpenDetails={setOpenDetails} refresh={refresh} setRefresh={setRefresh} />
      </Modal>
      <Modal
        centered
        visible={openEdit}
        closable
        footer={null}
        destroyOnClose
        onCancel={handleCloseEdit}
        width={'75%'}
      >
        {
          details &&
          <EditProject details={details} close={handleCloseEdit} refresh={refresh} setRefresh={setRefresh} />
        }
      </Modal>
    </>
  );
}
export default BudgetTable;