import { Button, Modal, Switch, Table, Tag, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { CreatePeriod } from './CreatePeriod'
import { apiEditPeriod, apiGetPeriods } from '../../../../utils/api'

export const PeriodsPage = () => {

    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const [modal, setmodal] = useState({
        isOpen: false
    })

    const getData = useCallback(() => {
        setloading(true)
        apiGetPeriods().then(res => {
            setdata(res.data)
            console.log(res)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }, [])

    const changeStatus = (value, row) => {
        apiEditPeriod(row.id, row.description, value).then(res => {
            getData()
        }).catch(err => {
            new Error(err)
        })
    }

    const columns = [
        {
            title: 'Periodo',
            dataIndex: 'description'
        },
        {
            title: 'Status',
            width: '20%',
            render: row => (
                <div>
                    {
                        row.isActive ? <Tag color='green'>Activo</Tag> : <Tag color='red'>Inactivo</Tag>
                    }
                </div>
            )
        },
        {
            title: 'Accion',
            width: '20%',
            render: row => (
                <div>
                    <Tooltip title={`${row.isActive ? 'Desactivar' : 'Activar'}`}>
                        <Switch checked={row.isActive} onChange={e => changeStatus(e, row)} />
                    </Tooltip>
                </div>
            )
        },
    ]

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div style={{ fontSize: '1.2rem' }}>
                Periodos de Presupuesto Participativo
            </div>
            <div style={{ width: '70%', height: 1, opacity: .1, backgroundColor: '#000' }}></div>


            <div style={{ width: '70%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='primary' onClick={() => setmodal(prev => ({ ...prev, isOpen: true }))}>Nuevo Periodo</Button>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', width: '70%' }}>
                <Table
                    rowKey={record => record.id}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    size='large'
                    scroll={{ y: '60vh', x: '100%' }}
                />
            </div>

            {
                modal.isOpen &&
                <Modal
                    visible={modal.isOpen}
                    centered
                    footer={null}
                    closable={false}
                    onCancel={() => setmodal(prev => ({ ...prev, isOpen: false }))}
                >
                    <CreatePeriod getData={getData} close={() => setmodal(prev => ({ ...prev, isOpen: false }))} />
                </Modal>
            }
        </div>
    )
}
