import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { menuActions } from '../store';
import isAllowed, { ROLES_DIVISIONS } from '../layout/roles';
import { List, Typography, Tooltip} from 'antd'
import { AppstoreOutlined, BookOutlined, FileAddOutlined, PhoneOutlined, SettingOutlined, SnippetsOutlined, UserOutlined, AuditOutlined, CalendarOutlined, FundViewOutlined } from '@ant-design/icons';
import { prMainLight } from '../themeConfig';
import { ReactComponent as VotoIcon } from '../asserts/voto.svg'

const MenuItemsProvider = (props) => {
    const { openAction, hide } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.session.user);
    const handleListItemClick = (event, route, index, text) => {
        history.push(route)
        dispatch(menuActions.updateMenuName(text))
        if (openAction !== undefined) {
            openAction(true);
        }
    };

    const menuItems = handleMenuItems(isAllowed, user, ROLES_DIVISIONS);

    return (
        <div >
            <List>
                {menuItems.map((item, index) => (
                    item.allowed && (
                        hide
                            ? (
                                <Tooltip title={item.text} key={index} placement='right'>
                                    <List.Item
                                        // selected={window.location.pathname === `/${item.route}`}
                                        style={{ backgroundColor: window.location.pathname.includes(`/${item.route}`) ? prMainLight : '', dispaly: 'flex', justifyContent: 'center', borderRadius: 20, marginBottom: 20, border: '0', cursor: 'pointer', transition: '1s' }}
                                        onClick={(event) => handleListItemClick(event, `/${item.route}`, item.index, item.text)}
                                    >
                                        <div >
                                            <item.icon size={40} style={{color: '#FFF', fontSize: 20}}/>
                                        </div>

                                    </List.Item>
                                </Tooltip>
                            )
                            : (
                                <List.Item
                                    key={index}
                                    // selected={window.location.pathname === `/${item.route}`}
                                    style={{ backgroundColor: window.location.pathname.includes(`/${item.route}`) ? prMainLight : '', borderRadius: 20, marginBottom: 5, border: '0', cursor: 'pointer', transition: '1s' }}
                                    onClick={(event) => handleListItemClick(event, `/${item.route}`, item.index, item.text)}
                                >
                                    <div style={{paddingLeft: 22, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <item.icon size={40} style={{marginRight: 15, color: '#FFF', fontSize: 20}} />
                                    <Typography.Text style={{color: '#FFF', fontSize: 17}}>{item.text}</Typography.Text>
                                    </div>
                                </List.Item>
                            )

                    )
                ))}
            </List>
        </div>
    );
}

const handleMenuItems = (isAllowed, user, ROLES_DIVISIONS) => {
    return [
        { icon: AppstoreOutlined, route: "dashboard", text: 'Tablero', index: 0, allowed: isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) },
        { icon: BookOutlined, route: "reportes", text: 'Reportes', index: 1, allowed: isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)},
        { icon: BookOutlined, route: "reportes", text: 'Reportes', index: 1, allowed: isAllowed(user, ROLES_DIVISIONS.CAPTURIST)},
        { icon: SnippetsOutlined, route: "peticiones", text: 'Peticiones', index: 1, allowed: isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)},
        { icon: FileAddOutlined, route: "crear-reporte", text: 'Capturar Reporte', index: 2, allowed: isAllowed(user, ROLES_DIVISIONS.CAPTURIST) || isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) || (isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER))},
        { icon: UserOutlined, route: "usuarios", text: 'Usuarios', index: 3, allowed: isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) },
        { icon: PhoneOutlined, route: "ciudadanos", text: 'Ciudadanos', index: 4, allowed: isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) },
        { icon: AuditOutlined, route: "apoyos", text: 'Programas sociales', index: 6, allowed: isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) },
        { icon: UserOutlined, route: "usuarios", text: 'Usuarios', index: 7, allowed: isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) },
        // { icon: BarChartOutlined, route: "TableroPresupuestoParticipativo", text: 'Tablero de Presupuesto participativo', index: 7, allowed: isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER) },
        { icon: VotoIcon, route: "PresupuestoParticipativo", text: 'Presupuesto participativo', index: 8, allowed: isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER) },
        { icon: CalendarOutlined, route: "Eventos", text: 'Eventos', index: 9, allowed: isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.EVENTS_MANAGER) },
        { icon: FundViewOutlined, route: "crm", text: 'CRM', index: 0, allowed: isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN) || isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER) || (isAllowed(user, ROLES_DIVISIONS.PURE_MANAGEMENT) && user.service.some(i => i.name === 'CRM')) },

        // { icon: VotoIcon, route: "votos", text: 'Votos', index: 8, allowed: true },
        //ya esta hasta abajo del menu!
        { icon: SettingOutlined, route: "configuracion", text: 'Configuración', index: 9, allowed: isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) },
    ];

}

export default MenuItemsProvider;