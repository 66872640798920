import React, { useEffect, useState } from 'react'
import { VotesChart } from '../../../charts/VotesChart'
import { colors } from './CRMDashboard'
import moment from 'moment'
import { Divider, Select, Space, Typography } from 'antd'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { apiGetReOpenReports } from '../../../../utils/api'

export const ReopenReports = ({refresh, setrefresh}) => {

    const [date, setdate] = useState({
        month: [parseInt(moment().format('M'))],
        year: moment().format('YYYY')
    })
    const [data, setdata] = useState([])

    const onChangeMonth = (data) => {
        setdate(prev => ({ ...prev, month: data }))
    }

    const createData = (data) => {
        if (data.length === 0) {
            let noData = [{
                x: 'Sin Datos',
                y: 0,
                fillColor: colors[0],
                strokeColor: colors[0],
            }]
            setdata(noData)

            return
        } else {

            let dataReport = data.map((item, index) => ({
                x: item.idReport,
                y: item.numberOfTimesOpened,
                fillColor: colors[index],
                strokeColor: colors[index],
            }))

            setdata(dataReport)

            return
        }
    }

    useEffect(() => {

        if (!date.year || date.month.length <= 0) {
            let noData = [{
                x: 'Sin Datos',
                y: 0,
                fillColor: colors[0],
                strokeColor: colors[0],
            }]
            setdata(noData)

            return
        }

        apiGetReOpenReports(date.year, date.month.join(',')).then(res => {
            let data = res.data.sort((a, b) => b.numberOfTimesOpened - a.numberOfTimesOpened)

            if (data.length > 10) {
                data = data.slice(0, 10)
            }

            createData(res.data)
        }).catch(err => {
            new Error(err)
        })

        setTimeout(() => {
            setrefresh(!refresh)

        }, [120000])

    }, [date.year, date.month, refresh])

    return (
        <div style={{ height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                <label style={{ fontSize: '1.2rem', opacity: 0.7 }}>Folios Más Reabiertos</label>

                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: '40%' }}>
                    <Select
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: '100%' }}
                        allowClear
                        size='small'
                        value={date.month}
                        onChange={onChangeMonth}
                        dropdownRender={menu => (
                            <>
                                <Space style={{ padding: '0 8px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <DoubleLeftOutlined onClick={() => setdate(prev => ({ ...prev, year: prev.year - 1 }))} />
                                    {date.year}
                                    <DoubleRightOutlined style={{ opacity: date.year >= parseInt(moment().format('YYYY')) ? .7 : 1 }} onClick={() => date.year >= parseInt(moment().format('YYYY')) ? null : setdate(prev => ({ ...prev, year: prev.year + 1 }))} />
                                </Space>
                                <Divider style={{ margin: '8px 0' }} />
                                {menu}
                            </>
                        )}
                    >
                        <Select.Option value={1} key={1}>Enero</Select.Option>
                        <Select.Option value={2} key={2}>Febrero</Select.Option>
                        <Select.Option value={3} key={3}>Marzo</Select.Option>
                        <Select.Option value={4} key={4}>Abril</Select.Option>
                        <Select.Option value={5} key={5}>Mayo</Select.Option>
                        <Select.Option value={6} key={6}>Junio</Select.Option>
                        <Select.Option value={7} key={7}>Julio</Select.Option>
                        <Select.Option value={8} key={8}>Agosto</Select.Option>
                        <Select.Option value={9} key={9}>Septiembre</Select.Option>
                        <Select.Option value={10} key={10}>Octubre</Select.Option>
                        <Select.Option value={11} key={11}>Noviembre</Select.Option>
                        <Select.Option value={12} key={12}>Diciembre</Select.Option>
                    </Select>
                    <Typography.Text style={{ color: '#6D6D6D', fontSize: 12 }}>Seleccione los meses</Typography.Text>
                </div>
            </div>
            <VotesChart series={data} />
        </div>
    )
}
