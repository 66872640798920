import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'loading',
    initialState: {
      loading: false,
    },
    reducers: {
          update(state, action) {
            state.loading = action.payload;
          },
    }
  });

export { actions as loadingActions };
export { reducer as loadingReducer }; 