import React, { useEffect, useState } from 'react';
import { Button, Modal, Result, Table, Tooltip } from 'antd'
import { LikeFilled, LikeOutlined, ReloadOutlined } from '@ant-design/icons';
import { apiBudgetVote, apiGetNearbyProjects, apiGetVotedProjects } from '../../../utils/api';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { prMain } from '../../../themeConfig';
import { MyVotes } from './MyVotes';

const VotesTable = (props) => {

  const { setfilter, filter, clickSearch } = props

  const { width, height } = useScreenSize()
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([]);

  // const [projectsVoted, setprojectsVoted] = useState([])

  const [tab, settab] = useState(1)
  const [rowSelected, setrowSelected] = useState(null)

  const [loading, setLoading] = useState(false);
  // const { user } = useSelector(state => state.session)
  const [refresh, setRefresh] = useState(false);
  // const [openModal, setOpenModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    description: ""
  })
  const [refreshVotes, setRefreshVotes] = useState(false)

  const columns = [
    {
      title: 'Proyecto',
      key: 'projectName',
      //sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render: row => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem' }}>
          <Tooltip title={row.projectName}>
            <div style={{ inlineSize: '85%', overflowWrap: 'break-word' }}>
              {row.projectName}
            </div>
          </Tooltip>

          <Tooltip title={tab !== 3 ? "Votar" : "Votado"}>
            {/* <NavLink to={{ pathname: '/apoyos/detalles', state: row }}> */}
            <Button type='link' icon={[1, 2].some(i => i === tab) ? <LikeOutlined style={{ color: '#000' }} /> : <LikeFilled style={{ color: prMain }} />} onClick={() => [1, 2].some(i => i === tab) && handleOpen(row)}>
            </Button>
            {/* </NavLink> */}
          </Tooltip>
        </div>
      )
    }
  ]

  const handleOpen = (row) => {
    setOpenModal(true)
    setrowSelected(row)
  }

  const handleClose = () => {
    setOpenModal(false)
    setError(false)
    setSuccess(false)
  }

  const handleVote = () => {

    let user = JSON.parse(sessionStorage.getItem('userVotes'))
    apiBudgetVote(rowSelected.idBudgetParticipatory, user.idUserVoteBudget, tab === 1 ? tab : 0).then(() => {
      // let userVotes = JSON.parse(sessionStorage.getItem('userVotes'))
      // apiGetVotedProjects(userVotes.idUserVoteBudget).then((response) => {
      //   setprojectsVoted(response.data)
      // }).catch(err => {
      //   new Error(err)
      // }).finally(() => {
      //   setLoading(false)
      // })
      setRefreshVotes(prev => !prev)
      setRefresh(!refresh)
      setSuccess(true)
      setError(false)
    }).catch((e) => {
      console.log(e)
      if (e.response.status === 412) {
        setErrorMessage({
          title: "Límite de votos alcanzado",
          description: `Has alcanzado el límite de votos de proyectos ${tab === 1 ? 'generales' : 'en tu zona'}`
        })
      } else if (e.response.status === 406) {
        setErrorMessage({
          title: "Votación Desactivada",
          description: "No es posible utilizar el servicio de votación debido a se encuentra desactivado"
        })
      } else if (e.response.status === 409) {
        setErrorMessage({
          title: "Votación Desactivada",
          description: "El voto por este proyecto ya se encuentra registrado"
        })
      } else {
        setErrorMessage({
          title: "Error al intentar votar",
          description: "Ha ocurrido un error al intentar registrar el voto. Por favor intente de nuevo."
        })
      }
      setSuccess(false)
      setError(true)
    })
  }

  useEffect(() => {
    if (tab === 3) return
    setLoading(true)
    setfilter("")
    let userVotes = JSON.parse(sessionStorage.getItem('userVotes'))

    setFilterData([])
    setData([])
    apiGetNearbyProjects(userVotes.curp, tab === 1 ? tab : 0).then((response) => {
      setFilterData(response.data)
      setData(response.data)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })

    // }

  }, [refresh, tab])
  useEffect(() => {

    if (filter.length === 0) {
      setFilterData(data)

    } else {
      const search = filter.split(' ').filter(item => item !== '');

      const dataFilter = data.filter(item => {
        return (search.every(word =>
          item.projectName.toLowerCase().includes(word.toLowerCase())
        ))
      })
      setFilterData(dataFilter)
    }
  }, [clickSearch])

  useEffect(() => {
    if (filter) return

    setFilterData(data)
  }, [filter])

  // const [votes, setvotes] = useState({
  //   zone: [],
  //   general: []
  // })
  useEffect(() => {

    if (tab === 3) return

    let userVotes = JSON.parse(sessionStorage.getItem('userVotes'))

    apiGetVotedProjects(userVotes.curp, 'active').then((response) => {
      console.log(response.data)
      const votes = {
        zone: response.data[0].Zona,
        general: response.data[1].General
      }

      if (votes.general.length >= 1) {
        settab(2)
      } 
      
      if (votes.zone.length >= 2 && votes.general.length >= 1) {
        settab(3)
      }
      // setvotes(prev => ({ ...prev, zone: response.data[0].Zona, general: response.data[1].General }))
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [tab, refreshVotes])

  return (
    <>
      <div style={{ paddingTop: width > 760 ? 15 : 10 }}>
        <div style={{ display: 'flex', justifyContent: width > 760 ? 'flex-start' : 'center' }}>
          {/* <div style={{ marginBottom: 6, display: 'flex', gap: '.5rem', backgroundColor: prMainLighter, padding: '6px', borderRadius: '32px' }}>
            <div onClick={() => tab !== 1 && settab(1)} style={{ transition: '.5s', padding: '2px 8px', borderRadius: '32px', cursor: tab === 1 ? '' : 'pointer', color: tab === 1 ? '#fff' : 'rgba(0,0,0,0.6)', backgroundColor: tab === 1 ? prMain : 'transparent' }}>Generales</div>
            <div onClick={() => tab !== 2 && settab(2)} style={{ transition: '.5s', padding: '2px 8px', borderRadius: '32px', cursor: tab === 2 ? '' : 'pointer', color: tab === 2 ? '#fff' : 'rgba(0,0,0,0.6)', backgroundColor: tab === 2 ? prMain : 'transparent' }}>Mi Zona</div>
            <div onClick={() => tab !== 3 && settab(3)} style={{ transition: '.5s', padding: '2px 8px', borderRadius: '32px', cursor: tab === 3 ? '' : 'pointer', color: tab === 3 ? '#fff' : 'rgba(0,0,0,0.6)', backgroundColor: tab === 3 ? prMain : 'transparent' }}>Mis Votos</div>
          </div> */}
          <div style={{ width: '100%', textAlign: 'center', fontSize: '1.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.6rem', color: prMain }}>
            <div style={{ width: '1.6rem', height: '1.6rem', borderRadius: 100, border: `2px solid ${prMain}`, fontSize: '1rem', display: 'grid', placeContent: 'center' }}> {tab} </div>
            {tab === 1 ? 'Proyectos Generales' : tab === 2 ? 'Proyectos De Mi Zona' : 'Mis Votos'}
          </div>
        </div>
        {
          tab !== 3 &&
          <>
            {
              tab !== 3 &&
              <>
                <div style={{ opacity: .5 }}>
                  Puede votar por {tab === 2 ? '2 proyectos' : '1 proyecto'} de esta categoria
                </div>
                <Table
                  rowKey={record => record.idBudgetParticipatory}
                  loading={loading}
                  columns={columns}
                  dataSource={filterData}
                  size="small"
                  scroll={{ y: height > 620 ? '48vh' : '40vh', x: width > 760 ? '100%' : '100%' }}
                  pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                />
                <div style={{ marginTop: '-50px' }} >
                  <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
                </div>
              </>
            }

          </>
        }
        {
          tab === 3 &&
          <MyVotes columns={columns} filter={filter} clickSearch={clickSearch} />
        }

      </div>

      {/* <Modal
      centered
      visible={openDetails}
      closable={false}
      footer={null}
      destroyOnClose
      onCancel={() => setOpenDetails(false)}
      width={'75%'}
    >
        <VotesDetails details={details} setOpenDetails={setOpenDetails} refresh={refresh} setRefresh={setRefresh}/> 
    </Modal> */}
      <Modal
        centered
        visible={openModal}
        closable={false}
        footer={null}
        onCancel={() => handleClose(false)}>
        {(!success && !error && rowSelected) &&
          <Result
            status={'info'}
            title={'Votar por este proyecto'}
            subTitle={`¿Desea votar por el proyecto "${rowSelected.projectName}"?`}
            extra={[

              <Button type='link' key='cancel' onClick={() => handleClose(false)}>Cancelar</Button>,
              <Button type='primary' key='approve' onClick={() => handleVote()} >Continuar</Button>,
            ]}
          >

          </Result>
        }
        {(success && !error) &&
          <Result
            status={'success'}
            title={'Voto registrado'}
            subTitle={'Su voto ha sido registrado exitosamente.'}
            extra={[

              <Button type='primary' key='cancel' onClick={() => handleClose(true)}>Cerrar</Button>,
            ]}
          >

          </Result>
        }

        {(error && !success) &&
          <Result
            status={'error'}
            title={errorMessage.title}
            subTitle={errorMessage.description}
            extra={[

              <Button type='primary' key='cancel' onClick={() => handleClose(false)}>Cerrar</Button>,
            ]}
          >

          </Result>
        }
      </Modal>
    </>
  );
}
export default VotesTable;