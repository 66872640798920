import React, { useRef, useState } from 'react';
import * as yup from 'yup'
import { Input, Button, Modal } from 'antd'
import { useScreenSize } from '../../../hooks/useScreenSize';
import { background, logo } from '../../../themeConfig';
import { useHistory } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { Checkbox } from 'antd';
import styled from "styled-components";
import { TermsConditions } from './TermsConditions';
import { apiRegister } from '../../../utils/api';
import { ResponseForm } from './ResponseForm';

export const ErrorMessageForm = styled.div`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;

`

export const ErrorMessageField = styled(ErrorMessage)`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;
`

const formSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    lastName: yup.string().required('El apellido es requerido'),
    phone: yup.string()
        .required()
        .matches(/^[0-9]+$/, "Ingrese solo numeros")
        .min(10, 'Formato de Teléfono Inválido')
        .max(10, 'Formato de Teléfono Inválido').required('El numero de teléfono requerido'),
    email: yup
        .string()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Formato de Correo Inválido "
        ),
});

export const Register = () => {

    const { width, height } = useScreenSize()
    const history = useHistory()
    const inputRef = useRef(null);
    const [loading, setloading] = useState(false)
    const [termsConditions, settermsConditions] = useState(false)
    const [error, seterror] = useState({
        phone: false,
        email: false
    })
    const [modal, setmodal] = useState({
        isOpen: false,
        option: ''
    })
    const [response, setresponse] = useState({
        success: false,
        error: false
    })

    const onChangeCheck = (e) => {
        settermsConditions(e.target.checked)
    }

    const goTo = (path) => {
        history.push(path)
    }

    const handleSubmit = (values) => {
        const {name, lastName, phone, email} = values

        setloading(true)
        apiRegister({name, lastName, phone, email: email ? email : null}).then(res => {
            setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
            setresponse(prev => ({ ...prev, success: true, error: false }))
            console.log(res)
        }).catch(err => {
            if(err.response.data.includes('Este Telefono ya fue Registrado')){
                seterror(prev => ({ ...prev, phone: true }))
                return
            }
            if(err.response.data.includes('Usuario con Correo')){
                seterror(prev => ({ ...prev, email: true }))
                return
            }
            
            setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
            setresponse(prev => ({ ...prev, success: false, error: true }))
            new Error(err)
        }).finally(() => {
            setloading(false)
        })
    }

    const handleCloseModal = () => {
        setresponse(prev => ({ ...prev, success: false, error: false }))
        setmodal(prev => ({ ...prev, isOpen: false, option: '' }))
    }
    const handleCloseModalSuccess = () => {
        goTo('/r')
    }

    const handleClickTerms = () => {
        setmodal(prev => ({ ...prev, isOpen: true, option: 'terms' }))
    }

    return (
        <div style={classes.main} >
            <div style={classes.image}></div>
            <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '60vw' }}>
                <form style={{ width: '100%' }}>

                    <div style={classes.a}>
                        <img style={classes.logo} src={logo} alt='Logo' />
                    </div>

                    <Formik
                        validationSchema={formSchema}
                        onSubmit={handleSubmit}
                        initialValues={{
                            name: "",
                            lastName: "",
                            phone: "",
                            email: ""
                        }}
                    >
                        {({ handleSubmit, handleChange, isSubmitting, handleBlur, values, errors, isValid, touched, resetForm }) => (
                            <section style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                                <div style={{display: 'flex', gap: '1.5rem',  flexDirection: height > 700 ? 'column' : 'row'}}>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <label style={{ fontSize: '1rem', opacity: 0.6 }}>Nombre *</label>
                                    <Input
                                        id='name'
                                        name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        style={{ height: 40 }}
                                        size='large'
                                        status={errors.name && 'error'}
                                        placeholder='Ingrese su nombre'
                                    />
                                    <ErrorMessageField name="name" component="div" />
                                </div>
                                <div style={{ position: 'relative',  width: '100%' }}>
                                    <label style={{ fontSize: '1rem', opacity: 0.6 }}>Apellido *</label>
                                    <Input
                                        id='lastName'
                                        name='lastName'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        style={{ height: 40 }}
                                        ref={inputRef}
                                        size='large'
                                        status={errors.lastName && 'error'}
                                        placeholder='Ingrese su apellido'
                                    />
                                    <ErrorMessageField name="lastName" component="div" />
                                </div>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <label style={{ fontSize: '1rem', opacity: 0.6 }}>Teléfono *</label>

                                    <Input
                                        id='phone'
                                        name='phone'
                                        onChange={e => {
                                            if (!e.target.value.match(/^[0-9]+$/)) return
                                            seterror(prev => ({ ...prev, phone: false }))
                                            handleChange(e)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        style={{ height: 40 }}
                                        ref={inputRef}
                                        size='large'
                                        status={(errors.phone || error.phone) && 'error'}
                                        max={10}
                                        placeholder='Ingrese su numero de teléfono'
                                    />

                                    {
                                        error.phone
                                            ?
                                            <ErrorMessageForm>
                                                Ya existe una cuenta con este número de teléfono
                                            </ErrorMessageForm>
                                            :
                                            <ErrorMessageField name="phone" component="div" />
                                    }
                                    <ErrorMessageField name="phone" component="div" />
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <label style={{ fontSize: '1rem', opacity: 0.6 }}>Correo Electrónico </label>

                                    <Input
                                        id='email'
                                        name='email'
                                        onChange={e => {
                                            seterror(prev => ({ ...prev, email: false }))
                                            handleChange(e)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        style={{ height: 40 }}
                                        size='large'
                                        status={(errors.email || error.email) && 'error'}
                                        placeholder='Ingrese su correo electrónico'
                                    />
                                    {
                                        error.email
                                            ?
                                            <ErrorMessageForm>
                                                Ya existe una cuenta con este correo electrónico
                                            </ErrorMessageForm>
                                            :
                                            <ErrorMessageField name="email" component="div" />
                                    }
                                </div>

                                <div style={{ display: 'flex', gap: 16 }}>
                                    <Checkbox onChange={onChangeCheck} checked={termsConditions}></Checkbox>
                                    <label onClick={handleClickTerms} style={{ opacity: 0.7, cursor: 'pointer', textDecoration: 'underline' }}>Acepto Términos y Condiciones</label>
                                </div>
                                <div style={classes.buttons} >
                                    <Button loading={loading} disabled={!isValid || !termsConditions || error.email || error.phone} style={{ ...classes.button, width: 'auto' }} type='primary' size='large' onClick={handleSubmit}>
                                        Registrarse
                                    </Button>
                                    <Button style={{ ...classes.button, width: 'auto' }} type='link' size='large' onClick={() => goTo('/login')}>
                                        Volver al Inicio de sesión
                                    </Button>
                                </div>
                            </section>
                        )}
                    </Formik>
                </form>
            </div>
            <Modal
                visible={modal.isOpen}
                footer={null}
                onCancel={handleCloseModal}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 30 }}
            >
                {
                    modal.option === 'terms' ? (
                        <TermsConditions />
                    ) : (
                        <ResponseForm response={response} closeSuccess={handleCloseModalSuccess} close={handleCloseModal} />
                    )
                }
            </Modal>
        </div>
    );
}

const classes = {
    button: {
        textTransform: 'capitalize',
        width: '50%',
        height: '50px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    main: {
        height: '100vh',
        minHeight: '100vh',
        display: 'flex',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // background: '#222',
        minwidth: 400,
        overflowX: 'hiden',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red'
    },
    root: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        height: 'auto',
        overflowY: 'auto',
        background: '#fafafa',
        borderRadius: '3%',
        display: 'flex',
        flexDirection: 'flex',
        justifyContent: 'center',
        padding: '20px',
        margin: '10%'
    },
    logo: {
        width: 100,
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
    a: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginBottom: 20
    },
    btnFacebook: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
}

