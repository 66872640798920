import React, { useState } from 'react'
import { requestChangePassword } from '../../../utils/api'
import { Button, Typography, Input, Form } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

export const RequestPassword = ({ setOpen }) => {

    const [request, setrequest] = useState(false)
    const [currentPassword, setcurrentPassword] = useState('')
    const [passwordError, setpasswordError] = useState(false)
    const [alredySent, setalredySent] = useState(false)
    const [empty, setempty] = useState(false)

    const handleChangePassword = e => {
        setalredySent(false)
        setempty(false)
        setpasswordError(false)
        setcurrentPassword(e.target.value)
    }

    const requestPassword = () => {
        if (currentPassword !== '' && !passwordError) {

            requestChangePassword(currentPassword)
                .then(response => {
                    if (response.status === 200) {
                        setrequest(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.data === 'Ya se ha enviado un codigo de verificacion activo') {
                        setalredySent(true)
                    } else {
                        setpasswordError(true)
                    }
                })
        } else {
            setempty(true)
        }
    }
    const handleClose = () => {
        setOpen(false)
        setrequest(false)
    }


    return (
        <section style={{ padding: '23px 30px', width: '100%' }}>
            <Typography.Title level={4} style={{ marginBottom: 20, textAlign: 'center' }}>Cambio de contraseña</Typography.Title>
            {
                request ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <CheckCircleOutlined style={{ fontSize: 70, marginBottom: 15 }} />
                        <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: 10 }} >Solicitud para cambiar contraseña realizada correctamente</Typography.Title>
                        <Typography.Text style={{ textAlign: 'center', marginBottom: 10 }}>Revise su correo electrónico o número de celular y siga las instrucciones para establecer su nueva contraseña.</Typography.Text>
                        <Typography.Text style={{ textAlign: 'center', marginBottom: 10 }}>NOTA: revise su correo basura o spam</Typography.Text>
                        <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                            <Button type='primary' size='large' onClick={handleClose} >Cerrar</Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Form.Item
                            validateStatus={(passwordError || empty || alredySent) ? 'error' : ''}
                            help={
                                passwordError
                                    ? "La contraseña actual es incorrecta"
                                    : empty
                                        ? "Ingrese su contraseña actual"
                                        : alredySent
                                            ? 'Ya se ha enviado un código de verificación a esta cuenta'
                                            : "Ingrese su contraseña actual"
                            }
                        >

                            <Input.Password
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        requestPassword()
                                    }
                                }}
                                size='large'
                                placeholder="Contraseña actual"
                                onChange={handleChangePassword}
                            />
                        </Form.Item>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                            <Button type='link' style={{ marginRight: 15 }} disabled={!currentPassword || passwordError || alredySent} onClick={requestPassword}>
                                Continuar
                            </Button>
                            <Button type='primary' style={{ padding: '0px 20px' }} onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>
                        </div>
                    </div>)
            }

        </section>
    )
}
