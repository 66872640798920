import { Button, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { apiGetVotedProjects } from '../../../utils/api'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { LikeFilled } from '@ant-design/icons'
import { prMain } from '../../../themeConfig'

export const MyVotes = ({ filter, clickSearch }) => {

    const { width, height } = useScreenSize()

    const columns = [
        {
            title: 'Proyecto',
            key: 'projectName',
            //sorter: (a, b) => a.projectName.localeCompare(b.projectName),
            render: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem' }}>
                    <Tooltip title={row.projectName}>
                        <div style={{ inlineSize: '85%', overflowWrap: 'break-word' }}>
                            {row.projectName}
                        </div>
                    </Tooltip>

                    <Tooltip title={"Votado"}>
                        {/* <NavLink to={{ pathname: '/apoyos/detalles', state: row }}> */}
                        <Button type='link' icon={<LikeFilled style={{ color: prMain }} />}>
                        </Button>
                        {/* </NavLink> */}
                    </Tooltip>
                </div>
            )
        }, {
            title: 'Periodo',
            dataIndex: 'period',
            key: 'period',
            width: '20%',
            //sorter: (a, b) => a.period - b.period,
            render: period => (
                <div>
                    {period}
                </div>
            )
        }
    ]

    const [loading, setLoading] = useState(false)
    const [fulldata, setfulldata] = useState([{ Zona: [] }, { General: [] }])
    const [data, setdata] = useState({
        general: [],
        zone: []
    })

    useEffect(() => {
        setLoading(true)
        // setfilter("")
        let userVotes = JSON.parse(sessionStorage.getItem('userVotes'))

        apiGetVotedProjects(userVotes.curp).then((response) => {
            setdata(prev => ({ ...prev, general: response.data[1].General, zone: response.data[0].Zona }))
            setfulldata(response.data)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    useEffect(() => {

        if (filter.length === 0) {
            setdata(prev => ({ ...prev, general: fulldata[1].General, zone: fulldata[0].Zona }))


        } else {
            const search = filter.split(' ').filter(item => item !== '');

            const dataFilterGeneral = fulldata[1].General.filter(item => {
                return (search.every(word => item.projectName.toLowerCase().includes(word.toLowerCase())))
            })
            const dataFilterZone = fulldata[0].Zona.filter(item => {
                return (search.every(word => item.projectName.toLowerCase().includes(word.toLowerCase())))
            })

            setdata(prev => ({ ...prev, general: dataFilterGeneral, zone: dataFilterZone }))

        }
    }, [clickSearch])

    useEffect(() => {
        if (filter) return

        setdata(prev => ({ ...prev, general: fulldata[1].General, zone: fulldata[0].Zona }))

    }, [filter])

    return (
        <div>
            <div style={{ opacity: .8, fontSize: '0.9rem', textAlign: 'left' }}>Proyectos De Mi Zona</div>
            <Table
                rowKey={record => record.idBudgetParticipatory}
                loading={loading}
                columns={columns}
                dataSource={data.zone}
                size="small"
                scroll={{ y: height > 620 ? '20vh' : '18vh', x: width > 760 ? '100%' : '100%' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />

            <div style={{ opacity: .8, fontSize: '0.9rem', marginTop: '1rem', textAlign: 'left' }}>Proyectos Generales</div>
            <Table
                rowKey={record => record.idBudgetParticipatory}
                loading={loading}
                columns={columns}
                dataSource={data.general}
                size="small"
                scroll={{ y: height > 620 ? '20vh' : '18vh', x: width > 760 ? '100%' : '100%' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
        </div>
    )
}
