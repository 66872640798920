import React, { useEffect, useState } from "react";
import { Input, Select, Button, Typography, Form } from 'antd'
import { openMessage } from "../Utils/MessageDisplay";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getAnalytics, logEvent } from "firebase/analytics";
import { apiAddUsers, apiDependencies, apiReportType, apiRoles } from "../../../utils/api";
import { useSelector } from "react-redux";
import { cammelCase } from "../../../utils/cammelCase";
import isAllowed, { ROLES_DIVISIONS } from "../../../layout/roles";
const { Option } = Select;


const classes = {

    container: {
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    div: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'space-between',
    },
    textfield: {
        width: '45%',
        marginBottom: '40px',
    },
    mailfield: {
        width: '100%',
        marginBottom: '40px',

    },
    formcontrol: {
        width: '45%',
        marginBottom: '40px',

    },
    button: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    notification: {
        width: '450px',
        height: '100%',
        padding: '30px',
    },
    title: {
        textAlign: 'center',
        marginBottom: 10,
        fontSize: 20,
        marginTop: -40,
        opacity: .8
    },
    subtitle: {
        textAlign: 'center',
        marginBottom: 20,
        fontSize: '15px',
        opacity: .8

    },
    checkIcon: {
        fontSize: '75px',
        marginLeft: '40%',
        marginBottom: '20px',

    },
}


const AddUser = ({ pathname, isClosable, handleClose, update, setUpdate }) => {

    const { user } = useSelector(state => state.session)

    const analytics = getAnalytics()
    const [roles, setRoles] = useState([]);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [userType, setUserType] = useState(pathname === '/ciudadanos' ? 4 : user.roles[0].id === 3 ? 5 : null);
    const [dependencies, setDependencies] = useState([]);
    const [servicesData, setservicesData] = useState([])
    const [servicesUser, setservicesUser] = useState([])
    const [dependency, setDependency] = useState(null);
    const [isAdded, setIsAdded] = useState(false);
    const [failedEmail, setfailedEmail] = useState(false)
    const [failedPhone, setfailedPhone] = useState(false)
    const [invalidPhone, setInvalidphone] = useState(false)
    const [failedPhoneExist, setfailedPhoneExist] = useState(false)
    const [loading, setloading] = useState(false)
    const [loadings, setloadings] = useState({
        isLoadingRoles: false,
        isLoadingDependencies: false,
        isLoadingServices: false
    })

    useEffect(() => {
        setloadings({ ...loadings, isLoadingRoles: true, isLoadingDependencies: true, isLoadingServices: true })

        apiRoles()
            .then((response) => {
                if(isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)) {
                    let array = []
                    response.data.forEach((item) => {
                        if (item.id === 7 || item.id === 8) {
                            array.push(item)
                        }
                    })
                    setRoles(array)
                } else {
                    setRoles(response.data);
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
                setloadings({ ...loadings, isLoadingRoles: false })
            })

        apiDependencies()
            .then((response) => {
                setDependencies(response.data)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setloadings({ ...loadings, isLoadingDependencies: false })
            })


    }, [])

    useEffect(() => {
        setservicesUser([])
        setloadings({ ...loadings, isLoadingServices: true })
        apiReportType()
            .then(response => {
                setservicesData(response.data.filter(i => i.dependency.id === dependency))
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setloadings({...loadings, isLoadingServices: false })
            })

    }, [dependency])

    const handleChangeServices = (event) => {
        setservicesUser(event)
    }

    const handleName = (event) => {
        setName(cammelCase(event.target.value));
    }

    const handleLastName = (event) => {
        setLastName(cammelCase(event.target.value));
    }

    const [failedEmailFormat, setfailedEmailFormat] = useState(false)

    const handlePhone = (e) => {
        setInvalidphone(false)
        setfailedPhone(false)
        setfailedPhoneExist(false)
        const re = /^[0-9]*$/
        if (e.target.value.length > 0) {
            if (re.test(e.target.value)) {
                if (e.target.value.length > 10) {
                    e.target.value = e.target.value.substring(0, e.target.value.length - 1)
                }
            } else {
                e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            }
        }
        setPhone(e.target.value);
    }

    const handleEmail = (event) => {
        setfailedEmail(false);
        setfailedEmailFormat(false);
        setEmail(event.target.value);
        const re =
            /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

        if (event.target.value.length > 0) {

            if (re.test(event.target.value)) {
                setfailedEmail(false);
            } else {
                setfailedEmailFormat(true);
            }
        }
    }

    const handleDependency = (event) => {
        setDependency(event);
    }

    const handleUserType = (event) => {
        setservicesUser([])
        setUserType(event);
    }

    const handleAddUser = () => {

        if (!name || !lastName || !userType || !phone || failedEmailFormat || failedPhone) {
            openMessage({ type: 'warning', message: 'Por favor llene todos los campos' })

        } else if (((userType === 3 || userType === 5) && !dependency && user.roles[0].id !== 3)) {
            openMessage({ type: 'warning', message: 'Por favor seleccione una dependencia' })
        } else {

            setloading(true)
            const data = {
                email: email ? email : null,
                lastName: lastName,
                name: name,
                rolesId: [userType],
                dependencyId: user.roles[0].id === 3 ? user.govDependency.id : (userType === 3 || userType === 5) ? dependency : null,
                phone,
                servicesId: servicesUser
            }
            apiAddUsers(data)
                .then((response) => {
                    logEvent(analytics, 'add_user', {
                        'user_id': response.data.id,
                        'user_type': response.data.rolesId[0].name,
                        'dependency_id': response.data.dependencyId,
                        'email': response.data.email,
                    })
                    setIsAdded(true);
                    isClosable(false)
                }).catch((error) => {
                    console.log(error);
                    if (error.response.data.includes('El correo Electrónico ya a sido registrado')) {
                        setfailedEmail(true);
                    } else if (error.response.data.includes('Este Teléfono ya fue Registrado')) {
                        setfailedPhoneExist(true);
                    } else if (error.response.data.includes('No se puede enviar mensaje a este número de teléfono')) {
                        setInvalidphone(true);
                    } else {
                        openMessage({ type: 'error', message: error.response.data })
                    }
                }).finally(() => {
                    setloading(false)
                })
        }
    }
    const handleOk = () => {
        handleClose()
        setUpdate(!update)
        setIsAdded(false);
        isClosable(true)
        setName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setUserType(null)
        setDependency(null)

    }

    return (
        <div>
            {!isAdded && <>
                <div style={classes.container}>
                    <div style={classes.div}>
                        <Form.Item
                            style={classes.textfield}
                            help={
                                (!name && 'Por favor, llene este campo') ||
                                (name && 'Ingrese el nombre')
                            }
                        >
                            <Input value={name} onChange={handleName} placeholder="Nombre(s)" size="medium" />
                        </Form.Item>
                        <Form.Item
                            style={classes.textfield}
                            help={
                                (!lastName && 'Por favor, llene este campo') ||
                                (lastName && 'Ingrese el apellido')
                            }
                        >
                            <Input value={lastName} onChange={handleLastName} placeholder="Apellido(s)" size="medium" />
                        </Form.Item>
                    </div>
                    <div style={classes.div}>
                        {
                            user.roles[0].id === 3 || pathname === '/ciudadanos'
                                ?
                                <div style={{ width: '45%', marginBottom: 20, marginTop: -10 }}>
                                    <div style={{ border: '1px solid #e3e3e3', padding: 5, paddingLeft: 10, borderRadius: 15 }}> {pathname === '/ciudadanos' ? 'Ciudadano' : 'Trabajador'}</div>
                                    <div style={{ color: '#8c8c8c' }}>Rol del usuario</div>
                                </div>
                                :
                                <Form.Item
                                    style={classes.formcontrol}
                                    help={
                                        (!userType && 'Por favor, llene este campo') ||
                                        (userType && 'Elija el tipo de usuario')
                                    }
                                >
                                    <Select loading={loadings.isLoadingRoles} value={userType} onChange={handleUserType} placeholder='Tipo de Usuario'>
                                        {
                                            user.roles[0].id === 3
                                                ?
                                                roles.map((item) => (
                                                    item.id === 5 &&
                                                    <Option key={item.id} value={item.id}>{item.description}</Option>
                                                ))
                                                :
                                                user.roles[0].id === 1
                                                    ?
                                                    roles.map((item) => (
                                                        <Option key={item.id} value={item.id}>{item.description}</Option>
                                                    ))
                                                    :
                                                    user.roles[0].id === 2
                                                        ?
                                                        roles.map((item) => (
                                                            (item.id !== 1) &&
                                                            <Option key={item.id} value={item.id}>{item.description}</Option>
                                                        ))
                                                        :
                                                        roles.map((item) => (
                                                            (item.id !== 4 && item.id !== 1) &&
                                                            <Option key={item.id} value={item.id}>{item.description}</Option>
                                                        ))
                                        }
                                    </Select>
                                </Form.Item>
                        }
                        {
                            (user.roles[0].id !== 3)
                                ?
                                <>
                                    {
                                        (userType === 3 || userType === 5) &&
                                        <Form.Item
                                            style={classes.formcontrol}
                                            help={
                                                (!dependency && 'Por favor, llene este campo') ||
                                                (dependency && 'Elija la dependencia')
                                            }
                                        >
                                            <Select loading={loadings.isLoadingDependencies} value={dependency} onChange={handleDependency} placeholder='Dependencia'>
                                                {
                                                    user.roles[0].id === 3
                                                        ?
                                                        dependencies.map((item) => (
                                                            item.id === user.govDependency.id &&
                                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                                        ))
                                                        :
                                                        dependencies.map((item) => (
                                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                                        ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    }
                                </>
                                :
                                <div style={{ width: '45%', marginBottom: 20, marginTop: -10 }}>
                                    <div style={{ border: '1px solid #e3e3e3', padding: 5, paddingLeft: 10, borderRadius: 15 }}> {user.govDependency.name}</div>
                                    <div style={{ color: '#8c8c8c' }}>Dependencia</div>
                                </div>
                        }
                    </div>
                    {
                        userType === 3 && dependency &&
                        <div style={classes.div}>
                            <div style={classes.formcontrol}></div>
                            <Form.Item
                                style={classes.formcontrol}
                                help={
                                    (servicesUser && 'Elija los servicios')
                                }
                            >
                                <Select
                                    placeholder='Servicios'
                                    value={servicesUser}
                                    onChange={handleChangeServices}
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    allowClear
                                >
                                    {
                                        servicesData.map(item => (
                                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    }
                    <div style={classes.div}>
                        <Form.Item
                            style={classes.textfield}
                            validateStatus={(failedPhone || failedPhoneExist || invalidPhone) ? 'error' : ''}
                            help={
                                (failedPhoneExist && 'Este teléfono ya se encuentra asociado a otra cuenta') ||
                                (failedPhone && 'Formato de teléfono incorrecto') ||
                                ((phone || !phone) && 'Por favor, ingrese el numero de teléfono') ||
                                (invalidPhone && 'No es posible enviar mensaje a este numero de teléfono')
                            }
                        >
                            <Input addonBefore="(+52)" value={phone} onChange={handlePhone} placeholder="Teléfono" size="medium"
                            />
                        </Form.Item>
                        <Form.Item
                            style={classes.textfield}
                            validateStatus={(failedEmail || failedEmailFormat) ? 'error' : ''}
                            help={
                                ((userType === 3 && !email) && 'Por favor, ingrese el correo electrónico') ||
                                (failedEmail && 'Correo ya registrado') ||
                                (failedEmailFormat && 'Formato de correo incorrecto') ||
                                (email && 'Correo electrónico')
                            }
                        >
                            <Input value={email} onChange={handleEmail} placeholder="Correo" size="medium"
                            />
                        </Form.Item>
                    </div>
                    <div style={classes.button}>
                        <Button
                            loading={loading}
                            onClick={handleAddUser}
                            style={{ width: '100%', fontWeight: 'bold' }}
                            type="primary"
                            disabled={ (phone.length < 10 || !phone || failedEmail || failedPhone || !name || !lastName || !userType || failedEmailFormat || invalidPhone)}
                        >
                            Guardar
                        </Button>
                    </div>
                </div>
            </>}

            {isAdded && <>
                <div style={classes.notification}>
                    <Typography style={classes.title} variant="h5">Usuario creado correctamente.</Typography>
                    <Typography style={classes.subtitle} >Revise los mensajes de texto o el correo, ahí encontrará su contraseña temporal</Typography>
                    <Typography style={classes.subtitle} >NOTA: El correo puede estar en la sección de correo no deseado</Typography>
                    <CheckCircleOutlined style={classes.checkIcon} /> <br />
                    <Button type='link' onClick={handleOk} style={{ marginRight: 'auto', marginLeft: 'auto', width: '100%' }}>Continuar</Button>
                </div>
            </>}
        </div>
    )
}

export default AddUser;