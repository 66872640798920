import React, { useEffect } from "react";
import { ReportDetails } from "./ReportDetails";
import { useState } from "react";
import { Table, Button, Typography, Modal, Tooltip } from 'antd'
import { CloseCircleOutlined, UserAddOutlined, EditOutlined, RetweetOutlined, ContainerOutlined, WarningOutlined } from "@ant-design/icons";
import HighPriority from "../../../asserts/HighPriority.svg";
import MediumPriority from "../../../asserts/MediumPriority.svg";
import LowPriority from "../../../asserts/LowPriority.svg";
import { ExportReports } from "./ExportReports";
import moment from "moment";
import { prMain } from "../../../themeConfig";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { useSelector } from 'react-redux';


const classes = {
    dialogTitle: {
        color: prMain,
        fontWeight: 'bold',
        textAlign: 'right',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '20px'
    },
    buttons: {
        width: '75%',
        margin: '0 auto 20px auto',
    },
    button: {
        width: '100%',
        marginTop: 20,
        textTransform: 'capitalize'
    },
    twoButtons: {
        width: '50%',
        margin: '15px',
        marginBottom: '0px',
        textTransform: 'capitalize'
    },
}

const DisplayedList = (props) => {

    const { statusReport, totalData, page, setpage, pageSize, setpageSize, order, setorder, status, dataType, dataUsers, from, to, userOption, refreshExcel, isFilter } = props;
    const [dataRow, setDataRow] = useState([]);
    const [openDetails, setOpenDetails] = useState(false);
    const { height } = useScreenSize()
    const { user } = useSelector(state => state.session);

    const handleDetails = (data) => {
        setOpenDetails(true);
        setDataRow(data);
    }

    const handleCloseDetails = (typeNotification) => {
        setOpenDetails(false);
    }

    const priority =
    {
        title: 'Prioridad',
        key: 'priority',
        width: '7%',
        render: row => {
            if (row.status.id !== 4) {
                switch (row.priority) {
                    case 'Alta':
                        return (<Tooltip title="Alta" placement="bottom" arrow>
                            <img src={HighPriority} alt='Prioridad Alta'></img>
                        </Tooltip>)
                    case 'Media':
                        return (<Tooltip title="Media" placement="bottom" arrow>
                            <img src={MediumPriority} alt='Prioridad Media'></img>
                        </Tooltip>)
                    case 'Baja':
                        return (<Tooltip title="Baja" placement="bottom" arrow>
                            <img src={LowPriority} alt='Prioridad Baja'></img>
                        </Tooltip>)
                    case 'Urgente':
                        return (<Tooltip title="Urgente" placement="bottom" arrow>
                            <WarningOutlined style={{ color: prMain, marginLeft: 3 }} />
                        </Tooltip>)
                    default:
                        return (<Tooltip title="Alta" placement="bottom" arrow>
                            <img src={HighPriority} alt='Prioridad Alta'></img>
                        </Tooltip>)
                }
            }
        },
        ellipsis: {
            showTitle: true
        }
    }

    const columns = [

        {
            title: 'Folio',
            dataIndex: 'id',
            key: 'id',
            width: '7%',
            sorter:  (a, b) => a.id.toString().localeCompare(b.id.toString()),
            sortDirections: ["ascend", "descend"],
            ellipsis: {
                showTitle: true
            },
            render: name => (
                <Tooltip title={name}>
                    {name}
                </Tooltip>
            )
        },
        {
            title: 'Tipo',
            dataIndex: 'servicesDto',
            key: 'servicesDto',
            render: text => (
                <Tooltip title={text.name}>
                    {text.name}
                </Tooltip>
            ),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Creado por',
            dataIndex: 'createBy',
            key: 'createBy',
            render: createBy => (
                createBy &&
                <Tooltip title={createBy.name}>
                    {createBy.name}
                </Tooltip>
            ),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Asignado por',
            dataIndex: 'assignedBy',
            key: 'assignedBy',
            render: assignedBy => (
                assignedBy &&
                <Tooltip title={assignedBy.name + ' ' + assignedBy.lastName}>
                    {assignedBy.name} {assignedBy.lastName}
                </Tooltip>
            ),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Encargado',
            dataIndex: 'user',
            key: 'user',
            render: text => (
                text &&
                <Tooltip title={text.name + ' ' + text.lastName}>
                    {text.name} {text.lastName}
                </Tooltip>
            ),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Fecha',
            dataIndex: 'createdAt',
            key: 'created_at',
            render: (text) => (
                <Tooltip title={moment(text).format('DD/MM/YYYY hh:mm a')}>
                    {moment(text).format('DD/MM/YYYY hh:mm a')}
                </Tooltip>
            ),
            sorter:  (a, b) => a.createdAt.toString().localeCompare(b.createdAt.toString()),
            sortDirections: ["ascend", "descend"],
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Acciones',
            width: '7%',
            render: (row) => (<>
                {row.status.id === 1 && <Button onClick={() => handleDetails(row)} icon={<UserAddOutlined />} ></Button>}
                {row.status.id === 2 && <Button onClick={() => handleDetails(row)} icon={<EditOutlined />}></Button>}
                {row.status.id === 3 && <Button onClick={() => handleDetails(row)} icon={<RetweetOutlined />}></Button>}
                {row.status.id === 4 && <Button onClick={() => handleDetails(row)} icon={<ContainerOutlined />}></Button>}

            </>),
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Estatus',
            width: '7%',
            render: (row) => {
                if (row.status.id !== 4) {
                    return row.expired ? 'Expirado' : 'Vigente'
                }
            },
            ellipsis: {
                showTitle: true
            }
        }

    ]


    const [refresh, setrefresh] = useState(false)


    const handleTable = (pagination, filters, sorter) => {
        props.setloadingReports(true)
        setpage(pagination.current)
        setpageSize(pagination.pageSize)
        setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : null }))
    }

    useEffect(() => {
        props.getDataReport()
    }, [page, pageSize, order.key, order.order, refresh])

    const getDataReports = () => {
        setrefresh(prev => !prev)
    }


    return (
        <div style={{ height: '100%', width: '100%', paddingBottom: 20 }}>
            <Table
                onChange={handleTable}
                rowKey={record => record.id}
                loading={props.loadingReports}
                columns={status === 4 ? columns : [priority, ...columns]}
                dataSource={props.data}
                size="small"
                scroll={{ y: height > 940 ? '49vh' : '39vh' }}
                pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />

            {
                user.roles[0].id === 2 
                ? <div style={{ marginTop: -50 }}>
                    <ExportReports
                        owner={props.owner}
                        statusReport={statusReport}
                        status={status}
                        dataType={dataType}
                        dataUsers={dataUsers}
                        fromDate={from}
                        toDate={to}
                        userOption={userOption}
                        refreshExcel={refreshExcel}
                        isFilter={isFilter}
                    />
                    </div>
                : <div></div>
            }
            {
                (dataRow && openDetails) &&
                <Modal
                    centered
                    visible={openDetails}
                    onCancel={handleCloseDetails}
                    closable={true}
                    closeIcon={<CloseCircleOutlined onClick={handleCloseDetails} style={{ color: 'red' }} />}
                    title={
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {dataRow.length !== 0 &&
                                <Typography style={classes.dialogTitle} >
                                    {dataRow.status.id === 1 && 'Pendiente'}
                                    {dataRow.status.id === 2 && 'En proceso'}
                                    {dataRow.status.id === 3 && 'En revisión'}
                                    {dataRow.status.id === 4 && 'Solucionado'}
                                    {(dataRow.reasonForDelete === 'No reason found' && !dataRow.deletedAt) ? "" : " (Solicitado para completar)"}
                                </Typography>}
                        </div>
                    }
                    footer={null}
                >
                    <ReportDetails refreshReports={props.refreshReports} setRefresh={setrefresh} getDataReport={getDataReports} changeStatus={props.changeStatus} setChangeStatus={props.setChangeStatus} data={dataRow} onCloseDetails={handleCloseDetails} />

                </Modal>
            }

        </div >

    )
}

export default DisplayedList;