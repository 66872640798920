import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';
import Sidebar from '../Sidebar';
import DisplayedMap from './reports/DisplayedMap';
import PrivateRoute from '../PrivateRoute';
import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';
import { sessionActions } from '../../store';
import { useHistory } from 'react-router';
import Dashboard from './dashboard/Dashboard';

import UsersListPage from './users/UsersListPage';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { ProfilePage } from './profile/ProfilePage';
import { NotificationsPage } from '../notifications/NotificationsPage';
import { Button, Spin, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, MenuOutlined } from '@ant-design/icons';
import './MainPage.css';
import { prMain } from '../../themeConfig';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getPerformance, trace } from 'firebase/performance';
import Modal from 'antd/lib/modal/Modal';
import { apiNotifications } from '../../utils/api';
import { CreateReport } from './reports/Create/CreateReport';
import { SettingsPage } from './settings/SettingsPage';
import { CitizensPage } from './citizens/CitizensPage';
import { RequestsPage } from './requests/RequestsPage';
import ScholarshipsPage from './scholarships/ScholarshipsPage';
import ScholarshipsDetails from './scholarships/ScholarshipsDetails';
import EventsPage from './Events/EventsPage';
import { BudgetTabs } from './participatoryBudget/BudgetTabs';
import { CRMPage } from './CRM/CRMPage';
// import { CRMPage } from './CRM/CRMPage';
// import VotesPage from './votes/VotesPage';

const MainPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const perf = getPerformance()
  const { user } = useSelector((state) => state.session);
  const initialized = useSelector(state => !!state.session.user);
  const analytics = getAnalytics()

  if (user.roles === []) {
    history.push('/login');
    dispatch(sessionActions.updateUser({}));
    dispatch(sessionActions.updateToken(null));
    sessionStorage.removeItem('user');
  }

  const { update, updateNotifications } = useSelector(state => state.notifications)
  const { tokenExpired } = useSelector(state => state.session)
  const [notifications, setnotifications] = useState([])

  const getNotifications = useCallback(async () => {

    apiNotifications().then((response) => {
      setnotifications(response.data);
    }).catch((error) => {
      console.log(error);
    })

  }, [user.roles])

  useEffect(() => {
    const t = trace(perf, 'notifications_load')
    t.start()
    getNotifications()
    t.stop()

  }, [update, updateNotifications])

  useEffect(() => {
    // const fetchDataAdmin = async () => {
    //   await fetchEventSource(`/api/report/admin/notification`, {
    //     method: "GET",
    //     headers: {
    //       Accept: "text/event-stream",
    //     },
    //     onopen(res) {
    //       if (res.ok && res.status === 200) {
    //         console.log("Connection made admin");
    //         dispatch(notificationsActions.update());
    //       } else if (
    //         res.status >= 400 &&
    //         res.status < 500 &&
    //         res.status !== 429
    //       ) {
    //         console.log("Client side error ", res);
    //       }
    //     },
    //     onmessage(event) {
    //       if (event.data && !isAllowed(user, ROLES_DIVISIONS.CAPTURIST)) {
    //         const parsedData = JSON.parse(event.data);
    //         openNotification({
    //           type: 'info',
    //           message: `${parsedData.notificationName}`,
    //           description: <NotificationDescription data={parsedData} description={parsedData.notificationDescription} />,
    //           placement: 'topRight',
    //           idReport: parsedData.idReport
    //         })
    //         if (parsedData.notificationType !== 8) {
    //           dispatch(notificationsActions.update());
    //         } else{
    //           dispatch(notificationsActions.updateNotification());
    //         }
    //         logEvent(analytics, 'notification_received')
    //       }
    //     },
    //     onclose() {
    //       console.log("Connection closed by the server");
    //     },
    //     onerror(err) {
    //       console.log("There was an error from server", err);
    //     },
    //   });
    // };
    // const fetchDataManager = async () => {
    //   await fetchEventSource(`/api/report/notification?userId=${user.id}`, {
    //     method: "GET",
    //     headers: {
    //       Accept: "text/event-stream",
    //     },
    //     onopen(res) {
    //       if (res.ok && res.status === 200) {
    //         console.log("Connection made manager");
    //         dispatch(notificationsActions.update());

    //       } else if (
    //         res.status >= 400 &&
    //         res.status < 500 &&
    //         res.status !== 429
    //       ) {
    //         console.log("Client side error ", res);
    //       }
    //     },
    //     onmessage(event) {
    //       if (event.data && !isAllowed(user, ROLES_DIVISIONS.CAPTURIST)) {
    //         const parsedData = JSON.parse(event.data);
    //         openNotification({
    //           type: 'info',
    //           message: `${parsedData.notificationName}`,
    //           description: <NotificationDescription data={parsedData} description={parsedData.notificationDescription} />,
    //           placement: 'topRight',
    //           idReport: parsedData.idReport
    //         })
    //         if (parsedData.notificationType !== 8) {
    //           dispatch(notificationsActions.update());
    //         }else{
    //           dispatch(notificationsActions.updateNotification());

    //         }
    //         logEvent(analytics, 'notification_received')
    //       }
    //     },
    //     onclose() {
    //       console.log("Connection closed by the server");
    //     },
    //     onerror(err) {
    //       console.log("There was an error from server", err);
    //     },
    //   });
    // };
    // (user.roles[0].id === 3)
    //   ? fetchDataManager()
    //   : (user.roles[0].id === 2 || user.roles[0].id === 1)
    //     ? fetchDataAdmin()
    //     : console.log()
  }, []);

  const [hideSideBar, sethideSideBar] = useState(false)

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: window.location.pathname,
    });

  }, [window.location.pathname])

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    window.location.replace('/')
  }

  return !initialized ? (<Spin />) : (
    <div style={classes.container} >
      {
        hideSideBar &&
        <Tooltip title='Abrir menú' placement='rightTop'>
          <MenuOutlined onClick={() => sethideSideBar(false)} style={{ cursor: 'pointer', color: prMain, margin: 5 }} />
        </Tooltip>

      }
      {
        !hideSideBar &&
        <Sidebar
          variant='permanent'
          open={true}
          notifications={notifications}
          sethideSideBar={sethideSideBar}
          hideSideBar={hideSideBar} />
      }
      <main style={classes.main} className='main' >
        <Switch>
          <PrivateRoute index path="/reportes/:idStatus?/:created?" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) || isAllowed(user, ROLES_DIVISIONS.CAPTURIST)} component={DisplayedMap} />
          <PrivateRoute path="/dashboard" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)} component={Dashboard} />
          <PrivateRoute path="/configuracion" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)} component={SettingsPage} />
          <PrivateRoute path="/crear-reporte/:idReport?" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.CAPTURIST) || isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) || (isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER))} component={CreateReport} />
          <PrivateRoute path="/usuarios" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)} component={UsersListPage} />
          <PrivateRoute path="/peticiones/:id?" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)} component={RequestsPage} />
          <PrivateRoute path="/ciudadanos" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)} component={CitizensPage} />
          <PrivateRoute path="/perfil" exact isAllowed={true} component={ProfilePage} />
          <PrivateRoute path="/notificaciones" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)} component={NotificationsPage} />
          <PrivateRoute path="/apoyos" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)} component={ScholarshipsPage} />
          <PrivateRoute path="/apoyos/detalles" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) || isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN)} component={ScholarshipsDetails} />
          <PrivateRoute path="/PresupuestoParticipativo" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN) || isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER)} component={BudgetTabs} />
          <PrivateRoute path="/crm" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.PURE_SUPERADMIN) || isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER) || (isAllowed(user, ROLES_DIVISIONS.PURE_MANAGEMENT) && user.service.some(i => i.name === 'CRM') )} component={CRMPage} />

          {/* <PrivateRoute path="/TableroPresupuestoParticipativo" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER)} component={BudgetDashboard}/> */}
          <PrivateRoute path="/Eventos" exact isAllowed={isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.EVENTS_MANAGER)} component={EventsPage} />
          {/* <PrivateRoute path="/votos" exact isAllowed={true} component={VotesPage} /> */}
          {
            isAllowed(user, ROLES_DIVISIONS.MANAGEMENT)
              ?
              <Route path="/">
                <Redirect to="/reportes" />
              </Route>
              : (isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR))
                ? <Route path="/">
                  <Redirect to="/apoyos" />
                </Route>
                : isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER)
                  ? <Route path="/">
                    <Redirect to="/PresupuestoParticipativo" />
                  </Route>
                  : isAllowed(user, ROLES_DIVISIONS.EVENTS_MANAGER)
                    ? <Route path="/">
                      <Redirect to="/Eventos" />
                    </Route>
                    : isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER)
                      ? <Route path="/">
                        <Redirect to="/crm" />
                      </Route>
                      :
                      <Route path="/">
                        <Redirect to="/crear-reporte" />
                      </Route>
          }
        </Switch>
      </main>
      <Modal
        visible={tokenExpired}
        closable={false}
        centered={true}
        footer={null}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100', flexDirection: 'column' }}>
          <InfoCircleOutlined style={{ color: prMain, marginBottom: 15, fontSize: 40 }} />
          <Typography.Title level={3} style={{ textAlign: 'center' }}>La sesión ha expirado</Typography.Title>
          <Typography.Text style={{ textAlign: 'center', fontSize: 20, marginBottom: 30, marginTop: 10 }}>Por favor, inicie sesión nuevamente</Typography.Text>
          <Button onClick={handleLogout} type='primary' size='large'>Aceptar</Button>
        </div>

      </Modal>
    </div>
  );
}

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'rows',
    height: '100vh',
    maxHeight: '100%',
    maxWidth: '100%',
    overFlow: 'auto',
    backgroundColor: '#f2f2f2',
  },
  main: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}

export default MainPage;
