import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReportDetails } from '../../reports/ReportDetails'
import { apiListRequestDelete } from '../../../../utils/api'
import { useHistory, useParams } from 'react-router-dom'
import { openMessage } from '../../Utils/MessageDisplay'

export const RequestReports = ({ refresh, setRefresh }) => {

    const history = useHistory()
    const [reports, setreports] = useState([])
    // const [refresh, setrefresh] = useState(false)
    const [loading, setloading] = useState(false)
    const [visible, setvisible] = useState({
        isVisible: false,
        reportSelected: {}
    })
    const { id } = useParams()

    useEffect(() => {
        setloading(true)
        apiListRequestDelete()
            .then(res => {
                setreports(res.data)
                if (id) {
                    let find = res.data.find(item => parseInt(item.id) === parseInt(id))

                    if (find) {
                        setvisible(prev => ({ ...prev, isVisible: true, reportSelected: find }))
                    } else{
                        openMessage({ type: 'warning', message: 'No se ha podido encontrar la petición' })
                        history.push('/peticiones')
                    }
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setloading(false)
            })
    }, [refresh])

    const handleClose = () => {
        setvisible(prev => ({ ...prev, isVisible: false, reportSelected: {} }))
        history.push('/peticiones')
    }

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            width: '10%'
        },
        {
            title: 'Dependencia',
            dataIndex: 'servicesDto',
            key: 'dependency',
            render: row => row.dependency.name
        },
        {
            title: 'Servicio',
            dataIndex: 'servicesDto',
            key: 'service',
            render: row => row.name
        },
        {
            title: 'Petición realizada por',
            dataIndex: 'requestedByName',
            key: 'requestedByName',
        },
        {
            title: 'Razón de modificación',
            dataIndex: 'reasonForDelete',
            key: 'reasonForDelete',
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: row => (
                <Tooltip title='Detalles'>
                    <Button type='link' icon={<InfoCircleOutlined onClick={() => setvisible(prev => ({ ...prev, isVisible: true, reportSelected: row }))} />} />
                </Tooltip>
            ),
            width: '10%'
        },
    ]


    return (
        <div>
            <Table
                rowKey={record => record.id}
                loading={loading}
                columns={columns}
                dataSource={reports}
                size="small"
                scroll={{ y: '60vh' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
            <div style={{ marginTop: -50 }}>
                <Button type='link' icon={<ReloadOutlined />} onClick={() => setRefresh(!refresh)}>Recargar datos</Button>
            </div>

            <Modal
                centered
                visible={visible.isVisible}
                closable={false}
                footer={null}
                onCancel={handleClose}>

                <ReportDetails setRefresh={setRefresh} data={visible.reportSelected} onCloseDetails={handleClose} />
            </Modal>

        </div>
    )
}
