import { Button, Form, Input, Radio, Result, Space, Typography } from 'antd'
import { useState } from 'react'
import { apiRequestAdmin } from '../../../utils/api';

export const ConfigDataUser = ({ data, isDone, setIsDone }) => {
    const { email, phone } = data
    const [value, setValue] = useState(email ? 'email' : 'phone');
    const [status, setstatus] = useState(1)
    const [failedEmailFormat, setfailedEmailFormat] = useState(false)
    const [failedPhone, setfailedPhone] = useState(false)
    const [failedPassword, setfailedPassword] = useState(false)
    const [failedValue, setfailedValue] = useState(false)
    const [loading, setloading] = useState(false)
    const [dataChange, setdataChange] = useState({
        password: '',
        email: '',
        phone: ''
    })

    const onChangeRadio = (e) => {
        setValue(e.target.value);
    };

    const handleChangeData = (e) => {
        setfailedPassword(false)
        setdataChange(prev => ({ ...prev, [e.target.name]: e.target.value }))

    }

    const handlePhone = (event) => {
        setfailedValue(false)
        setfailedPhone(false)
        setdataChange(prev => ({ ...prev, phone: event.target.value }))
        const re = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/

        if (event.target.value.length > 0) {
            if (re.test(event.target.value)) {
                setfailedPhone(false);
            } else {
                setfailedPhone(true);
            }
        }
    }

    const handleEmail = (event) => {
        setfailedValue(false)
        setfailedEmailFormat(false);
        setdataChange(prev => ({ ...prev, email: event.target.value }))
        const re =
            /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

        if (event.target.value.length > 0) {

            if (re.test(event.target.value)) {
                setfailedEmailFormat(false);
            } else {
                setfailedEmailFormat(true);
            }
        }
    }

    const handleRequestAdmin = () => {
        setloading(true)

        apiRequestAdmin(value, value === 'email' ? dataChange.email : dataChange.phone, dataChange.password, data.id)
            .then(response => {
                setstatus(3)
                setIsDone(true)
            })
            .catch(error => {
                console.log(error)
                if (error.response.status === 409) {
                    setfailedValue(true)
                } else {
                    setfailedPassword(true)
                }
            })
            .finally(() => {
                setloading(false)
            })
    }

    const handleBack = () => {
        setstatus(1)
        setIsDone(!isDone)
        setfailedEmailFormat(false)
        setfailedPhone(false)
        setfailedPassword(false)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
                status === 1 &&
                <>
                    <Typography style={{ marginBottom: 30, fontSize: 20, opacity: .7 }}>Seleccione el dato que desea cambiar</Typography>
                    <Radio.Group onChange={onChangeRadio} value={value}>
                        <Space direction="vertical">
                            {
                                email &&
                                <Radio value={'email'}> <Typography>Correo electrónico:</Typography> {email}</Radio>
                            }
                            {
                                phone &&
                                <Radio value={'phone'}><Typography>Número de teléfono:</Typography> {phone}</Radio>
                            }
                        </Space>
                    </Radio.Group>
                    <Button style={{ marginTop: 40 }} type='primary'
                        onClick={() => {
                            setstatus(2)
                            setIsDone(!isDone)
                        }}>Crear solicitud de cambio</Button>
                </>
            }
            {
                status === 2 &&
                <>
                    <Form.Item
                        style={{ marginBottom: 30 }}
                        validateStatus={failedPassword ? 'error' : ''}
                        help={
                            failedPassword ? 'Contraseña incorrecta'
                                : 'Ingrese su contraseña para continuar'
                        }
                    >
                        <Input.Password name='password' placeholder='Contraseña actual' onChange={handleChangeData} />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 30 }}
                        validateStatus={(failedEmailFormat || failedPhone || failedValue) ? 'error' : ''}
                        help={
                            (failedValue && `Este ${value === 'email' ? 'correo electrónico' : 'número de teléfono'} ya se encuentra registrado en otra cuenta`) ||
                            (failedEmailFormat && 'Correo electrónico inválido') ||
                            (failedPhone && 'Número de teléfono inválido') ||
                            `Ingrese el nuevo ${value === 'email' ? 'correo electrónico' : 'número de teléfono'}`
                        }
                    >
                        <Input name={value} onChange={value === 'email' ? handleEmail : handlePhone} placeholder={value === 'email' ? 'Correo electrónico' : 'Número de teléfono'} />
                    </Form.Item>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button loading={loading} onClick={handleRequestAdmin} disabled={(value === 'email' && !dataChange.email) || (value === 'phone' && !dataChange.phone) || !dataChange.password || failedEmailFormat || failedPhone || failedPassword} type='primary'>Solicitar</Button>
                        <Button disabled={loading} type='link' onClick={handleBack}>Regresar</Button>
                    </div>

                </>
            }
            {
                status === 3 &&
                <Result
                    status="success"
                    title={`Solicitud de cambio de ${value === 'email' ? 'correo electrónico' : 'número de teléfono'}`}
                    subTitle={`Se le ha enviado una notificación para cambiar su ${value === 'email' ? 'correo electrónico' : 'número de teléfono'} al usuario ${data.name}`}

                />
            }
        </div>
    )
}
