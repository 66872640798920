import React, { useState, useEffect, useCallback } from "react"
import VotesTable from "./VotesTable"
import { Button, Input, Spin } from 'antd'
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { logo, prMain } from "../../../themeConfig";
import { Register } from "./Register/Register";
import { useScreenSize } from "../../../hooks/useScreenSize";
import background from '../../../asserts/fondoStreets.png'
import moment from "moment";


const VotesPage = () => {

    const classes = {
        textfield: {
            // marginRight: '10%',
            backgroundColor: '#FFF',
            borderRadius: '20px 0 0 20px',
        },
        button: {
            width: 'auto',
            textTransform: 'capitalize',
        },
        box: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        searchButton: {
            color: '#fff',
            backgroundColor: prMain,
            height: 40,
            width: 40,
            borderRadius: '0 20px 20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-4px',
            marginLeft: '-2px'
        },
    }

    const { width, height } = useScreenSize()
    const [user, setUser] = useState()
    const [filter, setFilter] = useState("")
    const [clickSearch, setClickSearch] = useState(false)
    const [loading, setLoading] = useState(false)

    const getUserData = useCallback(() => {
        setLoading(true)

        let userVotes = JSON.parse(sessionStorage.getItem('userVotes'))
        console.log(userVotes)

        if (userVotes) {
            setUser(userVotes)
        }else{
            setUser(null)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        getUserData()
    }, [getUserData])

    const handleFilter = (event) => {
        setFilter(event.target.value);
    }

    const goToLogin = () => {
        sessionStorage.removeItem('userVotes')
        getUserData()
    }



    return (
        <div style={{ padding: `1rem ${width > 760 ? '4rem' : '1rem'}`, background: `url(${background})`, backgroundSize: 'cover', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <header>
                {
                    (width <= 760 && user) &&
                    <Button onClick={goToLogin} type='link' style={{padding: 0, marginTop: -10, alignSelf: 'flex-start'}}> <LeftOutlined style={{ marginRight: '0.3rem' }} />  Salir</Button>
                }
                {
                    user
                        ? <h2 style={{ fontSize: width > 760 ? '1.5rem' : '1.2rem', fontWeight: '700', opacity: 0.8, textAlign: 'center' }}>Votos Presupuesto Participativo</h2>
                        : <div></div>
                }
            </header>
            <Spin spinning={loading}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: width < 760 ? -40 : 0 }}>

                    {
                        !loading ? (
                            user ?
                                (
                                    <>
                                        <section style={{ ...classes.box, flexDirection: 'column', width: width > 1200 ? 1200 : '100%' }}>
                                            <div style={{ ...classes.box, width: '100%', flexDirection: width > 760 ? 'row' : 'column', gap: '2rem', alignItems: 'center' }}>
                                                {
                                                    width > 760 &&
                                                    <Button onClick={goToLogin} type='link'> <LeftOutlined style={{ marginRight: '0.3rem' }} />  Salir</Button>
                                                }
                                                <div style={{display: 'flex', flex: 1, textAlign: 'center'}}>
                                                    <Input onPressEnter={() => setClickSearch(!clickSearch)} value={filter} onChange={(e) => handleFilter(e)} style={{...classes.textfield, height: 30}} placeholder={`Buscar...`} size='large' allowClear />
                                                    <Button onClick={() => setClickSearch(!clickSearch)} type='text' icon={<SearchOutlined style={{...classes.searchButton, height: 30}} />}>
                                                    </Button>
                                                </div>
                                                {
                                                    width > 760 &&
                                                    <img onClick={goToLogin} src={logo} alt='Logo Delicias Touch' width={50} />
                                                }
                                            </div>
                                            <div style={{ width: '100%', height: height > 620 ? '70vh' : width < 760 ? '60vh' : '65vh', overflowY: 'auto' }}>
                                                <VotesTable setfilter={setFilter} filter={filter} clickSearch={clickSearch} />
                                            </div>
                                        </section>

                                    </>
                                ) : (
                                    <div>
                                        <Register getUserData={getUserData} />
                                    </div>
                                )
                        ) : (
                            <div style={{ width: '100%' }}>
                            </div>
                        )
                    }
                </div>
            </Spin>
            <footer>
                <hr style={{ opacity: 0.7 }} />
                <div> &copy; MVP Technology Solutions {moment().format('YYYY')} </div>
            </footer>
        </div>
    )
}

export default VotesPage