import React, { useState } from 'react'
import { prMain } from '../../../../themeConfig'
import { Reports } from './Reports'
import { ReopenReports } from './ReopenReports'
import { ReportsCalled } from './ReportsCalled'
import { Button } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

export const colors = [prMain, "#F3B415", "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#00A7C6", "#18D8D8", '#A9D794',
    '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29']

export const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 14,
        }}
        spin
    />
);
export const CRMDashboard = () => {

    // useEffect(() => {

    //     apiGetReOpenReports().then(res => {
    //         console.log(res.data)
    //     }).catch(err => {
    //         new Error(err)
    //     }) 

    //     apiGetReportSuccessfully().then(res => {
    //         console.log(res.data)
    //     }).catch(err => {
    //         new Error(err)
    //     })

    // }, [])

    const [refresh, setrefresh] = useState()

    return (
        <div style={{ height: '80vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div style={{ alignSelf: 'flex-end' }}>
                <Button type='link' icon={<ReloadOutlined />} onClick={() => setrefresh(prev => !prev)}>Recargar</Button>
            </div>
            <div style={{ width: '100%', height: '40%', display: 'flex', gap: '2rem' }}>
                <Reports refresh={refresh} setrefresh={setrefresh} />
                <ReportsCalled refresh={refresh} setrefresh={setrefresh} />
                {/* <CompleteReports /> */}
            </div>
            <div style={{ height: '45%', marginTop: '2rem' }}>

                <ReopenReports refresh={refresh} setrefresh={setrefresh} />
            </div>
        </div>
    )
}
