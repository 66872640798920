import React, { useEffect, useRef, useState } from 'react';
import { sessionActions } from '../../store';
import { useDispatch } from 'react-redux';
import { apiLogin } from '../../utils/api';
import { ForgotPassword } from '../forgotPasswod/ForgotPassword';
import { Input, Button, Modal, Form } from 'antd'
import { useScreenSize } from '../../hooks/useScreenSize';
import { openNotification } from './Utils/NotificationsDisplay';
import { getAnalytics, logEvent } from 'firebase/analytics';
// import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';
import { background, logo } from '../../themeConfig';
import isAllowed, { ROLES_DIVISIONS } from '../../layout/roles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const LoginPage = ({ status }) => {

  const history = useHistory()
  const [failed, setFailed] = useState(false);
  const [failedRol, setFailedRol] = useState(false);
  const [inactive, setinactive] = useState(false)
  const [userEmpty, setuserEmpty] = useState(false);
  const [passwordEmpty, setpasswordEmpty] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(false)
  const dispatch = useDispatch();
  const { width } = useScreenSize()
  const inputRef = useRef(null);
  const analytics = getAnalytics()

  const [openForgot, setOpenForgot] = useState(false)

  const handleCloseForgot = () => {
    setOpenForgot(false);
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    setFailed(false);
    if (email === '' || password === '') {
      if (email === '') {
        setuserEmpty(true);
      }
      if (password === '') {
        setpasswordEmpty(true);
      }
    } else {
      setloading(true)
      apiLogin(email, password).then(response => {

        if (response.status === 200) {
          const user = response.data;
          // if (user.roles[0].admin) {
          console.log(isAllowed(user, ROLES_DIVISIONS.EVENTS_MANAGER))
          if (isAllowed(user, ROLES_DIVISIONS.MANAGEMENT) ||
            isAllowed(user, ROLES_DIVISIONS.CAPTURIST) ||
            isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN) ||
            isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_VERIFICATOR) ||
            isAllowed(user, ROLES_DIVISIONS.BUDGET_MANAGER) ||
            isAllowed(user, ROLES_DIVISIONS.EVENTS_MANAGER) ||
            isAllowed(user, ROLES_DIVISIONS.CRM_MANAGER)
          ) {

            setFailed(false);
            dispatch(sessionActions.updateUser(user));
            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('drawerWidth', 230);
            dispatch(sessionActions.updateToken(user.token));
            dispatch(sessionActions.updateTokenExpired(false));


            logEvent(analytics, 'Login', {
              'email': user.email,
            })
            logEvent(analytics, 'session_start', {
              'email': user.email,
            })

          } else {
            setFailedRol(true)
            openNotification({
              type: 'error',
              message: 'No autorizado',
              description: 'El usuario no tiene permisos para acceder a esta web',
              placement: 'topLeft',
            });
          }
        } else if (response.status === 203) {
          setinactive(true)
          openNotification({
            type: 'error',
            message: 'No autorizado',
            description: 'El usuario no se encuentra activo',
            placement: 'topLeft',
          });

        } else {
          setPassword('');
        }
      }).catch(error => {
        setFailed(true);
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request)
        } else {
          console.log("Error : ", error.message)
        }
      }).finally(() => {
        setloading(false)
      })
    }

  }
  const handleEmailChange = (event) => {
    event.target.value = event.target.value.replace(/ /g, "")

    setEmail(event.target.value);
    setinactive(false)
    setuserEmpty(false)
    setFailedRol(false);
    setFailed(false)
  }

  const handlePasswordChange = (event) => {
    setinactive(false)
    setpasswordEmpty(false)
    setFailed(false)
    setPassword(event.target.value);
  }

  const goTo = (path) => {
    history.push(path)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [])

  return (
    <div style={classes.main} direction="coloum">
      <div style={classes.image}></div>
      <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '30vw' }}>
        <form style={{ width: '100%' }}>

          <div style={classes.a}>
            <img style={classes.logo} src={logo} alt='Logo' />
          </div>
          <Form.Item
            validateStatus={(userEmpty || failedRol || failed || inactive) ? 'error' : ''}
            help={
              (inactive && 'El usuario no se encuentra activo') ||
              (userEmpty && 'El campo no puede estar vacío') ||
              (failedRol && 'El usuario no tiene permisos para acceder') ||
              (failed && 'Usuario o contraseña incorrectos') ||
              (!userEmpty && !failedRol && !failed && 'Ingrese su correo electrónico')
            }
          >
            <Input
              style={{ height: 45 }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin(e)
                }
              }}
              value={email}
              ref={inputRef}
              size='large'
              status={(userEmpty || failedRol || failed) ? 'error' : ''}
              onChange={handleEmailChange}
              placeholder='Correo Electrónico o Numero de Teléfono'
            />
          </Form.Item>
          <div style={{ height: 15 }}></div>
          <Form.Item
            validateStatus={(passwordEmpty || failed) ? 'error' : ''}
            help={
              (passwordEmpty && 'El campo no puede estar vacío') ||
              (failed && 'Usuario o contraseña incorrectos') ||
              (!passwordEmpty && !failed && 'Ingrese su contraseña')
            }
          >
            <Input.Password
              style={{ padding: 0, height: 45, paddingLeft: 10, paddingRight: 10 }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin(e)
                }
              }}
              size='large'
              status={(passwordEmpty || failed) ? 'error' : ''}
              onChange={handlePasswordChange}
              placeholder='Contraseña'
            />
          </Form.Item>
          <div style={{ width: '100%', textAlign: 'right', marginBottom: 15 }}>
            <Button type='link' onClick={() => setOpenForgot(true)}>¿Olvidaste tu contraseña?</Button>
          </div>
          <div style={classes.buttons} >
            <Button loading={loading} style={{ ...classes.button, width: 'auto' }} type='primary' size='large' onClick={handleLogin}>
              Iniciar Sesión
            </Button>
            {
              status &&
              <Button style={{ ...classes.button, width: 'auto' }} type='link' size='large' onClick={() => goTo('/votos')}>
                Ir a Presupuestos Participativos
              </Button>
            }
            {/* <Button style={{ ...classes.button, width: 'auto' }} type='link' size='large' onClick={() => goTo('/register')}>
              Registrarse
            </Button> */}
          </div>
        </form>
      </div>
      <Modal
        visible={openForgot}
        footer={null}
        onCancel={handleCloseForgot}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 30 }}
      >
        <div style={{ width: '100%' }}>
          <ForgotPassword setOpen={setOpenForgot} />
        </div>
      </Modal>
    </div>
  );
}

const classes = {
  button: {
    textTransform: 'capitalize',
    width: '50%',
    height: '50px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  main: {
    height: '100vh',
    minHeight: '100vh',
    display: 'flex',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // background: '#222',
    minwidth: 400,
    overflowX: 'hiden',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'red'
  },
  root: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    height: 'auto',
    overflowY: 'auto',
    background: '#fafafa',
    borderRadius: '3%',
    display: 'flex',
    flexDirection: 'flex',
    justifyContent: 'center',
    padding: '20px',
    margin: '10%'
  },
  logo: {
    width: 100,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '& > *': {
    },
  },
  a: {
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
    marginBottom: 20
  },
  btnFacebook: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
    },
  },

}

export default LoginPage;
