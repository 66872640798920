import { Button, Radio, Result, Space } from 'antd'
import { useState } from 'react'
import { contactEmail } from '../../themeConfig'
import { apiResendCode } from '../../utils/api'

export const ResendCode = ({ loading, setloading, code, setcodeC, clear, handleClose }) => {
    const [successResend, setSuccessResend] = useState(false)
    const [value, setvalue] = useState('phone')
    const [message, setmessage] = useState(null)
    const [fail, setfail] = useState(false)

    const handleReesendCode = () => {
        setloading(true)

        apiResendCode(code, value)
            .then(res => {
                setloading(false)
                setcodeC('')
                clear()
                setSuccessResend(true)
            })
            .catch(err => {
                if (err.response.status === 409) {
                    setloading(false)
                    setcodeC('')
                    clear()
                    setSuccessResend(true)
                    setmessage(err.response.data)
                } else {
                    setfail(true)
                }

            })
    }
    return (
        <div>
            {
                successResend ?
                    <Result
                        status='success'
                        title='Código reenviado correctamente'
                        subTitle={message ? message : `Se le ha reenviado un código de verificación, por favor revise su ${value === 'email' ? 'correo electrónico' : 'teléfono'} y siga las instrucciones`}
                        extra={[
                            <Button key='ok' type='primary' onClick={handleClose} autoFocus >
                                Aceptar
                            </Button>
                        ]}
                    /> :
                    <>
                        {
                            fail
                                ?
                                <Result
                                    status='error'
                                    title='Error en la petición'
                                    subTitle='Se ha producido un error en la petición debido a que el código de verificación no es válido, haga el proceso de nuevo o contacte a soporte'
                                    extra={[
                                        <a key='mailto' href={`mailto:${contactEmail}`}>{contactEmail}</a>,
                                        <br key='br'/>,
                                        <Button style={{marginTop: 15}} key='ok' type='primary' onClick={handleClose} autoFocus >
                                            Salir
                                        </Button>
                                    ]}
                                />
                                :
                                <Result
                                    status='info'
                                    title='Reenviar código'
                                    subTitle='Seleccione el medio a donde se enviara el código de verificación'
                                    extra={[
                                        <Radio.Group key='radio' onChange={e => setvalue(e.target.value)} value={value}>
                                            <Space direction="vertical">
                                                <Radio value={'email'}>Correo electrónico</Radio>
                                                <Radio value={'phone'}>Teléfono</Radio>
                                            </Space>
                                        </Radio.Group>,
                                        <br key='br' />,
                                        <Button disabled={!value} style={{ marginTop: 10 }} loading={loading} key='ok' type='primary' onClick={handleReesendCode} autoFocus >
                                            Reenviar
                                        </Button>
                                    ]}
                                />
                        }
                    </>
            }
        </div>
    )
}
