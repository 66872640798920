import { Button, Result, Typography } from 'antd';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { useHistory } from 'react-router-dom';
import { background, contactEmail, logo, webName } from '../../../themeConfig';

const DeleteUserPage = () => {
  const { width } = useScreenSize()
  const history = useHistory()

  return (
    <div style={classes.main} direction="coloum">
      <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '30vw' }}>
        <Result
          icon={<img style={classes.logo} src={logo} alt='Logo' />}
          title={<div style={{ fontSize: 25, fontWeight: 'bold' }}>Eliminación de usuario {webName}</div>}
          subTitle={
            <div>
                <Typography style={{ fontSize: 17, marginTop: 20 }}>
                    Para la eliminación de usuario se realiza dentro de la aplicación con los siguientes pasos: 
                    <div style={{ textAlign: 'left', marginLeft: 20 }}><ul>
                        <li>Inicie sesión</li>
                        <li>Menú lateral izquierdo superior</li>
                        <li>Configuración</li>
                        <li>Eliminar cuenta</li>
                        <li>Aceptar</li>
                        </ul>
                    </div>
                    <p>Si tienes alguna duda, no dudes en contactarnos a:</p>
                </Typography>
              <a style={{ fontSize: 16 }} href={`mailto:${contactEmail}`} >{contactEmail}</a>
            </div>}
            extra={
              <Button type='primary' onClick={() => history.push('/')}>Salir</Button>
            }
        />
      </div>

    </div>
  );
}

const classes = {
  main: {
    height: '100vh',
    minHeight: '100vh',
    display: 'flex',
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minwidth: 400,
    overflowX: 'hiden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    height: 'auto',
    overflowY: 'auto',
    background: '#fafafa',
    borderRadius: '3%',
    display: 'flex',
    flexDirection: 'flex',
    justifyContent: 'center',
    padding: '20px',
    margin: '10%'
  },
  logo: {
    width: 100,
  },


}

export default DeleteUserPage;