import { CheckCircleOutlined, InfoCircleOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isAllowed, { ROLES_DIVISIONS } from '../../../../layout/roles';
import { apiDependencies, apiReportType } from '../../../../utils/api';
import { ConfigurationModal } from './ConfigurationModal';

export const Configuration = ({ setupdated, updated }) => {
    const columns = [
        {
            title: 'Descripción',
            dataIndex: 'name',
            key: 'name',
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'active',
            width: '12%',
            render: (text, record) =>
                record.active !== undefined &&
                <div style={{ color: record.active ? '#23ba27' : 'red', borderRadius: 10 }}>
                    {
                        record.active
                            ? <CheckCircleOutlined style={{ marginRight: 4 }} />
                            : <InfoCircleOutlined style={{ marginRight: 4 }} />
                    }
                    {record.active ? 'Activo' : 'Inactivo'}
                </div>,
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Mínimo (hrs)',
            dataIndex: 'minTime',
            key: 'min',
            width: '12%',
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Máximo (hrs)',
            dataIndex: 'maxTime',
            width: '12%',
            key: 'max',
            ellipsis: {
                showTitle: true
            }
        },
        {
            title: 'Configurar',
            width: '12%',
            render: (row) => (
                !row.children &&
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Tooltip title="Configurar">
                        <Button icon={<SettingFilled />} onClick={() => handleSelected(row)}>
                        </Button>
                    </Tooltip>
                </div>
            ),
            ellipsis: {
                showTitle: true
            }
        },

    ];

    const { user } = useSelector(state => state.session);
    const [data, setdata] = useState([])
    const [dataDependencies, setdataDependencies] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setopenModal] = useState({
        isOpen: false,
        data: {},
    })

    const handleSelected = (row) => {
        setopenModal(prev => ({ ...prev, isOpen: true, data: row }))

    }

    useEffect(() => {
        setLoading(true)
        apiDependencies()
            .then(res => {
                if (isAllowed(user, ROLES_DIVISIONS.PURE_MANAGEMENT)) {
                    res.data = res.data.filter(item => item.id === user.govDependency.id)
                }
                let dataTable = res.data.map((item, index) => ({ key: index, ...item, children: [] }))
                setdataDependencies(dataTable)
            }).catch(err => {
                console.log(err)
            })
    }, [updated])

    useEffect(() => {
        apiReportType()
            .then(res => {
                res.data = res.data.filter(item => item.name !== 'CRM')

                let dataTable = dataDependencies.map(item => ({ ...item, children: res.data.filter(i => i.dependency.id === item.id).map(item => ({ key: item.name + Math.random(), ...item })) }))
                setdata(dataTable)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [dataDependencies])

    return (
        <>
            <Table
                size='small'
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ y: '60vh' }}
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
            {
                (openModal.isOpen && openModal.data) &&
                <Modal
                    onCancel={() => setopenModal(prev => ({ ...prev, isOpen: false }))}
                    closable={false}
                    title="Configurar servicio"
                    footer={null}
                    visible={openModal.isOpen}
                >
                    <ConfigurationModal openModal={openModal} setopenModal={setopenModal} setUpdated={setupdated} />
                </Modal>
            }
        </>
    );
}
