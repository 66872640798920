import axios from 'axios';
import { deleteSession } from './common/DeleteSession';
import store from './store';
axios.interceptors.request.use(function (config) {
    const token = store.getState().session.token;
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

axios.interceptors.response.use(
  function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 403 ){
      deleteSession()
    }
    return Promise.reject(error);
  });

export default axios;