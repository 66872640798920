import { CloseOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, Modal, Radio, Table, Tooltip, Typography } from 'antd'
import { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { ReportDetails } from '../reports/ReportDetails'
import { Filters } from './Filters'
import { apiReportsFilterPagination } from '../../../utils/api'
import { useSelector } from 'react-redux'
import { CREATE_BY } from '../reports/DisplayedMap'
import { ExportReports } from './ExportReports'
import { prMain } from '../../../themeConfig'
import { validateNumbers } from '../../../utils/validateNumbers'
const { RangePicker } = DatePicker;


export const CRMTable = () => {

    const columns = [
        {
            title: 'Folio',
            key: 'id',
            width: '7%',
            sorter: true,
            render: row => (
                <div>
                    {row.id}
                </div>
            )
        },
        {
            title: 'Folio Origen',
            key: 'idCrm',
            render: row => (
                <div>
                    {row.idOrigin ? row.idOrigin : ''}
                </div>
            )
        },
        {
            title: 'Tipo de Reporte',
            key: 'idReport',
            render: row => (
                <div>
                    {row?.servicesDto?.name}
                </div>
            )
        },

        {
            title: 'Ciudadano',
            key: 'nameUser',
            render: row => (
                <div>
                    {row?.createBy?.name}
                </div>
            )
        },
        {
            title: 'Fecha de Llamada',
            key: 'dateBeenCalled',
            render: row => (
                row.noTracking ? 'Sin Seguimiento'
                    : row.crm ?
                        <Tooltip title={moment(row.crm.dateBeenCalled).format('DD/MM/YYYY hh:mm a')}>
                            {moment(row.crm.dateBeenCalled).format('DD/MM/YYYY hh:mm a')}
                        </Tooltip> :
                        'Pendiente de Llamar'
            )
        },
        {
            title: 'Fecha Completado',
            key: 'completed_at',
            ellipsis: {
                showTitle: true
            },
            sorter: true,
            render: row => (
                row.completedAt ?
                    <Tooltip title={moment(row.completedAt).format('DD/MM/YYYY hh:mm a')}>
                        {moment(row.completedAt).format('DD/MM/YYYY hh:mm a')}
                    </Tooltip> :
                    ''
            )
        },
    ]

    const actionsTable = {
        title: 'Acciones',
        key: 'actions',
        align: "center",
        width: '8%',
        ellipsis: {
            showTitle: true
        },
        render: (row) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Tooltip title="Ver Detalles del Reporte">
                    <Button type='link' icon={<EyeOutlined style={{ color: '#000' }} />} onClick={() => handleDetails(row, 'report')}>
                    </Button>
                </Tooltip>
            </div>
        ),
    }

    const options = [
        {
            label: 'Todos',
            value: 5,
        },
        {
            label: 'Pendientes de Llamar',
            value: 3,
        },
        {
            label: 'Llamada Hecha',
            value: 2,
        },
    ];

    const [loading, setloading] = useState(false)
    const [filterData, setfilterData] = useState([])
    const [fullData, setfullData] = useState([])
    const [refresh, setrefresh] = useState(false)
    const [refreshExcel, setrefreshExcel] = useState(false)
    const [modal, setmodal] = useState({
        isOpen: false,
        type: '',
        selected: null
    })
    const { user } = useSelector(state => state.session);

    const [search, setsearch] = useState()

    const [rangeDate, setrangeDate] = useState([moment('1/1/2022'), moment()])
    const [status, setstatus] = useState(4)

    const [userName, setUserName] = useState([])
    const [dataUsers, setDataUsers] = useState([])

    const [userOption, setuserOption] = useState(CREATE_BY)

    const [typeName, setTypeName] = useState(user.service ? user.service.map(i => (JSON.stringify({ id: i.id, name: i.name }))) : []);
    const [dataType, setDataType] = useState(user.service ? user.service.map(i => (JSON.stringify({ id: i.id, name: i.name }))) : []);

    const [optionCall, setoptionCall] = useState(3);

    const [totalData, settotalData] = useState(0)
    const [page, setpage] = useState(1)
    const [pageSize, setpageSize] = useState(10)
    const [order, setorder] = useState({ key: undefined, order: undefined })

    const [isFilter, setisFilter] = useState(false)
    const [owner, setowner] = useState('')


    const handleDetails = (row, type) => {
        setmodal(prev => ({ ...prev, isOpen: true, type, selected: row }))
    }

    const handleClose = () => {
        setmodal(prev => ({ ...prev, isOpen: false, type: '', selected: null }))
    }

    // const handleSearch = (value) => {
    //     if (!value) {
    //         setrefresh(prev => !prev)
    //         return
    //     }

    //     setloading(true)
    //     apiReportsByIdComplete(value).then(res => {
    //         settotalData(res.data.length)
    //         setfilterData([res.data])
    //         setfullData([res.data])
    //     }).catch(err => {
    //         new Error(err)
    //     }).finally(() => {
    //         setloading(false)
    //     })
    // }

    const changeSearch = (e) => {
        setsearch(validateNumbers(e))
    }

    const getDataReport = useCallback((pageOpt) => {
        setloading(true)
        // let from = new Date(fromOptional ? fromOptional : isFilter ? rangeDate[0] : '1/1/2020')
        // let to = new Date(toOptional ? fromOptional : isFilter ? rangeDate[1] : moment())
        let from = new Date(rangeDate[0])
        let to = new Date(rangeDate[1])
        apiReportsFilterPagination(status, status, from, to, search, owner, dataType.join(','), userOption, pageOpt ? pageOpt : page, pageSize, order.key, order.order, optionCall, 0, dataUsers.join(',')).then(res => {
            settotalData(res.data.amount)

            setfullData(res.data.reports)
            setfilterData(res.data.reports)

        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setloading(false)
        })

    }, [rangeDate, dataType, dataUsers, userOption, status, page, pageSize, order.key, order.order, optionCall, owner])

    useEffect(() => {
        getDataReport()

    }, [refresh, status, page, pageSize, order.key, order.order, optionCall])


    const handleChangeDates = (dates) => {
        setrangeDate(dates)
    }

    const handleTable = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter)
        setpage(pagination.current)
        setpageSize(pagination.pageSize)
        setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : null }))
    }

    const onChangeCallOption = ({ target: { value } }) => {
        setpage(1)
        setloading(true)
        setoptionCall(value);
    };

    return (
        <div style={{ height: '80vh', overflowY: 'auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', gap: '3rem' }}>
                <Input value={search} onChange={changeSearch} placeholder="Buscar Folio" style={{ width: '35%' }} />
                {/* <Search value={search} onChange={changeSearch} onSearch={handleSearch} placeholder="Buscar Folio" loading={false} style={{ width: '35%' }} /> */}
                <div style={{ width: '65%', display: 'flex' }}>
                    <Form.Item style={{ width: '100%' }} help={'Seleccione el rango de fechas'}>
                        <RangePicker allowClear={false} style={{ width: '100%' }} value={rangeDate} onChange={handleChangeDates} />
                    </Form.Item>
                </div>
            </div>
            <div style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
                <Filters
                    setsearch={setsearch}
                    fullData={fullData}
                    setoptionCall={setoptionCall}
                    setfulldata={setfullData}
                    rangeDate={rangeDate}
                    setrangeDate={setrangeDate}
                    status={status}
                    setstatus={setstatus}
                    userName={userName}
                    setUserName={setUserName}
                    dataUsers={dataUsers}
                    setDataUsers={setDataUsers}
                    userOption={userOption}
                    setuserOption={setuserOption}
                    typeName={typeName}
                    setTypeName={setTypeName}
                    dataType={dataType}
                    setDataType={setDataType}
                    folio={search}
                    owner={owner}
                    setowner={setowner}
                    setrefresh={setrefresh} setloading={setloading} setfilterData={setfilterData} optionCall={optionCall} setrefreshExcel={setrefreshExcel} settotalData={settotalData} page={page} pageSize={pageSize} order={order} setisFilter={setisFilter} />
            </div>

            <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Radio.Group options={options} onChange={onChangeCallOption} value={optionCall} />
            </div>

            <Table
                onChange={handleTable}
                rowKey={record => record.id}
                loading={loading}
                columns={[...columns, actionsTable]}
                dataSource={filterData}
                size="small"
                scroll={{ y: '50vh', x: '100%' }}
                pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
            />
            {/* <div style={{ marginTop: '-50px' }} >
                {
                    filterData &&
                    <ExportReports
                        owner={owner}
                        status={status}
                        dataType={dataType}
                        dataUsers={dataUsers}
                        optionCall={optionCall}
                        rangeDate={rangeDate}
                        userOption={userOption}
                        refresh={refresh}
                        refreshExcel={refreshExcel} isFilter={isFilter} folio={search} />
                }
            </div> */}

            <Modal
                centered
                visible={modal.isOpen}
                closable={false}
                footer={null}
                destroyOnClose
                onCancel={handleClose}
                width={'auto'}
            >{
                    modal.selected &&
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: '1rem', position: 'relative' }}>
                        {modal.selected.length !== 0 &&
                            <Typography style={{ alignSelf: 'center', color: prMain, textAlign: 'right', fontSize: '1.1rem' }} >
                                {modal.selected.status.id === 1 && 'Pendiente'}
                                {modal.selected.status.id === 2 && 'En proceso'}
                                {modal.selected.status.id === 3 && 'En revisión'}
                                {modal.selected.status.id === 4 && 'Solucionado'}
                            </Typography>}

                        <CloseOutlined style={{ cursor: 'pointer', textAlign: 'right', position: 'absolute', right: 0, top: 0 }} onClick={handleClose} />
                    </div>}
                {
                    modal.type === 'report' &&
                    <ReportDetails data={modal.selected} onCloseDetails={handleClose} refreshReports={setrefresh} />
                }
            </Modal>
        </div>
    )
}
