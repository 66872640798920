import React, { useEffect, useState } from "react"
import { Typography, Row } from "antd"
import { prMain } from "../../../../themeConfig"
import { HistoricalRow } from "./HistoricalRow"
import { apiDependencies, apiReportType } from "../../../../utils/api"
import moment from "moment"

const HistoricalDetails = (props) => {
    const { Text } = Typography
    const details = props.details
    const changes = props.details.changes
    const status = ["", "Pendiente", "En proceso", "En revisión", "Solucionado", "Completado"]
    const [services, setServices] = useState([])
    const [dependecies, setdependecies] = useState([])

    useEffect(() => {
        apiReportType().then((response) => {
            response.data = response.data.filter(item => item.name !== 'CRM')

            let array = [""]
            response.data.forEach(item => {
                array.push(item.name)
            });
            setServices(array)
        })
        apiDependencies().then(res => {
            console.log(res.data.sort((a, b) => a.id - b.id).map(i => i.name))
            setdependecies(res.data.sort((a, b) => a.id - b.id).map(i => i.name))
        })
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '4vh', flexDirection: 'row' }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold', color: prMain }}>Cambios en reporte</Text>
            </div>

            <Row style={{ fontSize: 18, borderBottom: `1px solid ${prMain}` }}>
                <Text style={{ color: prMain }}>{`${details.author} `}</Text>
                <Text style={{ color: prMain, textTransform: 'lowercase' }}>{`${details.message}`}</Text>
            </Row>

            {props.option === 1
                ? (
                    <>

                        {details.id && <HistoricalRow label={"Folio"} value={details.id} />}
                        {details.date && <HistoricalRow label={"Fecha"} value={moment(details.date).format('lll')} />}
                        {changes.description && <HistoricalRow label={"Descripción"} value={changes.description} />}
                        {changes.statusId && <HistoricalRow label={"Estatus"} value={status[changes.statusId]} />}
                        {changes.address && <HistoricalRow label={"Dirección"} value={changes.address} />}
                        {changes.observations && <HistoricalRow label={"Observación"} value={changes.observations} />}
                        {changes.reportTypeId && <HistoricalRow label={"Servicio"} value={services[changes.reportTypeId]} />}
                        {changes.userCreateName && <HistoricalRow label={"Autor"} value={changes.userCreateName} />}
                        {changes.userCreatePhone && <HistoricalRow label={"Teléfono"} value={changes.userCreatePhone} />}

                    </>
                )
                : (
                    <>
                        {details.id && <HistoricalRow label={"Id"} value={details.id} />}
                        {/* {changes.idStatus && <HistoricalRow label={"Estatus"} value={status[changes.idStatus] } />} */}
                        {changes.name && <HistoricalRow label={"Nombre"} value={changes.name} />}
                        {changes.lastName && <HistoricalRow label={"Apellido"} value={changes.lastName} />}
                        {changes.phone && <HistoricalRow label={"Telefono"} value={changes.phone} />}
                        {changes.email && <HistoricalRow label={"Correo"} value={changes.email} />}
                        {changes.dependencyId && <HistoricalRow label={"Dependencia"} value={dependecies[changes.dependencyId - 1]} />}

                    </>
                )
            }

        </div>
    )
}

export default HistoricalDetails