import React, { useEffect, useState } from 'react'
import { useScreenSize } from '../../../../hooks/useScreenSize'
import { prMain } from '../../../../themeConfig'
import { VotesChart2 } from '../../../charts/VotesChart2'

export const GraphicZone = ({ data, tab }) => {

    const { height } = useScreenSize()

    const [seriesZone, setseriesZone] = useState([])

    const createSeries = () => {
        const colors = [prMain, "#F3B415", "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#00A7C6", "#18D8D8", '#A9D794',
        '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29']

        let seriesZone = {}
            let dataBudget = data.find(i => i.zona === tab).budgetParticipatoryDtos.sort((a, b) => b.votes - a.votes)

            if (data.find(i => i.zona === tab).budgetParticipatoryDtos.length === 0) {
                let noData = [{
                    name: 'Sin Datos',
                    data: [0],
                    color: colors[0],
                    // strokeColor: colors[0],
                }]
                seriesZone = noData

            } else {

                let data = dataBudget.map((item, index) => ({
                    name: item.projectName,
                    data: [item.votes],
                    color: colors[index],
                    // strokeColor: colors[index],
                }))

                seriesZone = data
            }
        setseriesZone(seriesZone)

    }

    useEffect(() => {
        createSeries()
    }, [tab])
    
    return (
        <div style={{height: '30vh'}}>
            {
                seriesZone.length > 0 &&
                <VotesChart2 series={seriesZone} height={ height > 820 ? 600 : 450} zone={tab}/>
            }
        </div>
    )
}
