import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Modal, Form, Spin, Result } from 'antd';
import { useScreenSize } from '../../../hooks/useScreenSize';
import classes from './change.module.css';
import { apiChangeData } from '../../../utils/api';
import { ResendCode } from '../ResendCode';
import { validateNumbers } from '../../../utils/validateNumbers';
import { background, logo } from '../../../themeConfig';

export const ChangeEmail = () => {
    const { code } = useParams()
    const [codeC, setcodeC] = useState(code)
    const [failed, setfailed] = useState(false)
    const [invalidCode, setinvalidCode] = useState(false)
    const [expiredCode, setexpiredCode] = useState(false)
    const [notFoundCode, setnotFoundCode] = useState(false)
    const [email, setEmail] = useState('')
    const [failedEmailFormat, setfailedEmailFormat] = useState(false)
    const [failedEmail, setfailedEmail] = useState(false)
    const [success, setsuccess] = useState(false)
    const [loading, setloading] = useState(false)
    const [resend, setResend] = useState(false)
    const { width } = useScreenSize();

    const history = useHistory();

    const handleClose = () => {
        setsuccess(false)
        history.push('/')
    }

    const handleChange = (event) => {
        if (event.target.name === 'codeC') {
            setfailed(false)
            setexpiredCode(false)
            setinvalidCode(false)
            setnotFoundCode(false)
            setcodeC(validateNumbers(event))
        }
        if (event.target.name === 'email') {

            setfailedEmail(false);
            setfailedEmailFormat(false);
            setEmail(event.target.value);
            const re =
                /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

            if (event.target.value.length > 0) {

                if (re.test(event.target.value)) {
                    setfailedEmail(false);
                } else {
                    setfailedEmailFormat(true);
                }
            }
        }
    }

    const handleSubmit = (e) => {
        setloading(true)
        apiChangeData('email', email, codeC)
            .then(res => {
                console.log(res)
                setsuccess(true)

            })
            .catch(err => {
                console.log(err)
                if (err.response.status === 409) {
                    setfailedEmail(true)
                } else if (err.response.status === 400) {
                    setinvalidCode(true)
                } else if (err.response.status === 404) {
                    setnotFoundCode(true)
                } else if (err.response.status === 498) {
                    setexpiredCode(true)
                } else {
                    setfailed(true)
                }

            })
            .finally(() => {
                setloading(false)
            })
    }

    const handleOpenResend = () => {
        setResend(true)
        setsuccess(true)
        setfailed(false)
        setexpiredCode(false)
        setinvalidCode(false)
        setnotFoundCode(false)
    }

    const handleClear = () => {
        setEmail('')
    }

    return (
        <div>
            <div style={{backgroundImage: `url(${background})`}} className={classes.main} >
                <Spin spinning={loading}>
                    <div className={classes.image}></div>
                    <div className={classes.root} style={{ width: width < 600 ? '90vw' : width < 1000 ? '60vw' : '30vw' }}>
                        <form style={{ width: '100%' }}>
                            <div className={classes.a}>
                                <img className={classes.logo} src={logo} alt="Logo" />
                            </div>
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <Form.Item
                                    validateStatus={(invalidCode || expiredCode || notFoundCode || failed) ? 'error' : ''}
                                    help={
                                        invalidCode
                                            ? 'Ha ocurrido un error al intentar establecer el correo electrónico, el código de verificación no es válido'
                                            : expiredCode
                                                ? 'Ha ocurrido un error al intentar establecer el correo electrónico, el código de verificación ha expirado'
                                                : notFoundCode
                                                    ? 'Ha ocurrido un error al intentar establecer el correo electrónico, no cuenta con un código de verificación'
                                                    : failed
                                                        ? 'Ha ocurrido un error al intentar establecer el correo electrónico'
                                                        : !codeC
                                                            ? 'Ingrese un código de verificación'
                                                            : 'Código de verificación'
                                    }
                                >
                                    <Input
                                        size='large'
                                        onChange={handleChange}
                                        value={codeC}
                                        maxLength={6}
                                        placeholder="Código de verificación"
                                        name="codeC"
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ marginTop: 20 }}>
                                <Form.Item
                                    validateStatus={(failedEmail || failedEmailFormat) ? 'error' : ''}
                                    help={
                                        failedEmail
                                            ? 'Este correo no coincide con el solicitado para cambiarlo'
                                            : !email
                                                ? 'Ingrese un correo electrónico'
                                                : failedEmailFormat
                                                    ? 'El correo no es válido'
                                                    : 'Ingrese un correo electrónico'
                                    }
                                >

                                    <Input
                                        size="large"
                                        onChange={handleChange}
                                        value={email}
                                        placeholder="Correo electrónico"
                                        name="email"
                                    />
                                </Form.Item>
                            </div>
                            <div className={classes.buttons} >
                                {
                                    (!invalidCode && !expiredCode && !notFoundCode) &&
                                    <Button disabled={failedEmailFormat || failedEmail || !codeC || !email || codeC.length < 6} className={classes.button} onClick={handleSubmit} type='primary' size='large'>
                                        Establecer correo
                                    </Button>
                                }
                                {
                                    (invalidCode || expiredCode || notFoundCode) &&
                                    <>
                                        {
                                            expiredCode &&
                                            <Button className={classes.button} onClick={handleOpenResend} variant='contained' type='link
                                        ' size='large'>
                                                Reenviar código de verificación
                                            </Button>
                                        }
                                        <Button className={classes.button} onClick={handleClose} type='primary' size='large'>
                                            Salir
                                        </Button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </Spin>
            </div>
            {
                success &&
                <Modal
                    visible={success}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    footer={null}
                    closable={false}
                >
                    {
                        resend
                            ? <>
                                <ResendCode loading={loading} clear={handleClear} code={codeC} setcodeC={setcodeC} setloading={setloading} handleClose={handleClose} />
                            </>
                            :
                            <>
                                <Result
                                    status='success'
                                    title='Nuevo correo electrónico establecido correctamente'
                                    subTitle='Su nuevo correo electrónico ha sido establecido correctamente.'
                                    extra={[
                                        <Button key='ok' type='primary' onClick={handleClose} autoFocus >
                                            Aceptar
                                        </Button>
                                    ]}
                                /></>
                    }
                </Modal>
            }
        </div>

    )
}
