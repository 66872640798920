import { useEffect, useState } from "react"
import { Typography, Select, Button, Spin, Empty } from "antd"
import { prMain } from "../../../themeConfig"
import { apiGetScholarships, apiSendScholarshipNotification } from "../../../utils/api"
import { CheckCircleOutlined } from "@ant-design/icons"


const ListForNotifications = ({ scholarships, setOpenModal }) => {
    const { Text } = Typography
    const { Option } = Select
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let array = [];
        setLoading(true)
        apiGetScholarships().then(res => {
            let scholarships = res.data
            scholarships.forEach((row) => {
                let tempObj = {};
                if (row.beneficiaryList.length !== 0) {
                    row.beneficiaryList.forEach((item) => {

                        if (item.idStatus === 4) {
                            tempObj.idStatus = item.idStatus
                            tempObj.curp = item.curpBeneficiary
                            tempObj.name = item.nameBeneficiary
                            tempObj.lname1 = item.firstLastNameBeneficiary
                            tempObj.lname2 = item.secondLastNameBeneficiary

                            array.push(tempObj)
                            tempObj = {}
                        }
                    })
                } else {
                    if (row.idStatus === 4) {
                        tempObj.idStatus = row.idStatus
                        tempObj.curp = row.curp
                        tempObj.name = row.name
                        tempObj.lname1 = row.firstLastName
                        tempObj.lname2 = row.secondLastName

                        array.push(tempObj)
                        tempObj = {}
                    }
                }
            })
            setOptions(array)
        }).catch(err => {
            new Error(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const [beneficiaries, setBeneficiaries] = useState([])
    const [successMessage, setSuccessMessage] = useState(false)
    const handleChangeSelect = (event) => {
        setBeneficiaries(event)
    }

    const handleSendMsj = () => {
        apiSendScholarshipNotification(beneficiaries.join(',')).then(() => {
            setSuccessMessage(true)
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <>
            {successMessage

                ?
                <div style={{ display: 'flex', flexDirection: 'column', height: '30vh', }}>
                    <CheckCircleOutlined style={modalIcon} />

                    <Text style={modalTitle}>Mensaje Enviado.</Text>
                    <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                        <Text style={modalText}>Se notificar&aacute; a cada solicitante.</Text>
                    </div>
                </div>
                :
                <Spin spinning={loading}>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {options.length !== 0 &&
                            <>
                                <Text style={modalTitle}>Enviar mensaje</Text>

                                <Text>
                                    Seleccionar a los beneficiarios a quienes se le notificar&aacute; que su tarjeta del apoyo ya se encuentra disponible
                                </Text>

                                <Select
                                    size='large'
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%', margin: '2vh 0', }}
                                    placeholder="Beneficiarios"
                                    onChange={handleChangeSelect}
                                    value={beneficiaries}
                                    maxTagCount='responsive'
                                >

                                    {
                                        options.map((item) => (
                                            <Option key={item.curp} value={item.curp}>{`${item.curp}:  ${item.name} ${item.lname1} ${item.lname2}`}</Option>
                                        ))
                                    }

                                </Select>
                                <div style={modalButtons}>
                                    <Button style={rejectButton} onClick={() => setOpenModal(false)}>Cancelar</Button>
                                    <Button disabled={!beneficiaries} type="primary" style={{ color: '#fff' }} onClick={handleSendMsj}>Continuar</Button>
                                </div>
                            </>}

                        {options.length === 0 &&
                            <div style={{ display: 'flex', flexDirection: 'column', height: 'fit-content', padding: '1vh 1vw' }}>
                                <Text style={modalTitle}>Enviar mensaje</Text>

                                <div>

                                    {
                                        !loading &&
                                        <Empty />
                                    }
                                    <div style={{ marginTop: '2vh', textAlign: 'center' }}>
                                        <Text style={modalText}>
                                            {
                                                loading
                                                    ? 'Cargando Datos de Beneficiarios'
                                                    : 'Por favor, asegúrese de aceptar algun apoyo para realizar esta acción.'
                                            }
                                        </Text>
                                    </div>
                                </div>
                                <div style={{ width: 'fit-content', marginLeft: 'auto', marginTop: '1vh' }}>
                                    {
                                        !loading &&
                                        <Button style={rejectButton} onClick={() => setOpenModal(false)}>Cerrar</Button>
                                    }
                                </div>
                            </div>

                        }
                    </div>
                </Spin>
            }
        </>
    )
}

const rejectButton = {
    marginRight: '1vw',
    backgroundColor: '#ffffff00',
    border: `solid 1px ${prMain}`,
    color: prMain
}

const modalTitle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '26px',
    marginBottom: '4vh',
}

const modalText = {
    marginLeft: 'auto',
    marginRight: 'auto',
}

const modalButtons = {
    marginLeft: 'auto',
    marginTop: '2vh',
    display: 'flex',
    flexDirection: 'row'
}

const modalIcon = {
    fontSize: '75px',
    marginBottom: '1vh',
    marginTop: '4vh',
    color: prMain,
}

export default ListForNotifications