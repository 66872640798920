import React, { useCallback, useEffect, useState } from 'react';
import EditUser from './EditUser';
import { Button, Modal, Table, Tooltip, Typography } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, EditFilled, IssuesCloseOutlined, SettingFilled, SolutionOutlined, StopOutlined } from '@ant-design/icons';
// import { getPerformance, trace } from 'firebase/performance';
import { useSelector } from 'react-redux';
import { apiUsersPagination, apiUsersPaginationSearch } from '../../../utils/api';
import isAllowed, { ROLES_DIVISIONS } from '../../../layout/roles';
import { UserConfig } from './UserConfig';
import { ReportsAssigned } from './ReportsAssigned';

const UsersTable = ({ filter, setupdate, update, pathname, page, setpage }) => {

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '8%',
      sorter: true,
      render: name => (
        <Tooltip title={name}>
          {name}
        </Tooltip>
      )
    },
    {
      title: 'Nombre',
      key: 'name',
      sorter: true,
      render: row => (
        <Tooltip title={`${row.name} ${row.lastName ? row.lastName : ''}`}>
          {row.name} {row.lastName ? row.lastName : ''}
        </Tooltip>
      )
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: email => (
        <Tooltip title={email}>
          {email}
        </Tooltip>
      )
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: phone => (
        <Tooltip title={phone}>
          {phone}
        </Tooltip>
      )
    },
    pathname === '/ciudadanos'
      ?
      {
        title: 'Creado por',
        // dataIndex: 'nameCreateByUser',
        key: 'nameCreateByUser',
        ellipsis: {
          showTitle: true
        },
        width: '10%',
        render: row => (
          <Tooltip title={row.nameCreateByUser}>
            {row.nameCreateByUser ? row.nameCreateByUser : ''}
          </Tooltip>
        ),
      }
      :
      {
        title: 'Rol',
        dataIndex: 'roles',
        key: 'roles',
        ellipsis: {
          showTitle: true
        },
        width: '10%',
        render: role => (
          <Tooltip title={role[0].description}>
            {role[0].description}
          </Tooltip>
        ),
      },
    {
      title: 'Estado',
      dataIndex: 'user_status',
      key: 'user_status',
      width: '10%',
      ellipsis: {
        showTitle: true
      },
      render: (status) => (
        status &&
        <>
          {
            status.id === 1
              ? <CheckCircleOutlined style={{ color: '#71CF71', marginRight: 5 }} />
              : status.id === 2
                ? <StopOutlined style={{ color: '#FF4949', marginRight: 5 }} />
                : <IssuesCloseOutlined style={{ color: '#FFD233', marginRight: 5 }} />
          }
          <Typography.Text
            style={{ color: status.id === 1 ? '#71CF71' : status.id === 2 ? '#FF4949' : '#FFD233' }}
          >{status.name}</Typography.Text>
        </>
      )
    },
  ]

  const services = [
    {
      title: 'Dependencia',
      dataIndex: 'govDependency',
      key: 'govDependency',
      render: dependency => (
        dependency ? dependency.name : ''
      ),
    },
    {
      title: 'Servicios',
      dataIndex: 'service',
      key: 'service',
      render: services => (
        services.map(service => service ? service.name : '').join(', ')
      )
    }]

  const actionsTable = {
    title: 'Acciones',
    width: '10%',
    ellipsis: {
      showTitle: true
    },
    render: (row) => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {
          (isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN) || isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)) &&
          <Tooltip title="Configurar">
            <Button type='link' icon={<SettingFilled style={{ color: '#000' }} />} onClick={() => handleOpen(row, 'config')}>
            </Button>
          </Tooltip>
        }
        <Tooltip title="Editar">
          <Button type='link' icon={<EditFilled style={{ color: '#000' }} />} onClick={() => handleOpen(row, 'edit')}>
          </Button>
        </Tooltip>
        {
          window.location.pathname === '/usuarios' &&
          <Tooltip title="Ver Reportes Asignados">
          <Button type='link' icon={<SolutionOutlined style={{ color: '#000' }} />} onClick={() => { setDataRow(row); setOpenReports(true) }}>
          </Button>
        </Tooltip>
        }
      </div>
    ),
  }

  // const perf = getPerformance()
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(state => state.session)
  const [openReports, setOpenReports] = useState(false)

  const [dataRow, setDataRow] = useState({});
  const [option, setOption] = useState('');
  const [open, setOpen] = useState(false);

  const [totalData, settotalData] = useState(0)

  const [pageSize, setpageSize] = useState(10)
  const [order, setorder] = useState({
    order: '',
    key: undefined
  })

  const getUsers = useCallback(() => {
    setLoading(true)
    apiUsersPagination(pathname, page, pageSize, order.order, order.key).then(res => {
      console.log(res.data)
      settotalData(res.data.amount)
      setData(res.data.user)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [page, pageSize, order.key, order.order, filter])

  const getUsersSearch = useCallback(() => {
    setLoading(true)
    apiUsersPaginationSearch(pathname, page, pageSize, order.order, order.key, filter).then(res => {
      console.log(res.data)
      settotalData(res.data.amount)
      setData(res.data.user)
    }).catch(err => {
      new Error(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [page, pageSize, order.key, order.order, filter])


  const handleOpen = (data, option) => {
    setDataRow(data);
    setOption(option);
    setOpen(true);
  }

  const handleCloseReport = () => {
    setOpenReports(false)
    setOption('');
    setDataRow({});
  }

  const handleClose = () => {
    setOpenReports(false)
    setOpen(false);
    setOption('');
    setDataRow({});
    setupdate(prev => !prev)
  }

  const handleTable = (pagination, filter, sorter) => {
    setpage(pagination.current)
    setpageSize(pagination.pageSize)
    setorder(prev => ({ ...prev, key: sorter.columnKey, order: sorter.order ? sorter.order.substring(0, 3) : null }))
  }

  useEffect(() => {
    if (filter) {
      getUsersSearch()
    } else {
      getUsers()
    }
  }, [update, page, pageSize, order.order, order.key])

  return (
    <>
      <div style={{ paddingTop: 30 }}>
        <Table
          onChange={handleTable}
          rowKey={record => record.id}
          loading={loading}
          columns={
            (window.location.pathname === '/usuarios' && isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN))
              ? ([...columns, ...services, actionsTable])
              : (window.location.pathname === '/ciudadanos') && (isAllowed(user, ROLES_DIVISIONS.PURE_ADMIN))
                ? ([...columns, actionsTable])
                : isAllowed(user, ROLES_DIVISIONS.SCHOLARSHIP_ADMIN)
                  ? ([...columns, actionsTable])
                  : columns}
          dataSource={data}
          size="small"
          scroll={{ y: '60vh', x: '100%' }}
          pagination={{ total: totalData, current: page, defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
        />
      </div>

      <Modal
        centered
        visible={openReports}
        closable={false}
        footer={null}
        destroyOnClose
        onCancel={handleCloseReport}
        width={'auto'}
      >
        <ReportsAssigned data={dataRow} onClose={handleCloseReport} />
      </Modal>
      {
        dataRow && option &&
        <Modal
          centered
          visible={open}
          closable={false}
          footer={null}
          onCancel={handleClose}
          title={
            <div style={{ display: 'flex', flexDirection: 'row', }}>
              {option === 'edit' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Editar {pathname.slice(1, pathname.length - 1)}</Typography>}
              {option === 'delete' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Eliminar {pathname.slice(1, pathname.length - 1)}</Typography>}
              {option === 'config' && <Typography style={{ width: '95%', marginLeft: '5%', textAlign: 'center', }} variant="h6">Configurar {pathname.slice(1, pathname.length - 1)}</Typography>}
              <CloseCircleOutlined onClick={handleClose} style={{ color: 'red', marginLeft: 'auto' }} />
            </div>
          }
        >
          {option === 'edit' && <EditUser data={dataRow} setRefresh={setRefresh} refresh={refresh} />}
          {option === 'config' &&
            <>
              <UserConfig dataRow={dataRow} handleClose={handleClose} setRefresh={setRefresh} refresh={refresh} />
            </>
          }
        </Modal>
      }
    </>
  );
}
export default UsersTable;