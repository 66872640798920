import React, { useRef, useState } from 'react';
import * as yup from 'yup'
import { Input, Button, Modal, Typography, Select } from 'antd'
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { ErrorMessage, Formik } from 'formik';
import styled from "styled-components";
// import { TermsConditions } from '../TermsConditions';
import { apiBudgetFindUser, apiCreateUserProject, apiGetColonysVotes, apiGetPostcode, apiUpdateUserProject } from '../../../../utils/api';
import { ResponseForm } from './ResponseForm';
import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { openMessage } from '../../Utils/MessageDisplay';

export const ErrorMessageForm = styled.div`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;

`

export const ErrorMessageField = styled(ErrorMessage)`
    font-size: 0.625rem;
    position: absolute;
    color: red;
    font-size: 11px;
    bottom: -17px;
`
//Función para validar una CURP
function curpValida(curp) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);

    if (!validado)  //Coincide con el formato general?
        return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1]))
        return false;

    return true; //Validado
}

const formSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
    address: yup.string().required('La dirección es requerida'),
    curp: yup.string()
        .required('La CURP es requerida')
        .test('Valida', "Formato de CURP no valido", value => curpValida(value)),
    // .matches(/^[A-Z]{4}\d{2}(1|0)\d(0|1|2|3)\d(H|M)[A-Z]{5}\d{2}$/, 'Formato de CURP Inválido')
    // .min(18, 'Formato de CURP Inválido')
    // .max(18, 'Formato de CURP Inválido').required('La CURP es requerida'),
    postCode: yup
        .string()
        .matches(/^[0-9]+$/, "Ingrese solo numeros")
        .min(5, 'Formato de código postal Inválido')
        .max(5, 'Formato de código postal Inválido').required('El código postal es requerido'),
});

export const Register = ({ getUserData }) => {

    const { Text } = Typography

    const { width, height } = useScreenSize()
    const inputRef = useRef(null);
    const [loading, setloading] = useState(false)
    const [userData, setuserData] = useState(null)
    const [userExist, setuserExist] = useState(true)
    const [searched, setsearched] = useState(false)
    const [error, seterror] = useState({
        curp: false,
        postCode: false
    })
    const [modal, setmodal] = useState({
        isOpen: false,
        option: ''
    })
    const [response, setresponse] = useState({
        exist: false,
        success: false,
        error: false
    })

    const handleSubmit = (values) => {
        const { name, address, curp, postCode } = values

        setloading(true)
        apiGetPostcode(postCode).then(res => {

            apiCreateUserProject({ name, address, curp: curp.toUpperCase(), idPostcode: res.data.id, idColonia: colony }).then(res => {
                setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                setresponse(prev => ({ ...prev, success: true, error: false }))
                sessionStorage.setItem("userVotes", JSON.stringify(res.data))
                console.log(res)
            }).catch(err => {
                setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                setresponse(prev => ({ ...prev, success: false, error: true, exist: false }))
                new Error(err)
            }).finally(() => {
                setloading(false)
            })

        }).catch(err => {
            if (err.response.status === 404) {
                seterror(prev => ({ ...prev, postCode: true }))
            } else if (err.response.status === 404) {
                seterror(prev => ({ ...prev, curp: true }))
            } else {
                setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                setresponse(prev => ({ ...prev, success: false, error: true, exist: false }))
            }
            setloading(false)
            new Error(err)
        })

    }

    const handleSubmitUpdate = (values) => {
        const { name, address, curp, postCode } = values

        console.log(name, address, curp, postCode)

        if (name !== userData.name || address !== userData.address || postCode !== userData.postcode.postcode || colony !== userData.idColonia) {
            setloading(true)
            apiGetPostcode(postCode).then(res => {

                apiUpdateUserProject({ name, address, curp: curp.toUpperCase(), idPostcode: res.data.id, idColonia: colony }).then(res => {
                    sessionStorage.setItem("userVotes", JSON.stringify(res.data))
                    setresponse(prev => ({ ...prev, success: false, error: false, exist: false }))
                    setmodal(prev => ({ ...prev, isOpen: false, option: '' }))
                    getUserData()
                }).catch(err => {
                    setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                    setresponse(prev => ({ ...prev, success: false, error: true, exist: false }))
                    new Error(err)
                }).finally(() => {
                    setloading(false)
                })

            }).catch(err => {
                if (err.response.status === 404) {
                    seterror(prev => ({ ...prev, postCode: true }))
                } else if (err.response.status === 404) {
                    seterror(prev => ({ ...prev, curp: true }))
                } else {
                    setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                    setresponse(prev => ({ ...prev, success: false, error: true, exist: false }))
                }
                setloading(false)
                new Error(err)
            })

        } else {
            sessionStorage.setItem("userVotes", JSON.stringify(userData))
            setresponse(prev => ({ ...prev, success: false, error: false, exist: false }))
            setmodal(prev => ({ ...prev, isOpen: false, option: '' }))
            getUserData()
        }

    }

    const handleCloseModal = () => {
        getUserData()
        // sessionStorage.setItem('userVotes', JSON.stringify({name, address, curp, postCode}))
        setresponse(prev => ({ ...prev, success: false, error: false, exist: false }))
        setmodal(prev => ({ ...prev, isOpen: false, option: '' }))
    }

    const searchUser = (userCurp, setValues) => {
        setsearched(true)
        apiBudgetFindUser(userCurp).then((response) => {
            if (response.status === 204) {
                setuserExist(false)
            } else {
                setuserData(response.data)
                setValues(prev => ({
                    ...prev,
                    name: response.data.name,
                    address: response.data.address,
                    postCode: response.data.postcode.postcode
                }))
                getColonys(response.data.postcode.postcode, response.data.idColonia)
                setuserExist(true)
                // sessionStorage.setItem('userVotes', JSON.stringify(response.data))
                // setuserExist(true)
                // setmodal(prev => ({ ...prev, isOpen: true, option: 'response' }))
                // setresponse(prev => ({ ...prev, success: false, error: false, exist: true }))
            }
        }).catch(err => {
            openMessage({ type: 'error', message: 'Ha ocurrido un error al buscar el usuario intente nuevamente' })
        })
    }

    const [colonys, setcolonys] = useState([])
    const [colony, setcolony] = useState('')
    const [loadingColonys, setloadingColonys] = useState(false)

    const getColonys = (postCode, colonyId) => {
        setloadingColonys(true)
        apiGetColonysVotes(postCode).then(res => {
            if (res.data.length > 0) {
                setcolonys(res.data)

                if (colonyId) {
                    setcolony(colonyId)
                }
            } else {
                seterror(prev => ({ ...prev, postCode: true }))

            }
        }).catch(err => {
            new Error(err)
            seterror(prev => ({ ...prev, postCode: true }))
        }).finally(() => {
            setloadingColonys(false)
        })

    }

    const handleChangeColony = (value) => {
        setcolony(value)
    }

    return (
        <div style={classes.main} >
            <div style={classes.image}></div>
            <div style={{ ...classes.root, width: width < 600 ? '90vw' : width < 1000 ? '80vh' : '800px' }}>
                <form style={{ width: '100%' }}>
                    <div>
                        <div style={{ fontSize: '1.5rem', fontWeight: '700', textAlign: 'center', marginBottom: '.5rem' }}>
                            Registro para presupuesto participativo
                        </div>
                    </div>
                    <Formik
                        validationSchema={formSchema}
                        onSubmit={handleSubmit}
                        initialValues={{
                            name: "",
                            address: "",
                            curp: "",
                            postCode: ""
                        }}
                    >
                        {({ handleSubmit, handleChange, isSubmitting, handleBlur, values, setErrors, errors, isValid, touched, resetForm, setValues }) => (
                            <section style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ display: 'flex', flexDirection: (height > 700 || width < 600) ? 'column' : 'row', gap: '1rem', width: '100%' }}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>CURP *</label>

                                        <div style={{ display: 'flex' }}>

                                            <Input
                                                disabled={searched}
                                                id='curp'
                                                name='curp'
                                                onChange={e => {
                                                    if (e.target.value.length > 18) return

                                                    e.target.value = e.target.value.toUpperCase()
                                                    seterror(prev => ({ ...prev, curp: false }))
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                                value={values.curp}
                                                style={{ height: 30, borderRadius: '15px 0 0 15px' }}
                                                ref={inputRef}
                                                size='small'
                                                status={(errors.curp || error.curp) && 'error'}
                                                max={10}
                                                placeholder='Ingrese su CURP'
                                                onPressEnter={() => searchUser(values.curp, setValues)}
                                            />
                                            {
                                                !searched
                                                    ? <Button disabled={errors.curp || !values.curp} onClick={() => searchUser(values.curp, setValues)} icon={<SearchOutlined />} style={{ borderRadius: '0 15px 15px 0', height: 30 }} />
                                                    : <Button onClick={() => {
                                                        setErrors(prev => ({ ...prev, name: false, address: false, postCode: false, }))
                                                        setsearched(false)
                                                        resetForm()
                                                        setcolony('')
                                                        setcolonys([])
                                                    }} icon={<CloseOutlined />} style={{ borderRadius: '0 15px 15px 0', height: 30 }} />

                                            }
                                        </div>

                                        {
                                            error.curp
                                                ?
                                                <ErrorMessageForm>
                                                    Ya existe un usuario con esta CURP
                                                </ErrorMessageForm>
                                                :
                                                <ErrorMessageField name="curp" component="div" />
                                        }
                                    </div>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Nombre *</label>
                                        <Input
                                            disabled={!searched}
                                            id='name'
                                            name='name'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            style={{ height: 30 }}
                                            size='small'
                                            status={errors.name && 'error'}
                                            placeholder='Ingrese su nombre'
                                        />
                                        <ErrorMessageField name="name" component="div" />
                                    </div>
                                </div>


                                <div style={{ display: 'flex', flexDirection: (height > 700 || width < 600) ? 'column' : 'row', gap: '1rem', width: '100%', marginBottom: '0.5rem' }}>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Dirección *</label>
                                        <Input
                                            disabled={!searched}
                                            id='address'
                                            name='address'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address}
                                            style={{ height: 30 }}
                                            ref={inputRef}
                                            size='small'
                                            status={errors.address && 'error'}
                                            placeholder='Ingrese su Dirección'
                                        />
                                        <ErrorMessageField name="address" component="div" />
                                    </div>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Código Postal *</label>
                                        <div style={{ display: 'flex' }}>
                                            <Input
                                                disabled={!searched}
                                                id='postCode'
                                                name='postCode'
                                                onChange={e => {
                                                    if (!e.target.value.match(/^[0-9]+$/) && e.target.value !== '') return
                                                    setcolonys([])
                                                    setcolony('')
                                                    seterror(prev => ({ ...prev, postCode: false }))
                                                    handleChange(e)
                                                }}
                                                onBlur={handleBlur}
                                                value={values.postCode}
                                                style={{ height: 30, borderRadius: '15px 0 0 15px' }}
                                                size='small'
                                                status={(errors.postCode || error.postCode) && 'error'}
                                                placeholder='Ingrese su Código Postal'
                                                onPressEnter={() => getColonys(values.postCode)}
                                            />
                                            <Button disabled={errors.postCode || !values.postCode} onClick={() => getColonys(values.postCode)} icon={loadingColonys ? <LoadingOutlined /> : <SearchOutlined />} style={{ borderRadius: '0 15px 15px 0', height: 30 }} />
                                        </div>

                                        {
                                            error.postCode
                                                ?
                                                <ErrorMessageForm>
                                                    Este código postal no está registrado
                                                </ErrorMessageForm>
                                                :
                                                <ErrorMessageField name="postCode" component="div" />
                                        }
                                    </div>
                                    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <label style={{ fontSize: '0.8rem', opacity: 0.6 }}>Colonia *</label>
                                        <Select
                                            disabled={colonys.length <= 0}
                                            onChange={handleChangeColony}
                                            value={colony}
                                        >
                                            {
                                                colonys.map((item) => (
                                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>

                                <div style={classes.buttons} >
                                    <Text style={classes.infoText} >Verifique que su colonia sea correcta, ya que se mostrarán solo proyectos de su zona.</Text>

                                    {
                                        (searched && userExist)
                                            ?
                                            <Button loading={loading} disabled={!colony || !searched || !isValid || error.postCode || error.curp} style={{ ...classes.button, width: 'auto', marginTop: '1rem' }} type='primary' size='small' onClick={() => handleSubmitUpdate(values)}>
                                                Continuar con la votación
                                            </Button>
                                            :
                                            <Button loading={loading} disabled={!colony || !searched || !isValid || error.postCode || error.curp} style={{ ...classes.button, width: 'auto', marginTop: '1rem' }} type='primary' size='small' onClick={() => handleSubmit(values)}>
                                                Registrarse
                                            </Button>
                                    }
                                </div>
                            </section>
                        )}
                    </Formik>
                </form>
            </div>
            <Modal
                visible={modal.isOpen}
                footer={null}
                onCancel={handleCloseModal}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 30 }}
            >

                <ResponseForm response={response} closeSuccess={handleCloseModal} close={handleCloseModal} />
            </Modal>
        </div>
    );
}

const classes = {
    button: {
        textTransform: 'capitalize',
        width: '50%',
        height: '35px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    main: {
        display: 'flex',
        height: '100%',
        // background: '#222',
        // width: '60vw',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: 'red'
    },
    root: {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        height: 'auto',
        overflowY: 'auto',
        background: '#fafafa',
        borderRadius: '3%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
        padding: '2rem 3rem',
    },
    logo: {
        width: 100,
    },
    buttons: {
        display: 'flex',
        gap: '2rem',
        marginTop: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
    a: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginBottom: 20
    },
    btnFacebook: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
        },
    },
    infoText: {
        fontSize: 11,
        color: '#00000070',
        textAlign: 'center',

    },
}

