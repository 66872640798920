import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RequestPassword } from './RequestPassword'
import { Typography, Button, Avatar, Modal, Spin, Tooltip } from 'antd'
import { EditOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { RequesData } from './RequestData'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { EditPersonalData } from './EditPersonalData'
const { Title } = Typography

export const ProfilePage = () => {

    const { user } = useSelector(state => state.session)
    const [open, setopen] = useState({
        isOpen: false,
        type: '',
    })
    const { width } = useScreenSize()
    const [loading, setloading] = useState(false)

    // const getData = useCallback(() => {
    //     setloading(true)
    //     getDataProfile(user.id).then(response => {
    //         setuserData(response.data)
    //         setloading(false)
    //     }).catch(error => {
    //         setloading(false)
    //         console.log(error)
    //     })
    // }, [user])

    // useEffect(() => {
    //     getData()
    // }, [getData])

    return (
        <Spin spinning={loading}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <section style={{ width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button type='primary' onClick={() => setopen({
                        isOpen: true,
                        type: 'password'
                    })}>
                        Cambiar contraseña
                    </Button>
                </section>
                <div style={{ padding: 10, marginTop: 20, width: '80%', backgroundColor: '#FFF', borderRadius: 20 }}>
                    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Avatar size={84} icon={<UserOutlined />} />
                        {
                            (user) &&
                            <>
                                {
                                    user.roles &&
                                    <Title level={3} style={{ textAlign: 'center' }}>{user.roles[0].description}</Title>
                                }
                                <Title level={4} style={{ marginTop: 0, width: '100%', textAlign: 'center' }}>{user.name ? user.name : ''} {user.lastName ? user.lastName : ''}<EditOutlined onClick={() => setopen(prev => ({ ...prev, isOpen: true, type: 'personal' }))} /></Title>
                            </>
                        }
                        <section style={{ display: 'flex', flexDirection: width < 750 ? 'column' : 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginTop: 30 }}>
                            {
                                user.phone &&
                                <>
                                    <div style={{ width: width < 750 ? '100%' : '50%', marginTop: width < 750 ? 20 : 0 }}>
                                        <Title level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center', overflowWrap: 'break-word', cursor: 'pointer' }}>Numero de teléfono</Title>
                                        <Tooltip title='Solicitar cambio de numero'>
                                            <Title onClick={() => setopen({ isOpen: true, type: 'phone' })} level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center', overflowWrap: 'break-word', cursor: 'pointer', fontWeight: 'normal' }}>{user.phone} <EditOutlined /></Title>
                                        </Tooltip>
                                    </div>
                                </>
                            }
                            {
                                user.email &&
                                <div style={{ width: width < 750 ? '100%' : '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Title level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center', overflowWrap: 'break-word', cursor: 'pointer' }}>Correo electrónico</Title>
                                    {
                                        user.email
                                            ?
                                            <Tooltip title='Solicitar cambio de correo'>
                                                <Title onClick={() => setopen({ isOpen: true, type: 'email' })} level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center', overflowWrap: 'break-word', cursor: 'pointer', fontWeight: 'normal' }}>{user.email} <EditOutlined /></Title>
                                            </Tooltip>
                                            :
                                            <Button onClick={() => setopen({ isOpen: true, type: 'addemail' })} type='link'>Agregar correo electrónico <PlusCircleOutlined /></Button>
                                    }
                                </div>}
                        </section>
                        {
                            user.govDependency &&
                            <>
                                <div style={{ width: '85%', height: 2, backgroundColor: 'rgba(0,0,0,0.2)', margin: '30px 0 30px 0' }}></div>
                                <Title level={4} style={{ marginTop: 0, width: '100%', textAlign: 'center' }}>Dependencia</Title>
                                <Title level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center' }}>{user.govDependency.name}</Title>
                            </>
                        }
                        {
                            (user.service.length > 0) &&
                            <Title level={5} style={{ marginTop: 0, width: '100%', textAlign: 'center' }}>( {user.service.map(i => i.name).join(', ')} )</Title>
                        }
                    </section>
                </div>

                {
                    open.type &&
                    <Modal
                        onCancel={() => setopen(prev => ({ ...prev, isOpen: false, type: '' }))}
                        visible={open.isOpen}
                        closable={false}
                        footer={null}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 30 }}
                    >
                        <div style={{ width: '100%' }}>
                            {
                                open.type === 'password' &&
                                <RequestPassword setOpen={setopen} />
                            }
                            {
                                (open.type === 'phone' || open.type.includes('email')) &&
                                <RequesData type={open.type} setopen={setopen} />
                            }
                            {
                                (open.type === 'personal') &&
                                <EditPersonalData user={user} setopen={setopen} />
                            }
                        </div>

                    </Modal>
                }
            </div>
        </Spin>

    )
}
