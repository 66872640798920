import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { logo, ColorBackground } from '../themeConfig';

const antIcon = <LoadingOutlined style={{ fontSize: 54, color: '#FFF', marginTop: 15 }} spin />;
export const LoadingPage = () => {
    return (
        <div
            style={styles.container}>
            {
                logo &&
                <img
                    src={logo}
                    alt="logo"
                    style={styles.logo} />
            }
            <Spin indicator={antIcon}/>
        </div>
    )
}

const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: ColorBackground,
        flexDirection: 'column'
    },
    logo: {
        width: 100,
    }
}