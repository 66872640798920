import { Row, Col, Typography, Table, Tooltip } from "antd"
import { prMainLight } from "../../../../themeConfig"


const ScholarshipSE = ({ details }) => {
  const { Text } = Typography
  const columns = [
    {
      title: 'Familiares',
      width: '20%',
      sorter: (a, b) => a.id - b.id,
      render: row => (
        <Tooltip title={`${row.nameFamilyMember} ${row.firstLastNameFamilyMember ? row.firstLastNameFamilyMember : ''} ${row.secondLastNameFamilyMember ? row.secondLastNameFamilyMember : ''}`}>
          {row.nameFamilyMember} {row.firstLastNameFamilyMember ? row.firstLastNameFamilyMember : ''} {row.secondLastNameFamilyMember ? row.secondLastNameFamilyMember : ''}
        </Tooltip>
      )
    },
    {
      title: 'Parentesco',
      dataIndex: 'relationship',
      key: 'relationship',
      width: '20%',
      sorter: (a, b) => a.id - b.id,
      render: relationship => (
        <Tooltip title={relationship}>
          {relationship}
        </Tooltip>
      )
    },
    {
      title: 'Ocupación',
      dataIndex: 'employment',
      key: 'employment',
      width: '20%',
      sorter: (a, b) => a.id - b.id,
      render: employment => (
        <Tooltip title={employment}>
          {employment}
        </Tooltip>
      )
    },
    {
      title: 'Lugar de trabajo',
      dataIndex: 'placeWorker',
      key: 'placeWorker',
      width: '20%',
      sorter: (a, b) => a.id - b.id,
      render: placeWorker => (
        <Tooltip title={placeWorker}>
          {placeWorker}
        </Tooltip>
      )
    },
    {
      title: 'Salario mensual',
      dataIndex: 'monthlySalary',
      key: 'monthlySalary',
      width: '20%',
      sorter: (a, b) => a.id - b.id,
      render: monthlySalary => (
        <Tooltip title={monthlySalary}>
          {monthlySalary}
        </Tooltip>
      )
    },
  ]

  const filterData = details.familyMembersList
  //     [{Familiares: 'Mauricio Rios', Parentesco: 'Ninguno', Ocupacion: 'Desarrollador', Lugartrabajo: 'MVP', Salario: '123123123'}, 
  //                         {Familiares: 'Mauricio Rios', Parentesco: 'Ninguno', Ocupacion: 'Desarrollador', Lugartrabajo: 'MVP', Salario: '123123123'},
  //                         {Familiares: 'Mauricio Rios', Parentesco: 'Ninguno', Ocupacion: 'Desarrollador', Lugartrabajo: 'MVP', Salario: '123123123'}
  // ]

  return (
    <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '4vh' }}>

      <Table
        rowKey={record => `family_id_${record.id}`}
        //   loading={loading}
        columns={columns}
        dataSource={filterData}
        size="small"
      />


      <Row>
        <Col span={11}>
          <Row>
            <Col style={colHeader} span={8}>Tipo vivienda</Col>
            <Col style={colStyle} span={16}>{details.typeLivingPlace}</Col>
            <Col style={colHeader} span={8}>Techo</Col>
            <Col style={colStyle} span={16}>{details.roof}</Col>
            <Col style={colHeader} span={8}>Paredes</Col>
            <Col style={colStyle} span={16}>{details.walls}</Col>
            <Col style={colHeader} span={8}>Pisos</Col>
            <Col style={colStyle} span={16}>{details.floors}</Col>
          </Row>
        </Col>
        <Col span={11} offset={2}>
          <Row>
            <Col style={colHeader} span={8}>N. Habitaciones</Col>
            <Col style={colStyle} span={16}>{details.roomsNumber}</Col>
            <Col style={colHeader} span={8}>Num. Ba&ntilde;os</Col>
            <Col style={colStyle} span={16}>{details.bathroomsNumber}</Col>
            <Col style={colHeader} span={8}>Patio</Col>
            <Col style={colStyle} span={16}>{details.backYard}</Col>
            <Col style={colHeader} span={8}>Total</Col>
            <Col style={colStyle} span={16}>{details.total}</Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Row>
            <Tooltip title="Servicio en el hogar">
                <Col style={colHeader} span={8}>Seguro médico</Col>
            </Tooltip>
            <Col style={colStyle} span={16}>{details.medicalServices}</Col>
          </Row>
        </Col>
        <Col span={11} offset={2}>
          <Row>
            <Tooltip title="Dependientes económicos">
                <Col style={colHeader} span={8}>Dependientes</Col>
            </Tooltip>
            <Col style={colStyle} span={16}>{details.dependents ? details.dependents : ""}</Col>
          </Row>
        </Col>
      </Row>

      <Text style={titleStyle}>Gastos en el hogar por mes</Text>

      <Row style={{ marginTop: '2vh' }}>
        <Col span={11}>
          <Row>
            <Col style={colHeader} span={8}>Renta</Col>
            <Col style={colStyle} span={16}>{details.rent}</Col>
            <Col style={colHeader} span={8}>Luz</Col>
            <Col style={colStyle} span={16}>{details.light}</Col>
            <Col style={colHeader} span={8}>Gas</Col>
            <Col style={colStyle} span={16}>{details.gas}</Col>
            <Col style={colHeader} span={8}>Transporte</Col>
            <Col style={colStyle} span={16}>{details.transport}</Col>
            <Col style={colHeader} span={8}>Deudas</Col>
            <Col style={colStyle} span={16}>{details.debt}</Col>
          </Row>
        </Col>
        <Col span={11} offset={2}>
          <Row>
            <Col style={colHeader} span={8}>Agua</Col>
            <Col style={colStyle} span={16}>{details.water}</Col>
            <Col style={colHeader} span={8}>Teléfono</Col>
            <Col style={colStyle} span={16}>{details.billsPhone}</Col>
            <Col style={colHeader} span={8}>Alimentación</Col>
            <Col style={colStyle} span={16}>{details.feed}</Col>
            <Col style={colHeader} span={8}>Escolares</Col>
            <Col style={colStyle} span={16}>{details.schoolFees}</Col>
            <Col style={colHeader} span={8}>Otros</Col>
            <Col style={colStyle} span={16}>{details.other}</Col>
          </Row>
        </Col>
      </Row>

      {/* <Row>
               <Col span={24}>
                    <Row>
                        <Col style = {colHeader}  span={4}>Observaciones:</Col>
                        <Col style = {colStyle}  span={20}>''</Col>
                    </Row>
               </Col>
           </Row> */}


    </div>
  )

}

//styles 
const titleStyle = {
  fontSize: '18px',
}

const colHeader = {
  border: 'solid 0px #000',
  height: '30px',
  marginBottom: '15px',
  backgroundColor: prMainLight,
  borderRadius: '100px 0px 0px 100px',
  textAlign: 'center',
  paddingTop: '4px',
  color: '#fff'
}

const colStyle = {
  border: `solid 1px ${prMainLight}`,
  height: '30px',
  marginBottom: '15px',
  // backgroundColor: prMainLight,
  borderRadius: '0px 100px 100px 0px',
  paddingLeft: '15px',
  paddingTop: '4px',

}


export default ScholarshipSE