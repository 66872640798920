import React, { useState } from 'react'
import { forgotPassword } from '../../utils/api'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Input, Button, Typography, Form, Result } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const ForgotPassword = ({ setOpen }) => {

    const history = useHistory()
    const [email, setemail] = useState('')
    const [checked, setchecked] = useState(false)
    const [loading, setloading] = useState(false)
    const [failed, setFailed] = useState({
        notFound: false,
        format: false
    })
    const [error, seterror] = useState({
        isFail: false,
        message: ''
    })
    // const [notFound, setnotFound] = useState(false)
    // const [format, setformat] = useState(false)

    const handleEmailChange = (event) => {
        // setnotFound(false)
        // setformat(false)
        setFailed(prev => ({ ...prev, notFound: false, format: false }))
        setemail(event.target.value);
        const regexEmail =
            /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
        const regexPhone = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/
        if (regexEmail.test(event.target.value) || regexPhone.test(event.target.value)){
            // setformat(false)
            setFailed(prev => ({ ...prev, format: false }))
        } else {
            // setformat(true)
            setFailed(prev => ({ ...prev, format: true }))

        }
    }

    const handleSubmit = () => {
        setloading(true)
        forgotPassword(email)
            .then(response => {
                if (response.status === 200) {
                    setemail('')
                    setchecked(true)
                } else {
                    console.log(response.data)
                }
            }).catch(error => {
                // setnotFound(true)
                if(error.response.status === 404){
                    setFailed(prev => ({ ...prev, notFound: true }))
                }
                if(error.response.status === 409){
                    seterror(prev => ({...prev, isFail: true, message: error.response.data}))
                }
                console.log(error)
            }).finally(() => {
                setloading(false)
            })

    }

    return (
        <div style={{ height: 'auto', backgroundColor: '#FFF', padding: '30px 40px' }}>
            {
                error.isFail &&
                <Result
                    status='error'
                    title='Error en la petición'
                    subTitle={error.message}
                    extra={[
                    <Button 
                        key='close'
                        onClick={() => {
                            setOpen(false)
                            seterror(prev => ({...prev, isFail: false, message: ''}))
                            setemail('')
                        }}
                        type='primary'
                    >
                        Cerrar
                    </Button>
                ]}
                />
            }
            {
                !error.isFail &&
            <>
            {
                checked
                ? (<>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <CheckCircleOutlined style={{ fontSize: 70, marginBottom: 15 }} />
                            <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: 10 }} >Solicitud para recuperar contraseña realizada correctamente</Typography.Title>
                            <Typography.Text style={{ textAlign: 'center', marginBottom: 10 }}>Revise su correo electrónico o numero de celular ingresado y siga las instrucciones para restablecer su contraseña</Typography.Text>
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                                <Button type='primary' size='large' onClick={() => {
                                    history.push('/r')
                                    setOpen(false)
                                    setchecked(false)
                                }} >Cerrar</Button>
                            </div>
                        </div>
                    </>)
                    : (<>
                        <Typography.Title level={4} style={{ textAlign: 'center', marginBottom: 30 }}>Recuperar Contraseña</Typography.Title>

                        <Form.Item
                            validateStatus={(failed.notFound || failed.format) ? 'error' : ''}
                            help={
                                (failed.notFound && 'El correo electrónico o número de celular no se encuentra registrado.') ||
                                ((!failed.notFound && !failed.format) && 'Por favor ingrese un correo electrónico o número de celular registrado.') ||
                                ((failed.format) && 'Por favor ingrese un correo electrónico o número de celular registrado (formato incorrecto).')
                            }
                            style={{width: '100%'}}
                        >

                            <Input
                                style={{ minWidth: 100, maxWidth: 500 }}
                                autoFocus
                                size='large'
                                value={email}
                                onChange={handleEmailChange}
                                placeholder='Correo Electrónico o Numero de Celular'
                                />
                        </Form.Item>
                        <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
                            <Button loading={loading} type='primary' size='large' onClick={handleSubmit} disabled={!email || failed.format || failed.notFound}>Continuar</Button>
                        </div>
                    </>)
            }
            </>
            }
        </div>
    )
}
